import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useCommon, useCommonSia } from '../../hooks';

export function FiltrosSia({ filtros, responseForm }) {
    const [listEmpleadosComponente, setListEmpleadoComponente] = useState(null);

    const { getSkyViewEmpleados, getCuenta, listCuentas } = useCommon();
    const { getTiposComponentes, listTiposComponentes, getEstados, listEstados, getTiposPrestamos, listTiposPrestamos, getMarcas, listMarcas, getUbicaciones, listUbicaciones } = useCommonSia();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: (formValue) => {
            responseForm(formValue);
        }
    });

    useEffect(() => {
        (async () => {
            await getCuenta();
            await getEstados();
            await getTiposPrestamos();
            await getTiposComponentes();
            await getMarcas();
            await getUbicaciones();
            const response_empleados = await getSkyViewEmpleados();
            let format_empleados = [];

            for (const empleado of response_empleados) {
                format_empleados.push({
                    name: `${empleado.Badge} - ${empleado.NombreEmpleado} - ${empleado.Cuenta}`,
                    value: empleado.id
                });
            }

            setListEmpleadoComponente(format_empleados);
        })();
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid my-3">
                {filtros?.FechaSalida && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Calendar value={formik.values.FechaSalida} onChange={(e) => formik.setFieldValue("FechaSalida", e.value)} id="FechaSalida" name="FechaSalida" className="w-full" />
                            <label htmlFor="FechaSalida">Fecha de Salida</label>
                        </span>
                    </div>
                )}
                {filtros?.FechaEntrega && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Calendar value={formik.values.FechaEntrega} onChange={(e) => formik.setFieldValue("FechaEntrega", e.value)} id="FechaEntrega" name="FechaEntrega" className="w-full" />
                            <label htmlFor="FechaEntrega">Fecha de Entrega</label>
                        </span>
                    </div>
                )}
                {filtros?.FechaCompra && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Calendar value={formik.values.FechaCompra} onChange={(e) => formik.setFieldValue("FechaCompra", e.value)} id="FechaCompra" name="FechaCompra" className="w-full" />
                            <label htmlFor="FechaCompra">Fecha de Compra</label>
                        </span>
                    </div>
                )}
                {filtros?.Empleado && (
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <Dropdown filter className="w-full" id="Empleado" name="Empleado" value={formik.values.Empleado} onChange={(e) => formik.setFieldValue('Empleado', e.value)} options={listEmpleadosComponente} optionLabel="name" />
                            <label htmlFor="Empleado">Empleado</label>
                        </span>
                    </div>
                )}
                {filtros?.Cuenta && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown className="w-full" filter id="Cuenta" name="Cuenta" value={formik.values.Cuenta} onChange={(e) => formik.setFieldValue('Cuenta', e.value)} options={listCuentas} optionLabel="name" />
                            <label htmlFor="Cuenta">Cuenta</label>
                        </span>
                    </div>
                )}
                {filtros?.Marca && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown className="w-full" filter id="Marca" name="Marca" value={formik.values.Marca} onChange={(e) => formik.setFieldValue('Marca', e.value)} options={listMarcas} optionLabel="name" />
                            <label htmlFor="Marca">Marca</label>
                        </span>
                    </div>
                )}
                {filtros?.Ubicacion && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown className="w-full" filter id="Ubicacion" name="Ubicacion" value={formik.values.Ubicacion} onChange={(e) => formik.setFieldValue('Ubicacion', e.value)} options={listUbicaciones} optionLabel="name" />
                            <label htmlFor="Ubicacion">Ubicaciones</label>
                        </span>
                    </div>
                )}
                {filtros?.EstadoPrestamo && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown
                                className="w-full"
                                filter
                                id="EstadoPrestamo"
                                name="EstadoPrestamo"
                                value={formik.values.EstadoPrestamo}
                                onChange={(e) => formik.setFieldValue('EstadoPrestamo', e.value)}
                                options={listTiposPrestamos}
                                optionLabel="name"
                            />
                            <label>Estado de Prestamo</label>
                        </span>
                    </div>
                )}
                {filtros?.EstadoComponente && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown
                                className="w-full"
                                filter
                                id="EstadoComponente"
                                name="EstadoComponente"
                                value={formik.values.EstadoComponente}
                                onChange={(e) => formik.setFieldValue('EstadoComponente', e.value)}
                                options={listEstados}
                                optionLabel="name"
                            />
                            <label>Estado de Componente</label>
                        </span>
                    </div>
                )}
                {filtros?.TipoComponente && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown
                                className="w-full"
                                filter
                                id="TipoComponente"
                                name="TipoComponente"
                                value={formik.values.TipoComponente}
                                onChange={(e) => formik.setFieldValue('TipoComponente', e.value)}
                                options={listTiposComponentes}
                                optionLabel="name"
                            />
                            <label>Tipo de Componente</label>
                        </span>
                    </div>
                )}
            </div>
            <div className="grid">
                <div className="col-12 text-right">
                    <Button type="submit" severity="success">
                        Aplicar Filtros
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        FechaSalida: '',
        FechaEntrega: '',
        FechaCompra: '',
        Empleado: '',
        Cuenta: '',
        EstadoPrestamo: '',
        TipoComponente: '',
        EstadoComponente: '',
        Marca: "",
        Ubicacion: "",
    };
}

function validationSchema() {
    return {
        FechaSalida: Yup.string(),
        FechaEntrega: Yup.string(),
        FechaCompra: Yup.string(),
        Empleado: Yup.string(),
        Cuenta: Yup.string(),
        EstadoPrestamo: Yup.string(),
        TipoComponente: Yup.object(),
        EstadoComponente: Yup.string(),
        Marca: Yup.string(),
        Ubicacion: Yup.string(),
    };
}
