import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export function FilterEditarMarcas({ response }) {
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            response(formValue);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid my-3">
                <div className="col-12 md:col-4">
                    <span className="p-float-label">
                        <InputText className="w-full" id="Badge" name="Badge" value={formik.values.Badge} onChange={formik.handleChange} />
                        <label htmlFor="Badge">Badge</label>
                    </span>
                </div>
                <div className="col-12 md:col-4">
                    <span className="p-float-label">
                        <Calendar className="w-full" id="Fecha" name="Fecha" value={formik.values.Fecha} onChange={(e) => formik.setFieldValue('Fecha', e.value)} />
                        <label htmlFor="Fecha">Fecha</label>
                    </span>
                </div>
                <div className="col-12 md:col-4">
                    <Button severity="primary" size="large" type="submit" id="btnBuscarDetalle">
                        Buscar Marca
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        Badge: '',
        Fecha: ''
    };
}

function validationSchema() {
    return {
        Badge: Yup.string().required(true),
        Fecha: Yup.string().required(true)
    };
}
