import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { FormExcepcionOnline, FormExcepcionReject, TableDetalleMarca } from '../Skytracking';
import { useSkytracking } from '../../hooks';

export function TableHouyrsSupervisor({ horas, Fecha, tipoHoras }) {
    const toast = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);

    const { onAprobarHorasPersonales, onRechazarHorasPersonales, onValidarHorasSupervisor } = useSkytracking();

    const onOpenCloseModal = () => setShowModal((prev) => !prev);

    const onExcepciones = (rowData) => {
        setTitleModal(`Excepcion ${rowData.Badge} - ${rowData.Nombre}`);
        setShowModal(true);
        setContentModal(<FormExcepcionOnline datosMarca={rowData} closeModal={onOpenCloseModal} />);
    };

    const onDetalleMarca = (rowData) => {
        setTitleModal(`Detalle Horas ${rowData.Badge} - ${rowData.Nombre} / ${Fecha}`);
        setShowModal(true);
        setContentModal(<TableDetalleMarca rowDetail={rowData} />)
    }

    const accept = async (idMarca, event) => {
        await onValidarHorasSupervisor(idMarca);

        const padre = event.target.parentNode;

        padre.parentNode.removeChild(padre);

        toast.current.show({
            severity: 'success',
            summary: 'Horas',
            detail: 'Horas Validadas Correctamente',
            life: 3000
        });
    };

    const reject = () => {
        toast.current.show({
            severity: 'warn',
            summary: 'Horas',

            detail: 'Horas no Validadas, Intente validar nuevamente',
            life: 3000
        });
    };

    const onValidarHoras = (event, rowData) => {
        confirmPopup({
            target: event.currentTarget,
            message: '¿Desea Validar las Horas?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(rowData.idMarca, event),
            reject
        });
    };

    const onAprobarHoras = async (idMarca, evento) => {
        const padre = evento.target.parentNode.parentNode;

        padre.parentNode.removeChild(padre);

        await onAprobarHorasPersonales(idMarca, 1);

        toast.current.show({
            severity: 'success',
            summary: 'Horas',
            detail: 'Horas Aprobadas Correctamente',
            life: 3000
        });
    };

    const onResponseRechazarHoras = async (motivoRechazo, idMarca, evento) => {
        const padre = evento.target.parentNode.parentNode;

        padre.parentNode.removeChild(padre);

        await onAprobarHorasPersonales(idMarca, 2);

        await onRechazarHorasPersonales(motivoRechazo, idMarca);
        toast.current.show({
            severity: 'success',
            summary: 'Horas',
            detail: 'Horas Rechazadas correctamente',
            life: 3000
        });

        setShowModal(false);
    };

    const onRechazarHoras = (idMarca, event) => {
        setShowModal(true);
        setContentModal(<FormExcepcionReject idExcepcion={idMarca} response={onResponseRechazarHoras} elemento={event} />);
    };

    const cols = [
        { field: 'Badge', header: 'Badge' },
        { field: 'Nombre', header: 'Nombre' },
        { field: 'Fecha', header: 'Fecha' },
        { field: 'HorasPagar', header: 'Horas a Pagar' },
        { field: 'HorasTrabajadas', header: 'Horas a Trabajadas' },
        { field: '1', header: 'Horas Programadas' },
        { field: 'HorasAdicionales', header: 'Horas Adicionales' },
        { field: 'HorasExtra', header: 'Horas Extra' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, horas);
                doc.save('MasterRoster.pdf');
            });
        });
    };

    const exportExcel = () => {
        let listHorasExcel = [];

        for (const row of horas) {
            let temp_listaHorasExcel = [];

            temp_listaHorasExcel['Badge'] = row.Badge;
            temp_listaHorasExcel['Nombre'] = row.Nombre;
            temp_listaHorasExcel['Fecha'] = row.Fecha;
            temp_listaHorasExcel['HorasPagar'] = row.HorasPagar;
            temp_listaHorasExcel['HorasTrabajadas'] = row.HorasTrabajadas;
            temp_listaHorasExcel['Horas Programadas'] = row[1];
            temp_listaHorasExcel['HorasAdicionales'] = row.HorasAdicionales;
            temp_listaHorasExcel['HorasExtra'] = row.HorasExtra;

            listHorasExcel.push(temp_listaHorasExcel);
        }

        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(listHorasExcel);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'MasterRoster');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <React.Fragment>
                <div className='grid my-3'>
                    <div className='col-12'>
                        <Button type='button' severity='info' onClick={() => onDetalleMarca(rowData)}>
                            Detalle Horas
                        </Button>
                    </div>
                </div>
                <div className="grid">
                    {tipoHoras ? (
                        <>
                            {rowData.ValidacionMarca == null ? (
                                <div className="col-12">
                                    <Button type="button" severity="success" onClick={() => onExcepciones(rowData)}>
                                        Excepciones
                                    </Button>
                                </div>
                            ) : (
                                <Message severity="success" text="Marca Completada" />
                            )}

                            {rowData.EstadoMarca == '1' && (
                                <>
                                    {rowData.ValidacionMarca == null && (
                                        <div className="col-12">
                                            <Button type="button" onClick={(e) => onValidarHoras(e, rowData)}>
                                                Validar Horas
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {rowData.EstadoMarca > 0 ? (
                                rowData.EstadoMarca == 1 ? (
                                    <Message severity="success" text="Aprobada" />
                                ) : (
                                    <Message severity="error" text="Rechazada" />
                                )
                            ) : (
                                <>
                                    <div className="col-12 md:col-6">
                                        <Button type="button" severity="success" onClick={(e) => onAprobarHoras(rowData.idMarca, e)}>
                                            Aprobar Horas
                                        </Button>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <Button type="button" severity="danger" onClick={(e) => onRechazarHoras(rowData.idMarca, e)}>
                                            Rechazar Horas
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </React.Fragment>
        );
    };
    return (
        <div className="grid mt-5 mb-5">
            <div className="col-12">
                <div className="card">
                    <h5>Fecha {Fecha}</h5>
                    <p>Horas detalle, aqui puede poner excepciones para cada uno de estos empleados</p>

                    <Dialog header={titleModal} style={{ width: '50vw' }} visible={showModal} modal onHide={() => setShowModal(false)}>
                        {contentModal}
                    </Dialog>

                    <Toast ref={toast} />
                    <ConfirmPopup />

                    <DataTable
                        value={horas}
                        header={header}
                        paginator
                        rows={25}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    >
                        <Column field="Badge" header="Badge" filter sortable></Column>
                        <Column field="Nombre" header="Nombre" filter sortable></Column>
                        <Column field="Fecha" header="Fecha" filter></Column>
                        <Column body={actionBodyTemplate} exportable={false}></Column>

                        <Column field="HorasPagar" header="Horas a Pagar" filter sortable></Column>
                        <Column field="HorasTrabajadas" header="Horas a Trabajadas" filter sortable></Column>
                        <Column field="HorasPagadasPorLey" header="Horas Pagadas por ley" filter sortable></Column>
                        <Column field="1" header="Horas Programadas" filter sortable></Column>
                        <Column field="30" header="Unpaid Excess Login" filter sortable></Column>
                        <Column field="NoCallNoShow" header="No Call No Show" filter sortable></Column>
                        <Column field="57" header="Non Worked Hours" filter sortable></Column>
                        <Column field="60" header="Hours Correction" filter sortable></Column>
                        {/* <Column field="HorasDevengadas_online" header="Horas Devengadas (online)"></Column>
            <Column field="HorasDevengadas_offline" header="Horas Devengadas (offline)"></Column> */}
                        <Column field="HorasAdicionales" header="Horas Adicionales" filter sortable></Column>
                        <Column field="HorasExtra" header="Horas Extra" filter sortable></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
