import React, { useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import {InputText} from "primereact/inputtext"
import {Checkbox} from "primereact/checkbox"
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFilterRoster, useCommon } from '../../hooks';

export function FilterReportAp({ responseForm }) {
    const { cuentas, getCuentas } = useFilterRoster();
    const { listEmpleados, loadingCommon, listTiposAp, getSkyEmpleados_badge, getTipoAp } = useCommon();

    useEffect(() => {
        (async () => {
            await getCuentas();
            await getSkyEmpleados_badge();
            await getTipoAp();
        })();
    }, []);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            responseForm(formValue);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid my-3">
                <div className="col-12 md:col-3">
                    <span className="p-float-label">
                        <InputText className='w-full' id='NumAp' name='NumAp' value={formik.values.NumAp} onChange={formik.handleChange} />
                        <label htmlFor='NumAp'>Num AP</label>
                    </span>
                </div>
                <div className="col-12 md:col-3">
                    <span className="p-float-label">
                        <Calendar
                            className={`w-full ${formik.errors.Cuenta ? 'p-invalid' : ''}`}
                            id="RangoFechas"
                            name="RangoFechas"
                            selectionMode="range"
                            value={formik.values.RangoFechas}
                            onChange={(e) => formik.setFieldValue('RangoFechas', e.value)}
                        />
                        <label htmlFor="">Rango de Fechas</label>
                    </span>
                </div>
                <div className="col-12 md:col-3">
                    <span className="p-float-label">
                        <Dropdown
                            className={`w-full ${formik.errors.Cuenta ? 'p-invalid' : ''}`}
                            name="Cuenta"
                            id="Cuenta"
                            filter
                            value={formik.values.Cuenta}
                            options={cuentas}
                            optionLabel="name"
                            onChange={(e) => {
                                formik.setFieldValue('Cuenta', e.value);
                            }}
                        />
                        <label htmlFor="">Cuenta</label>
                    </span>
                </div>
                <div className="col-12 md:col-3">
                    <span className="p-float-label">
                        <Dropdown
                            options={listTiposAp}
                            optionLabel="name"
                            filter
                            className={`w-full ${formik.errors.TipoAp && 'p-invalid'}`}
                            id="TipoAp"
                            name="TipoAp"
                            value={formik.values.TipoAp}
                            onChange={(e) => formik.setFieldValue('TipoAp', e.value)}
                        />
                        <label htmlFor="">Tipo de AP {loadingCommon && (
                            <i className='pi pi-spinner pi-spin'></i>
                        )}</label>
                    </span>
                </div>
                <div className="col-12 md:col-3">
                    <span className="p-float-label">
                        <Dropdown
                            options={listEmpleados}
                            optionLabel="name"
                            filter
                            className={`w-full ${formik.errors.Empleado && 'p-invalid'}`}
                            id="Empleado"
                            name="Empleado"
                            value={formik.values.Empleado}
                            onChange={(e) => formik.setFieldValue('Empleado', e.value)}
                        />
                        <label htmlFor="">Empleado {loadingCommon && (
                            <i className='pi pi-spinner pi-spin'></i>
                        )}</label>
                    </span>
                </div>
                <div className='col-12 md:col-3'>
                    <Checkbox inputId='ApPendientes' name='ApPendientes' checked={formik.values.ApPendientes} onChange={(e) => formik.setFieldValue("ApPendientes",e.checked)} />
                    <label htmlFor='ApPendientes' className='ml-2'>Mis AP Pendientes</label>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 text-right">
                    <Button severity="primary" type="submit">
                        Consultar
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        NumAp: '',
        RangoFechas: '',
        Cuenta: '',
        TipoAp: '',
        Empleado: '',
        ApPendientes:'',
    };
}

function validationSchema() {
    return {
        NumAp: Yup.string(),
        RangoFechas: Yup.array(),
        Cuenta: Yup.string(),
        TipoAp: Yup.string(),
        Empleado: Yup.string(),
        ApPendientes: Yup.boolean(),
    };
}
