import React, { useEffect, useState } from "react"
import {Dropdown} from "primereact/dropdown"
import {Button} from "primereact/button"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {filter} from "lodash"
import {useFormik} from "formik"
import * as Yup from "yup"
import {useAp, useMasterRoster} from "../../hooks"

export function ReportPersonalApPage () {

    const [listDropdownEmpleados, setListDropdownEmpleados] = useState(null)
    const [listApPersonales, setListApPersonales] = useState(null)

    const {getPersonalAps, getDetailAp, apDetail} = useAp()
    const {getSkyEmpleadosViewPerso, empleados} = useMasterRoster()

    useEffect(() => {
        (async () => {
            await getSkyEmpleadosViewPerso()
        })()
    }, [])

    useEffect(() => {

        if (empleados != null){

            (async () => {
    
                let lista_empleados = []
    
                for await (const emp of empleados){
    
                    lista_empleados.push({
                        name: `${emp.Badge.Badge} - ${emp[2]}`,
                        value: emp.Badge.Badge
                    })
    
                }

                setListDropdownEmpleados(lista_empleados)
            })()

        }
        
    }, [empleados])

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {

            const response_ap = await getPersonalAps(formValue.Badge)
            
            setListApPersonales(response_ap)
        }
    })

    const nombreTemplateTable = () => {
        const filter_employee = filter(empleados, ["Badge.Badge", formik.values.Badge])
        
        return (
            <>
            {filter_employee[0].Badge.Badge} - {filter_employee[0][2]} - {filter_employee[0].Cuenta.Nombre}
            </>
        )
    }

    return (
        <>
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Reporte de Ap por persona</h5>
                    <p>Selecciona la persona para poder visualizar sus ap's</p>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid">
                            <div className="col-12">
                                <span className="p-float-label">
                                    <Dropdown value={formik.values.Badge} className="w-full" filter options={listDropdownEmpleados} optionLabel="name" onChange={(e) => formik.setFieldValue("Badge", e.value)} id="Badge" name="Badge" />
                                    <label htmlFor="Badge">Empleado</label>
                                </span>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <Button severity="success" type="submit">Consultar AP</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Aps Resultado</h5>
                    <p>Aqui puedes ver todas las Ap del empleado</p>
                    <DataTable value={listApPersonales}>
                        <Column field="id" header="NumAp" filter sortable></Column>
                        <Column header="Empleado" exportable={false} body={nombreTemplateTable} filter sortable></Column>
                        <Column field="tipoAp_data.Nombre" header="Tipo de Ap" filter sortable></Column>
                        <Column header="Fecha Efectiva" field="FechaEfectiva" filter sortable></Column>
                    </DataTable>

                </div>
            </div>
        </div>
        </>
    )
}

function initialValues(){
    return {
        Badge: '',
    }
}

function validationSchema(){
    return {
        Badge: Yup.string().required(true),
    }
}