import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image } from 'primereact/image';
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

export function DateRangeFormAp({ response }) {

  const [previewImage, setPreviewImage] = useState(null);
  const [disabledState, setDisabledState] = useState(false);

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile[0];
        formik.setFieldValue('ImagenAp', file);
        setPreviewImage(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    });

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: (formValue) => {
      setDisabledState(true)
      response(formValue);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid">
        <div className="col">
          <label>Rango de Fechas</label>
          <Calendar
            name="FechasEfectivas"
            value={formik.values.FechasEfectivas}
            onChange={(e) => formik.setFieldValue("FechasEfectivas", e.value)}
            selectionMode="range"
            readOnlyInput
            dateFormat="yy-mm-dd"
            className="w-full"
          />
        </div>
      </div>
      <div className="grid">
        <div className="col">
          <label>Observacion</label>
          <InputTextarea
            rows={5}
            cols={30}
            name="Observacion"
            className="w-full"
            value={formik.values.Observacion}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="grid">
                <div className="col-6">
                    <Button type="button" severity="primary" size="large" {...getRootProps()}>
                        Subir Imagen para la AP
                    </Button>
                    <input {...getInputProps()} />
                </div>
                <div className="col-6">
                    <Image src={previewImage} width="100%" />
                </div>
            </div>
      <div className="grid">
        <div className="col">
          <Button severity="success" type="submit" disabled={disabledState}>
            Guardar Ap
          </Button>
        </div>
      </div>
    </form>
  );
}

function initialValues() {
  return {
    FechasEfectivas: "",
    Observacion: "",
    ImagenAp: ''
  };
}

function validationSchema() {
  return {
    FechasEfectivas: Yup.array().required(true),
    Observacion: Yup.string().required(true),
    ImagenAp: Yup.string()
  };
}
