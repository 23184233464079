import React, { useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFilterRoster } from '../../hooks';
import { listRosterEstados } from '../../utilities/options';
import { weekYearDate } from '../../utilities/constants';

export function FilterRoster({ showField, responseForm }) {
    const { cuentas, lobs, supervisores, estadosRosterUpdate, loadingFilterRoster, getCuentas, getDependientes } = useFilterRoster();

    useEffect(() => {
        (async () => {
            await getCuentas();
        })();
    }, []);

    const onGetLobs = async (idcuenta) => {
        await getDependientes(idcuenta);
    };

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema(showField)),
        validateOnChange: false,
        onSubmit: (formValue) => {
            responseForm(formValue);
        }
    });

    const onSemanaActual = () => {
        formik.setFieldValue('Semana', weekYearDate());
    };

    const onResetLob = () => {
        formik.setFieldValue('Lob', '');
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid mt-5">
                {showField?.Semana && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Calendar
                                className={`w-full ${formik.errors.Semana && 'p-invalid'}`}
                                value={formik.values.FechaSemana}
                                name="FechaSemana"
                                id="FechaSemana"
                                onChange={(e) => {
                                    formik.setFieldValue('FechaSemana', e.value);
                                    const date_week = new Date(e.value);

                                    formik.setFieldValue('Semana', moment(date_week).format('W'));
                                }}
                                dateFormat="yy-mm-dd"
                            />
                            {/* <InputText className={`w-full ${formik.errors.Semana && 'p-invalid'}`} name="Semana" id="Semana" value={formik.values.Semana} onChange={formik.handleChange} /> */}
                            <label htmlFor="Semana">Fecha</label>
                        </span>
                        {/* <Button type="button" severity="info" link onClick={onSemanaActual}>
                            Actual
                        </Button> */}
                    </div>
                )}
                {showField?.Cuenta && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown
                                className="w-full"
                                name="Cuenta"
                                id="Cuenta"
                                filter
                                value={formik.values.Cuenta}
                                options={cuentas}
                                optionLabel="name"
                                onChange={(e) => {
                                    formik.setFieldValue('Cuenta', e.value);
                                    onGetLobs(e.value);
                                }}
                            />
                            <label htmlFor="Cuenta">Cuenta</label>
                        </span>
                    </div>
                )}
                {showField?.Lob && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown
                                className="w-full"
                                name="Lob"
                                id="Lob"
                                filter
                                value={formik.values.Lob}
                                options={lobs}
                                optionLabel="name"
                                onChange={(e) => {
                                    formik.setFieldValue('Lob', e.value);
                                }}
                            />
                            <label htmlFor="Lob">
                                Lob&nbsp;
                                {loadingFilterRoster && <i className="pi pi-spin pi-spinner"></i>}
                            </label>
                        </span>
                        <Button type="button" severity="info" link onClick={onResetLob}>
                            Reset Lob
                        </Button>
                    </div>
                )}
                {showField?.Supervisor && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown
                                className="w-full"
                                name="Supervisor"
                                id="Supervisor"
                                filter
                                value={formik.values.Supervisor}
                                options={supervisores}
                                optionLabel="name"
                                onChange={(e) => {
                                    formik.setFieldValue('Supervisor', e.value);
                                }}
                            />
                            <label htmlFor="Supervisor">
                                Supervisor&nbsp;
                                {loadingFilterRoster && <i className="pi pi-spin pi-spinner"></i>}
                            </label>
                        </span>
                    </div>
                )}
                {showField?.EstadoRosterUpdate && (
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown
                                className="w-full"
                                name="EstadoRosterUpdate"
                                id="EstadoRosterUpdate"
                                filter
                                value={formik.values.EstadoRosterUpdate}
                                options={listRosterEstados}
                                optionLabel="name"
                                onChange={(e) => {
                                    formik.setFieldValue('EstadoRosterUpdate', e.value);
                                }}
                            />
                            <label htmlFor="EstadoRosterUpdate">Estado Roster Update</label>
                        </span>
                    </div>
                )}
            </div>

            <div className="grid">
                <div className="col-12 text-right">
                    <Button type="submit" severity="primary" size="large" disabled={loadingFilterRoster}>
                        Filtrar Roster
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        Semana: '',
        FechaSemana: '',
        Cuenta: '',
        Lob: '',
        Supervisor: '',
        EstadoRosterUpdate: ''
    };
}

function validationSchema(showField) {
    return {
        Semana: Yup.string().required(true),
        FechaSemana: Yup.string().required(true),
        Cuenta: showField?.CuentaRequired ? Yup.string().required(true) : Yup.string(),
        Lob: Yup.string(),
        Supervisor: Yup.string(),
        EstadoRosterUpdate: Yup.string()
    };
}
