import React from 'react';

export function ReportPowerBiPage() {
    return (
        <div className="grid">
            <div className="col-12">
                <iframe
                    title="Attendance React"
                    width="100%"
                    height="700px"
                    src="
    https://app.powerbi.com/view?r=eyJrIjoiMTRjMzM1Y2EtZmQxOC00YzIzLTg0ODQtOTM1ZDEzYzVlYjYyIiwidCI6ImQ2Mjc1OGQ2LThhYzEtNGE5NS05YzBmLWM2NjY2MDFhYWNjOSJ9"
                    frameborder="0"
                    allowFullScreen="true"
                ></iframe>
            </div>
        </div>
    );
}
