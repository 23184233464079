import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import {Button} from "primereact/button"
import { useCommonSam, useCommon } from '../../hooks';
import { listIdiomaFiltroSam, listNivelIdiomaFiltroSam, listJobSam, listPais } from '../../utilities/options';

export function FiltrosSam({ filtros, response, param_required }) {
    const { getEstadoAplicante, getPautas, loadingCommonSam, estadoAplicante, pautas } = useCommonSam();
    const { getCuenta, listCuentas } = useCommon();

    useEffect(() => {
        (async () => {
            await getEstadoAplicante();
            await getPautas();
            await getCuenta();
        })();
    }, []);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema(param_required)),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            response(formValue);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                {filtros?.FechaAplicacion && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <Calendar className="w-full" value={formik.values.FechaAplicacion} onChange={(e) => formik.setFieldValue('FechaAplicacion', e.value)} name="FechaAplicacion" id="FechaAplicacion" selectionMode="range" readOnlyInput />
                            <label htmlFor="FechaAplicacion">Fecha de Aplicacion</label>
                        </span>
                    </div>
                )}

                {filtros?.FechaAprobacion && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <Calendar className="w-full" value={formik.values.FechaAprobacion} onChange={(e) => formik.setFieldValue('FechaAprobacion', e.value)} name="FechaAprobacion" id="FechaAprobacion" selectionMode="range" readOnlyInput />
                            <label htmlFor="FechaAprobacion">Fecha de Aprobacion</label>
                        </span>
                    </div>
                )}

                {filtros?.EstadoAplicante && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <Dropdown
                                className="w-full"
                                value={formik.values.EstadoAplicante}
                                name="EstadoAplicante"
                                id="EstadoAplicante"
                                options={estadoAplicante}
                                optionLabel="name"
                                onChange={(e) => formik.setFieldValue('EstadoAplicante', e.value)}
                            />
                            <label htmlFor="EstadoAplicante">Estado del Aplicante</label>
                        </span>
                    </div>
                )}

                {filtros?.Pauta && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <Dropdown className="w-full" filter value={formik.values.Pauta} name="Pauta" id="Pauta" options={pautas} optionLabel="name" onChange={(e) => formik.setFieldValue('Pauta', e.value)} />
                            <label htmlFor="Pauta">Pauta</label>
                        </span>
                    </div>
                )}

                {filtros?.Referido && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <InputText className="w-full" value={formik.values.Referido} name="Referido" id="Referido" onChange={formik.handleChange} />
                            <label htmlFor="Referido">Referido</label>
                        </span>
                    </div>
                )}

                {filtros?.Idioma && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <Dropdown className="w-full" value={formik.values.Idioma} name="Idioma" id="Idioma" options={listIdiomaFiltroSam} optionLabel="name" onChange={(e) => formik.setFieldValue('Idioma', e.value)} />
                            <label htmlFor="Idioma">Idioma</label>
                        </span>
                    </div>
                )}

                {filtros?.NivelIdioma && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <Dropdown
                                className="w-full"
                                value={formik.values.NivelIdioma}
                                name="NivelIdioma"
                                id="NivelIdioma"
                                options={listNivelIdiomaFiltroSam[formik.values.Idioma]}
                                optionLabel="name"
                                onChange={(e) => formik.setFieldValue('NivelIdioma', e.value)}
                            />
                            <label htmlFor="NivelIdioma">Nivel del Idioma</label>
                        </span>
                    </div>
                )}

                {filtros?.Job && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <Dropdown className="w-full" value={formik.values.Job} name="Job" id="Job" options={listJobSam} optionLabel="name" onChange={(e) => formik.setFieldValue('Job', e.value)} />
                            <label htmlFor="Job">Posicion Deseada</label>
                        </span>
                    </div>
                )}

                {filtros?.Pais && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <Dropdown className="w-full" value={formik.values.Pais} name="Pais" id="Pais" options={listPais} optionLabel="name" onChange={(e) => formik.setFieldValue('Pais', e.value)} />
                            <label htmlFor="Pais">Pais del Aplicante</label>
                        </span>
                    </div>
                )}

                {filtros?.Nombre && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <InputText className="w-full" value={formik.values.Nombre} name="Nombre" id="Nombre" onChange={formik.handleChange} />
                            <label htmlFor="Nombre">Nombre de Aplicante</label>
                        </span>
                    </div>
                )}

                {filtros?.Cuenta && (
                    <div className="col-12 md:col-3 my-3">
                        <span className="p-float-label">
                            <Dropdown className="w-full" filter value={formik.values.Cuenta} name="Cuenta" id="Cuenta" options={listCuentas} optionLabel="name" onChange={(e) => formik.setFieldValue('Cuenta', e.value)} />
                            <label htmlFor="Cuenta">Cuentas</label>
                        </span>
                    </div>
                )}
            </div>
            <div className='grid my-3'>
                <div className='col-12 text-right'>
                    <Button className='mx-3' severity='warning' type='button' onClick={() => formik.resetForm()}>Reiniciar Filtros</Button>
                    <Button className='mx-3' severity='primary' type='submit'>Aplicar Filtros</Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        FechaAplicacion: '',
        FechaAprobacion: '',
        EstadoAplicante: '',
        Pauta: '',
        Referido: '',
        Idioma: '',
        NivelIdioma: '',
        Job: '',
        Pais: '',
        Nombre: '',
        Cuenta: ''
    };
}

function validationSchema(param_required) {
    return {
        FechaAplicacion: param_required?.FechaAplicacion ? Yup.array().required(true) : Yup.array(),
        FechaAprobacion: Yup.array(),
        EstadoAplicante: param_required?.EstadoAplicante ? Yup.string().required(true) : Yup.string(),
        Pauta: Yup.string(),
        Referido: Yup.string(),
        Idioma: Yup.string(),
        NivelIdioma: Yup.string(),
        Job: Yup.string(),
        Pais: Yup.string(),
        Nombre: Yup.string(),
        Cuenta: Yup.string()
    };
}
