import React, { useState } from "react"
import moment from "moment"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import { FilterReportAp } from '../../components/Skyap';
import {useAp} from '../../hooks/useAp'

export function ReportApAttrition () {

    const [dataAttrition, setDataAttrition] = useState(null)

    const {getReportAttrition} = useAp()

    const responseForm = async (dataForm) => {

        const result_data = await getReportAttrition(moment(dataForm.RangoFechas[0]).format("YYYY-MM-DD"), moment(dataForm.RangoFechas[1]).format("YYYY-MM-DD"))
        setDataAttrition(result_data)
        console.log(result_data)
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte Attrition</h5>
                        <p>Reporte de attrition y tambien podras cambiar los valores de Bajas</p>
                        <FilterReportAp responseForm={responseForm} />
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Tabla de Attrition</h5>
                        <DataTable
                            value={dataAttrition}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}">
                            <Column sortable filter header="Numero de AP" field="NumAp"></Column>
                            <Column sortable filter header="Badge" field="Badge"></Column>
                            <Column sortable filter header="Nombre" field="Nombre"></Column>
                            <Column sortable filter header="Plaza" field="NombrePlaza"></Column>
                            <Column sortable filter header="Tipo de AP" field="Tipo_Ap"></Column>

                            <Column sortable filter header="Fecha de Ingreso" field="Hire_DT"></Column>
                            <Column sortable filter header="Fecha de Entrenamiento" field="Training_DT"></Column>
                            <Column sortable filter header="Fecha de Produccion" field="Production_DT"></Column>
                            <Column sortable filter header="Fecha de Efectiva" field="Fecha_Efectiva"></Column>

                            <Column sortable filter header="Tipo de Despido" field="Tipo_Despido"></Column>
                            <Column sortable filter header="Cuenta" field="NombreCuenta"></Column>
                            <Column sortable filter header="Lob" field="NombreLob"></Column>
                            <Column sortable filter header="Pais" field="Country"></Column>
                            <Column sortable filter header="Edificio" field="Site"></Column>
                            <Column sortable filter header="Term Condition" field="Tipo_Renuncia"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    )
}