import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export function SimpleForm({ response }) {

    const [previewImage, setPreviewImage] = useState(null);
    const [disabledState, setDisabledState] = useState(false);

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile[0];
        formik.setFieldValue('ImagenAp', file);
        setPreviewImage(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    });

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            setDisabledState(true)
            response(formValue);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid my-3">
                <div className="col">
                    <span className="p-float-label">
                        <Calendar dateFormat="yy-mm-dd" className="w-full" name="FechaEfectiva" value={formik.values.FechaEfectiva} onChange={(e) => formik.setFieldValue('FechaEfectiva', e.value)} />
                        <label htmlFor="FechaEfectiva">Fecha Efectiva</label>
                    </span>
                </div>
            </div>
            <div className="grid my-3">
                <div className="col">
                    <span className="p-float-label">
                        <InputTextarea cols={30} rows={5} className="w-full" name="Observacion" id="Observacion" value={formik.values.Observacion} onChange={formik.handleChange} />
                        <label htmlFor="Observacion">Observacion</label>
                    </span>
                </div>
            </div>
            <div className="grid">
                <div className="col-6">
                    <Button type="button" severity="primary" size="large" {...getRootProps()}>
                        Subir Imagen para la AP
                    </Button>
                    <input {...getInputProps()} />
                </div>
                <div className="col-6">
                    <Image src={previewImage} width="100%" />
                </div>
            </div>
            <div className="grid my-5">
                <div className="col">
                    <Button severity="success" type="submit" disabled={disabledState}>
                        Guardar Ap
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        FechaEfectiva: '',
        Observacion: '',
        ImagenAp: ''
    };
}

function validationSchema() {
    return {
        FechaEfectiva: Yup.string().required(true),
        Observacion: Yup.string().required(true),
        ImagenAp: Yup.string()
    };
}
