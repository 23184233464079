import { useState } from 'react';
import { getUsersApi, getMeApi, updateUserApi } from '../api/login/user';
import { useAuth } from './useAuth';

export function useUser() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [users, setUsers] = useState(null);
    const { auth } = useAuth();

    const getMe = async (token) => {
        try {
            const response = await getMeApi(token);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getUsers = async () => {
        try {
            setLoading(true);
            const response = await getUsersApi(auth.token);
            setLoading(false);
            setUsers(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const changePassword = async (pass) => {
        try {
            setLoading(true);
            await updateUserApi(
                {
                    password: pass,
                    username: auth.me.username
                },
                auth.me.id,
                auth.token
            );
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    return {
        loading,
        error,
        users,
        getMe,
        getUsers,
        changePassword
    };
}
