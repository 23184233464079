import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TermReason, TypeResignation, TermType } from '../../../utilities/options';

export function DespidoFormAp({ response }) {
    const [previewImage, setPreviewImage] = useState(null);
    const [disabledState, setDisabledState] = useState(false);

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile[0];
        formik.setFieldValue('ImagenAp', file);
        setPreviewImage(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    });

    const TiposDespidos = [
        {
            value: 'RECONTRATABLE',
            name: 'Recontratable'
        },
        {
            value: 'NORECONTRATABLE',
            name: 'No Recontratable'
        }
    ];

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            setDisabledState(true)
            response(formValue);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="col">
                    <label>Fecha Efectiva</label>
                    <Calendar dateFormat="yy-mm-dd" className="w-full" name="Fecha Efectiva" value={formik.values.FechaEfectiva} onChange={(e) => formik.setFieldValue('FechaEfectiva', e.value)} />
                </div>
            </div>
            <div className="grid">
                <div className="col">
                    <label>Observacion</label>
                    <InputTextarea cols={30} rows={5} className="w-full" name="Observacion" value={formik.values.Observacion} onChange={formik.handleChange} />
                </div>
            </div>

            <div className="grid my-3">
                <div className="col-6">
                    <label>Tipo de Terminacion</label>
                    <Dropdown filter options={TermType} optionLabel="name" name="TipoTerminacion" value={formik.values.TipoTerminacion} className="w-full" onChange={(e) => formik.setFieldValue('TipoTerminacion', e.value)} />
                </div>
                <div className="col-6">
                    <label>Tipo de Despido</label>
                    <Dropdown filter options={TiposDespidos} optionLabel="name" name="TipoDespido" value={formik.values.TipoDespido} className="w-full" onChange={(e) => formik.setFieldValue('TipoDespido', e.value)} />
                </div>
            </div>

            <div className="grid my-3">
                <div className="col-6">
                    <label htmlFor="RazonTerminacion">Razon de Terminacion</label>
                    <Dropdown filter name="RazonTerminacion" id="RazonTerminacion" value={formik.values.RazonTerminacion} options={TermReason} optionLabel="name" onChange={(e) => formik.setFieldValue('RazonTerminacion', e.value)} className="w-full" />
                </div>
                <div className="col-6">
                    <label htmlFor="TipoRenuncia">Tipo de Renuncia</label>
                    <Dropdown filter name="TipoRenuncia" id="TipoRenuncia" value={formik.values.TipoRenuncia} options={TypeResignation} optionLabel="name" onChange={(e) => formik.setFieldValue('TipoRenuncia', e.value)} className="w-full" />
                </div>
            </div>
            
            <div className="grid">
                <div className="col-6">
                    <Button type="button" severity="primary" size="large" {...getRootProps()}>
                        Subir Imagen para la AP
                    </Button>
                    <input {...getInputProps()} />
                </div>
                <div className="col-6">
                    <Image src={previewImage} width="100%" />
                </div>
            </div>
            <div className="grid">
                <div className="col">
                    <Button severity="success" type="submit" disabled={disabledState}>
                        Guardar Ap
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        FechaEfectiva: '',
        Observacion: '',
        TipoDespido: '',
        ImagenAp: '',
        RazonTerminacion: '',
        TipoRenuncia: '',
        TipoTerminacion: '',
        TipoAttrition: '',
    };
}

function validationSchema() {
    return {
        FechaEfectiva: Yup.string().required(true),
        Observacion: Yup.string().required(true),
        TipoDespido: Yup.string().required(true),
        ImagenAp: Yup.string(),
        RazonTerminacion: Yup.string(),
        TipoRenuncia: Yup.string(),
        TipoTerminacion: Yup.string(),
        TipoAttrition: Yup.string()
    };
}
