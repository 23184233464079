import React, { useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {size} from "lodash"
import { useAp } from '../../hooks';

export function ApImplementPage() {
    const { ejecutarHoras, ejecutarRoster, getAps, aps } = useAp();

    useEffect(() => {

        if (size(aps) > 0){

            (async () => {

                await ejecutarHoras(aps[0].idTipoAp, aps[0].NumAp, aps[0].Badge);
                await ejecutarRoster(aps[0].NumAp, aps[0].Badge, aps[0].idEmpleado);

            })()

        }

    }, [aps])

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {

            await getAps("", "", "", "", "", formValue.NumAp);

        }
    });

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Modulo de ejecucion de AP</h5>
                        <p>Ingresa el numero de AP para ejecutarlo</p>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid">
                                <div className="col">
                                    <span className="p-float-label">
                                        <InputText name="NumAp" id="NumAp" value={formik.values.NumAp} onChange={formik.handleChange} className="w-full" />
                                        <label htmlFor="NumAp">Numero de AP</label>
                                    </span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col">
                                    <Button type="submit" severity="primary">
                                        Ejecutar AP
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

function initialValues() {
    return {
        NumAp: ''
    };
}

function validationSchema() {
    return {
        NumAp: Yup.string().required(true)
    };
}
