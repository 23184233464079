import { useState } from 'react';
import { size, filter, find, uniq } from 'lodash';
import moment from 'moment';
import { useAuth } from './useAuth';
import { useUploadFiles, useRosterUpdate } from '../hooks';
import { addApApi, getApsApi, getReportAutomateAp, getValidApPendingApi, getReportAttritionApi } from '../api/skyap/ap';
import { addDetalleApApi, getDetalleApsApi, updateDetalleApApi } from '../api/skyap/detalleap';
import { getEmpleadoByFilterApi, updateDetalleEmpleadoApi, addDetalleEmpleadoApi } from '../api/skyroster/detalleempleado';
import { getConfiguracionTipoApsApi } from '../api/skyap/configuraciontipoap';
import { getAprobacionApsApi, updateAprobacionApApi, addAprobacionApApi } from '../api/skyap/aprobacionap';
import { getTipoApsApi } from '../api/skyap/tipoap';
import { getPropiedadApsApi } from '../api/skyap/propiedadap';
import { getCuentasApi } from '../api/skyroster/cuenta';
import { getLobsApi } from '../api/skyroster/lob';
import { getDetalleMarcaByMarcaFilterApi, addDetalleMarcaApi, updateDetalleMarcaApi } from '../api/skytracking/detallemarca';
import { weekYearDate } from '../utilities/constants';

export function useAp() {
    const [error, setError] = useState(null);
    const [loadingAp, setLoadingAp] = useState(false);
    const [aps, setAps] = useState(null);
    const [apHeader, setApHeader] = useState(null);
    const [apDetail, setApDetail] = useState(null);
    const [dataEmpleado, setDataEmpleado] = useState(null);

    const { auth } = useAuth();
    const { calcAuxMarca } = useUploadFiles();
    const { insertUpdateHorario } = useRosterUpdate();

    const insertHeadAp = async (data) => {
        try {
            setLoadingAp(true);
            data['BadgeSolicitante'] = auth.me.username;
            const response = await addApApi(data, auth.token);

            await addAprobacionApApi(
                {
                    Aprobado: auth.me.username,
                    TipoAprobacion: 'A',
                    Ap: response.id
                },
                auth.token
            );

            setLoadingAp(false);

            return response;
        } catch (error) {
            setLoadingAp(false);
            setError(error);
        }
    };

    const getReportAttrition = async (fechaInicio, fechaFinal) => {
        try {
            setLoadingAp(true);
            const response = await getReportAttritionApi(auth.token, fechaInicio, fechaFinal)

            setLoadingAp(false);

            return response;
        } catch (error) {
            setLoadingAp(false);
            setError(error);
        }
    };

    const getApAutomateReport = async (FechaInicio, FechaFinal, Cuenta, TipoAp, Empleado, pendiente, NumAp) => {
        try {
            setLoadingAp(true)

            let pendiente_env = ''

            if (pendiente){
                pendiente_env = auth.me.username
            }

            const response_ap = await getReportAutomateAp(auth.token, FechaInicio, FechaFinal, Cuenta, TipoAp, Empleado, pendiente_env, NumAp);
            let response_trated_ap = []

            for await (const ap of response_ap){
                let ap_temp = ap;

                let response_str = "Procesada";

                if (
                    ap.AprobacionSupervisor.includes("P") ||
                    ap.AprobacionAccount.includes("P") ||
                    ap.AprobacionDirector.includes("P") ||
                    ap.AprobacionHR.includes("P") ||
                    ap.AprobacionRTA.includes("P")
                ) {
                    response_str = "Pendiente";
                }

                ap_temp["EstadoResponse"] = response_str;

                response_trated_ap.push(ap_temp);
            }

            setLoadingAp(false)

            return response_trated_ap;
        } catch (error) {
            setLoadingAp(false)
            setError(error)
        }
    }

    const insertDetailAp = async (data) => {
        try {
            setLoadingAp(true);
            await addDetalleApApi(data, auth.token);
            setLoadingAp(false);
        } catch (error) {
            setLoadingAp(false);
            setError(error);
        }
    };

    const updateDetailAp = async (data, id) => {
        try {
            setLoadingAp(true);
            await updateDetalleApApi(data,id, auth.token);
            setLoadingAp(false);
        } catch (error) {
            setLoadingAp(false);
            setError(error);
        }
    };

    const getPersonalAps = async (BadgeEmployee) => {
        try {
            setLoadingAp(true)

            let list_ap_employee = await getApsApi(auth.token, "", BadgeEmployee);

            let contador = 0

            for await (const ap_employee of list_ap_employee){

                const detalle_ap = await getDetalleApsApi(auth.token, "", "", "", "", "", ap_employee.id)
                let FechaEfectiva = filter(detalle_ap, ["PropiedadAp", 11])

                if (size(FechaEfectiva) > 0){

                    list_ap_employee[contador]["FechaEfectiva"] = FechaEfectiva[0].Valor

                }else{

                    let FechaInicio = filter(detalle_ap, ["PropiedadAp", 12])
                    let FechaFinal = filter(detalle_ap, ["PropiedadAp", 13])

                    if (size(FechaInicio) > 0){

                        list_ap_employee[contador]["FechaEfectiva"] = `${FechaInicio[0].Valor} - ${FechaFinal[0].Valor}`
                    }else{
                        list_ap_employee[contador]["FechaEfectiva"] = "Fecha Efectiva No Asignada"

                    }

                }


                contador += 1

            }

            setLoadingAp(false)

            return list_ap_employee
        } catch (error) {
            setLoadingAp(false)
            setError(error)
        }
    }

    const getAps = async (Cuenta, FechaInicio, FechaFinal, TipoAp, Empleado, numAp) => {
        try {
            setLoadingAp(true);
            let emp_arr = [];

            if (Cuenta != ""){

                const response_empleado = await getEmpleadoByFilterApi(auth.token, Cuenta, 3);
                for await (const empleado of response_empleado) {
                    emp_arr.push(empleado.empleado_data.Badge);
                }
                emp_arr = uniq(emp_arr)
                
            }

            let response_ap = []

            if (numAp != ""){

                response_ap = await getDetalleApsApi(auth.token, '', "", "", '', '12%2C11',numAp, '', "", "", "");

                
            }else{

                response_ap = await getDetalleApsApi(auth.token, '', FechaInicio, FechaFinal, '', '12%2C11', '', '', emp_arr.join('%2C'), TipoAp, Empleado);
            }
            let array_response_ap = [];

            for await (const ap of response_ap) {
                let AprobacionAp = {
                    SUPERVISOR: 'N/A',
                    'ACCOUNT MANAGER': 'N/A',
                    'HR GENERALIST': 'N/A',
                    GERENCIA: 'N/A',
                    RTA: 'N/A'
                };

                let array_response_ap_detalle = [];
                const response_ap_table = await getApsApi(auth.token, ap.Ap);
                //const response_detalle_ap = await getDetalleApsApi(auth.token, '', '', '', '', '', ap.Ap);
                const response_plaza_empleado = await getEmpleadoByFilterApi(auth.token, '', 6, weekYearDate(), auth.me.username);

                const response_empleado_nombre = await getEmpleadoByFilterApi(auth.token, '', 2, '', '', response_ap_table[0].Empleado);
                const response_empleado_supervisor = await getEmpleadoByFilterApi(auth.token, '', 10, '', '', response_ap_table[0].Empleado);
                const response_empleado_account_manager = await getEmpleadoByFilterApi(auth.token, '', 11, '', '', response_ap_table[0].Empleado);
                const response_configuracion_ap = await getConfiguracionTipoApsApi(auth.token, response_ap_table[0].TipoAp, '', 'true');
                const mostrarBotones = await getAprobacionApsApi(auth.token, ap.Ap, auth.me.username);
                let plazas_aprobar = [];
                const response_aprobaciones = await getAprobacionApsApi(auth.token, ap.Ap);

                for await (const configuracion of response_configuracion_ap) {
                    let respuesta_aprobacion = [];
                    plazas_aprobar.push(configuracion.plaza_data.id);

                    switch (configuracion.plaza_data.Nombre) {
                        case 'SUPERVISOR':
                            respuesta_aprobacion = await getAprobacionApsApi(auth.token, ap.Ap, response_empleado_supervisor[0].Valor);

                            break;

                        case 'ACCOUNT MANAGER':
                            respuesta_aprobacion = await getAprobacionApsApi(auth.token, ap.Ap, response_empleado_account_manager[0].Valor);

                            break;

                        default:
                            for await (const aprobado_dt of response_aprobaciones){
                                if (aprobado_dt.Aprobado != response_empleado_supervisor[0].Valor && aprobado_dt.Aprobado != response_empleado_account_manager[0].Valor){

                                    const response_aprobado_plaza = await getEmpleadoByFilterApi(auth.token, '', 6, weekYearDate(), aprobado_dt.Aprobado);

                                    if(response_aprobado_plaza[0].Valor == 17){
                                        respuesta_aprobacion = await getAprobacionApsApi(auth.token, ap.Ap, aprobado_dt.Aprobado);
                                    }else if (response_aprobado_plaza[0].Valor == 6){
                                        respuesta_aprobacion = await getAprobacionApsApi(auth.token, ap.Ap, aprobado_dt.Aprobado);
                                    }
                                    
                                }
                            }
                            break;
                    }

                    if (size(respuesta_aprobacion) > 0) {
                        AprobacionAp[configuracion.plaza_data.Nombre] = respuesta_aprobacion[0].TipoAprobacion;
                    } else {
                        AprobacionAp[configuracion.plaza_data.Nombre] = 'P';
                    }
                }

                array_response_ap_detalle['NumAp'] = ap.Ap;
                array_response_ap_detalle['idEmpleado'] = response_ap_table[0].Empleado;
                array_response_ap_detalle['idTipoAp'] = response_ap_table[0].TipoAp;
                array_response_ap_detalle['TipoAp'] = response_ap_table[0].tipoAp_data.Nombre;
                array_response_ap_detalle['Badge'] = response_empleado_nombre[0].empleado_data.Badge;
                array_response_ap_detalle['Nombre'] = response_empleado_nombre[0].Valor;

                if (size(mostrarBotones) > 0) {
                    array_response_ap_detalle['showButton'] = false;
                } else {
                    if (auth.me.username == response_empleado_supervisor[0].Valor || auth.me.username == response_empleado_account_manager[0].Valor || plazas_aprobar.includes(parseInt(response_plaza_empleado[0].Valor))) {
                        array_response_ap_detalle['showButton'] = true;
                    } else {
                        array_response_ap_detalle['showButton'] = false;
                    }
                }

                array_response_ap_detalle['SUPERVISOR'] = AprobacionAp['SUPERVISOR'];
                array_response_ap_detalle['ACCOUNT MANAGER'] = AprobacionAp['ACCOUNT MANAGER'];
                array_response_ap_detalle['HR GENERALIST'] = AprobacionAp['HR GENERALIST'];
                array_response_ap_detalle['GERENCIA'] = AprobacionAp['GERENCIA'];
                array_response_ap_detalle['RTA'] = AprobacionAp['RTA'];

                if (response_ap_table[0].TipoAp != 3 && response_ap_table[0].TipoAp != 4 && response_ap_table[0].TipoAp != 6) {
                    array_response_ap.push(array_response_ap_detalle);
                } else {
                    if (response_empleado_nombre[0].empleado_data.Badge != auth.me.username) {
                        array_response_ap.push(array_response_ap_detalle);
                    }
                }
            }

            setAps(array_response_ap);

            setLoadingAp(false);
        } catch (error) {
            console.log(error);
            setLoadingAp(false);
            setError(error);
        }
    };

    const rejectApMultiple = async (data) => {
        try {
            setLoadingAp(true)

            if (data.Aprobado != auth.me.username){
                data.MotivoRechazo = "Rechazo Automatico"
            }

            await addAprobacionApApi(
                data,
                auth.token
            );
            setLoadingAp(false)
        } catch (error) {
            setLoadingAp(false)
            setError(error)
        }
    }

    const rejectAp = async (idAp) => {
        try {
            setLoadingAp(true);

            const aprobacionAp = await getAprobacionApsApi(auth.token, idAp, auth.me.username);

            if (size(aprobacionAp) > 0) {
                await updateAprobacionApApi(
                    {
                        Aprobado: auth.me.username,
                        TipoAprobacion: 'R'
                    },
                    aprobacionAp[0].id,
                    auth.token
                );
            } else {
                await addAprobacionApApi(
                    {
                        Aprobado: auth.me.username,
                        TipoAprobacion: 'R',
                        Ap: idAp
                    },
                    auth.token
                );
            }

            setLoadingAp(false);
        } catch (error) {
            setLoadingAp(false);
            setError(error);
        }
    };

    const getSlaTimeAp = async (Ap, Aprobado) => {
        try {
            setLoadingAp(true)
            const response_aprobaciones = await getAprobacionApsApi(auth.token, Ap, Aprobado);
            setLoadingAp(false)
            return response_aprobaciones
        } catch (error) {
            setLoadingAp(false)
            setError(error)
        }
    }

    const approvedAp = async (idAp) => {
        try {
            setLoadingAp(true);

            await addAprobacionApApi(
                {
                    Aprobado: auth.me.username,
                    TipoAprobacion: 'A',
                    Ap: idAp
                },
                auth.token
            );

            let AprobacionAp = {
                SUPERVISOR: 'N/A',
                'ACCOUNT MANAGER': 'N/A',
                'HR GENERALIST': 'N/A',
                GERENCIA: 'N/A',
                RTA: 'N/A'
            };

            const response_ap_table = await getApsApi(auth.token, idAp);

            const response_plaza_empleado = await getEmpleadoByFilterApi(auth.token, '', 6, weekYearDate(), auth.me.username);

            const response_empleado_supervisor = await getEmpleadoByFilterApi(auth.token, '', 10, '', '', response_ap_table[0].Empleado);
            const response_empleado_account_manager = await getEmpleadoByFilterApi(auth.token, '', 11, '', '', response_ap_table[0].Empleado);
            const response_configuracion_ap = await getConfiguracionTipoApsApi(auth.token, response_ap_table[0].TipoAp, '', 'true');
            const response_aprobaciones = await getAprobacionApsApi(auth.token, idAp);

            for await (const configuracion of response_configuracion_ap) {
                let respuesta_aprobacion = [];

                switch (configuracion.plaza_data.Nombre) {
                    case 'SUPERVISOR':
                        respuesta_aprobacion = await getAprobacionApsApi(auth.token, idAp, response_empleado_supervisor[0].Valor);

                        break;

                    case 'ACCOUNT MANAGER':
                        respuesta_aprobacion = await getAprobacionApsApi(auth.token, idAp, response_empleado_account_manager[0].Valor);

                        break;

                    default:
                        for await (const aprobado_dt of response_aprobaciones){
                            if (aprobado_dt.Aprobado != response_empleado_supervisor[0].Valor && aprobado_dt.Aprobado != response_empleado_account_manager[0].Valor){

                                const response_aprobado_plaza = await getEmpleadoByFilterApi(auth.token, '', 6, weekYearDate(), aprobado_dt.Aprobado);

                                if(response_aprobado_plaza[0].Valor == 17){
                                    respuesta_aprobacion = await getAprobacionApsApi(auth.token, idAp, aprobado_dt.Aprobado);
                                }else if (response_aprobado_plaza[0].Valor == 6){
                                    respuesta_aprobacion = await getAprobacionApsApi(auth.token, idAp, aprobado_dt.Aprobado);
                                }
                                
                            }
                        }
                        break;
                }

                /* if (parseInt(response_plaza_empleado[0].Valor) == configuracion.plaza_data.id) {
                    respuesta_aprobacion = await getAprobacionApsApi(auth.token, idAp, auth.me.username);
                } */

                if (size(respuesta_aprobacion) > 0) {
                    AprobacionAp[configuracion.plaza_data.Nombre] = respuesta_aprobacion[0].TipoAprobacion;
                } else {
                    AprobacionAp[configuracion.plaza_data.Nombre] = 'P';
                }
            }

            let pendientes = 0;
            let noAplica = 0;
            let rechazados = 0;
            let aprobados = 0;

            for await (const plaza of Object.keys(AprobacionAp)) {
                switch (AprobacionAp[plaza]) {
                    case 'N/A':
                        noAplica += 1;
                        break;

                    case 'A':
                        aprobados += 1;
                        break;

                    case 'R':
                        rechazados += 1;
                        break;

                    case 'P':
                        pendientes += 1;
                        break;

                    default:
                        break;
                }
            }

            setLoadingAp(false);

            if (pendientes == 0 && rechazados == 0 && noAplica < 5) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            setLoadingAp(false);
            setError(error);
        }
    };

    const getApImage = async (numAp) => {
        try {
            setLoadingAp(true)
            const response_apHeader = await getApsApi(auth.token, numAp);
            setLoadingAp(false)

            return response_apHeader
        } catch (error) {
            setLoadingAp(false)
            setError(error)
        }
    }

    const getResponseApDetail = async (numAp) => {
        try {
            setLoadingAp(true)
            const response_apHeader = await getDetalleApsApi(auth.token, "", "", "", "", "", numAp);
            setLoadingAp(false)

            return response_apHeader
        } catch (error) {
            setLoadingAp(false)
            setError(error)
        }
    }

    const getDetailAp = async (numAp) => {
        try {
            setLoadingAp(true);

            const response_apHeader = await getApsApi(auth.token, numAp);
            const response_apDetail = await getDetalleApsApi(auth.token, '', '', '', '', '', '', numAp);
            const response_empleado_nombre = await getEmpleadoByFilterApi(auth.token, '', 2, '', '', response_apHeader[0].Empleado);
            const response_empleado_cuentaId = await getEmpleadoByFilterApi(auth.token, '', 3, '', '', response_apHeader[0].Empleado);
            const response_cuenta = await getCuentasApi(auth.token, 1, response_empleado_cuentaId[0].Valor);
            const response_empleado_lobId = await getEmpleadoByFilterApi(auth.token, '', 5, '', '', response_apHeader[0].Empleado);
            const response_lob = await getLobsApi(auth.token, true, '', `${response_empleado_lobId[0].Valor}/`);

            const response_empleado_supervisor = await getEmpleadoByFilterApi(auth.token, '', 10, '', '', response_apHeader[0].Empleado);
            const response_supervisor_name = await getEmpleadoByFilterApi(auth.token, '', 2, '', response_empleado_supervisor[0].Valor);
            const response_empleado_account = await getEmpleadoByFilterApi(auth.token, '', 11, '', '', response_apHeader[0].Empleado);
            const response_account_name = await getEmpleadoByFilterApi(auth.token, '', 2, '', response_empleado_account[0].Valor);
            const response_empleado_director = await getEmpleadoByFilterApi(auth.token, '', 12, '', '', response_apHeader[0].Empleado);
            const response_director_name = await getEmpleadoByFilterApi(auth.token, '', 2, '', response_empleado_director[0].Valor);

            let data_empleado_response = {
                Badge: response_empleado_nombre[0].empleado_data.Badge,
                Nombre: response_empleado_nombre[0].Valor,
                Cuenta: response_cuenta[0].Nombre,
                Lob: response_lob.Lob,
                Supervisor: `${response_empleado_supervisor[0].Valor} - ${response_supervisor_name[0].Valor}`,
                Account: `${response_empleado_account[0].Valor} - ${response_account_name[0].Valor}`,
                Director: `${response_empleado_director[0]?.Valor} - ${response_director_name[0]?.Valor}`
            };

            setApHeader(response_apHeader[0]);
            setApDetail(response_apDetail);
            setDataEmpleado(data_empleado_response);

            setLoadingAp(false);
        } catch (error) {
            console.log(error);
            setLoadingAp(false);
            setError(error);
        }
    };

    const ejecutarHoras = async (IdTipoAp, IdAp, badge) => {
        try {
            setLoadingAp(true);

            const response_tipoAp = await getTipoApsApi(auth.token, IdTipoAp);

            console.log("Ejecutar Horas AP");

            if (response_tipoAp.TipoMarca != null) {

                const response_detalleAp = await getDetalleApsApi(auth.token, '', '', '', '', '', IdAp);

                const fecha_inicio = filter(response_detalleAp, (detalle) => {
                    if (detalle.PropiedadAp == 12) {
                        return detalle;
                    } else {
                        if (detalle.PropiedadAp == 11) {
                            return detalle;
                        }
                    }
                });

                const fecha_final = filter(response_detalleAp, (detalle) => {
                    if (detalle.PropiedadAp == 13) {
                        return detalle;
                    } else {
                        if (detalle.PropiedadAp == 11) {
                            return detalle;
                        }
                    }
                });

                let fecha_inicio_dt = new Date(fecha_inicio[0].Valor);
                let fecha_final_dt = new Date(fecha_final[0].Valor);
                let insert_tipomarca = response_tipoAp.TipoMarca;
                let contador = 0;

                while (fecha_inicio_dt <= fecha_final_dt) {
                    contador += 1;

                    if (response_tipoAp.id == 1 && contador > 3) {
                        insert_tipomarca = 51;
                    }
                    const fecha = `${fecha_inicio_dt.getFullYear()}-${fecha_inicio_dt.getMonth() + 1}-${fecha_inicio_dt.getDate()}`;
                    const response_horasProgramadas = await getDetalleMarcaByMarcaFilterApi(auth.token, badge, fecha, 1);
                    const response_horasProgramadas_night = await getDetalleMarcaByMarcaFilterApi(auth.token, badge, fecha, 62);

                    if (size(response_horasProgramadas) > 0) {
                        let horas_prog_real = 0
                        const horas_justificar_ap = filter(response_detalleAp, {"PropiedadAp": 22})
                        const tiempo_completo_bool = filter(response_detalleAp, {"PropiedadAp": 21})
                        if (size(tiempo_completo_bool) > 0){
                            if (tiempo_completo_bool[0].Valor){
                                horas_prog_real = parseFloat(response_horasProgramadas[0]?.Horas) + parseFloat(response_horasProgramadas_night[0]?.Horas)
                            }else{
                                horas_prog_real = horas_justificar_ap[0]?.Valor
                            }
                        }else{
                            horas_prog_real = parseFloat(response_horasProgramadas[0]?.Horas) + parseFloat(response_horasProgramadas_night[0]?.Horas)
                        }
                        const response_existMarca = await getDetalleMarcaByMarcaFilterApi(auth.token, badge, fecha, response_tipoAp.TipoMarca);

                        if (size(response_existMarca) > 0) {
                            await updateDetalleMarcaApi(
                                {
                                    Horas: horas_prog_real
                                },
                                response_existMarca[0].id,
                                auth.token
                            );
                        } else {
                            await addDetalleMarcaApi(
                                {
                                    Marca: response_horasProgramadas[0].Marca,
                                    TipoMarca: insert_tipomarca,
                                    Horas: horas_prog_real
                                },
                                auth.token
                            );
                        }

                        await calcAuxMarca(response_horasProgramadas[0].Marca);
                    }

                    fecha_inicio_dt.setDate(fecha_inicio_dt.getDate() + 1);
                }
            }

            setLoadingAp(false);
        } catch (error) {
            setLoadingAp(false);
            setError(error);
        }
    };

    const ejecutarRoster = async (IdAp, Badge, idEmpleado) => {
        try {
            setLoadingAp(true);

            const response_detalleAp = await getDetalleApsApi(auth.token, '', '', '', '', '', IdAp);

            const horario_ap = find(response_detalleAp, { PropiedadAp: 20 });

            if (size(horario_ap) > 0) {
                const horario_obj = JSON.parse(horario_ap.Valor);

                for await (const dia of Object.keys(horario_obj)) {
                    const destruct_horario = horario_obj[dia];

                    let horas_diurnas = destruct_horario.HorasProgramadas - destruct_horario.horasNocturnas;
                    let horas_nocturnas = destruct_horario.horasNocturnas > destruct_horario.HorasProgramadas ? destruct_horario.horasNocturnas - destruct_horario.Almuerzo : destruct_horario.horasNocturnas;
                    let libre = false;
                    if (destruct_horario.HoraInicio == destruct_horario.HoraFinal) {
                        libre = true;
                        horas_diurnas = 0;
                        horas_nocturnas = 0;
                    }

                    await insertUpdateHorario(idEmpleado, dia, {
                        Dia: dia,
                        HoraInicio: moment(destruct_horario.HoraInicio).format('HH:mm:ss'),
                        HoraFinal: moment(destruct_horario.HoraFinal).format('HH:mm:ss'),
                        Almuerzo: destruct_horario.Almuerzo,
                        TotalHorasDia: horas_diurnas,
                        HorasNocturnas: horas_nocturnas,
                        Libre: libre,
                        Empleado: idEmpleado
                    });
                }
            }

            for await (const propiedad_ap of response_detalleAp) {
                const response_propiedad = await getPropiedadApsApi(auth.token, propiedad_ap.PropiedadAp);

                if (response_propiedad.PropiedadEmpleado != null) {
                    const response_propiedad_exists = await getEmpleadoByFilterApi(auth.token, '', response_propiedad.PropiedadEmpleado, weekYearDate(), Badge);

                    if (size(response_propiedad_exists) > 0) {
                        await updateDetalleEmpleadoApi(
                            {
                                Valor: propiedad_ap.Valor
                            },
                            response_propiedad_exists[0].id,
                            auth.token
                        );
                    } else {
                        await addDetalleEmpleadoApi(
                            {
                                Propiedad: response_propiedad.PropiedadEmpleado,
                                Empleado: idEmpleado,
                                Valor: propiedad_ap.Valor
                            },
                            auth.token
                        );
                    }
                }
            }

            setLoadingAp(false);
        } catch (error) {
            setLoadingAp(false);
            setError(error);
        }
    };

    const onValidRepeatAp = async (Empleado, TipoAp) => {
        try {
            setLoadingAp(true)
            const response_api = await getValidApPendingApi(auth.token, Empleado, TipoAp)
            setLoadingAp(false)

            return response_api
        } catch (error) {
            setLoadingAp(false)
            setError(error)
        }
    }

    return {
        error,
        loadingAp,
        aps,
        apHeader,
        apDetail,
        dataEmpleado,
        insertHeadAp,
        insertDetailAp,
        getAps,
        rejectAp,
        approvedAp,
        getDetailAp,
        ejecutarHoras,
        ejecutarRoster,
        getPersonalAps,
        getApAutomateReport,
        rejectApMultiple,
        getApImage,
        onValidRepeatAp,
        getResponseApDetail,
        updateDetailAp,
        getSlaTimeAp,
        getReportAttrition
    };
}
