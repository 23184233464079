import React, { useEffect, useRef, useState } from 'react';
import moment from "moment"
import {filter, size} from "lodash"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterService } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { useAuth } from '../../hooks/useAuth';
import { useAp, useCommon } from '../../hooks';
import {FromRejectAp} from "../Skyap"
import {listTipoAttrition, listAllotment} from "../../utilities/options"

export function TableAutomateReportAp({ ListAp, Rol }) {
    const toast = useRef();

    const [filters, setFilters] = useState({
        /* name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH }, */
        id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Badge: { value: null, matchMode: FilterMatchMode.CONTAINS },
        NombreEmpleado: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Nombre: { value: null, matchMode: FilterMatchMode.IN },
        Cuenta: { value: null, matchMode: FilterMatchMode.IN },
        AprobacionSupervisor: { value: null, matchMode: FilterMatchMode.CONTAINS },
        AprobacionAccount: { value: null, matchMode: FilterMatchMode.CONTAINS },
        AprobacionDirector: { value: null, matchMode: FilterMatchMode.CONTAINS },
        AprobacionHR: { value: null, matchMode: FilterMatchMode.CONTAINS },
        AprobacionRTA: { value: null, matchMode: FilterMatchMode.CONTAINS },
        EstadoResponse: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // For using custom filters, you must set FilterMatchMode.CUSTOM to matchMode.
        /* activity: { value: null, matchMode: FilterMatchMode.CUSTOM },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS } */
    });
    
    const [representatives] = useState([
        { name: 'Incapacidad Medica', value: 'Incapacidad Medica' },
        { name: 'Permiso sin Goce de Sueldo', value: 'Permiso sin Goce de Sueldo' },
        { name: 'Despido con Indemnizacion', value: 'Despido con Indemnizacion' },
        { name: 'Despido sin Indemnizacion', value: 'Despido sin Indemnizacion' },
        { name: 'New Hire - NCNS', value: 'New Hire - NCNS' },
        { name: 'Abandono de Trabajo', value: 'Abandono de Trabajo' },
        { name: 'Bereavement', value: 'Bereavement' },
        { name: 'Cambio Contrato', value: 'Cambio Contrato' },
        { name: 'Coaching', value: 'Coaching' },
        { name: 'Excepcion de Horario', value: 'Excepcion de Horario' },
        { name: 'Fallecimiento', value: 'Fallecimiento' },
        { name: 'Hiipa Training', value: 'Hiipa Training' },
        { name: 'Incapacidades', value: 'Incapacidades' },
        { name: 'Jubilacion', value: 'Jubilacion' },
        { name: 'Paternidad', value: 'Paternidad' },
        { name: 'Permiso con Goce de Sueldo', value: 'Permiso con Goce de Sueldo' },
        { name: 'Permiso Medico', value: 'Permiso Medico' },
        { name: 'Renuncia', value: 'Renuncia' },
        { name: 'Traslados', value: 'Traslados' },
        { name: 'Reingreso', value: 'Reingreso' },
        { name: 'Solicitud de Vacaciones', value: 'Solicitud de Vacaciones' },
        { name: 'Sanciones Disciplinarias', value: 'Sanciones Disciplinarias' },
        { name: 'Aumento de Salario, Nuevo Puesto, Periodo de Prueba', value: 'Aumento de Salario, Nuevo Puesto, Periodo de Prueba' },
        { name: 'Cambio de Horario', value: 'Cambio de Horario' },
        { name: 'Nivelacion de Salario', value: 'Nivelacion de Salario' },
        { name: 'Wellness Day Col', value: 'Wellness Day Col' },
        { name: 'Maternity', value: 'Maternity' },
    ]);

    const [listEmpleados, setListEmpleados] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [listAprobacionesHr, setListAprobacionesHr] = useState([]);
    const [listApEstados, setListApEstados] = useState([
        {value: "Pendiente", name:"Pendiente"},
        {value: "Procesada", name:"Procesada"}
    ]);

    const { auth } = useAuth();

    const { getCuenta, listCuentas, getLob, listLob, getPlazas, listPlazas, getSkyViewEmpleados } = useCommon();
    const { rejectAp, approvedAp, ejecutarHoras, ejecutarRoster, getApImage, getResponseApDetail, insertDetailAp, updateDetailAp, getSlaTimeAp } = useAp();

    useEffect(() => {
        (async () => {
            await getCuenta()
            await getLob()
            await getPlazas()

            const response_view = await getSkyViewEmpleados()
            setListEmpleados(response_view)

            let consult_rrhh = ['LTSC4894','SV5063','SV4942','SV3977','SV5812','SV1759','SV5658','LT5658'];
            let aprobacion = []

            let response_time = []
            for await (const badge_rrhh of consult_rrhh){
                response_time = await getSlaTimeAp("", badge_rrhh)
                if (size(response_time) > 0){
                    aprobacion = [...aprobacion, ...response_time]
                }
            }

            setListAprobacionesHr(aprobacion)

        })()
    }, [])

    const onAprobarAp = async (ap_data, event) => {
        toast.current.show({
            severity: 'success',
            summary: 'Ap',
            detail: 'Aprobando AP',
            life: 3000
        });

        const padre = event.target.parentNode.parentNode;

        padre.parentNode.removeChild(padre);

        const estado_ap = await approvedAp(ap_data.id);

        if (estado_ap) {
            await ejecutarHoras(ap_data.ID_TipoAp, ap_data.id, ap_data.Badge);
            await ejecutarRoster(ap_data.id, ap_data.Badge, ap_data.ID_Empleado);

            toast.current.show({
                severity: 'success',
                summary: 'Ap',
                detail: 'Ap Aprobada y Ejecutada',
                life: 3000
            });
        } else {
            toast.current.show({
                severity: 'success',
                summary: 'Ap',
                detail: 'Ap Aprobada',
                life: 3000
            });
        }
    };

    const onRechazarAp = async (ap_data, event) => {
        const padre = event.target.parentNode.parentNode;

        padre.parentNode.removeChild(padre);

        await rejectAp(ap_data.id);

        toast.current.show({
            severity: 'warn',
            summary: 'Ap',
            detail: 'Ap Rechazada',
            life: 3000
        });
    };

    const onVerDetalleAp = (ap_data) => {
        window.open(`/#/Skyap/${ap_data.id}`);
    };

    const onVerComentariosImagen = async (RowData) => {

        
        const response_ap_get = await getApImage(RowData.id);
        
        console.log(response_ap_get)
        setShowModal(true);
        setContentModal(<>
        <div className='grid'>
            <div className='col-12'>
                <Image src={response_ap_get[0].File} width='100%' preview />
            </div>
        </div>
        </>);
    }

    const actionBodyTemplate = (RowData) => {
        let bandera_action = false;
        let bandera_tipoAp = false;

        switch(RowData.ID_TipoAp){
            case 3:
            case 4:
            case 5:
            case 6:
            case 19:
                bandera_tipoAp = true;
                break;

        }

        if (auth.me.username == RowData.SuppEmpleado && RowData.AprobacionSupervisor == 'P') {
            bandera_action = true;
        }

        if (auth.me.username == RowData.AccountEmpleado && RowData.AprobacionAccount == 'P') {
            bandera_action = true;
        }

        if (auth.me.username == RowData.DirectorEmpleado && RowData.AprobacionDirector == 'P') {
            bandera_action = true;
        }

        if (auth.me.username == 'LTSC5914' || auth.me.username == 'LTSC5915' || auth.me.username == 'LT2262' || auth.me.username == 'SV5021' || auth.me.username == 'LT6249' || auth.me.username == 'SV6676' || auth.me.username == 'SV6669' || auth.me.username == 'SV6486') {
            if (RowData.AprobacionRTA == 'P') {
                bandera_action = true;
            }
        }

        if (auth.me.username == 'LTSC4894' || auth.me.username == 'SV5063' || auth.me.username == 'SV4942' || auth.me.username == 'SV3977' || auth.me.username == 'SV5812' || auth.me.username == 'SV1759' || auth.me.username == 'SV5658' || auth.me.username == 'LT5658' || auth.me.username == 'SV4378' || auth.me.username == 'SV6570') {
            if (RowData.AprobacionHR == 'P') {
                bandera_action = true;
            }
        }

        return (
            <>
                <div className="grid">
                    <div className="col-12">
                        <Button severity="primary" type="button" onClick={(e) => onVerDetalleAp(RowData)}>
                            Ver Detalle de AP
                        </Button>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <Button severity="primary" type="button" onClick={(e) => onVerComentariosImagen(RowData)}>
                            <i className='pi pi-eye' style={{fontSize: '1rem'}}></i>
                        </Button>
                    </div>
                </div>
                {((auth.me.username == 'LT2262' || auth.me.username == 'SV2091' || auth.me.username == 'LTSC5914' || auth.me.username == 'LTSC5915') && bandera_tipoAp ) && (
                    <div className='grid'>
                        <div className='col-12'>
                            <Button severity='danger' type='button' onClick={() => onModalTipoBaja(RowData)}>
                                Tipo de Baja
                            </Button>
                        </div>
                    </div>
                )}
                {bandera_action && (
                    <>
                        <div className="grid">
                            <div className="col-6">
                                <Button severity="success" type="button" onClick={(e) => onAprobarAp(RowData, e)}>
                                    Aprobar
                                </Button>
                            </div>
                            <div className="col-6">
                                <Button severity="danger" type="button" onClick={(e) => onRechazarApModal(RowData, e)}>
                                    Rechazar
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    const [valorCbx, setValorCbx] = useState(null)
    const [allotmentSt, setAllotmentSt] = useState(null)

    const onModalTipoBaja = async (ValueAp) => {

        const response_detalle = await getResponseApDetail(ValueAp.id)
        const valor_tipoAttrition = filter(response_detalle, ["PropiedadAp", 25])
        const valor_allotment = filter(response_detalle, ["PropiedadAp", 26])
        let valor_cbx = null
        let allotment = null
        let bandera_insert_update = true;
        let bandera_insert_allotment = true;

        if (size(valor_tipoAttrition) > 0){
            setValorCbx(valor_tipoAttrition[0].Valor)
            valor_cbx = valor_tipoAttrition[0].Valor
            bandera_insert_update=false
        }

        if (size(valor_allotment) > 0){
            setAllotmentSt(valor_allotment[0].Valor)
            allotment = valor_allotment[0].Valor
            bandera_insert_allotment=false
        }


        setShowModal(true);
        setContentModal(
            <form>

                <div className='grid'>
                    <div className='col-12'>
                        <h5>Tipo Attrition: {valor_cbx}</h5>
                    </div>
                </div>
                <div className='grid'>
                    <div className='col-12'>
                        <h5>Allotment: {allotment}</h5>
                    </div>
                </div>

                <div className='grid my-5'>
                    <div className='col-12'>
                        <span className='p-float-label'>
                            <Dropdown options={listTipoAttrition} optionLabel='name' className='w-full' value={valor_cbx} onChange={(e) => {
                                if (bandera_insert_update){
                                    insertDetailAp({
                                        Ap: ValueAp.id,
                                        PropiedadAp: 25,
                                        Valor: e.value
                                    });
                                    bandera_insert_update=false
                                }else{
                                    updateDetailAp({
                                        Valor: e.value
                                    },valor_tipoAttrition[0].id)
                                }
                                setValorCbx(e.value)
                                valor_cbx = e.value
                                setShowModal(false);

                                toast.current.show({
                                    severity: 'success',
                                    summary: 'Ap',
                                    detail: 'El estado de attrition cambio correctamente',
                                    life: 3000
                                });

                                
                            }} />
                            <label htmlFor=''>Tipo de Attrition</label>
                        </span>
                    </div>
                </div>
                <div className='grid my-5'>
                    <div className='col-12'>
                        <span className='p-float-label'>
                            <Dropdown options={listAllotment} optionLabel='name' className='w-full' value={allotment} onChange={(e) => {
                                if (bandera_insert_allotment){
                                    insertDetailAp({
                                        Ap: ValueAp.id,
                                        PropiedadAp: 26,
                                        Valor: e.value
                                    });
                                    bandera_insert_allotment=false
                                }else{
                                    updateDetailAp({
                                        Valor: e.value
                                    },valor_tipoAttrition[0].id)
                                }
                                setValorCbx(e.value)
                                allotment = e.value
                                setShowModal(false);

                                toast.current.show({
                                    severity: 'success',
                                    summary: 'Ap',
                                    detail: 'El estado de attrition cambio correctamente',
                                    life: 3000
                                });

                                
                            }} />
                            <label htmlFor=''>Allotment</label>
                        </span>
                    </div>
                </div>
            </form>
        );
    }

    const representativeRowFilterTemplate = (options) => {
        return (
            <>
                <MultiSelect
                    value={options.value}
                    options={representatives}
                    onChange={(e) => options.filterApplyCallback(e.value)}
                    optionLabel="name"
                    placeholder="Any"
                    className="p-column-filter"
                    maxSelectedLabels={1}
                    style={{ minWidth: '14rem' }}
                />
            </>
        );
    };

    const filterSelectAPState = (options) => {
        return (

            <>
            <Dropdown value={options.value} options={listApEstados} placeholder='Estado AP' optionLabel='name' className='p-columns-filter' style={{minWidth: '14rem'}} onChange={(e) => {
                options.filterApplyCallback(e.value);
                
                }} />
            </>

        )
    }

    const onOpenCloseModal = () => setShowModal((prev) => !prev)

    const onRechazarApModal = (idAp, event) => {
        setShowModal(true);
        setContentModal(<FromRejectAp NumAp={idAp.id} event={event} modal={onOpenCloseModal} />);
    };

    const exportExcel = async () => {

        let config_format_ap = []

        
        for (const ap of ListAp){
            
            const detail_ap_data = await getResponseApDetail(ap.id);

            const nombre_cuenta = filter(listCuentas, { value: parseInt(ap.Cuenta) });
            const nombre_lob = filter(listLob, { value: parseInt(ap.Lob) });
            const supervisor_name = filter(listEmpleados, { Badge:  ap.SuppEmpleado });
            const account_name = filter(listEmpleados, { Badge:  ap.AccountEmpleado });
            const director_name = filter(listEmpleados, { Badge:  ap.DirectorEmpleado });

            let ap_data = {     
                "ID_Configuracion": ap.ID_Configuracion,
                "FechaCreacionAp": ap.FechaCreacionAp,
                "id": ap.id,
                "ID_Empleado": ap.ID_Empleado,
                "Badge": ap.Badge,
                "NombreEmpleado": ap.NombreEmpleado,
                "SuppEmpleado": size(supervisor_name) > 0 ? `${supervisor_name[0].Badge} - ${supervisor_name[0].NombreEmpleado}` :  ap.SuppEmpleado,
                "AccountEmpleado": size(account_name) > 0 ? `${account_name[0].Badge} - ${account_name[0].NombreEmpleado}` :  ap.AccountEmpleado,
                "DirectorEmpleado": size(director_name) > 0 ? `${director_name[0].Badge} - ${director_name[0].NombreEmpleado}` :  ap.DirectorEmpleado,
                "Cuenta": size(nombre_cuenta) > 0 ? nombre_cuenta[0].name : ap.Cuenta,
                "Lob": size(nombre_lob) > 0 ? nombre_lob[0].name : ap.Lob,
                "SolicitadoPor": ap.SolicitadoPor,
                "ID_TipoAp": ap.ID_TipoAp,
                "Nombre": ap.Nombre,
                "SuppNeed": ap.SuppNeed,
                "AccountNeed": ap.AccountNeed,
                "DirectorNeed": ap.DirectorNeed,
                "RTANeed": ap.RTANeed,
                "StringTrue": ap.StringTrue,
                "EstadoAp": ap.EstadoResponse,
                "AprobacionSupervisor": ap.AprobacionSupervisor,
                "AprobacionAccount": ap.AprobacionAccount,
                "AprobacionDirector": ap.AprobacionDirector,
                "AprobacionHR": ap.AprobacionHR,
                "AprobacionRTA": ap.AprobacionRTA,
                "StringGet": ap.StringGet,
                "FechaEfectiva": ap.FechaEfectiva,
                "FechaInicio": ap.FechaInicio,
                "FechaFinal": ap.FechaFinal,
                "Observacion": ap.Observacion,
            }

            for await (const detalle_ap of detail_ap_data){
                ap_data[detalle_ap.propiedadAp_data.Nombre] = detalle_ap.Valor
            }

            config_format_ap.push(ap_data)

        }
        
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(config_format_ap);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'HorasPlanilla');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
        </div>
    );

    const templateTiempo = (rowData) => {
        const fechaActual = moment();
        const fechaCreacion = moment(rowData.FechaCreacionAp)

        const diffinDays = fechaActual.diff(fechaCreacion, 'days')
        const diffinHours = fechaActual.diff(fechaCreacion, 'hours')

        return `${diffinDays} days ${diffinHours - (diffinDays * 24)} hours`
    }

    const templateTiempoHR = (rowData) => {

        const fechaAprobacion = filter(listAprobacionesHr, {"Ap": parseInt(rowData.id)})
        
        if (size(fechaAprobacion) > 0){

            const fechaActual = moment(fechaAprobacion[0].created_at);
            const fechaCreacion = moment(rowData.FechaCreacionAp)

            const diffinDays = fechaActual.diff(fechaCreacion, 'days')
            const diffinHours = fechaActual.diff(fechaCreacion, 'hours')

            return `${moment(fechaAprobacion[0].created_at).format("YYYY-MM-DD HH:MM:SS")} - ${diffinDays} days ${diffinHours - (diffinDays * 24)} hours`
        }else{

            return `0000-00-00 00:00:00`
        }

    }

    const templateCuenta = (rowData) => {
        const nombre_cuenta = filter(listCuentas, { value: parseInt(rowData.Cuenta) });

        let nombre_cuenta_str = size(nombre_cuenta) > 0 ? nombre_cuenta[0].name : rowData.Cuenta;

        return (
            <>
            {nombre_cuenta_str}
            </>
        )

    }

    console.log(ListAp)

    return (
        <>
            <Toast ref={toast} />
            <Dialog header="Rechazo de AP" style={{ width: '30vw' }} visible={showModal} modal onHide={() => setShowModal(false)}>
                {contentModal}
            </Dialog>
            <DataTable
                value={ListAp}
                filters={filters}
                filterDisplay="row"
                paginator
                header={header}
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
                <Column filter filterField="id" field="id" header="No de AP"></Column>
                <Column filter filterField="Badge" field="Badge" header="Badge Empleado"></Column>
                <Column filter filterField="NombreEmpleado" field="NombreEmpleado" header="Nombre de Empleado"></Column>
                <Column filter filterField='Cuenta' field="Cuenta" body={templateCuenta} header="Cuenta"></Column>
                <Column
                    header="Tipo de AP"
                    filterField="Nombre"
                    showFilterMenu={false}
                    filterMenuStyle={{ width: "14rem" }}
                    style={{ minWidth: "14rem" }}
                    field="Nombre"
                    filter
                    filterElement={representativeRowFilterTemplate}
                ></Column>
                <Column filter filterField='FechaCreacionAp' field='FechaCreacionAp' header="Creacion de AP"></Column>
                <Column filter filterField='TiempoPendiente' header="Tiempo Transcurrido" body={templateTiempo}></Column>
                <Column filter body={actionBodyTemplate} exportable={false} header="Acciones"></Column>
                <Column filter className="text-center" filterField="AprobacionSupervisor" field="AprobacionSupervisor" header="Aprobacion de Supervisor"></Column>
                <Column filter className="text-center" filterField="AprobacionAccount" field="AprobacionAccount" header="Aprobacion de Account"></Column>
                <Column filter className="text-center" filterField="AprobacionDirector" field="AprobacionDirector" header="Aprobacion de Director"></Column>
                <Column filter className="text-center" filterField="AprobacionHR" field="AprobacionHR" header="Aprobacion de HR"></Column>
                <Column filter filterField='TiempoHr' header="Tiempo" body={templateTiempoHR}></Column>
                <Column filter className="text-center" filterField="AprobacionRTA" field="AprobacionRTA" header="Aprobacion de RTA"></Column>
                <Column
                    header="Estado AP"
                    filterField="EstadoResponse"
                    showFilterMenu={false}
                    filterMenuStyle={{ width: "14rem" }}
                    style={{ minWidth: "14rem" }}
                    field="EstadoResponse"
                    filter
                    filterElement={filterSelectAPState}
                ></Column>
            </DataTable>
        </>
    );
}
