import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from 'primereact/button';
import {InputSwitch} from "primereact/inputswitch"
import { Dialog } from 'primereact/dialog';
import { FilterHoras, TableException, FormExcepcionOffline } from '../../components/Skytracking';
import { useException } from '../../hooks';

export function ExcepcionesPage() {
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);

    const [fechaInicioST, setFechaInicioST] = useState(null);
    const [fechaFinalST, setFechaFinalST] = useState(null);
    const [autoReload, setAutoReload] = useState(false)
    const [datosFormState, setDatosFormState] = useState(null)

    const { exceptions, loadingException, getExceptions, setLoadingException } = useException();

    const onOpenShowModal = () => setShowModal((prev) => !prev);
    const onReloadData = () => {
        setTimeout(() => {
            onResponseForm(datosFormState)
            setLoadingException(false)
            onReloadData()
        }, 5000)
    };

    useEffect(()=>{
        if(autoReload){
            onReloadData()
        }else{
            

        }
    },[autoReload])

    const onResponseForm = async (formData) => {
        setDatosFormState(formData)
        const fechaInicio = new Date(formData.RangoFechas[0]);
        const fechaFinal = new Date(formData.RangoFechas[1]);

        /* const fechaInicio_str = `${fechaInicio.getFullYear()}-${fechaInicio.getMonth() + 1}-${fechaInicio.getDate()}`;
        const fechafinal_str = `${fechaFinal.getFullYear()}-${fechaFinal.getMonth()}-${fechaFinal.getDate()}`; */
        const fechaInicio_str = moment(fechaInicio).format('YYYY-MM-DD');
        const fechafinal_str = moment(fechaFinal).format('YYYY-MM-DD');

        await getExceptions(fechaInicio_str, fechafinal_str, formData.Cuenta, formData.Lob, formData.EstadoExcepcion);

        setFechaInicioST(fechaInicio_str);
        setFechaFinalST(fechafinal_str);
    };

    const onOpenModalException = () => {
        setShowModal(true);
        setContentModal(<FormExcepcionOffline close={onOpenShowModal} />);
    };

    return (
        <>
            <Dialog header="Skyverse Skytracking" style={{ width: '50vw' }} visible={showModal} modal onHide={() => setShowModal(false)}>
                {contentModal}
            </Dialog>
            <div className="grid">
                <div className="col-12 text-right">
                    <Button severity="primary" type="button" size="large" onClick={onOpenModalException}>
                        Agregar Excepcion
                    </Button>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Excepciones</h5>
                        <p>Modulo de excepciones Skyverse Skytracking. Completa todos los campos para poder consultar las excepciones de tu team</p>
                        <FilterHoras
                            requiredFields={{
                                Cuenta: false,
                                EstadoExcepcion: true
                            }}
                            showFields={{
                                RangoFechas: true,
                                Cuenta: true,
                                Lob: true,
                                EstadoExcepcion: true
                            }}
                            responseForm={onResponseForm}
                        />
                    </div>
                </div>
            </div>

            {loadingException ? (
                <div className="grid mt-5">
                    <div className="col-12 text-center">
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                exceptions && (
                    <>
                        <div className="grid mb-2">
                            <div className="col-12 text-right">
                                <InputSwitch checked={autoReload} onChange={(e) => {
                                    setAutoReload(e.value)
                                    if(!e.value){
                                        window.location.reload()
                                    }
                                }}>Reload</InputSwitch>
                            </div>
                        </div>
                        <div className="grid mt-5">
                            <div className="col-12">
                                <div className="card">
                                    <h5>Tabla Excepciones</h5>
                                    <p>
                                        Consultando excepciones desde {fechaInicioST} hasta {fechaFinalST}. Aqui puedes aprobar o rechazar las excepciones
                                    </p>

                                    <TableException excepciones={exceptions} />
                                </div>
                            </div>
                        </div>
                    </>
                )
            )}
        </>
    );
}
