import React from 'react';
import { useNavigate } from 'react-router-dom';

const AppFooter = () => {
    const navigate = useNavigate();

    return (
        <div className="layout-footer">
            <div className="grid">
                <div className="col-12  lg:col-4">
                    <div className="grid">
                        <div className="col-6 ">
                            <span className="footer-menutitle">SITE MAP</span>
                            <ul>
                                <li>
                                    <button className="p-link" onClick={() => navigate('/')}>
                                        Dashboard
                                    </button>
                                </li>
                                <li>
                                    <button className="p-link" onClick={() => navigate('Skytracking/HorasPersonales')}>
                                        Horas Personales
                                    </button>
                                </li>
                                <li>
                                    <button className="p-link" onClick={() => navigate('Skyprofile')}>
                                        Perfil
                                    </button>
                                </li>
                                <li>
                                    <button className="p-link" onClick={() => navigate('Skyprofile/ResetPassword')}>
                                        Cambiar Contraseña
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <span className="footer-menutitle"></span>
                            <ul>
                                <li>
                                    <button className="p-link" onClick={() => navigate('Skyprofile/PayStub')}>
                                        Boleta de Pago
                                    </button>
                                </li>
                                {/* <li>
                                    <button className="p-link" onClick={() => navigate('/utilities/widgets')}>
                                        Widgets
                                    </button>
                                </li>
                                <li>
                                    <button className="p-link" onClick={() => navigate('/start/documentation')}>
                                        Documentation
                                    </button>
                                </li>
                                <li>
                                    <a href="https://www.primefaces.org/store">Buy Now</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <span className="footer-menutitle">CONTACT US</span>
                    <ul>
                        <li>( + 503 ) 7597 4075</li>
                        <li>Calle El Boqueron, Bulevard Orden de Malta</li>
                        <li>Santa Elena, Antiguo Cuscatlan, #6</li>
                    </ul>
                </div>
                <div className="col-12 md:col-6 lg:col-5">
                    <span className="footer-menutitle">NEWSLETTER</span>
                    <span className="footer-subtitle">No hay noticias que mostrar</span>
                </div>
                <div className="col-12">
                    <div className="footer-bottom">
                        <h4>SkyVerse</h4>
                        <h6>Copyright 2023 &copy; SkyCom | WebExperience - Danilo Rauda</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppFooter;
