import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { listSociedades, listBancos, listTiposAfp } from '../../../utilities/options';

export function PayrollTab({ formik }) {
    return (
        <div className="grid mt-5 p-3">
            <div className="col-12">
                <h3>Informacion de Planilla</h3>

                <div className="grid mt-5">
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <label htmlFor="">Contrato</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" options={listSociedades} optionLabel="name" name="Sociedad" id="Sociedad" value={formik.values.Sociedad} onChange={(e) => formik.setFieldValue('Sociedad', e.value)} />
                            <label htmlFor="Sociedad">Sociedad SV - LT</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputNumber className="w-full" maxFractionDigits={2} name="Salario" id="Salario" value={formik.values.Salario} onChange={(e) => formik.setFieldValue('Salario', e.value)} />
                            <label htmlFor="Salario">Salario Ordinario</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputNumber className="w-full" maxFractionDigits={2} allowEmpty={true} name="Bono" id="Bono" value={formik.values.Bono} onChange={(e) => formik.setFieldValue('Bono', e.value)} />
                            <label htmlFor="Bono">Bonificacion</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputNumber className="w-full" maxFractionDigits={2} allowEmpty={true} name="Comision" id="Comision" value={formik.values.Comision} onChange={(e) => formik.setFieldValue('Comision', e.value)} />
                            <label htmlFor="Comision">Comision</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" options={listBancos} optionLabel="name" name="Banco" id="Banco" filter value={formik.values.Banco} onChange={(e) => formik.setFieldValue('Banco', e.value)} />
                            <label htmlFor="Banco">Banco</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputNumber className="w-full" maxFractionDigits={2} name="CuentaBanco" id="CuentaBanco" useGrouping={false} value={formik.values.CuentaBanco} onChange={(e) => formik.setFieldValue('CuentaBanco', e.value)} />
                            <label htmlFor="CuentaBanco">Cuenta Bancaria</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputNumber className="w-full" maxFractionDigits={2} name="NumeroISSS" id="NumeroISSS" useGrouping={false} value={formik.values.NumeroISSS} onChange={(e) => formik.setFieldValue('NumeroISSS', e.value)} />
                            <label htmlFor="NumeroISSS">Numero ISSS</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputNumber className="w-full" maxFractionDigits={2} name="Nup" id="Nup" useGrouping={false} value={formik.values.Nup} onChange={(e) => formik.setFieldValue('Nup', e.value)} />
                            <label htmlFor="Nup">Nup</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" options={listTiposAfp} optionLabel="name" name="TipoAfp" id="TipoAfp" filter value={formik.values.TipoAfp} onChange={(e) => formik.setFieldValue('TipoAfp', e.value)} />
                            <label htmlFor="TipoAfp">AFP</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputNumber className="w-full" maxFractionDigits={2} name="AFP" id="AFP" useGrouping={false} value={formik.values.AFP} onChange={(e) => formik.setFieldValue('AFP', e.value)} />
                            <label htmlFor="AFP">AFP Number</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputNumber className="w-full" maxFractionDigits={2} allowEmpty={true} name="IPSFA" id="IPSFA" useGrouping={false} value={formik.values.IPSFA} onChange={(e) => formik.setFieldValue('IPSFA', e.value)} />
                            <label htmlFor="IPSFA">IPSFA</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Calendar className="w-full" dateFormat="dd/mm/yy" name="FechaEgreso" id="FechaEgreso" value={formik.values.FechaEgreso} onChange={(e) => formik.setFieldValue('FechaEgreso', e.value)} />
                            <label htmlFor="FechaEgreso">Fecha Egreso</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
