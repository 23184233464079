import { BASE_API } from '../../utilities/constants';

export async function getEmpleadosApi(token, Week, Badge = '') {
    try {
        const weekFilter = `Week=${Week}`;
        const badgeFilter = `Badge=${Badge}`;

        const url = `${BASE_API}v1/Empleado/?${weekFilter}&${badgeFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getEmpleadoIdApi(token, idEmpleado) {
    try {
        const idEmpleadoFilter = `id=${idEmpleado}`;
        const url = `${BASE_API}v1/Empleado/?${idEmpleadoFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getSkyEmpleadosApi(token) {
    try {
        const url = `${BASE_API}v1/SkyEmpleados/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getSkyEmpleadosPersoApi(token) {
    try {
        const url = `${BASE_API}v1/DashboardRoster/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getMasterRosterAutomate(token, Semana='', Cuenta='', Lob='') {
    try {

        const SemanaFilter = `Week=${Semana}`
        const CuentaFilter = `Cuenta=${Cuenta}`
        const LobFilter = `Lob=${Lob}`

        const url = `${BASE_API}v1/MasterRosterReport/?${SemanaFilter}&${CuentaFilter}&${LobFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getEmpleadoApi(token) {
    try {
        const url = `${BASE_API}v1/Empleado/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addEmpleadoApi(data, token) {
    try {
        const url = `${BASE_API}v1/Empleado/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateEmpleadoApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/Empleado/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
