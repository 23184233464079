import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { filter, size, reject } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { usePrestamoSia, useCommonSia } from '../../hooks';

export function RecibirPrestamoPage() {
    const [listPrestamosRecibir, setListPrestamosRecibir] = useState([]);
    const [comentariosPrestamo, setComentariosPrestamo] = useState([]);
    const [imprimirState, setImprimirState] = useState(false);

    const { prestamo, empleado } = useParams();
    const { getRecibirPrestamo } = usePrestamoSia();
    const { getEstados, listEstados, getUbicaciones, listUbicaciones } = useCommonSia();

    const toast = useRef(null);
    const btnRecibir = useRef(null);

    useEffect(() => {
        (async () => {
            const response_prestamo_recibir = await getRecibirPrestamo(empleado, prestamo);
            await getEstados();
            await getUbicaciones();

            setComentariosPrestamo(
                response_prestamo_recibir.map(
                    (componente) =>
                        componente.componente_data.tipoComponente_data.id !== 2 && {
                            ID_Componente: componente.componente_data.id,
                            serial_temp: componente.componente_data.Serial,
                            comentario_temp: '',
                            estado_temp: componente.componente_data.EstadoComponente,
                            ubicacion_temp: componente.componente_data.Ubicacion
                        }
                )
            );

            setListPrestamosRecibir(() => reject(response_prestamo_recibir, ['componente_data.tipoComponente_data.id', 2]));
        })();
    }, []);

    const saveComentarioTemp = (rowData, comentario_val) => {
        setComentariosPrestamo((prevComentarios) => prevComentarios.map((comentario) => (comentario.serial_temp === rowData.componente_data.Serial ? { ...comentario, comentario_temp: comentario_val } : comentario)));
    };

    const onRecibirEquipo = async () => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: async () => {
                setImprimirState(true);
                for await (const recibir of comentariosPrestamo) {
                    if (size(recibir) > 0) {
                        if (recibir.comentario_temp != '') {
                            console.log('Pendiente');
                            console.log(recibir);
                        } else {
                            console.log('Recibir');
                            console.log(recibir);
                        }
                    }
                }

                
                toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
            },
            reject: () => {
                toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
            }
        });

        
    };

    const templateNotas = (rowData) => {
        const notas_actual = filter(comentariosPrestamo, ['serial_temp', rowData.componente_data.Serial]);

        return (
            <>
                <InputTextarea className="w-full" value={notas_actual[0].comentario_temp} onChange={(e) => saveComentarioTemp(rowData, e.target.value)} />
            </>
        );
    };

    const templateEstados = (rowData) => {
        const estado_actual = filter(comentariosPrestamo, ['serial_temp', rowData.componente_data.Serial]);

        return (
            <>
                <Dropdown
                    filter
                    className="w-full"
                    value={estado_actual[0].estado_temp}
                    options={listEstados}
                    optionLabel="name"
                    onChange={(e) => setComentariosPrestamo((prevComentarios) => prevComentarios.map((comentario) => (comentario.serial_temp === rowData.componente_data.Serial ? { ...comentario, estado_temp: e.value } : comentario)))}
                />
            </>
        );
    };

    const templateUbicacion = (rowData) => {
        const ubicacion_actual = filter(comentariosPrestamo, ['serial_temp', rowData.componente_data.Serial]);

        return (
            <>
                <Dropdown
                    filter
                    className="w-full"
                    value={ubicacion_actual[0].ubicacion_temp}
                    options={listUbicaciones}
                    optionLabel="name"
                    onChange={(e) => setComentariosPrestamo((prevComentarios) => prevComentarios.map((comentario) => (comentario.serial_temp === rowData.componente_data.Serial ? { ...comentario, ubicacion_temp: e.value } : comentario)))}
                />
            </>
        );
    };

    const templateDeleteComponente = (rowData) => {
        return (
            <>
                <Button severity="danger" onClick={() => setListPrestamosRecibir((prevComponente) => reject(prevComponente, ['componente_data.Serial', rowData.componente_data.Serial]))}>
                    X
                </Button>
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Recibir Prestamo SIA</h5>
                        <p>Aqui puedes recibir el prestamo de SIA</p>

                        <DataTable value={listPrestamosRecibir}>
                            <Column header="Componente" field="componente_data.tipoComponente_data.TipoComponente"></Column>
                            <Column header="Serial" field="componente_data.Serial"></Column>
                            <Column header="Estado" body={templateEstados}></Column>
                            <Column header="Ubicacion" body={templateUbicacion}></Column>
                            <Column header="Notas" body={templateNotas}></Column>
                            <Column header="Acciones" body={templateDeleteComponente}></Column>
                        </DataTable>

                        <div className="grid my-4">
                            <div className="col-12 text-right">
                                <Button type="button" size="large" onClick={onRecibirEquipo} ref={btnRecibir}>
                                    Recibir Equipo
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div className="grid my-2">
                            <div className="col-12 text-center">
                                <h4>comprobante de entrega de equipo</h4>
                            </div>
                        </div>
                        <div className="grid my-2">
                            <div className="col-12">
                                <p>
                                    Por este medio Yo, SV085 RICARDO JOSE FERNANDEZ DELGADO, El dia, 07 de 08 del año 2024, declaro que entrego el siguiente equipo al área de IT el cual que se me asigno al momento de mi contratación para el
                                    desarrollo de mis funciones en la empresa.
                                </p>
                            </div>
                        </div>
                        <div className="grid my-2">
                            <div className="col-12">
                                <DataTable value={listPrestamosRecibir}>
                                    <Column header="Componente" field="componente_data.tipoComponente_data.TipoComponente"></Column>
                                    <Column header="Serial" field="componente_data.Serial"></Column>
                                </DataTable>
                            </div>
                        </div>
                        <div className="grid my-2">
                            <div className="col-9">
                                <p>Nombre Empleado:</p>
                            </div>
                            <div className="col-3">
                                <p>Firma:_________________________</p>
                            </div>
                        </div>
                        <div className="grid my-2">
                            <div className="col-9">
                                <p>Nombre IT:</p>
                            </div>
                            <div className="col-3">
                                <p>Firma:_________________________</p>
                            </div>
                        </div>
                        <div className="grid my-2">
                            <div className="col-9">
                                <p>Nombre HR:</p>
                            </div>
                            <div className="col-3">
                                <p>Firma:_________________________</p>
                            </div>
                        </div>

                        {imprimirState && (

                            <div className='grid'>
                                <div className='col-12 text-right'>
                                <Button type="button" size="large">
                                        Imprimir
                                    </Button>
                                </div>
                            </div>

                        )}


                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div className='grid my-3'>
                            <div className='col-12'>
                                <h2>Fecha</h2>
                            </div>
                        </div>
                        <div className="grid my-2">
                            <div className="col-12 text-right">
                                <b>
                                    <p>August 7 - 2024, La Libertad, Antiguo Cuscatlan</p>
                                </b>
                                <b>
                                    <p>Skycom El Salvador, S.A. de C.V.</p>
                                </b>
                            </div>
                        </div>
                        <div className="grid my-2">
                            <div className="col-12">
                                <p>Se autoriza a RICARDO JOSE FERNANDEZ DELGADO Codigo SV085 a mover el siguiente equipo por parte de SKYCOM, desde SKYCOM hacia Direccion, segun el siguiente detalle:</p>
                            </div>
                        </div>
                        <div className="grid my-2">
                            <div className="col-12">
                                <DataTable value={listPrestamosRecibir}>
                                    <Column header="Componente" field="componente_data.tipoComponente_data.TipoComponente"></Column>
                                    <Column header="Serial" field="componente_data.Serial"></Column>
                                </DataTable>
                            </div>
                        </div>

                        <div className="grid my-2">
                            <div className="col-12">
                                <p>
                                    Se le da salida al equipo especificado anteriormente por motivos de Asinacion de equipo para trabajo desde casa. Es responsabilidad del empleado mantenerlo en buenas condiciones y retornarlo cuando Skycom lo
                                    requiera.
                                </p>
                                <p>
                                    El equipo especificado es propiedad de <b>Skycom El Salvador</b>
                                </p>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-3">
                                <div className="grid">
                                    <div className="col-12">
                                        <p>F.____________________</p>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-12">
                                        <p>Vigilancia</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="grid">
                                    <div className="col-12">
                                        <p>F.____________________</p>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-12">
                                        <p>Site Manager</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="grid">
                                    <div className="col-12">
                                        <p>F.____________________</p>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-12">
                                        <p>IT</p>
                                        <p>Nombre IT</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="grid">
                                    <div className="col-12">
                                        <p>F.____________________</p>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-12">
                                        <p>Agente</p>
                                        <p>Nombre Agente</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
