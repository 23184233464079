import { BASE_API } from '../../utilities/constants';

export async function getPropiedadApsApi(token, IdPropiedad) {
    try {
        const url = `${BASE_API}v1/PropiedadAp/${IdPropiedad}/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addPropiedadApApi(data, token) {
    try {
        const url = `${BASE_API}v1/PropiedadAp/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updatePropiedadApApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/PropiedadAp/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
