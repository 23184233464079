import React, { useEffect, useRef, useState } from 'react';
import {size} from "lodash"
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog"
import {Toast} from "primereact/toast"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCommon, useUploadFiles } from '../../hooks';

export function FormRosterUpdatePanel({ rowSelected, onCloseSelf }) {
    const [visible, setVisible] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [listTableData, setListTableData] = useState(null);
    const [optionAux, setOptionAux] = useState(null);

    const toast = useRef(null)

    const accept = () => {
        toast.current.show({
            severity: "info", summary: "Procesando cambios", detail: "Se estan aplicando los cambios al roster", life: 3000
        })

        formik.submitForm()
    }

    const reject = () => {
        toast.current.show({
            severity: "warn", summary: "Cambios Rechazados", detail: "No se Aplicaran los cambios", life: 3000
        })
    }

    const confirm = () => {
        confirmDialog({
            message: `Seguro que quieres aplicar estos cambios a ${size(rowSelected)} empleados`,
            header: "Cambios Masivos",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: 'reject',
            accept,
            reject
        })
    }

    const { getCuenta, getPlazas, listCuentas, listPlazas, getPropiedades, listPropiedades, getAccountManagers, getDirectores, getSupervisores, listSupervisores, listAccountManager, listDirectores } = useCommon();
    const {insertDetalleEmpleado} = useUploadFiles()

    useEffect(() => {
        (async () => {
            await getPropiedades();
        })();
    }, []);

    useEffect(() => {
        if(size(listCuentas) > 0){

            setListTableData(listCuentas);
            setVisible(true);

        }
    }, [listCuentas])

    useEffect(() => {
        if(size(listPlazas) > 0){

            setListTableData(listPlazas);
            setVisible(true);
            
        }
    }, [listPlazas])

    useEffect(() => {
        if(size(listDirectores) > 0){

            setListTableData([...listSupervisores, ...listAccountManager, ...listDirectores]);
            setVisible(true);
            
        }
    }, [listDirectores])

    const formik = useFormik({
        initialValues: initialValues(rowSelected),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            for await (const emp of formValue.Empleados){
                await insertDetalleEmpleado(emp.IdEmpleado, formValue.Propiedad, formValue.Valor)
            }

            onCloseSelf()
        }
    });

    const onSelectOption = async (tipo_search) => {
        switch (tipo_search) {
            case 1:
                await getCuenta();
                break;

            case 2:
                await getSupervisores();
                await getAccountManagers();
                await getDirectores();

                break;

            case 3:
                await getPlazas();
                
                break;

            default:
                break;
        }
    };

    return (
        <form className="py-3" onSubmit={formik.handleSubmit}>
            <Dialog header="Select Option" visible={visible} style={{ width: '20vw' }} onHide={() => setVisible(false)}>
                <DataTable
                    value={listTableData}
                    selectionMode="single"
                    selection={optionAux}
                    onSelectionChange={(e) => {
                        setVisible(false);
                        formik.setFieldValue('Valor', e.value.value);
                    }}
                >
                    <Column header="Valor" field="name"></Column>
                </DataTable>
            </Dialog>
            <Toast ref={toast} />
            <ConfirmDialog />
            <h5>Modificaciones Roster Update</h5>
            <p>Modifica Valores Masivos para el roster update, los cambios en este modulo afectaran a todos los empleados seleccionados</p>
            <div className="grid">
                <div className="col-12 md:col-8">
                    <div className="grid my-5">
                        <div className="col-12 md:col-6 mb-3">
                            <span className="p-float-label">
                                <Dropdown value={formik.values.Propiedad} onChange={(e) => formik.setFieldValue("Propiedad", e.value)} options={listPropiedades} optionLabel="name" filter className="w-full" id="Propiedad" name="Propiedad" />
                                <label htmlFor="Propiedad">Propiedad</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-6 mb-3">
                            <span className="p-float-label">
                                <InputText className="w-full" id="Valor" name="Valor" value={formik.values.Valor} onChange={formik.handleChange} />
                                <label htmlFor="Valor">Valor</label>
                            </span>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-3">
                            <Button severity="primary" type="button" onClick={() => onSelectOption(1)}>
                                Select Account or Lobs
                            </Button>
                        </div>
                        <div className="col-12 md:col-3">
                            <Button severity="primary" type="button" onClick={() => onSelectOption(2)}>
                                Select Supervisor, Account Manager or Director
                            </Button>
                        </div>
                        <div className="col-12 md:col-3">
                            <Button severity="primary" type="button" onClick={() => onSelectOption(3)}>
                                Select Plazas
                            </Button>
                        </div>
                    </div>
                    <div className='grid'>
                        <div className='col-12'>
                            <Button type='button' onClick={confirm} size='large' severity='success'>Realizar Cambios</Button>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <DataTable value={formik.values.Empleados}>
                        <Column field="Badge" header="Badge"></Column>
                        <Column field="2" header="Nombre"></Column>
                    </DataTable>
                </div>
            </div>
        </form>
    );
}

function initialValues(rowSelected) {
    return {
        Propiedad: '',
        Valor: '',
        Empleados: rowSelected
    };
}

function validationSchema() {
    return {
        Propiedad: Yup.string().required(),
        Valor: Yup.string().required(),
        Empleados: Yup.array().required()
    };
}
