import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { useCommon } from '../../hooks';

export function SkyFilterHoras({ Campos, responsefc }) {
    const [listEmpleadosComponente, setListEmpleadoComponente] = useState(null);

    const { getSkyViewEmpleados } = useCommon();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: (formValue) => {
            responsefc(formValue);
        }
    });

    console.log(formik.errors)

    useEffect(() => {
        (async () => {
            const response_empleados = await getSkyViewEmpleados();
            let format_empleados = [];
            let list_empleados_api = [];

            for (const empleado of response_empleados) {
                format_empleados.push({
                    name: `${empleado.Badge} - ${empleado.NombreEmpleado} - ${empleado.Cuenta}`,
                    value: empleado
                });

                list_empleados_api.push(empleado.Badge)
            }

            setListEmpleadoComponente(format_empleados);
            formik.setFieldValue("EmpleadosAsignados", list_empleados_api);
        })();
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">

                {Campos?.Fecha && (

                    <div className="md:col-4 col-12">
                        <span className="p-float-label">
                            <Calendar name="Fecha" id="Fecha" className="w-full" value={formik.values.Fecha} onChange={(e) => formik.setFieldValue('Fecha', e.value)} />
                            <label htmlFor="Fecha">Fecha</label>
                        </span>
                    </div>

                )}

                {Campos?.RangoFechas && (

                    <div className="md:col-4 col-12">
                        <span className="p-float-label">
                            <Calendar name="RangoFechas" id="RangoFechas" className="w-full" value={formik.values.RangoFechas} onChange={(e) => formik.setFieldValue('RangoFechas', e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection />
                            <label htmlFor="RangoFechas">Rango de Fechas</label>
                        </span>
                    </div>

                )}

                {Campos?.Empleado && (
                    
                    <div className="md:col-4 col-12">
                        <span className="p-float-label">
                            <Dropdown id="Empleado" name="Empleado" className="w-full" value={formik.values.Empleado} onChange={(e) => formik.setFieldValue('Empleado', e.value)} options={listEmpleadosComponente} optionLabel="name" filter />
                            <label htmlFor="Empleado">Empleados Asignados</label>
                        </span>
                    </div>
                    
                )}

                {Campos?.EmpleadosMultiple && (
                    
                    <div className="md:col-4 col-12">
                        <span className="p-float-label">
                            <MultiSelect id="EmpleadosMultiple" name="EmpleadosMultiple" className="w-full" value={formik.values.EmpleadosMultiple} onChange={(e) => formik.setFieldValue('EmpleadosMultiple', e.value)} options={listEmpleadosComponente} optionLabel="name" filter />
                            <label htmlFor="EmpleadosMultiple">Empleados Asignados</label>
                        </span>
                    </div>
                    
                )}
            </div>
            <div className="grid">
                <div className="col-12 text-right">
                    <Button type="submit" severity="primary" size="large">
                        Aplicar Filtros
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        Fecha: '',
        RangoFechas: '',
        Empleado: '',
        EmpleadosAsignados: "",
        EmpleadosMultiple: ""
    };
}

function validationSchema() {
    return {
        Fecha: Yup.string(),
        RangoFechas: Yup.array(),
        Empleado: Yup.object(),
        EmpleadosAsignados: Yup.array(),
        EmpleadosMultiple: Yup.array()
    };
}
