import { BASE_API } from '../../utilities/constants';

export async function getMarcasApi(token, Fecha = '', FechaInicio = '', FechaFinal = '') {
    try {
        const fechaFilter = `Fecha=${Fecha}`;
        const fechaInicioFilter = `Fecha__gte=${FechaInicio}`;
        const fechaFinalFilter = `Fecha__lte=${FechaFinal}`;

        const url = `${BASE_API}v1/Marca/?${fechaFilter}&${fechaInicioFilter}&${fechaFinalFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getSkyViewMarcasApi(token, Fecha = '', Cuenta='', Lob='') {
    try {
        const fechaFilter = `Fecha=${Fecha}`;
        const cuentaFilter = `Cuenta=${Cuenta}`;
        const lobFilter = `Lob=${Lob}`;

        const url = `${BASE_API}v1/SkyViewMarcas/?${fechaFilter}&${cuentaFilter}&${lobFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getHoursSkyviewApi(token, FechaInicio="", FechaFinal="", Empleado="") {
    try {
        const FechaInicio_filter = `FechaInicio=${FechaInicio}`;
        const FechaFinal_filter = `FechaFinal=${FechaFinal}`;
        const Empleado_filter = `Empleado=${Empleado}`;
        const url = `${BASE_API}v1/SkyHoursView/?${FechaInicio_filter}&${FechaFinal_filter}&${Empleado_filter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getStaffTimeApi(token, FechaInicio, FechaFinal) {
    try {
        const fechaInicioFilter = `FechaInicio=${FechaInicio}`;
        const fechaFinalFilter = `FechaFinal=${FechaFinal}`;

        const url = `${BASE_API}v1/StaffTime/?${fechaInicioFilter}&${fechaFinalFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getMarcasByFiltersApi(token, Badge, Fecha) {
    try {
        const badgeFilter = `Empleado__Badge=${Badge}`;
        const fechaFilter = `Fecha=${Fecha}`;

        const url = `${BASE_API}v1/Marca/?${fechaFilter}&${badgeFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addMarcaApi(data, token) {
    try {
        const url = `${BASE_API}v1/Marca/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateMarcaApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/Marca/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
