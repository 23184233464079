import React from "react";
import {FiltrosSam} from "../../components/skyapplicantmanagement"

export function AplicantesSupervisor () {

    const list_filtros = {
        FechaAplicacion: false,
        EstadoAplicante: false,
        Pauta: false,
        Idioma: false,
        NivelIdioma: false,
        Pais: false,
        Nombre: true,
        Cuenta: true,
        Job: false,
        FechaAprobacion: false,
        Referido: true,
    }

    const onResponseForm = async (FormData) => {
        console.log(FormData)
    }

    return (
        <>
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Reporte de Lead</h5>
                    <p>Selecciona los campos para poder filtrar a los aplicantes</p>

                    <FiltrosSam filtros={list_filtros} response={onResponseForm} />
                </div>
            </div>
        </div>

        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>TABLA</h5>
                </div>
            </div>
        </div>
        </>
    )
}