import { BASE_API } from '../../utilities/constants';

export async function getExcepcionesApi(token, FechaInicio = '', FechaFinal = '', Estado = '', Empleados = '', TipoMarca='') {
    try {
        const fechaInicioFilter_less = `FechaInicio__lte=${FechaFinal}`;
        const fechaInicioFilter_greater = `FechaInicio__gte=${FechaInicio}`;

        const fechaFinalFilter_less = `FechaFin__lte=${FechaFinal}`;
        const fechaFinalFilter_greater = `FechaFin__gte=${FechaInicio}`;

        const estadoFilter = `EstadoExcepcion=${Estado}`;
        const tipoMarcaFilter = `TipoMarca=${TipoMarca}`;

        const empleadosFilter = `Empleado__Badge__in=${Empleados}`;

        const url = `${BASE_API}v1/Excepcion/?${fechaInicioFilter_greater}&${fechaInicioFilter_less}&${fechaFinalFilter_greater}&${fechaFinalFilter_less}&${estadoFilter}&${empleadosFilter}&${tipoMarcaFilter}`;
        //const url = `${BASE_API}v1/Excepcion/?${fechaInicioFilter_greater}&${fechaFinalFilter_less}&${estadoFilter}&${empleadosFilter}&${tipoMarcaFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getOnlyExceptionApi(token, idExcepcion) {
    try {
        const url = `${BASE_API}v1/Excepcion/${idExcepcion}/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
export async function getSkyExceptionApi(token, FechaInicio="", FechaFinal="", Cuenta="", Estado='') {
    try {

        const fechaInicioFilter = `FechaInicio=${FechaInicio}`
        const fechaFinalFilter = `FechaFinal=${FechaFinal}`
        const cuentaFilter = `Cuenta=${Cuenta}`
        const estadoFilter = `Estado=${Estado}`

        const url = `${BASE_API}v1/SkyException/?${fechaInicioFilter}&${fechaFinalFilter}&${cuentaFilter}&${estadoFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addExcepcionApi(data, token) {
    try {

        const formData = new FormData()
        
        formData.append("File", data.File)
        formData.append("TipoMarca", data.TipoMarca)
        formData.append("Motivo", data.Motivo)
        formData.append("Empleado", data.Empleado)
        formData.append("FechaInicio", data.FechaInicio)
        formData.append("FechaFin", data.FechaFin)
        formData.append("ValorExcepcion", data.ValorExcepcion)
        formData.append("SolicitadoPor", data.SolicitadoPor)
        formData.append("EstadoExcepcion", data.EstadoExcepcion)

        const url = `${BASE_API}v1/Excepcion/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateExcepcionApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/Excepcion/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
