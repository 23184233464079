import { BASE_API } from '../../utilities/constants';

export async function getDetalleApsApi(token, Valor_exact = '', Valor_gte = '', Valor_lte = '', PropiedadAp_exact = '', PropiedadAp_in = '', Ap_exact = '', Ap_in = '', Badge_in = '', TipoAp = '', Empleado = '') {
    try {
        const valorExactFilter = `Valor=${Valor_exact}`;
        const valorGteFilter = `Valor__gte=`;
        const valorLteFilter = `Valor__lte=`;
        const propiedadApExactFilter = `PropiedadAp=${PropiedadAp_exact}`;
        const propiedadApInFilter = `PropiedadAp__in=${PropiedadAp_in}`;
        const apExactFilter = `Ap=${Ap_exact}`;
        const apInFilter = `Ap__in=${Ap_in}`;
        const badgeInFilter = `Ap__Empleado__Badge__in=${Badge_in}`;
        const tipoApFilter = `Ap__TipoAp=${TipoAp}`;
        const empleadoFilter = `Ap__Empleado=${Empleado}`;
        const creacionGteFilter = `Ap__created_at__gte=${Valor_gte != "" ? `${Valor_gte} 00:00:00` : ""}`
        const creacionLteFilter = `Ap__created_at__lte=${Valor_lte != "" ? `${Valor_lte} 23:59:59` : ""}`

        const url = `${BASE_API}v1/DetalleAp/?${valorExactFilter}&${valorGteFilter}&${valorLteFilter}&${propiedadApExactFilter}&${propiedadApInFilter}&${apExactFilter}&${apInFilter}&${badgeInFilter}&${tipoApFilter}&${empleadoFilter}&${creacionGteFilter}&${creacionLteFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addDetalleApApi(data, token) {
    try {
        const url = `${BASE_API}v1/DetalleAp/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateDetalleApApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/DetalleAp/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
