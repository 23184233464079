import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from "moment"
import { FiltrosSia } from '../../components/skyinventoryadministrator';
import { usePrestamoSia } from '../../hooks';

export function ReporteComponentePage() {
    const [listPrestamos, setListPrestamos] = useState([]);
    const [viewDetalles, setViewDetalles] = useState(false);
    const [dataComponenteMas, setDataComponenteMas] = useState([]);
    const [dataMovimientos, setDataMovimientos] = useState([]);

    const { getComponenteSiaReport, loadingPrestamo, getComponente, componenteDetalle, componenteData, prestamosComponenteDetalle, getMovimientoComponente } = usePrestamoSia();

    const list_filtros = {
        FechaSalida: false,
        FechaEntrega: false,
        FechaCompra: true,
        Empleado: false,
        Cuenta: false,
        EstadoPrestamo: false,
        TipoComponente: true,
        EstadoComponente: true,
        Marca: true,
        Ubicacion: true
    };


    const onResponsePrestamos = async (formValue) => {
        setViewDetalles(false);
        setListPrestamos(await getComponenteSiaReport(formValue));
    };

    const actionDetalleComponente = async (rowData) => {

        await getComponente(rowData.Serial);
        setDataComponenteMas(rowData);

        const data_movimiento_response = await getMovimientoComponente(rowData.ID_Componente);
        setDataMovimientos(data_movimiento_response)
        console.log(data_movimiento_response)

        setViewDetalles(true);
    };

    const templateDetallesComponente = (rowData) => {
        return (
            <>
                <Button type="button" severity="success" onClick={() => actionDetalleComponente(rowData)}>
                    Mas Detalles
                </Button>
            </>
        );
    };

    const templateTipoMovimiento = (rowData) => {

        let str_return = ""

        if(rowData.TipoMovimiento == "1"){
            str_return = "Salida"
        }else{
            str_return = "Retorno"
        }

        return (
            <>
                {str_return}
            </>
        )
    }

    const templateEstadoPrestamo = (rowData) => {

        let str_return = ""

        if(rowData.TipoPrestamo == "1"){
            str_return = "Activo"
        }else{
            str_return = "Inactivo"
        }

        return (
            <>
                {str_return}
            </>
        )
    }

    const templateFechaMovimiento = (rowData) => {
        return (
            <>{moment(rowData.created_at).format("YYYY-MM-DD HH:MM:SS")}</>
        )
    }

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte de Componentes</h5>
                        <p>Llena todos los filtros para poder ver la lista de componentes</p>

                        <FiltrosSia filtros={list_filtros} responseForm={onResponsePrestamos} />
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        {loadingPrestamo ? (
                            <div className="text-center">
                                <i className="pi pi-spin pi-spinner" style={{ fontSize: '5rem' }}></i>
                            </div>
                        ) : (
                            <>
                                <DataTable
                                    value={listPrestamos}
                                    paginator
                                    rows={25}
                                    rowsPerPageOptions={[5, 10, 25, 50, 500, 1000]}
                                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                >
                                    <Column filter sortable header="Correlativo" field="Correlativo"></Column>
                                    <Column filter sortable header="TipoComponente" field="TipoComponente"></Column>
                                    <Column filter sortable header="Marca" field="Marca"></Column>
                                    <Column filter sortable header="Modelo" field="Modelo"></Column>
                                    <Column filter sortable header="Serial" field="Serial"></Column>
                                    <Column filter sortable header="EstadoComponente" field="EstadoComponente"></Column>
                                    <Column filter sortable header="Ubicacion" field="Ubicacion"></Column>
                                    <Column filter sortable header="FechaCompra" field="FechaCompra"></Column>
                                    <Column filter sortable header="CreacionComponente" field="CreacionComponente"></Column>

                                    <Column header="Acciones" body={templateDetallesComponente}></Column>

                                    {/* <Column header="ID_Componente" field="ID_Componente"></Column>
                                    <Column header="ID_Marca" field="ID_Marca"></Column>
                                    <Column header="ID_Modelo" field="ID_Modelo"></Column>
                                    <Column header="ID_TipoComponente" field="ID_TipoComponente"></Column>
                                    <Column header="ID_Ubicacion" field="ID_Ubicacion"></Column>
                                    <Column header="ID_EstadoComponente" field="ID_EstadoComponente"></Column>
                                    <Column header="ID_UsuarioPrestamo" field="ID_UsuarioPrestamo"></Column>
                                    <Column header="ID_TipoPrestamo" field="ID_TipoPrestamo"></Column> */}
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {viewDetalles && (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h5>Mas Detalles de componente</h5>
                            <p>Ficha de componente con todos los detalles</p>

                            <div className="grid p-5">
                                <div className="col-3 flex align-items-center justify-content-center">
                                    Correlativo:&nbsp;
                                    {dataComponenteMas.Correlativo}
                                </div>
                                <div className="col-3 flex align-items-center justify-content-center">Estado de Componente: {dataComponenteMas.EstadoComponente}</div>
                                <div className="col-3 flex align-items-center justify-content-center">Serial: {dataComponenteMas.Serial}</div>
                                <div className="col-3 flex align-items-center justify-content-center">Marca: {dataComponenteMas.Marca}</div>
                            </div>
                            <div className="grid p-5">
                                <div className="col-3 flex align-items-center justify-content-center">Modelo: {dataComponenteMas.Modelo}</div>
                                <div className="col-3 flex align-items-center justify-content-center">Tipo de Componente: {dataComponenteMas.TipoComponente}</div>
                                <div className="col-3 flex align-items-center justify-content-center">Ubicacion: {dataComponenteMas.Ubicacion}</div>
                                <div className="col-3 flex align-items-center justify-content-center">Fecha de Compra: {dataComponenteMas.FechaCompra}</div>
                            </div>

                            <div className='grid'>
                                <div className='col-6'>
                                    <h5>Prestamos Historico</h5>
                                    <DataTable value={prestamosComponenteDetalle}>
                                        {/* <Column header="ID Prestamo" field='id'></Column> */}
                                        <Column header="Asignacion" field='Badge'></Column>
                                        <Column header="Fecha de Asignacion" field='FechaSalida'></Column>
                                        <Column header="Fecha de Retorno" field='FechaRetorno'></Column>
                                        <Column header="Asignado Por" field='Usuario'></Column>
                                        <Column header="Nota" field=''></Column>
                                        <Column header="Estado de Prestamo" body={templateEstadoPrestamo}></Column>
                                    </DataTable>
                                </div>
                                <div className='col-6'>
                                    <h5>Movientos Historico</h5>
                                    <DataTable value={dataMovimientos}>
                                        <Column header="Usuario" field='Usuario'></Column>
                                        <Column header="Tipo de Movimiento" field='TipoMovimiento' body={templateTipoMovimiento}></Column>
                                        <Column header="Nota" field=''></Column>
                                        <Column header="Fecha de Movimiento" body={templateFechaMovimiento}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
