import React, { useState } from 'react';
import moment from "moment"
import { FilterHoras, TableReportException } from '../../components/Skytracking';
import {useException} from "../../hooks"

export function ReportExceptionsPage() {

    const [listExceptions, setListExceptions] = useState(null)

    const {getSkyExceptionView, loadingException} = useException()

    const onResponseForm = async (formData) => {
        console.log(formData)

        const fechaInicio_str = moment(formData.RangoFechas[0]).format('YYYY-MM-DD');
        const fechafinal_str = moment(formData.RangoFechas[1]).format('YYYY-MM-DD');

        const response_exception = await getSkyExceptionView(fechaInicio_str, fechafinal_str, formData.Cuenta, formData.EstadoExcepcion)

        console.log(response_exception)

        setListExceptions(response_exception)
    };


    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Excepciones</h5>
                        <FilterHoras
                            requiredFields={{
                                Cuenta: false,
                                EstadoExcepcion: true
                            }}
                            showFields={{
                                RangoFechas: true,
                                Cuenta: true,
                                Lob: true,
                                EstadoExcepcion: true
                            }}
                            responseForm={onResponseForm}
                        />
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Resultado de Excepciones</h5>
                        <TableReportException excepciones={listExceptions} />
                    </div>
                </div>
            </div>
        </>
    );
}
