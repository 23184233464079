import React, { useState, useEffect } from 'react';
import {size} from "lodash"
import { FilterHoras, TableHouyrsSupervisor } from '../../components/Skytracking';
import { useSkytracking } from '../../hooks';

export function HorasSupervisorPage() {
    const [listHorasComponent, setListHorasComponent] = useState(null);
    const [loadingPage, setLoadingPage] = useState(false);
    const [segundosTranscurridos, setSegundosTranscurridos] = useState(0);

    const { getHorasSup, datosConsultadosHoras, setDatosConsultadosHoras } = useSkytracking();

    const onResponseForm = async (formData) => {
        let listComponentTableHours = [];
        let contador = 0;
        setLoadingPage(true);

        for await (const fecha of formData.Fechas) {
            const response_horas = await getHorasSup(formData.Cuenta, formData.Lob, fecha, size(formData.Fechas), contador);

            listComponentTableHours.push(<TableHouyrsSupervisor key={contador} tipoHoras={true} horas={response_horas} Fecha={fecha} />);

            contador += 1;
        }

        setListHorasComponent(listComponentTableHours);

        setLoadingPage(false);
    };

    useEffect(() => {
        if (datosConsultadosHoras > 0) {
            const timer = setTimeout(() => {
                setDatosConsultadosHoras((prev) => prev - 1);
                setSegundosTranscurridos((prev) => prev + 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [datosConsultadosHoras]);

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Consulta de Horas</h5>
                        <p>Modulo de consulta de horas Skyverse Skytracking. Completa todos los campos requeridos para poder Consultar Horas, Agregar Excepciones y Validar Horas como supervisor</p>
                        <FilterHoras
                            requiredFields={{
                                Cuenta: true
                            }}
                            showFields={{
                                RangoFechas: true,
                                Cuenta: true,
                                Lob: true,
                                Supervisor: false
                            }}
                            responseForm={onResponseForm}
                        />
                    </div>
                </div>
            </div>

            {loadingPage ? (
                <div className="grid mt-5">
                    <div className="col-12 text-center">
                        <div className="relative">
                            <div className="absolute top-0 left-0">
                                <h6>Tiempo Restante Estimado: {datosConsultadosHoras.toFixed(0)} seg</h6>
                            </div>
                            <div className="absolute top-0 right-0">
                                <h6>Tiempo Transcurrido: {segundosTranscurridos.toFixed(0)} seg</h6>
                            </div>
                        </div>
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                listHorasComponent
            )}
        </>
    );
}
