import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {Dialog} from "primereact/dialog"
import {useAuth, useMasterRoster} from "../../hooks"

export function TableMasterRoster({ listEmpleados }) {

    const [visibleModal, setVisibleModal] = useState(false)

    const {auth} = useAuth()
    const {getHorarioEmpleado, horarioEmpleado} = useMasterRoster()

    const dt = useRef(null);

    const cols = [
        { field: '1', header: 'Payroll' },
        { field: '2', header: 'Employee Name' },
        { field: '3', header: 'Campaign' },
        { field: '4', header: 'Country' },
        { field: '5', header: 'LOB' },
        { field: '6', header: 'Role' },
        { field: '7', header: 'ACD ID' },
        { field: '8', header: 'ACD Name' },
        { field: '9', header: 'ACD Skill' },
        { field: '10', header: 'Sup Payroll' },
        { field: '11', header: 'Account Manager Payroll' },
        { field: '12', header: 'Director Payroll' },
        { field: '13', header: 'Shift Type' },
        { field: '14', header: 'HIRE DT' },
        { field: '15', header: 'Training DT' },
        { field: '16', header: 'Prod DT' },
        { field: '17', header: 'LOB 2' },
        { field: '18', header: 'LOB 3' },
        { field: '19', header: 'LOB2 Date' },
        { field: '20', header: 'LOB 3 Date' },
        { field: '21', header: 'Site' },
        { field: '22', header: 'BYOD' },
        { field: '23', header: 'Access Card N' },
        { field: '24', header: 'Windows User' },
        { field: '25', header: 'Internal email' },
        { field: '26', header: 'Parent' },
        { field: '27', header: 'Personal Email' },
        { field: '28', header: 'Cel Phone' },
        { field: '29', header: 'Contect Ph#2' },
        { field: '30', header: 'Contact Ph#3' },
        { field: '36', header: 'Emergency Contact' },
        { field: '31', header: 'Emergency Contact Number' },
        { field: '37', header: 'Emergency Pref' },
        { field: '32', header: 'AnyDeskCode' },
        { field: '33', header: 'Home Address' },
        { field: '34', header: 'Departamento' },
        { field: '35', header: 'Municipio' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, listEmpleados);
                doc.save('MasterRoster.pdf');
            });
        });
    };

    const exportExcel = () => {
        let listEmpleadosExcel = [];

        for (const row of listEmpleados) {
            let temp_listEmpleadosExcel = [];

            row.forEach((value, i) => {
                const valor_nuevo = cols.filter((item) => item.field == i);

                if (valor_nuevo[0]?.header != undefined) {

                    temp_listEmpleadosExcel[valor_nuevo[0]?.header] = value;

                }

            });

            listEmpleadosExcel.push(temp_listEmpleadosExcel);
        }

        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(listEmpleadosExcel);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'MasterRoster');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const rosterUpdate = (rowData) => {
        /* navigate(`/Skyroster/RosterUpdate/${rowData.id_empleado}`) */

        window.open(`/#/Skyroster/RosterUpdate/${rowData.id_empleado}`);
    };

    const onFichaEmpleado = (rowData) => {
        /* navigate(`/Skyroster/RosterUpdate/${rowData.id_empleado}`) */

        window.open(`/#/Skyroster/FichaEmpleado/${rowData.id_empleado}`);
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="grid my-3">
                    <div className="col-12">
                        <Button type="button" onClick={() => rosterUpdate(rowData)}>
                            {' '}
                            Roster Update{' '}
                        </Button>
                    </div>
                </div>
                <div className="grid my-3">
                    <div className="col-12">
                        <Button type="button" onClick={async () => {
                            setVisibleModal(true)
                            await getHorarioEmpleado(rowData.id_empleado)
                            }}>
                            Horario
                        </Button>
                    </div>
                </div>
                {auth.me.is_staff && (

                    <div className="grid my-3">
                        <div className="col-12">
                            <Button type="button" onClick={() => onFichaEmpleado(rowData)}>
                                Ficha de Empleado
                            </Button>
                        </div>
                    </div>
                    
                )}
            </React.Fragment>
        );
    };
    return (
        <>
            <Dialog header="Horario de Empleado" visible={visibleModal} style={{width: "50vw"}} onHide={() => setVisibleModal(false)}>
                <DataTable value={horarioEmpleado}>
                    <Column header="Dia" field='Dia'></Column>
                    <Column header="Hora de Inicio" field='HoraInicio'></Column>
                    <Column header="Hora de Final" field='HoraFinal'></Column>
                    <Column header="Almuerzo" field='Almuerzo'></Column>
                </DataTable>
            </Dialog>
            <DataTable
                value={listEmpleados}
                scrollable
                scrollHeight="600px"
                header={header}
                paginator
                rows={25}
                rowsPerPageOptions={[5, 10, 25, 50, 500, 1000]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
                <Column body={actionBodyTemplate} exportable={false} frozen></Column>
                <Column field="1" header="Payroll " filter frozen></Column>
                <Column field="2" header="Employee Name " filter frozen></Column>
                <Column field="Cuenta" header="Campaign " filter></Column>
                <Column field="4" header="Country " filter></Column>
                <Column field="Lob1" header="LOB " filter></Column>
                <Column field="Plaza" header="Role " filter></Column>
                <Column field="57" header="ACD" filter></Column>
                <Column field="7" header="ACD ID " filter></Column>
                <Column field="8" header="ACD Name " filter></Column>
                <Column field="9" header="ACD Skill " filter></Column>
                <Column field="10" header="Badge Supervisor " filter></Column>
                <Column field="NombreSupervisor" header="Nombre Supervisor " filter></Column>
                <Column field="11" header="Badge Account Manager " filter></Column>
                <Column field="NombreAccount" header="Nombre Account Manager " filter></Column>
                <Column field="12" header="Badge Director " filter></Column>
                <Column field="NombreDirector" header="Nombre Director " filter></Column>
                <Column field="13" header="Shift Type " filter></Column>
                <Column field="14" header="HIRE DT " filter></Column>
                <Column field="15" header="Training DT " filter></Column>
                <Column field="16" header="Prod DT " filter></Column>
                <Column field="17" header="LOB 2 " filter></Column>
                <Column field="18" header="LOB 3 " filter></Column>
                <Column field="19" header="LOB2 Date " filter></Column>
                <Column field="20" header="LOB 3 Date " filter></Column>
                <Column field="21" header="Site " filter></Column>
                <Column field="22" header="BYOD " filter></Column>
                <Column field="23" header="Access Card N " filter></Column>
                <Column field="24" header="Windows User " filter></Column>
                <Column field="25" header="Internal email " filter></Column>
                <Column field="26" header="Parent " filter></Column>
                <Column field="27" header="Personal Email " filter></Column>
                <Column field="28" header="Cel Phone " filter></Column>
                <Column field="29" header="Contect Ph#2 " filter></Column>
                <Column field="30" header="Contact Ph#3 " filter></Column>
                <Column field="36" header="Emergency Contact " filter></Column>
                <Column field="31" header="Emergency Contact Number " filter></Column>
                <Column field="37" header="Emergency Pref. " filter></Column>
                <Column field="32" header="AnyDeskCode " filter></Column>
                <Column field="33" header="Home Address " filter></Column>
                <Column field="34" header="Departamento " filter></Column>
                <Column field="35" header="Municipio " filter></Column>
            </DataTable>
        </>
    );
}
