import React, { useEffect } from "react"
import {Chips} from "primereact/chips"
import {InputNumber} from "primereact/inputnumber"
import {Button} from "primereact/button"
import {useFormik} from "formik"
import * as Yup from "yup"
import {useCommon} from "../../hooks"

export function FilterHorasProgramadas ({response}) {

    const {getAllBadges} = useCommon();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            if(formValue.Badges[0] == "all" || formValue.Badges[0] == "ALL"){

                let badges_sups = []

                const all_badges_response = await getAllBadges()

                for await (const badge of all_badges_response){
                    badges_sups.push(badge.Badge)
                }

                formValue.Badges = badges_sups

            }
            
            response(formValue)
        }
    })

    /* useEffect(() => {
        formik.setFieldValue("Badges", ["LT5967","LT5765","LT5965","LT4696","LT5918","LT4571","LT5999","LT6043","LT4334","LT4917","LT4919","LT5031","LT5323","LT5736","LT4949","LT5763","LT5641","LT5495","LT5924","LT5169","LT6020","LT5957","LT5643","LT5175","LT5496","LT881","LT5157","LT1920","LT4480","LT1443","LT6050","LT2949","LT4494","LT4388","LT6021","LT6036","LT6048","LT5928","LT4925","LT5757","LT6028","LT3992","LT5759","LT6040","LT4803","LT3742","LT5642","LT6035","LT4188","LT5931","LT5034","LT5741","LT4307","LT4923","LT5964","LT5604","LT4953","LT6027","LT6001","LT5648","LT5927","LT4781","LT5166","LT5739","LT5612","LT5674","LT5760","LT4715","LT5245","LT4714","LT6034","LT5508","LT5762","LT5751","LT6002","LT6003","LT4950","LT6015","LT1918","LT5040","LT5264","LT5275","LT4324","LT4719","LT5962","LT5919","LT5152","LT6037","LT6023","LT892","LT5932","LT5743","LT6042","LT6005","LT5752","LT4774","LT6017","LT5925","LT5670","LT5644","LT4332","LT5273","LT5738","LT5501","LT6026","LT5196","LT5673","LT5753","LT4718","LT5758","LT5926","LT4890"])
    }, []) */

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <span className="p-float-label p-fluid">
                        <Chips value={formik.values.Badges} onChange={(e) => formik.setFieldValue("Badges", e.value)} id="Badge" name="Badge" className="w-full" />
                        <label htmlFor="Badge">Badges</label>
                    </span>
                </div>
                <div className="col-12 md:col-6">
                    <span className="p-float-label">
                        <InputNumber value={formik.values.Semanas} onChange={(e) => formik.setFieldValue("Semanas", e.value)} id="Semanas" name="Semanas" className="w-full" />
                        <label htmlFor="Semanas">Numero Semanas a Cargar</label>
                    </span>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 text-right">
                    <Button type="submit" severity="primary">Cargar Horas Programadas</Button>
                </div>
            </div>
        </form>
    )
}

function initialValues() {
    return {
        Badges: "",
        Semanas: "",
    }
}

function validationSchema() {
    return {
        Badges: Yup.array().required(true),
        Semanas: Yup.number().required("Ingrese un numero valido").integer()
    }
}