import { BASE_API } from '../../utilities/constants';

export async function getPrestamoSiasApi(token, EstadoComponente="", Serial="", TipoComponente="", FechaCompra_gte="", FechaCompra_lte="", FechaSalida_gte="", FechaSalida_lte="", FechaRetorno_gte="", FechaRetorno_lte="", Ubicacion="", TipoPrestamo="", Marca="", ID_Prestamo = "", Componente = "", Badge="", Cuenta="") {
    try {
        const EstadoComponenteFilter = `Componente__EstadoComponente=${EstadoComponente}`;
        const SerialFilter = `Componente__Serial=${Serial}`;
        const TipoComponenteFilter = `Componente__TipoComponente=${TipoComponente}`;
        const FechaCompraFilterGte = `Componente__FechaCompra__gte=${FechaCompra_gte}`;
        const FechaCompraFilterLte = `Componente__FechaCompra__lte=${FechaCompra_lte}`;
        const FechaSalidaFilterGte = `FechaSalida__gte=${FechaSalida_gte}`;
        const FechaSalidaFilterLte = `FechaSalida__lte=${FechaSalida_lte}`;
        const FechaRetornoFilterGte = `FechaRetorno__gte=${FechaRetorno_gte}`;
        const FechaRetornoFilterLte = `FechaRetorno__lte=${FechaRetorno_lte}`;
        const UbicacionFilter = `Componente__Ubicacion=${Ubicacion}`;
        const TipoPrestamoFilter = `TipoPrestamo=${TipoPrestamo}`;
        const MarcaFilter = `Componente__Modelo__Marca=${Marca}`;
        const ID_PrestamoFilter = `id=${ID_Prestamo}`;
        const ComponenteFilter = `Componente=${Componente}`;
        const BadgeFilter = `Badge=${Badge}`;
        const CuentaFilter = `Cuenta=${Cuenta}`;

        const url = `${BASE_API}v1/PrestamoSia/?${EstadoComponenteFilter}&${SerialFilter}&${TipoComponenteFilter}&${FechaCompraFilterGte}&${FechaCompraFilterLte}&${FechaSalidaFilterGte}&${FechaSalidaFilterLte}&${FechaRetornoFilterGte}&${FechaRetornoFilterLte}&${UbicacionFilter}&${TipoPrestamoFilter}&${MarcaFilter}&${ID_PrestamoFilter}&${ComponenteFilter}&${BadgeFilter}&${CuentaFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addPrestamoSiaApi(data, token) {
    try {
        const url = `${BASE_API}v1/PrestamoSia/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updatePrestamoSiaApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/PrestamoSia/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
