import React from 'react';

export function ReportSkyTempPage() {
    return (
        <>
            <iframe
                title="Skytracking New Version v.2"
                width="100%"
                height="1060"
                src="
https://app.powerbi.com/view?r=eyJrIjoiOWUzZjBlMjUtY2M3Mi00ZjdjLWE5MzctNDllOTNkOTk0NThlIiwidCI6ImQ2Mjc1OGQ2LThhYzEtNGE5NS05YzBmLWM2NjY2MDFhYWNjOSJ9"
                frameborder="0"
                allowFullScreen="true"
            ></iframe>
        </>
    );
}
