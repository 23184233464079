import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSkytracking } from '../../hooks';

export function TableDetalleMarca({ rowDetail }) {
    const [listDetalleMarca, setListDetalleMarca] = useState(null);

    const { getMarcasEditar, loadingSkytracking } = useSkytracking();

    useEffect(() => {
        (async () => {
            const response_detalle_marcas = await getMarcasEditar(rowDetail.Badge, rowDetail.Fecha);

            const horas_programadas_diurnas = filter(response_detalle_marcas, { TipoMarca: 1 });
            const horas_programadas_nocturnas = filter(response_detalle_marcas, { TipoMarca: 62 });
            const dibujar_detalle = filter(response_detalle_marcas, (o) => {
                return o.tipoMarca_data.Tipo > 1;
            });

            setListDetalleMarca([...horas_programadas_diurnas, ...horas_programadas_nocturnas, ...dibujar_detalle]);
        })();
    }, []);

    return (
        <>
        {loadingSkytracking ? (
            <div className='grid'>
                <div className='col-12 text-center'>
                    <i className='pi pi-spinner pi-spin' style={{fontSize: '5rem'}}></i>
                </div>
            </div>
        ) : (

            <div className="grid">
                <div className="col-12">
                    <h5>Detalle de Horas</h5>
                    <p>Puedes ver el detalle de horas que se estan sumando en las horas a pagar y puedes realizar tu mismo los calculos ademas de hacer tu mismo los filtros de las horas</p>

                    <DataTable
                        value={listDetalleMarca}
                        paginator
                        rows={25}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    >
                        <Column field="tipoMarca_data.NombreTipoMarca" header="Pausa" filter></Column>
                        <Column field="Horas" header="Horas" filter></Column>
                    </DataTable>
                </div>
            </div>
        )}
        </>
    );
}
