import React, { useEffect, useRef, useState} from 'react';
import {size} from "lodash"
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import {Button} from "primereact/button"
import {Badge} from "primereact/badge"
import {Avatar} from "primereact/avatar"
import {Dialog} from "primereact/dialog"
import AppMenu from './AppMenu';
import { useNavigate } from 'react-router-dom';
import {useAuth, useProfile, useNotificacion} from "./hooks"
import {TableTiempoCarga} from "./components/common"

const AppTopbar = (props) => {
    const navigate = useNavigate();
    const searchPanelDesktop = useRef(null);
    const searchPanelMobile = useRef(null);
    const [visible, setVisible] = useState(false)
    const [nameEmployee, setNameEmployee] = useState(null)

    const {logout, auth} = useAuth()
    const {getNameProfile} = useProfile()
    const {getListAsignacionNotificacion, listAsignacionNotificaciones} = useNotificacion()

    useEffect(() => {
        if (props.searchActive) {
            if (window.innerWidth >= 576) searchPanelDesktop.current.focus();
            else searchPanelMobile.current.focus();
        }
    }, [props.searchActive]);

    useEffect(() => {
        (async ()=>{
            try {

                await getListAsignacionNotificacion();
                
                const response_name = await getNameProfile()
                if (size(response_name) > 0){
                    setNameEmployee(response_name[0].Valor)
                }
            } catch (error) {
                logout()
                
            }
        })()
    }, [])

    const onInputKeydown = (event) => {
        const key = event.which;

        //escape, tab and enter
        if (key === 27 || key === 9 || key === 13) {
            if (props.searchActive) props.onTopbarSearchToggle();
        }
    };

    return (
        <div className="layout-topbar">
            <div className="layout-topbar-wrapper">
                <div className="layout-topbar-left">
                    <button tabIndex="0" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>
                    <button tabIndex="0" id="logo-link" onClick={() => navigate('/')} className="layout-topbar-logo p-link">
                        {/* <img src={`assets/layout/images/logo-${props.topbarScheme === 'dark' ? 'freya-white' : 'freya'}.svg`} alt="freya-layout" /> */}
                        <img src="assets/layout/images/logo-sc.png" alt="skycom-layout" />
                    </button>
                </div>

                <AppMenu
                    menuMode={props.menuMode}
                    sidebarActive={props.sidebarActive}
                    sidebarStatic={props.sidebarStatic}
                    model={props.menu}
                    menuActive={props.menuActive}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMobileMenuActive={props.onMobileMenuActive}
                    onMenuItemClick={props.onMenuItemClick}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    onToggleMenu={props.onToggleMenu}
                    resetActiveIndex={props.resetActiveIndex}
                    onMenuClick={props.onMenuClick}
                />

                <Dialog header="Tiempos de carga para Skyverse" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                    <TableTiempoCarga />
                </Dialog>

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-actions">
                        <li className={classNames('topbar-item search-item', { 'active-topmenuitem': props.searchActive })}>
                            {/* <button className="p-link" tabIndex="0" style={{ cursor: 'pointer' }} onClick={props.onTopbarSearchToggle}>
                                <i className="topbar-icon pi pi-search"></i>
                            </button> */}

                            <div className="search-input-wrapper" onClick={props.onTopbarSearchClick}>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search"></i>
                                    <InputText ref={searchPanelDesktop} type="text" placeholder="Search..." onKeyDown={onInputKeydown} />
                                </span>
                            </div>

                            {/* <ul className="fadeInDown">
                                <div className="search-input-wrapper p-fluid" style={{ width: '100%' }} onClick={props.onTopbarSearchClick}>
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search"></i>
                                        <InputText ref={searchPanelMobile} type="text" placeholder="Search..." onKeyDown={onInputKeydown} />
                                    </span>
                                </div>
                            </ul> */}
                        </li>
                        <li>
                            <Button link onClick={() => setVisible(true)}>Tiempos de Carga</Button>
                        </li>
                        <li>&nbsp;{auth.me.username}, {nameEmployee}&nbsp;</li>

                        <li className={classNames('topbar-item user-profile', { 'active-topmenuitem fadeInDown': props.topbarUserMenuActive })}>
                            <button className="p-link" style={{ cursor: 'pointer' }} onClick={props.onTopbarUserMenuClick}>
                                <img src="assets/layout/images/profile.png" alt="perfil-skycom" />
                            </button>
                            <ul className="fadeInDown">
                                <li onClick={() => navigate('Skyprofile')}>
                                    <button className="p-link">
                                        <span>Profile</span>
                                    </button>
                                </li>
                                <li onClick={() => logout()}>
                                    <button className="p-link">
                                        <span>Cerrar Sesion</span>
                                    </button>
                                </li>
                                {/* <li>
                                    <button className="p-link">
                                        <span>Settings</span>
                                    </button>
                                </li>
                                <li>
                                    <button className="p-link">
                                        <span>Messages</span>
                                    </button>
                                </li>
                                <li>
                                    <button className="p-link">
                                        <span>Notifications</span>
                                    </button>
                                </li> */}
                            </ul>
                        </li>
                    </ul>
                    <Avatar style={{cursor: 'pointer'}} className='p-overlay-badge layout-rightpanel-button p-link' icon="pi pi-bell" size="large" onClick={props.onRightPanelButtonClick}>
                        <Badge value={size(listAsignacionNotificaciones)} />
                    </Avatar>
                    {/* <button tabIndex="0" style={{cursor: 'pointer'}} className='layout-rightpanel-button p-link' onClick={props.onRightPanelButtonClick}>
                        <i className="pi pi-bell" style={{fontSize: '2rem'}}></i>
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default AppTopbar;
