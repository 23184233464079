import { BASE_API } from '../../utilities/constants';

export async function loginApi(formValue) {
    try {
        const url = `${BASE_API}v1/auth/login/`;
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formValue)
        };

        const response = await fetch(url, params);

        if (response.status !== 200) {
            throw new Error('Usuario o Contraseña Incorrecto');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function getMeApi(token) {
    try {
        const url = `${BASE_API}v1/auth/me/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getUsersApi(token) {
    try {
        const url = `${BASE_API}v1/users/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getUsersApi_byone(token, ID_Usuario = "") {
    try {
        const url = `${BASE_API}v1/users/${ID_Usuario}/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addUserApi(data, token) {
    try {
        const url = `${BASE_API}v1/users/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateUserApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/users_p/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
