import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image } from 'primereact/image';
import { Calendar } from 'primereact/calendar';
import {InputText} from "primereact/inputtext"
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormHorario } from '../../Skyroster';

export function ReingresoFormAp({ response, tipoAp }) {

    const [previewImage, setPreviewImage] = useState(null);
    const [disabledState, setDisabledState] = useState(false);
    const [checked, setChecked] = useState(false);

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile[0];
        formik.setFieldValue('ImagenAp', file);
        setPreviewImage(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    });

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            setDisabledState(true)
            response(formValue);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid my-3">
                <div className="col-12 md:col-6">
                    <span className="p-float-label">
                        <Calendar className="w-full" id="FechaEfectiva" name="FechaEfectiva" value={formik.values.FechaEfectiva} onChange={(e) => formik.setFieldValue('FechaEfectiva', e.value)} />
                        <label htmlFor="FechaEfectiva">Fecha Efectiva</label>
                    </span>
                </div>
                {(tipoAp != undefined && tipoAp == 30) && (

                    <>
                        <div className="col-12 md:col-3">
                            <label htmlFor="SalarioCheck">Ingresar Salario</label>
                            <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                        </div>
                        {checked && (

                            <div className="col-12 md:col-3">
                                <span className="p-float-label">
                                    <InputText className="w-full" id="Salario" name="Salario" value={formik.values.Salario} onChange={formik.handleChange} />
                                    <label htmlFor="Salario">Salario</label>
                                </span>
                            </div>

                        )}
                    </>

                    
                )}
            </div>

            <div className="grid mt-3">
                <div className="col-12">
                    <span className="p-float-label">
                        <InputTextarea className="w-full" id="Observacion" name="Observacion" rows={5} cols={30} value={formik.values.Observacion} onChange={formik.handleChange} />
                        <label htmlFor="Observacion">Observacion</label>
                    </span>
                </div>
            </div>

            <div className="grid">
                <div className="col-6">
                    <Button type="button" severity="primary" size="large" {...getRootProps()}>
                        Subir Imagen para la AP
                    </Button>
                    <input {...getInputProps()} />
                </div>
                <div className="col-6">
                    <Image src={previewImage} width="100%" />
                </div>
            </div>

            <hr />
            <h5>Horario</h5>
            <p>Ingresa el nuevo horario de el empleado</p>

            <FormHorario formik={formik} />

            <div className="grid my-3">
                <div className="col-12 text-right">
                    <Button severity="primary" size="large" type="submit" disabled={disabledState}>
                        Guardar AP
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    let hora_cero = new Date();

    hora_cero.setHours(0);
    hora_cero.setMinutes(0);
    hora_cero.setSeconds(0);

    let hoy_inicio = new Date();
    let hoy_final = new Date();

    hoy_inicio.setHours(8);
    hoy_inicio.setMinutes(0);
    hoy_inicio.setSeconds(0);

    hoy_final.setHours(17);
    hoy_final.setMinutes(0);
    hoy_final.setSeconds(0);

    return {
        FechaEfectiva: '',
        Observacion: '',
        Salario: '',

        HoraInicio_L: hoy_inicio,
        HoraFinal_L: hoy_final,
        Almuerzo_L: 1,
        HorasProgramadas_L: '',
        HoraInicio_M: hoy_inicio,
        HoraFinal_M: hoy_final,
        Almuerzo_M: 1,
        HorasProgramadas_M: '',
        HoraInicio_X: hoy_inicio,
        HoraFinal_X: hoy_final,
        Almuerzo_X: 1,
        HorasProgramadas_X: '',
        HoraInicio_J: hoy_inicio,
        HoraFinal_J: hoy_final,
        Almuerzo_J: 1,
        HorasProgramadas_J: '',
        HoraInicio_V: hoy_inicio,
        HoraFinal_V: hoy_final,
        Almuerzo_V: 1,
        HorasProgramadas_V: '',
        HoraInicio_S: hora_cero,
        HoraFinal_S: hora_cero,
        Almuerzo_S: 0,
        HorasProgramadas_S: '',
        HoraInicio_D: hora_cero,
        HoraFinal_D: hora_cero,
        Almuerzo_D: 0,
        HorasProgramadas_D: '',
        HorasSemanales: 40,
        ImagenAp: ''
    };
}

function validationSchema() {
    return {
        FechaEfectiva: Yup.string().required(true),
        Observacion: Yup.string().required(true),
        Salario:  Yup.string(),

        HoraInicio_L: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_L: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_L: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_M: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_M: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_M: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_X: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_X: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_X: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_J: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_J: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_J: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_V: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_V: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_V: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_S: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_S: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_S: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_D: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_D: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_D: Yup.string().required('Debes completar el horario del empleado'),
        HorasProgramadas_L: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_M: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_X: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_J: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_V: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_S: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_D: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasSemanales: Yup.string().required('Horas Programadas no puede ser nulo'),
        ImagenAp: Yup.string()
    };
}
