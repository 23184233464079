import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormRosterUpdate } from '../../components/Skyroster';
import { useMasterRoster } from '../../hooks';

export function RosterUpdatePage() {
    const { idEmpleado } = useParams();

    const { loadingMasterRoster, empleado, getEmpleadoUpdate } = useMasterRoster();

    useEffect(() => {
        (async () => {
            await getEmpleadoUpdate(idEmpleado);
        })();
    }, []);

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Roster Update</h5>
                    <p>Formulario para crear los roster update de los empleados. Esta informacion sera revisada por el equipo de WFM y ellos pueden hacer las modificaciones que consideren necesarias para validar la informacion</p>

                    {loadingMasterRoster ? (
                        <div className="grid">
                            <div className="col-12 text-center">
                                <i className="pi pi-spinner pi-spin" style={{ fontSize: '3rem' }}></i>
                            </div>
                        </div>
                    ) : (
                        empleado && <FormRosterUpdate empleado={empleado} />
                    )}
                </div>
            </div>
        </div>
    );
}
