import React, { useState } from 'react';
import { size, filter, map } from 'lodash';
import moment from "moment"
import { getMarcasByFiltersApi, addMarcaApi } from '../api/skytracking/marca';
import { getDetalleMarcaExcepcion, updateDetalleMarcaApi, addDetalleMarcaApi, getDetalleMarcaByFilterApi } from '../api/skytracking/detallemarca';
import { getEmpleadosApi, addEmpleadoApi } from '../api/skyroster/empleado';
import { getDetalleEmpleadoApi, updateDetalleEmpleadoApi, addDetalleEmpleadoApi } from '../api/skyroster/detalleempleado';
import { useAuth } from './useAuth';
import { weekYearDate } from '../utilities/constants';

export function useUploadFiles() {
    const [error, setError] = useState(false);
    const [loadingFileUpload, setLoadingFileUpload] = useState(false);

    const { auth } = useAuth();

    const insertMarca = async (Badge, Fecha) => {
        try {
            setLoadingFileUpload(true);
            let response = await getMarcasByFiltersApi(auth.token, Badge, Fecha);

            if (!(size(response) > 0)) {
                const response_empleado = await getEmpleadosApi(auth.token, weekYearDate(), Badge);

                await addMarcaApi(
                    {
                        Fecha,
                        Empleado: response_empleado[0].id,
                        MarcaCreadaPor: auth.me.username
                    },
                    auth.token
                );

                response = await getMarcasByFiltersApi(auth.token, Badge, Fecha);
            }

            setLoadingFileUpload(false);

            return response;
        } catch (error) {
            setLoadingFileUpload(false);
            setError(error);
        }
    };

    const insertDetalleMarca = async (Detalle, idMarca) => {
        try {
            setLoadingFileUpload(true);

            for await (const pausa of Object.keys(Detalle)) {
                const valorDetalle = Detalle[pausa];

                const response_detalle_marca = await getDetalleMarcaExcepcion(auth.token, idMarca, pausa);

                if (size(response_detalle_marca) > 0) {
                    await updateDetalleMarcaApi(
                        {
                            Horas: valorDetalle.toFixed(3)
                        },
                        response_detalle_marca[0].id,
                        auth.token
                    );
                } else {
                    await addDetalleMarcaApi(
                        {
                            Marca: idMarca,
                            TipoMarca: pausa,
                            Horas: valorDetalle.toFixed(3)
                        },
                        auth.token
                    );
                }
            }

            setLoadingFileUpload(false);
        } catch (error) {
            setLoadingFileUpload(false);
            setError(error);
        }
    };

    const calcAuxMarca = async (idMarca) => {
        try {
            setLoadingFileUpload(true);

            const response_detalle_horas = await getDetalleMarcaByFilterApi(auth.token, idMarca);

            const horas_programadas_diurnas = filter(response_detalle_horas, { TipoMarca: 1 });
            const horas_programadas_nocturnas = filter(response_detalle_horas, { TipoMarca: 62 });
            const No_call_No_Show_horas_filter = filter(response_detalle_horas, { TipoMarca: 52 });

            let horas_noche_program = 0
            let horas_dia_program = 0
            let no_call_no_show_horas = 0

            if (size(horas_programadas_nocturnas) > 0){
                horas_noche_program = horas_programadas_nocturnas[0].Horas
            }

            if (size(horas_programadas_diurnas) > 0){
                horas_dia_program = horas_programadas_diurnas[0].Horas
            }

            if (size(No_call_No_Show_horas_filter) > 0){
                
                no_call_no_show_horas = No_call_No_Show_horas_filter[0].Horas
            }

            let list_aux = {
                unpaidExcessLogin_night: { Aux: 65, Horas: parseFloat(0) },
                lateness_night: { Aux: 64, Horas: parseFloat(horas_noche_program) },
                unpaidExcessLogin: { Aux: 30, Horas: parseFloat(0) },
                lateness: { Aux: 57, Horas: parseFloat(horas_dia_program) },
                noCallNoShow: {Aux: 52, Horas: parseFloat(no_call_no_show_horas)}
            };

            map(response_detalle_horas, (dt) => {
                if (dt.tipoMarca_data.Tipo != 1) {
                    const nombre_pausa_include = dt.tipoMarca_data.NombreTipoMarca;

                    if (nombre_pausa_include.includes('Night')) {
                        switch (dt.tipoMarca_data.Tipo) {
                            case 2:
                                list_aux.lateness_night.Horas = parseFloat(list_aux.lateness_night.Horas) - parseFloat(dt.Horas);
                                break;

                            case 5:
                            case 6:
                                list_aux.lateness_night.Horas = parseFloat(list_aux.lateness_night.Horas) - parseFloat(dt.Horas);
                                break;

                            case 3:
                                list_aux.unpaidExcessLogin_night.Horas -= parseFloat(dt.Horas);
                                break;
                            case 9:
                                list_aux.noCallNoShow.Horas -= parseFloat(dt.Horas);
                                break;
                            case 4:
                                list_aux.unpaidExcessLogin_night.Horas -= parseFloat(dt.Horas);
                                break;

                            default:
                                break;
                        }
                    } else {
                        switch (dt.tipoMarca_data.Tipo) {
                            case 2:
                                list_aux.lateness.Horas = parseFloat(list_aux.lateness.Horas) - parseFloat(dt.Horas);
                                break;

                            case 5:
                            case 6:
                                list_aux.lateness.Horas = parseFloat(list_aux.lateness.Horas) - parseFloat(dt.Horas);
                                break;

                            case 3:
                                list_aux.unpaidExcessLogin.Horas -= parseFloat(dt.Horas);
                                break;
                            case 9:
                                list_aux.noCallNoShow.Horas -= parseFloat(dt.Horas);
                                break;
                            case 4:
                                list_aux.unpaidExcessLogin.Horas -= parseFloat(dt.Horas);
                                break;

                            default:
                                break;
                        }
                    }
                }
            });

            if (list_aux.lateness.Horas < 0) {
                list_aux.unpaidExcessLogin.Horas += list_aux.lateness.Horas * -1;

                list_aux.lateness.Horas = 0;
            }

            if (list_aux.lateness_night.Horas < 0) {
                list_aux.unpaidExcessLogin_night.Horas += list_aux.lateness_night.Horas * -1;

                list_aux.lateness_night.Horas = 0;
            }

            if((list_aux.lateness.Horas + list_aux.lateness_night.Horas) == (parseFloat(horas_dia_program) + parseFloat(horas_noche_program))){
                if(list_aux.noCallNoShow.Horas == 0){

                    list_aux.noCallNoShow.Horas = parseFloat(horas_dia_program) + parseFloat(horas_noche_program)
                    
                }
                list_aux.lateness.Horas = 0
                list_aux.lateness_night.Horas = 0
            }else if((list_aux.lateness.Horas + list_aux.lateness_night.Horas) > 0){
                list_aux.noCallNoShow.Horas = 0
            }else{
                list_aux.noCallNoShow.Horas = 0
            }

            for await (const aux of Object.keys(list_aux)) {
                const response_detalle_marca = await getDetalleMarcaExcepcion(auth.token, idMarca, list_aux[aux].Aux);

                if (list_aux[aux].Horas < 0) {
                    list_aux[aux].Horas = 0;
                }

                if (size(response_detalle_marca) > 0) {
                    await updateDetalleMarcaApi(
                        {
                            Horas: list_aux[aux].Horas.toFixed(5)
                        },
                        response_detalle_marca[0].id,
                        auth.token
                    );
                } else {
                    await addDetalleMarcaApi(
                        {
                            Marca: idMarca,
                            TipoMarca: list_aux[aux].Aux,
                            Horas: list_aux[aux].Horas.toFixed(5)
                        },
                        auth.token
                    );
                }
            }

            setLoadingFileUpload(false);
        } catch (error) {
            setLoadingFileUpload(false);
            setError(error);
        }
    };

    const calcAuxMarca_antiguo = async (idMarca) => {
        try {
            setLoadingFileUpload(true);

            const response_horas_programadas = await getDetalleMarcaByFilterApi(auth.token, idMarca);

            const horas_programadas_filter = filter(response_horas_programadas, { TipoMarca: 1 });
            const trabajadas_filter = filter(response_horas_programadas, function (dt) {
                return dt.tipoMarca_data.Tipo == 2 || dt.tipoMarca_data.Tipo == 5;
            });

            if (size(horas_programadas_filter) > 0) {
                let auxiliares = {
                    57: 0,
                    30: 0,
                    52: 0
                };

                const horas_programadas = parseFloat(horas_programadas_filter[0].Horas);
                let trabajadas = 0;

                map(trabajadas_filter, (h) => {
                    trabajadas += parseFloat(h.Horas);
                });

                if (trabajadas < horas_programadas) {
                    auxiliares[57] = horas_programadas - trabajadas;

                    if (auxiliares[57] == horas_programadas) {
                        auxiliares[57] = 0;
                        auxiliares[52] = horas_programadas;
                    }
                } else {
                    auxiliares[30] = trabajadas - horas_programadas;
                }

                for await (const aux_pausa of Object.keys(auxiliares)) {
                    const response_detalle_marca = await getDetalleMarcaExcepcion(auth.token, idMarca, aux_pausa);

                    if (size(response_detalle_marca) > 0) {
                        await updateDetalleMarcaApi(
                            {
                                Horas: auxiliares[aux_pausa].toFixed(5)
                            },
                            response_detalle_marca[0].id,
                            auth.token
                        );
                    } else {
                        await addDetalleMarcaApi(
                            {
                                Marca: idMarca,
                                TipoMarca: aux_pausa,
                                Horas: auxiliares[aux_pausa].toFixed(5)
                            },
                            auth.token
                        );
                    }
                }
            }

            setLoadingFileUpload(false);
        } catch (error) {
            setLoadingFileUpload(false);
            setError(error);
        }
    };

    const insertEmpleado = async (Badge) => {
        try {
            setLoadingFileUpload(true);

            const hoy_fecha = new Date();

            //const empleado_response = await getEmpleadosApi(auth.token, weekYearDate(), Badge);
            const empleado_response = await getEmpleadosApi(auth.token, moment().week(), Badge);
            let insertEmpleado_data = null;

            if (size(empleado_response) > 0) {
                insertEmpleado_data = empleado_response[0];
            } else {
                insertEmpleado_data = await addEmpleadoApi(
                    {
                        Badge: Badge,
                        Week: moment().week(),
                        Year: 2024
                    },
                    auth.token
                );
            }

            setLoadingFileUpload(false);

            return insertEmpleado_data;
        } catch (error) {
            setLoadingFileUpload(false);
            setError(error);
        }
    };

    const insertDetalleEmpleado = async (idEmpleado, idPropiedad, valor) => {
        try {
            setLoadingFileUpload(true);

            const exist_propiedad = await getDetalleEmpleadoApi(auth.token, idPropiedad, idEmpleado);

            if (size(exist_propiedad) > 0) {
                await updateDetalleEmpleadoApi(
                    {
                        Valor: valor
                    },
                    exist_propiedad[0].id,
                    auth.token
                );
            } else {
                await addDetalleEmpleadoApi(
                    {
                        Propiedad: idPropiedad,
                        Empleado: idEmpleado,
                        Valor: valor
                    },
                    auth.token
                );
            }

            setLoadingFileUpload(false);
        } catch (error) {
            setLoadingFileUpload(false);
            setError(error);
        }
    };

    return {
        error,
        loadingFileUpload,
        insertMarca,
        insertDetalleMarca,
        calcAuxMarca,
        insertEmpleado,
        insertDetalleEmpleado,
        calcAuxMarca_antiguo
    };
}
