import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useMasterRoster } from '../../hooks';

export function LoadRosterPage() {
    const [weekYear, setWeekYear] = useState(0);
    const [weekSelected, setWeekSelected] = useState(0);
    const [submitButton, setSubmitButton] = useState(false);
    const [calcEmpleados, setCalcEmpleados] = useState(false);
    const [segundosTranscurridos, setSegundosTranscurridos] = useState(0);

    const { getEmpleadosLoad, tiempoEstimado, empleadosProcesados, empleadosProcesar, setTiempoEstimado } = useMasterRoster();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            const date_semana = new Date(formValue.Semana);
            const semana = moment(date_semana).week();
        }
    });

    useEffect(() => {
        const hoy = new Date();
        const SemanaActual = moment(hoy).week();

        setWeekYear(SemanaActual);
    }, []);

    useEffect(() => {
        if (empleadosProcesados < empleadosProcesar) {
            const timer = setTimeout(() => {
                setTiempoEstimado((prev) => prev - 1)
                setSegundosTranscurridos((prev) => prev + 1)
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [tiempoEstimado]);

    const onEmpleadosLoad = async () => {
        setCalcEmpleados(true);
        await getEmpleadosLoad(weekSelected);
        setCalcEmpleados(false);
        setSubmitButton(true)
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Carga de Roster Semanal</h5>
                        <p>Selecciona que semana quieres cargar a esta semana</p>

                        <div className="grid my-2">
                            <div className="col-12">
                                <h5>Semana Actual {weekYear}</h5>
                            </div>
                        </div>

                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid my-1">
                                <div className="col-12 md:col-6">
                                    <span className="p-float-label">
                                        <Calendar
                                            className="w-full"
                                            id="Semana"
                                            name="Semana"
                                            value={formik.values.Semana}
                                            onChange={(e) => {
                                                formik.setFieldValue('Semana', e.value);
                                                const date_selected = new Date(e.value);
                                                const semana_selected = moment(date_selected).week();
                                                setWeekSelected(semana_selected);
                                            }}
                                        />
                                        <label htmlFor="Semana">Semana</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-6">
                                    <h5>
                                        Se copiaran los datos de la semana # {weekSelected} hacia la semana # {weekYear}
                                    </h5>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12">
                                    <Button severity="primary" className='mx-2' type="button" onClick={onEmpleadosLoad} disabled={calcEmpleados}>
                                        Calcular Cambios&nbsp;
                                        {calcEmpleados && <i className="pi pi-spin pi-spinner"></i>}
                                    </Button>
                                    <Button severity="success" className='mx-2' type="submit" visible={submitButton}>
                                        Cargar Roster
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className='grid my-3'>
                <div className='col-12 md:col-3'>
                    <h5>Tiempo Estimado: {tiempoEstimado} seg</h5>
                </div>
                <div className='col-12 md:col-3'>
                    <h5>Empleados Procesados: {empleadosProcesados} de {empleadosProcesar}</h5>
                </div>
                <div className='col-12 md:col-3'>
                    <h5>Tiempo Transcurrido: {segundosTranscurridos} seg</h5>
                </div>
                <div className='col-12 md:col-3'>
                    <h5>Porcentaje: {((empleadosProcesados / empleadosProcesar) * 100).toFixed(4)}</h5>
                </div>
            </div>
        </>
    );
}

function initialValues() {
    return {
        Semana: ''
    };
}

function validationSchema() {
    return {
        Semana: Yup.string().required(true)
    };
}
