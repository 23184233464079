import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {FormRosterUpdatePanel, FormInsertExceptionsMultiple} from "../Skypanel"

export function TablePanelRoster({ Empleados, onRefetch }) {
    const [visible, setVisible] = useState(false);
    const [contentModal, setContentModal] = useState(null)
    const [selectedEmployees, setSelectedEmployees] = useState(null)

    const cols = [
        { field: 'Badge', header: 'Badge' },
        { field: '2', header: 'Employee Name' },
        { field: 'Cuenta', header: 'Cuenta' },
        { field: '4', header: 'Country' },
        { field: 'Lob', header: 'Lob' },
        { field: 'Plaza', header: 'Plaza' },
        { field: '7', header: 'ACD ID ' },
        { field: '8', header: 'ACD Name ' },
        { field: '9', header: 'ACD Skill ' },
        { field: '10', header: 'Badge Supervisor ' },
        { field: 'NombreSupervisor', header: 'NombreSupervisor' },
        { field: '11', header: 'Badge Account Manager ' },
        { field: 'NombreAccount', header: 'NombreAccount' },
        { field: '12', header: 'Badge Director ' },
        { field: 'NombreDirector', header: 'NombreDirector' },
        { field: '13', header: 'Shift Type ' },
        { field: '14', header: 'HIRE DT ' },
        { field: '15', header: 'Training DT ' },
        { field: '16', header: 'Prod DT ' },
        { field: '17', header: 'LOB 2 ' },
        { field: '18', header: 'LOB 3 ' },
        { field: '19', header: 'LOB2 Date ' },
        { field: '20', header: 'LOB 3 Date ' },
        { field: '21', header: 'Site ' },
        { field: '22', header: 'BYOD ' },
        { field: '23', header: 'Access Card N ' },
        { field: '24', header: 'Windows User ' },
        { field: '25', header: 'Internal email ' },
        { field: '26', header: 'Parent ' },
        { field: '27', header: 'Personal Email ' },
        { field: '28', header: 'Cel Phone ' },
        { field: '29', header: 'Contect Ph#2 ' },
        { field: '30', header: 'Contact Ph#3 ' },
        { field: '36', header: 'Emergency Contact ' },
        { field: '31', header: 'Emergency Contact Number ' },
        { field: '37', header: 'Emergency Pref. ' },
        { field: '32', header: 'AnyDeskCode ' },
        { field: '33', header: 'Home Address ' },
        { field: '34', header: 'Departamento ' },
        { field: '35', header: 'Municipio ' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, Empleados);
                doc.save('MasterRoster.pdf');
            });
        });
    };

    const exportExcel = () => {
        let listEmpleadosExcel = [];

        for (const row of Empleados) {
            let temp_listEmpleadosExcel = [];
            let contador = 0

            temp_listEmpleadosExcel["NombreCuenta"] = row.Cuenta.Nombre;
            temp_listEmpleadosExcel["NombreLob"] = row.Lob?.Lob;
            temp_listEmpleadosExcel["Payroll"] = row.Badge.Badge;

            for (const detalle_row of Object.keys(row)){

                const valor_nuevo = cols.filter((item) => item.field == detalle_row);

                if (valor_nuevo[0]?.header != undefined) {

                    temp_listEmpleadosExcel[valor_nuevo[0]?.header] = row[detalle_row];

                }

                contador = contador + 1

            }

            listEmpleadosExcel.push(temp_listEmpleadosExcel);
        }

        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(listEmpleadosExcel);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'MasterRoster');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const onRefetchTable = () => {
        setVisible(false)
        onRefetch()
    }

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
            <Button type="button" icon="pi pi-user" severity="info" onClick={() => {
                setVisible(true)
                setContentModal(<FormRosterUpdatePanel rowSelected={selectedEmployees} onCloseSelf={onRefetchTable} />)
            }}></Button>
            <Button type="button" icon="pi pi-clock" severity="info" onClick={() => {
                setVisible(true)
                setContentModal(<FormInsertExceptionsMultiple rowSelected={selectedEmployees} onCloseSelf={onRefetchTable} />)
            }}></Button>
        </div>
    );

    return (
        <>
            <Dialog header="Modificacion Masiva Roster" visible={visible} maximizable style={{ width: '80vw' }} onHide={() => setVisible(false)}>
                {contentModal}
            </Dialog>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            value={Empleados}
                            scrollable
                            header={header}
                            scrollHeight="600px"
                            paginator
                            rows={25}
                            rowsPerPageOptions={[5, 10, 25, 50, 500, 1000]}
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}"
                            selectionMode="null"

                            selection={selectedEmployees}
                            onSelectionChange={(e) => setSelectedEmployees(e.value)}
                        >
                            <Column selectionMode='multiple' headerStyle={{width: '3rem'}} frozen></Column>
                            <Column field="Badge.Badge" header="Payroll" frozen filter></Column>
                            <Column field="2" header="Employee Name" frozen filter></Column>
                            <Column field="4" header="Country " filter></Column>
                            <Column field="Cuenta.Nombre" header="Cuenta" filter></Column>
                            <Column field="Lob.Lob" header="Lob" filter></Column>
                            <Column field="7" header="ACD ID " filter></Column>
                            <Column field="8" header="ACD Name " filter></Column>
                            <Column field="9" header="ACD Skill " filter></Column>
                            <Column field="10" header="Badge Supervisor " filter></Column>
                            <Column field="11" header="Badge Account Manager " filter></Column>
                            <Column field="12" header="Badge Director " filter></Column>
                            <Column field="13" header="Shift Type " filter></Column>
                            <Column field="14" header="HIRE DT " filter></Column>
                            <Column field="15" header="Training DT " filter></Column>
                            <Column field="16" header="Prod DT " filter></Column>
                            <Column field="17" header="LOB 2 " filter></Column>
                            <Column field="18" header="LOB 3 " filter></Column>
                            <Column field="19" header="LOB2 Date " filter></Column>
                            <Column field="20" header="LOB 3 Date " filter></Column>
                            <Column field="21" header="Site " filter></Column>
                            <Column field="22" header="BYOD " filter></Column>
                            <Column field="23" header="Access Card N " filter></Column>
                            <Column field="24" header="Windows User " filter></Column>
                            <Column field="25" header="Internal email " filter></Column>
                            <Column field="26" header="Parent " filter></Column>
                            <Column field="27" header="Personal Email " filter></Column>
                            <Column field="28" header="Cel Phone " filter></Column>
                            <Column field="29" header="Contect Ph#2 " filter></Column>
                            <Column field="30" header="Contact Ph#3 " filter></Column>
                            <Column field="36" header="Emergency Contact " filter></Column>
                            <Column field="31" header="Emergency Contact Number " filter></Column>
                            <Column field="37" header="Emergency Pref. " filter></Column>
                            <Column field="32" header="AnyDeskCode " filter></Column>
                            <Column field="33" header="Home Address " filter></Column>
                            <Column field="34" header="Departamento " filter></Column>
                            <Column field="35" header="Municipio " filter></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
