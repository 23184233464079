import React from "react"
import {FormSurveyUpdate} from "../../components/Skyroster"

export function DashboardSkytrackingPage() {
    return (
        <>
            <FormSurveyUpdate />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Dashboard Skytracking</h5>
                        <p>Initial Page for Skytracking</p>
                        <div className="grid">
                            <div className="col-12">
                                <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=1lgn1sGKlUqcD8ZmYBqsydrcT97kvrdFghYXnT3P6xhUNUdITExZOUUzNFRONTc0NDZTREZZRk5ZOC4u" target="_blank" role="noopener noreferrer" className="p-button font-bold">Encuesta de Satisfacción Skycom</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}