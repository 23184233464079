import React from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAp } from '../../hooks';

export function FromRejectAp({ NumAp, event, modal }) {
    const formik = useFormik({
        initialValues: initialValues(),
        validateOnChange: false,
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formData) => {
            const response_datos_ap = await getApAutomateReport('', '', '', '', '', '', NumAp);

            // console.log(response_datos_ap);

            const supp_empleado = response_datos_ap[0].SuppEmpleado;
            const account_empleado = response_datos_ap[0].AccountEmpleado;
            const director_empleado = response_datos_ap[0].DirectorEmpleado;
            const hr_empleado = 'SV5812';
            const rta_empleado = 'LT2262';

            const aprobacion_Supervisor = response_datos_ap[0].AprobacionSupervisor;
            const aprobacion_Account = response_datos_ap[0].AprobacionAccount;
            const aprobacion_Director = response_datos_ap[0].AprobacionDirector;
            const aprobacion_HR = response_datos_ap[0].AprobacionHR;
            const aprobacion_RTA = response_datos_ap[0].AprobacionRTA;

            // console.log(aprobacion_Supervisor);

            aprobacion_Supervisor == 'P' ? await rejectApMultiple({ Aprobado: supp_empleado, TipoAprobacion: 'R', Ap: NumAp, MotivoRechazo: formData.MotivoRechazo }) : console.log('no automatico');
            aprobacion_Account == 'P' ? await rejectApMultiple({ Aprobado: account_empleado, TipoAprobacion: 'R', Ap: NumAp, MotivoRechazo: formData.MotivoRechazo }) : console.log('no automatico');
            aprobacion_Director == 'P' ? await rejectApMultiple({ Aprobado: director_empleado, TipoAprobacion: 'R', Ap: NumAp, MotivoRechazo: formData.MotivoRechazo }) : console.log('no automatico');
            aprobacion_HR == 'P' ? await rejectApMultiple({ Aprobado: hr_empleado, TipoAprobacion: 'R', Ap: NumAp, MotivoRechazo: formData.MotivoRechazo }) : console.log('no automatico');
            aprobacion_RTA == 'P' ? await rejectApMultiple({ Aprobado: rta_empleado, TipoAprobacion: 'R', Ap: NumAp, MotivoRechazo: formData.MotivoRechazo }) : console.log('no automatico');

            modal();

            const padre = event.target.parentNode.parentNode;

            padre.parentNode.removeChild(padre);
        }
    });

    const { getApAutomateReport, rejectApMultiple } = useAp();

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid my-4">
                <div className="col-12">
                    <span className="p-float-label">
                        <InputTextarea name="MotivoRechazo" id="MotivoRechazo" className="w-full" value={formik.values.MotivoRechazo} onChange={formik.handleChange} />
                        <label htmlFor="MotivoRechazo">Motivo de Rechazo</label>
                    </span>
                </div>
            </div>
            <div className="grid my-3">
                <div className="col-12 text-right">
                    <Button severity="danger" type="submit">
                        Rechazar Excepcion
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        MotivoRechazo: ''
    };
}

function validationSchema() {
    return {
        MotivoRechazo: Yup.string().required(true)
    };
}
