import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { size,filter } from 'lodash';
import moment from 'moment';
import { FormExcepcionReject } from '../Skytracking';
import { useException, useSkytracking, useUploadFiles, useCommon, useNotificacion } from '../../hooks';

export function TableReportException({ excepciones }) {
    const toast = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [showButtonsApprove, setShowButtonsApprove] = useState(null);

    const { onAprobarHorasPersonales, onChangeStateMarcaRechazada, getMarcasEditar, updateMarcasEditar, addDetalleMarcasEditar, getMarcasEditarEspec } = useSkytracking();
    const { getMarcaException, insertDetalleMarcaExcepcion, updateExcepcionReject, getOnlyException } = useException();
    const { calcAuxMarca } = useUploadFiles();
    const { getPlazaValid, getCuenta, listCuentas } = useCommon();
    const {addNotificacion, addAsignacionNotificacion} = useNotificacion()

    useEffect(() => {
        (async () => {
            const response_plaza = await getPlazaValid();
            if (size(response_plaza) > 0) {
                switch (response_plaza[0].Valor) {
                    case '8':
                    case '11':
                    case '17':
                    case '16':
                        setShowButtonsApprove(true);
                        break;

                    default:
                        setShowButtonsApprove(false);
                        break;
                }
            } else {
                setShowButtonsApprove(false);
            }

            await getCuenta();
        })();
    }, []);

    const onResponseForm = async (motivo, idExcepcion, event) => {
        try {
            const padre = event.target.parentNode.parentNode;

            padre.parentNode.removeChild(padre);

            const dataExcepcion = await getOnlyException(idExcepcion);

            const response_marca = await getMarcaException(dataExcepcion.empleado_data.Badge, dataExcepcion.FechaInicio);

            const response_notificacion = await addNotificacion({
                Modulo: 2,
                Descripcion: `Excepcion No ${idExcepcion} rechazada, ${dataExcepcion.empleado_data.Badge} - ${dataExcepcion.FechaInicio}: ${motivo}`,
            })

            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: dataExcepcion.SolicitadoPor,
                Estado: 0
            })

            if (size(response_marca) > 0) {
                await onAprobarHorasPersonales(response_marca.id, null);
                await onChangeStateMarcaRechazada(response_marca.id, 2);
                await updateExcepcionReject(idExcepcion, motivo);
            }

            toast.current.show({
                severity: 'success',
                summary: 'Excepcion',
                detail: 'Excepcion Rechazada correctamente',
                life: 3000
            });

            setShowModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    const onRechazarExceptionModal = (idExcepcion, event) => {
        setShowModal(true);
        setContentModal(<FormExcepcionReject idExcepcion={idExcepcion.id} response={onResponseForm} elemento={event} />);
    };

    const onDetalleExcepcion = async (idExcepcion) => {
        const response_excepcion = await getOnlyException(idExcepcion.id)
        setShowModal(true);
        setContentModal(
            <>
            <div className='grid'>
                <div className='col-12'>
                    <Image src={response_excepcion.File} width='100%' preview />
                </div>
            </div>
            </>
        );
    };

    const onAprobarEjecutarException = async (exception, event) => {
        const padre = event.target.parentNode.parentNode;

        padre.parentNode.removeChild(padre);

        let fechaInicio = new Date(exception.FechaInicio);
        let fechaFinal = new Date(exception.FechaFin);
        let arr_fechas = [];

        while (fechaInicio <= fechaFinal) {
            /* arr_fechas.push(`${fechaInicio.getFullYear()}-${fechaInicio.getMonth() + 1}-${fechaInicio.getDate() + 1}`); */
            fechaInicio.setDate(fechaInicio.getDate() + 1);

            arr_fechas.push(moment(fechaInicio).format('YYYY-MM-DD'));
        }

        for await (const fecha of arr_fechas) {
            const response_marca = await getMarcaException(exception.Badge, fecha);

            if (size(response_marca) > 0) {
                await onAprobarHorasPersonales(response_marca.id, null);
                await insertDetalleMarcaExcepcion(response_marca.id, exception.TipoMarca_id, exception.ValorExcepcion, exception.id);
                await onChangeStateMarcaRechazada(response_marca.id, 1);

                await calcAuxMarca(response_marca.id);
            }
        }

        toast.current.show({
            severity: 'success',
            summary: 'Excepcion',
            detail: 'Excepcion Aprobada',
            life: 3000
        });
    };

    const aprobacionExcepcionesAutomatizado = async (exception, event) => {
        const padre = event.target.parentNode.parentNode;

        padre.parentNode.removeChild(padre);

        console.log(exception)

        let fecha_inicio = moment(exception.FechaInicio)
        let fecha_final = moment(exception.FechaFin)

        while (fecha_inicio <= fecha_final){

            const fecha_marca = fecha_inicio.format("YYYY-MM-DD")

            switch (exception.TipoMarca_id) {
                case 44:
                    console.log("Restas y sumar nocturnidad")
                    const list_convert = {
                        2:3,
                        4:5,
                        6:66,
                        7:67,
                        8:68,
                        9:69,
                        10:70,
                        11:71,
                        12:72,
                        13:73,
                        14:74,
                        15:75,
                        16:76,
                        17:77,
                        18:78,
                    }
                    const response_marca = await getMarcasEditar(exception.Badge, fecha_marca);
                    let rest_total = exception.ValorExcepcion

                    for await (const marcas_resta of response_marca){
                        if(marcas_resta.tipoMarca_data.Tipo == 2 && rest_total > 0){
                            let guardar_horas = marcas_resta.Horas - rest_total
                            
                            if (guardar_horas >= 0){
                                await updateMarcasEditar(guardar_horas, marcas_resta.id)
                                await addDetalleMarcasEditar(marcas_resta.Marca, list_convert[marcas_resta.tipoMarca_data.id], rest_total)
                                /* console.log("guardar la pausa: " + marcas_resta.tipoMarca_data.id + " -> " + guardar_horas)
                                console.log("guardar la pausa: " + list_convert[marcas_resta.tipoMarca_data.id] + " -> " + rest_total) */
                            }else{
                                await updateMarcasEditar(0, marcas_resta.id)
                                await addDetalleMarcasEditar(marcas_resta.Marca, list_convert[marcas_resta.tipoMarca_data.id], marcas_resta.Horas)
                                /* console.log("guardar la pausa: " + marcas_resta.tipoMarca_data.id + " -> " + 0)
                                console.log("guardar la pausa: " + list_convert[marcas_resta.tipoMarca_data.id] + " -> " + marcas_resta.Horas) */
                            }

                            rest_total = guardar_horas * (-1)

                        }
                    }

                    if (rest_total > 0){
                        const response_marca_espec = await getMarcasEditarEspec(exception.Badge, fecha_marca, 3);
                        let valor_marca = 0
                        if (size(response_marca_espec) > 0){
                            valor_marca = response_marca_espec[0].Horas
                            await updateMarcasEditar(valor_marca + rest_total, response_marca[0].Marca)
                        }else{
                            await addDetalleMarcasEditar(response_marca[0].Marca, 3, rest_total)
                        }
                        /* console.log("Llenar con " + rest_total + " night_logintime") */
                    }

                    console.log(response_marca)
                    break;
            
                default:
                    
                    break;
            }

            const response_marca = await getMarcaException(exception.Badge, fecha_marca);

            const response_notificacion = await addNotificacion({
                Modulo: 2,
                Descripcion: `Excepcion No ${exception.id} aprobada, Empleado ${exception.Badge} - ${exception.NombreEmpleado}`,
            })

            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: exception.SolicitadoPor,
                Estado: 0
            })

            if (size(response_marca) > 0) {
                await onAprobarHorasPersonales(response_marca.id, null);
                await insertDetalleMarcaExcepcion(response_marca.id, exception.TipoMarca_id, exception.ValorExcepcion, exception.id);
                await onChangeStateMarcaRechazada(response_marca.id, 1);

                await calcAuxMarca(response_marca.id);
            }

            fecha_inicio = fecha_inicio.set("date", parseInt(fecha_inicio.format("DD")) + 1)
        }

        toast.current.show({
            severity: 'success',
            summary: 'Excepcion',
            detail: 'Excepcion Aprobada',
            life: 3000
        });
    }

    const actionBodyTemplate = (rowData) => {
        let str_estadoExcepcion = '';

        switch (rowData.EstadoExcepcion) {
            case '0':
                str_estadoExcepcion = 'Pendiente';
                break;

            case '1':
                str_estadoExcepcion = 'Aprobada';
                break;
            case '2':
                str_estadoExcepcion = 'Rechazada';
                break;

            default:
                break;
        }

        return (
            <React.Fragment>
                {rowData.EstadoExcepcion == 0 && showButtonsApprove && (
                    <div className="grid">
                        <div className="col-6">
                            <Button type="button" severity="success" aria-label="Aprobar" onClick={(e) => aprobacionExcepcionesAutomatizado(rowData, e)}>
                                Aprobar
                            </Button>
                        </div>
                        <div className="col-6">
                            <Button type="button" severity="danger" aria-label="Rechazar" onClick={(e) => onRechazarExceptionModal(rowData, e)}>
                                Rechazar
                            </Button>
                        </div>
                    </div>
                )}
                <div className="grid">
                    <div className="col-12">{str_estadoExcepcion}</div>
                </div>
                <div className='grid'>
                    <div className='col-12'>
                        <Button type='button' severity='primary' onClick={(e) => onDetalleExcepcion(rowData)}>

                            <i className='pi pi-eye' style={{fontSize: '1rem'}}></i>

                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const templateCuenta = (rowData) => {

        const filter_cuenta = filter(listCuentas, ["value", parseInt(rowData.Cuenta)])

        return filter_cuenta[0].name
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog header="Skyverse Skytracking" style={{ width: '35vw' }} visible={showModal} modal onHide={() => setShowModal(false)}>
                {contentModal}
            </Dialog>
            <DataTable
                value={excepciones}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
                <Column field="FechaInicio" header="Fecha Inicio" filter></Column>
                <Column field="FechaFin" header="Fecha Final" filter></Column>
                <Column field="Badge" header="Empleado" filter></Column>
                <Column field="NombreEmpleado" header="Nombre" filter></Column>
                <Column body={templateCuenta} header="Cuenta" filter></Column>
                <Column field="ValorExcepcion" header="Horas" filter></Column>
                <Column field="NombreTipoMarca" header="Pausa" filter></Column>
                <Column field="Motivo" header="Descripcion de la excepcion" filter></Column>
                <Column body={actionBodyTemplate} exportable={false}></Column>
                <Column field="MotivoRechazo" header="Motivo Rechazo"></Column>
            </DataTable>
        </>
    );
}
