import { BASE_API } from '../../utilities/constants';

export async function getComponenteSiasApi(token, estadoComponente = "", serialComponente="", TipoComponente="", FechaCompra="", Ubicacion="",Modelo="") {
    try {

        const estadoComponenteFilter = `EstadoComponente=${estadoComponente}`;
        const serialComponenteFilter = `Serial=${serialComponente}`;

        const tipoComponenteFilter = `TipoComponente=${TipoComponente}`;
        const fechaCompraFilter = `FechaCompra=${FechaCompra}`;
        const ubicacionFilter = `Ubicacion=${Ubicacion}`;
        const modeloMarcaFilter = `Modelo__Marca=${Modelo}`;

        const url = `${BASE_API}v1/ComponenteSia/?${estadoComponenteFilter}&${serialComponenteFilter}&${tipoComponenteFilter}&${fechaCompraFilter}&${ubicacionFilter}&${modeloMarcaFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addComponenteSiaApi(data, token) {
    try {
        const url = `${BASE_API}v1/ComponenteSia/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateComponenteSiaApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/ComponenteSia/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
