import React, { useState, useEffect } from 'react'
import { FilterRoster, TableMasterRosterReport } from '../../components/Skyroster';
import {useMasterRoster} from "../../hooks"

export function MasterRosterAutoPage() {

    const [listRoster, setListRoster] = useState([])
    
    
    const {getMasterRosterAutomateReport, loadingMasterRoster} = useMasterRoster()

    const onResponseForm = async (formData) => {

        const response_roster = await getMasterRosterAutomateReport(formData.Semana, formData.Cuenta, formData.Lob)

        setListRoster(response_roster)

    };


    

  return (
    <>
        <div className='grid'>
            <div className='col-12'>
                <div className='card'>
                    <h5>Reporte Master Roster</h5>
                    <FilterRoster
                            showField={{
                                Semana: true,
                                Cuenta: true,
                                Lob: true,
                                Supervisor: false,
                                CuentaRequired:false
                            }}
                            responseForm={onResponseForm}
                        />
                </div>
            </div>
        </div>

        <div className='grid'>
            <div className='col-12'>
                <div className='card'>
                    <h5>Table de Resultados Master Roster</h5>
                    {loadingMasterRoster ? (
                        <div className='grid'>
                            <div className='col-12 text-center'>
                                <i className='pi pi-spin pi-spinner' style={{fontSize: '5rem'}}></i>
                            </div>
                        </div>
                    ) : (
                        <>
                        <TableMasterRosterReport ListRoster={listRoster} />
                        </>
                    )}
                </div>
            </div>
        </div>
    </>
  )
}
