import React, { useEffect, useRef, useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { size, filter } from 'lodash';
import moment from 'moment';
import { BASE_API } from '../../utilities/constants';
import { useRosterUpdate } from '../../hooks';
import excel from '../../assets/img/excel.png';
import template from '../../assets/template/TemplateSkyVerseSkyRoster_horarios.xlsx';

export function CargarHorariosPage() {
    const toast = useRef();
    const { insertUpdateHorario, getNocturnidadConfig, inicioNocturnidad, finNocturnidad, listNocturnidades } = useRosterUpdate();
    const [progressBar, setProgressBar] = useState(0);

    useEffect(() => {
        getNocturnidadConfig();
    }, []);

    const onUpload = async (e) => {
        let response = JSON.parse(e.xhr.response);
        let procentaje = 0;
        let contador = 1;
        let total = size(response);

        const filter_response_pais = filter(listNocturnidades, ['Pais', 'El Salvador']);
        let inicio_nocturnidad_date = new Date();
        let final_nocturnidad_date = new Date();
        let midnight_date = new Date();
        midnight_date.setHours(0);
        midnight_date.setMinutes(0);
        midnight_date.setSeconds(0);

        let medianoche_date = new Date();
        medianoche_date.setHours(24);
        medianoche_date.setMinutes(0);
        medianoche_date.setSeconds(0);

        if (size(filter_response_pais) > 0) {
            let hin = filter_response_pais[0].HoraInicioNocturnidad.split(':');
            inicio_nocturnidad_date.setHours(hin[0]);
            inicio_nocturnidad_date.setMinutes(hin[1]);
            inicio_nocturnidad_date.setSeconds(hin[2]);

            let hfn = filter_response_pais[0].HoraFinNocturnidad.split(':');
            final_nocturnidad_date.setHours(hfn[0]);
            final_nocturnidad_date.setMinutes(hfn[1]);
            final_nocturnidad_date.setSeconds(hfn[2]);
        }

        for await (const empleado of Object.keys(response)) {
            for await (const dia of Object.keys(response[empleado])) {
                let { HoraInicio, HoraFinal, Almuerzo } = response[empleado][dia];
                let hours_diff = 0;
                let madrugada_diff = 0;
                let noche_diff = 0;
                let mensaje = 'Horas Dia';
                let libre = true;

                let horas_programadas_diurnas = 0
                let horas_programadas_nocturnas = 0

                if (HoraInicio != '00:00:00' && HoraInicio != 'OFF') {
                    let hora_inicio_date = new Date();
                    let hid = HoraInicio.split(':');
                    hora_inicio_date.setHours(hid[0]);
                    hora_inicio_date.setMinutes(hid[1]);
                    hora_inicio_date.setSeconds(hid[2]);

                    let hora_final_date = new Date();
                    let hfd = HoraFinal.split(':');
                    hora_final_date.setHours(hfd[0]);
                    hora_final_date.setMinutes(hfd[1]);
                    hora_final_date.setSeconds(hfd[2]);

                    /* console.log('Inicio: ' + moment(hora_inicio_date).format('HH:mm:ss'));
                    console.log('Final: ' + moment(hora_final_date).format('HH:mm:ss'));
                    console.log('almuerzo: ' + Almuerzo); */

                    HoraInicio = moment(hora_inicio_date).format('HH:mm:ss')
                    HoraFinal = moment(hora_final_date).format('HH:mm:ss')
                    Almuerzo = Almuerzo;

                    if (hora_inicio_date < final_nocturnidad_date && hora_inicio_date > midnight_date) {
                        let madrugada_mill_diff = Math.abs(final_nocturnidad_date - hora_inicio_date);
                        madrugada_diff = madrugada_mill_diff / 3600000;
                    }

                    if (hora_final_date > inicio_nocturnidad_date && hora_final_date < medianoche_date) {
                        let noche_mill_diff = Math.abs(hora_final_date - inicio_nocturnidad_date);
                        noche_diff = noche_mill_diff / 3600000;
                    }

                    if (hora_inicio_date > hora_final_date) {
                        hora_final_date.setDate(hora_final_date.getDate() + 1);
                        mensaje = 'Horas Noche';
                    }

                    let milliseconds_diff = Math.abs(hora_final_date - hora_inicio_date);
                    hours_diff = milliseconds_diff / 3600000 - Almuerzo;

                    if (mensaje == "Horas Noche"){
                        horas_programadas_nocturnas = (hours_diff - (madrugada_diff + noche_diff)).toFixed(5)
                        horas_programadas_diurnas = 0
                    }else{
                        horas_programadas_diurnas = (hours_diff - (madrugada_diff + noche_diff)).toFixed(5)
                        horas_programadas_nocturnas = (madrugada_diff + noche_diff).toFixed(5)
                    }

                    /* console.log(mensaje + ': ' + (hours_diff - (madrugada_diff + noche_diff)).toFixed(2) + ' Nocturnidad: ' + (madrugada_diff + noche_diff).toFixed(2));
                    console.log('Horas Totales Dia: ' + (parseFloat((hours_diff - (madrugada_diff + noche_diff)).toFixed(2)) + parseFloat((madrugada_diff + noche_diff).toFixed(2))));

                    console.log('*************************************'); */

                    libre = false;
                } else if (HoraInicio == 'OFF') {
                    HoraInicio = '00:00:00';
                    HoraFinal = '00:00:00';
                    Almuerzo = 0;
                }

                await insertUpdateHorario(empleado, dia, {
                    Dia: dia,
                    HoraInicio: HoraInicio,
                    HoraFinal: HoraFinal,
                    Almuerzo: Almuerzo,
                    TotalHorasDia: horas_programadas_diurnas,
                    HorasNocturnas: horas_programadas_nocturnas,
                    Libre: libre,
                    Empleado: empleado
                });
            }

            procentaje = (contador / total) * 100;
            setProgressBar(procentaje.toFixed(2));
            contador += 1;
        }

        toast.current.show({
            severity: 'success',
            summary: 'Horarios',
            detail: 'Horarios Cargados Exitosamente',
            life: 3000
        });
    };

    const onDownloadTemplate = () => {
        window.open(template);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Carga de Horarios</h5>
                        <p>Carga todos los horarios de los empleados, al cargar masivamente los horarios desde este modulo se realizaran las siguientes acciones: </p>
                        <ul>
                            <li>Carga de horarios para la semana actual</li>
                            <li>Carga de horas de programadas segun los horarios en el template</li>
                            <li>Calculo de Aux para los horarios ingresados</li>
                        </ul>
                        <div className="grid my-5">
                            <div className="col-12">
                                <h5>Progreso %</h5>
                                <ProgressBar value={progressBar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 md:col-8">
                    <div className="card">
                        <h5>Formato de carga</h5>
                        <p>
                            Este template unicamente sirve para cargar horarios de los empleados, llena el formato con todas las columnas y usa el formato de celda HH:MM:SS sin fechas. El campo de lunch para cada dia tiene que estar en numeros para
                            que el modulo calcule correctamente las horas
                        </p>
                        <div className="grid my-3">
                            <div className="col-12">
                                <h5>Template</h5>
                                <Button severity="secondary" outlined onClick={onDownloadTemplate}>
                                    <div className="grid">
                                        <div className="col-12">
                                            <img src={excel} />
                                        </div>
                                        <div className="col-12">
                                            <p>DESCARGAR</p>
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div className="card">
                        <h5>Upload</h5>
                        <p>Anexa el formato de carga aqui y da click en subir para iniciar el proceso</p>
                        <div className="grid my-3">
                            <div className="col-12">
                                <FileUpload mode="basic" name="roster_schedule_upload" url={`${BASE_API}v1/Roster_Schedule_Upload/`} maxFileSize={1000000} onUpload={onUpload} accept=".xlsx" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
