import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCommon, useException } from '../../hooks';

export function FormExcepcionOffline({ close }) {
    const toast = useRef(null);
    const [definicionST, setDefinicionST] = useState(null);

    const { listEmpleados, loadingCommon, listPausas, getEmpleados, getPausasFilter } = useCommon();
    const { insertExceptionComplete } = useException();

    useEffect(() => {
        (async () => {
            await getEmpleados();
            await getPausasFilter([], false, true);
        })();
    }, []);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            for await (const empleado of formValue.Empleados) {
                await insertExceptionComplete(formValue, empleado);
            }

            toast.current.show({
                severity: 'success',
                summary: 'Exito',
                detail: 'Excepcion Agregada correctamente',
                life: 3000
            });

            close();
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast} />
            <div className="grid mb-3 mt-5">
                <div className="col-12 md:col-6">
                    <span className="p-float-label">
                        <Calendar className="w-full" id="RangoFechas" name="RangoFechas" selectionMode="range" value={formik.values.RangoFechas} onChange={(e) => formik.setFieldValue('RangoFechas', e.value)} />
                        <label htmlFor="RangoFechas">Rango de Fechas</label>
                    </span>
                </div>
                <div className="col-12 md:col-6">
                    {loadingCommon ? (
                        <div className="grid mb-3">
                            <div className="col-12 text-center">
                                <i className="pi pi-spinner pi-spin"></i>
                            </div>
                        </div>
                    ) : (
                        <span className="p-float-label">
                            <MultiSelect
                                maxSelectedLabels={2}
                                options={listEmpleados}
                                optionLabel="name"
                                filter
                                id="Empleados"
                                name="Empleados"
                                className="w-full"
                                value={formik.values.Empleados}
                                onChange={(e) => formik.setFieldValue('Empleados', e.value)}
                            />
                            <label htmlFor="Empleados">Empleados</label>
                        </span>
                    )}
                </div>
            </div>
            <div className="grid mb-3">
                <div className="col-12">
                    <span className="p-float-label">
                        <Dropdown
                            options={listPausas}
                            optionLabel="name"
                            filter
                            id="Pausa"
                            name="Pausa"
                            className="w-full"
                            value={formik.values.Pausa}
                            onChange={(e) => {
                                setDefinicionST(e.value.definicion);
                                formik.setFieldValue('Pausa', e.value);
                            }}
                        />
                        <label htmlFor="Pausa">Pausa</label>
                    </span>
                </div>
            </div>
            {definicionST && (
                <div className="grid p-3 mb-3 bg-indigo-800 text-white">
                    <div className="col-12 text-center">{definicionST}</div>
                </div>
            )}
            <div className="grid mb-3 mt-2">
                <div className="col-12">
                    <span className="p-float-label">
                        <InputTextarea className="w-full" id="Comentario" name="Comentario" cols={30} rows={5} value={formik.values.Comentario} onChange={formik.handleChange} />
                        <label htmlFor="Comentario">Comentario de la excepcion</label>
                    </span>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 text-right">
                    <Button type="submit" severity="primary" size="large">
                        Guardar Excepcion
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        RangoFechas: '',
        Empleados: '',
        Pausa: '',
        Comentario: ''
    };
}

function validationSchema() {
    return {
        RangoFechas: Yup.array().required(),
        Empleados: Yup.array().required(),
        Pausa: Yup.object().required(),
        Comentario: Yup.string().required()
    };
}
