import { BASE_API } from '../../utilities/constants';

export async function getDetallesEmpleadoApi(token, Empleado) {
    try {
        const empleadoFilter = `Empleado=${Empleado}`;

        const url = `${BASE_API}v1/DetalleEmpleado/?${empleadoFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getDetalleEmpleadoApi(token, Propiedad, Empleado) {
    try {
        const propiedadFilter = `Propiedad=${Propiedad}`;
        const empleadoFilter = `Empleado=${Empleado}`;

        const url = `${BASE_API}v1/DetalleEmpleado/?${propiedadFilter}&${empleadoFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getSkyViewEmpleadoApi(token, Supervisor='') {
    try {

        const supervisorFilter = `Supervisor=${Supervisor}`

        const url = `${BASE_API}v1/SkyViewEmpleado/?${supervisorFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
export async function getSkyViewEmpleadoByIdApi(token, EmpleadoId='', Week="") {
    try {

        const EmpleadoIdFilter = `Empleado=${EmpleadoId}`;
        const WeekFilter = `Week=${Week}`;

        const url = `${BASE_API}v1/SkyViewEmpleadoById/?${EmpleadoIdFilter}&${WeekFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getEmpleadoByFilterApi(token, Cuenta, Propiedad, Week = '', Badge = '', Empleado = '', Valor_in = '') {
    try {
        const cuentaFilter = `Valor=${Cuenta}`;
        const valorInFilter = `Valor__in=${Valor_in}`;
        const propiedadFilter = `Propiedad=${Propiedad}`;
        const weekFilter = `Empleado__Week=${Week}`;
        const badgeFilter = `Empleado__Badge=${Badge}`;
        const empleadoFilter = `Empleado=${Empleado}`;

        const url = `${BASE_API}v1/DetalleEmpleadoSkytracking/?${cuentaFilter}&${propiedadFilter}&${weekFilter}&${badgeFilter}&${empleadoFilter}&${valorInFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addDetalleEmpleadoApi(data, token) {
    try {
        const url = `${BASE_API}v1/DetalleEmpleado/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateDetalleEmpleadoApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/DetalleEmpleado/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {}
}
