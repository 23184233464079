import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import moment from "moment"
import * as Yup from 'yup';
import { size, filter } from 'lodash';
import { useRosterUpdate, useNotificacion, useMasterRoster, useCommon } from '../../hooks';
import { listProfesiones, listTieneHijos, listSeguros, listAcdName, listEquipoTrabajo, listPropiedadesFormik } from '../../utilities/options';

export function FormRosterUpdate({ empleado }) {
    const toast = useRef(null);
    const btnRef = useRef()
    const [rosterUpdatePendiente, setRosterUpdatePendiente] = useState(false);
    const [visibleButton, setVisibleButton] = useState(false);
    const [listSupervisores, setListSupervisores] = useState(null);

    const { insertRosterUpdate, insertRosterUpdateDetalle, existRosterUpdate } = useRosterUpdate();
    const {addNotificacion, addAsignacionNotificacion} = useNotificacion()
    const {getMasterRosterAutomateReport} = useMasterRoster()
    const {getLob, listLob} = useCommon()

    useEffect(() => {
        (async () => {
            setRosterUpdatePendiente(await existRosterUpdate(empleado.Payroll));
            const response_roster = await getMasterRosterAutomateReport(moment().week(), empleado[3], "")
            const filter_sups = filter(response_roster, ["Plaza", "3"])
            let supervisor_list = []

            for await (const sup of filter_sups){
                supervisor_list.push({
                    name: `${sup.Badge} - ${sup.Nombre}`,
                    value: sup.Badge
                })
            }

            await getLob(empleado[3])

            setListSupervisores(supervisor_list)
        })();
    }, []);

    const formik = useFormik({
        initialValues: initialValues(empleado),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            const response_rosterUpdate = await insertRosterUpdate(empleado.Payroll);
            const response_notificacion = await addNotificacion({
                Modulo: 1,
                Descripcion: `Roster Update Ingresado para ${empleado.Payroll} - ${empleado[2]}`,
            })

            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: 'LT2262',
                Estado: 0
            })

            if (size(response_rosterUpdate) > 0) {
                for await (const field of Object.keys(formValue)) {
                    const field_destruct = formValue[field];
                    let propiedad = listPropiedadesFormik[field];

                    if (propiedad != '') {
                        await insertRosterUpdateDetalle(response_rosterUpdate.id, propiedad, field_destruct);
                    }
                }
            }

            toast.current.show({
                severity: 'success',
                summary: 'Exito',
                detail: 'Roster Update Agregado correctamente',
                life: 3000
            });

            setRosterUpdatePendiente(true);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <h5>
                        Roster Update para el empleado - {empleado.Payroll} {empleado[2]}
                    </h5>
                </div>
            </div>

            {rosterUpdatePendiente ? (
                <div className="grid">
                    <div className="col-12">
                        <h5>Roster Update Pendiente para este empleado</h5>
                    </div>
                </div>
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown className="w-full" options={listProfesiones} optionLabel="name" id="Profesion" name="Profesion" value={formik.values.Profesion} filter onChange={(e) => formik.setFieldValue('Profesion', e.value)} />
                                <label htmlFor="Profesion">Profesion</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown className="w-full" options={listTieneHijos} optionLabel="name" id="Parent" name="Parent" value={formik.values.Parent} filter onChange={(e) => formik.setFieldValue('Parent', e.value)} />
                                <label htmlFor="Parent">Parent</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputMask className="w-full" name="Phone" id="Phone" autoClear={false} value={formik.values.Phone} onChange={formik.handleChange} mask="( + 999 ) 9999 9999 9999" />
                                <label htmlFor="Phone">Phone</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputMask className="w-full" name="Phone_2" id="Phone_2" autoClear={false} value={formik.values.Phone_2} onChange={formik.handleChange} mask="( + 999 ) 9999 9999 9999" />
                                <label htmlFor="Phone_2">Contact Phone 1</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputMask className="w-full" name="Phone_3" id="Phone_3" autoClear={false} value={formik.values.Phone_3} onChange={formik.handleChange} mask="( + 999 ) 9999 9999 9999" />
                                <label htmlFor="Phone_3">Contact Phone 2</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputText className="w-full" name="EmergencyName" id="EmergencyName" value={formik.values.EmergencyName} onChange={formik.handleChange} />
                                <label htmlFor="EmergencyName">Emergency Contact Name</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputMask className="w-full" name="EmergencyPhone" id="EmergencyPhone" autoClear={false} value={formik.values.EmergencyPhone} onChange={formik.handleChange} mask="( + 999 ) 9999 9999 9999" />
                                <label htmlFor="EmergencyPhone">Emergency Contact Number</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <Dropdown className="w-full" options={listSeguros} optionLabel="name" id="Seguro" name="Seguro" value={formik.values.Seguro} filter onChange={(e) => formik.setFieldValue('Seguro', e.value)} />
                                <label htmlFor="Seguro">Emergency Pref</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-3">
                            <span className="p-float-label">
                                <Dropdown className="w-full" options={listAcdName} optionLabel="name" id="Acd" name="Acd" value={formik.values.Acd} filter onChange={(e) => formik.setFieldValue('Acd', e.value)} />
                                <label htmlFor="Acd">ACD</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-3">
                            <span className="p-float-label">
                                <InputText className="w-full" name="AcdID" id="AcdID" value={formik.values.AcdID} onChange={formik.handleChange} />
                                <label htmlFor="AcdID">ACDID</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-3">
                            <span className="p-float-label">
                                <InputText className="w-full" name="AcdName" id="AcdName" value={formik.values.AcdName} onChange={formik.handleChange} />
                                <label htmlFor="AcdName">ACD Name</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-3">
                            <span className="p-float-label">
                                <InputText className="w-full" name="AcdSkill" id="AcdSkill" value={formik.values.AcdSkill} onChange={formik.handleChange} />
                                <label htmlFor="AcdSkill">ACD Skill</label>
                            </span>
                        </div>

                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown className='w-full' name='Supervisor' id='Supervisor' value={formik.values.Supervisor} onChange={(e) => {formik.setFieldValue("Supervisor", e.value)}} options={listSupervisores} optionLabel='name' />
                                <label htmlFor="Supervisor">Supervisor</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown className='w-full' name='Lob' id='Lob' value={formik.values.Lob} onChange={(e) => {formik.setFieldValue("Lob", e.value)}} options={listLob} optionLabel='name' />
                                <label htmlFor="Lob">Lob</label>
                            </span>
                        </div>

                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <Calendar dateFormat="yy-mm-dd" name="Lob2DT" id="Lob2DT" className="w-full" value={formik.values.Lob2DT} onChange={(e) => formik.setFieldValue('Lob2DT', e.value)} />
                                <label htmlFor="Lob2DT">Lob 2 Date</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <Calendar dateFormat="yy-mm-dd" name="Lob3DT" id="Lob3DT" className="w-full" value={formik.values.Lob3DT} onChange={(e) => formik.setFieldValue('Lob3DT', e.value)} />
                                <label htmlFor="Lob3DT">Lob 3 Date</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <Dropdown className="w-full" options={listEquipoTrabajo} optionLabel="name" id="Byod" name="Byod" value={formik.values.Byod} filter onChange={(e) => formik.setFieldValue('Byod', e.value)} />
                                <label htmlFor="Byod">BYOD</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputText className="w-full" name="InternalEmail" id="InternalEmail" value={formik.values.InternalEmail} onChange={formik.handleChange} />
                                <label htmlFor="InternalEmail">Internal Email</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputText className="w-full" name="PersonalEmail" id="PersonalEmail" value={formik.values.PersonalEmail} onChange={formik.handleChange} />
                                <label htmlFor="PersonalEmail">Personal Email</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputText className="w-full" name="AccessCard" id="AccessCard" value={formik.values.AccessCard} onChange={formik.handleChange} />
                                <label htmlFor="AccessCard">Access Card N</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputText className="w-full" name="WindowsUser" id="WindowsUser" value={formik.values.WindowsUser} onChange={formik.handleChange} />
                                <label htmlFor="WindowsUser">Windows User</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4">
                            <span className="p-float-label">
                                <InputText className="w-full" name="AnyDesk" id="AnyDesk" value={formik.values.AnyDesk} onChange={formik.handleChange} />
                                <label htmlFor="AnyDesk">AnyDesk Code</label>
                            </span>
                        </div>
                    </div>

                    <div className="grid mt-3 mb-5">
                        <div className="col-12 text-right">
                            <Button style={{display: 'none'}} type='submit' ref={btnRef}></Button>
                            <Button severity="success" type="button" size="large" onClick={(e) => {
                                setVisibleButton(true)
                                btnRef.current.click()
                            }} disabled={visibleButton}>
                                {visibleButton && (
                                    <i className='pi pi-spin pi-spinner mr-3'></i>
                                )}
                                Enviar Roster Update
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </form>
    );
}

function initialValues(empleado) {
    return {
        EmergencyName: empleado[36] ? empleado[36] : '',
        AcdID: empleado[7] ? empleado[7] : '',
        AcdName: empleado[8] ? empleado[8] : '',
        AcdSkill: empleado[9] ? empleado[9] : '',
        InternalEmail: empleado[25] ? empleado[25] : '',
        PersonalEmail: empleado[27] ? empleado[27] : '',
        AccessCard: empleado[23] ? empleado[23] : '',
        WindowsUser: empleado[24] ? empleado[24] : '',
        AnyDesk: empleado[32] ? empleado[32] : '',
        Phone: empleado[28] ? empleado[28] : '',
        Phone_2: empleado[29] ? empleado[29] : '',
        Phone_3: empleado[30] ? empleado[30] : '',
        EmergencyPhone: empleado[31] ? empleado[31] : '',
        Lob2DT: empleado[19] ? empleado[19] : '',
        Lob3DT: empleado[20] ? empleado[20] : '',
        Profesion: empleado[1] ? empleado[1] : '',
        Parent: empleado[26] ? empleado[26] : '',
        Seguro: empleado[37] ? empleado[37] : '',
        Acd: empleado[57] ? empleado[57] : '',
        Byod: empleado[22] ? empleado[22] : '',
        Supervisor: empleado[10] ? empleado[10] : '',
        Lob: empleado[5] ? parseInt(empleado[5]) : '',
    };
}

function validationSchema() {
    return {
        EmergencyName: Yup.string(),
        AcdID: Yup.string(),
        AcdName: Yup.string(),
        AcdSkill: Yup.string(),
        InternalEmail: Yup.string(),
        PersonalEmail: Yup.string(),
        AccessCard: Yup.string(),
        WindowsUser: Yup.string(),
        AnyDesk: Yup.string(),
        Phone: Yup.string(),
        Phone_2: Yup.string(),
        Phone_3: Yup.string(),
        EmergencyPhone: Yup.string(),
        Lob2DT: Yup.string(),
        Lob3DT: Yup.string(),
        Profesion: Yup.string(),
        Parent: Yup.string(),
        Seguro: Yup.string(),
        Acd: Yup.string(),
        Byod: Yup.string(),
        Supervisor: Yup.string(),
        Lob: Yup.string()
    };
}
