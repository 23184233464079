import { useState } from 'react'
import moment from "moment"
import {getNotificacionesApi, updateNotificacionApi, addNotificacionApi} from "../api/skynotificacion/notificacion"
import {getAsignacionNotificacionsApi, addAsignacionNotificacionApi, updateAsignacionNotificacionApi} from "../api/skynotificacion/asignacionnotificacion"
import {useAuth} from "./useAuth"

export function useNotificacion() {

    const [loadingNotificacion, setLoadingNotificacion] = useState(false)
    const [error, setError] = useState(false)
    const [listNotificaciones, setListNotificaciones] = useState(null)
    const [listAsignacionNotificaciones, setListAsignacionNotificacion] = useState(null)

    const {auth} = useAuth()

    const getListNotificacion = async () => {
        try {
            setLoadingNotificacion(true)

            const response_notificacion = await getNotificacionesApi(auth.token);

            setListNotificaciones(response_notificacion)

            setLoadingNotificacion(false)
        } catch (error) {
            setLoadingNotificacion(false)
            setError(error)
        }
    }

    const getNotificacion = async () => {
        try {
            setLoadingNotificacion(true)

            const response_notificacion = await getNotificacionesApi(auth.token);

            setLoadingNotificacion(false)

            return response_notificacion
        } catch (error) {
            setLoadingNotificacion(false)
            setError(error)
        }
    }

    const addNotificacion = async (data) => {
        try {
            setLoadingNotificacion(true)

            const response_notificacion = await addNotificacionApi(data, auth.token);

            setLoadingNotificacion(false)

            return response_notificacion
        } catch (error) {
            setLoadingNotificacion(false)
            setError(error)
        }
    }

    const updateNotificacion = async (data, id) => {
        try {
            setLoadingNotificacion(true)

            const response_notificacion = await updateNotificacionApi(data, id, auth.token);

            setLoadingNotificacion(false)

            return response_notificacion
        } catch (error) {
            setLoadingNotificacion(false)
            setError(error)
        }
    }

    const getListAsignacionNotificacion = async () => {
        try {
            setLoadingNotificacion(true)

            const date_inicio = moment()

            const list_notificacion = await getAsignacionNotificacionsApi(auth.token, auth.me.username, "", date_inicio.format("YYYY-MM-DD"))

            setListAsignacionNotificacion(list_notificacion)

            setLoadingNotificacion(false)
        } catch (error) {
            setLoadingNotificacion(false)
            setError(error)
        }
    }

    const getAsignacionNotificacion = async () => {
        try {
            setLoadingNotificacion(true)

            const list_notificacion = await getAsignacionNotificacionsApi(auth.token)

            setLoadingNotificacion(false)

            return list_notificacion;
        } catch (error) {
            setLoadingNotificacion(false)
            setError(error)
        }
    }

    const addAsignacionNotificacion = async (data) => {
        try {
            setLoadingNotificacion(true)

            const list_notificacion = await addAsignacionNotificacionApi(data, auth.token)

            setLoadingNotificacion(false)

            return list_notificacion;
        } catch (error) {
            setLoadingNotificacion(false)
            setError(error)
        }
    }

    const updateAsignacionNotificacion = async (data, id) => {
        try {
            setLoadingNotificacion(true)

            const list_notificacion = await updateAsignacionNotificacionApi(data, id, auth.token)

            setLoadingNotificacion(false)

            return list_notificacion;
        } catch (error) {
            setLoadingNotificacion(false)
            setError(error)
        }
    }

  return {
    loadingNotificacion,
    error,
    listNotificaciones,
    listAsignacionNotificaciones,
    getListNotificacion,
    getNotificacion,
    addNotificacion,
    updateNotificacion,
    getListAsignacionNotificacion,
    getAsignacionNotificacion,
    addAsignacionNotificacion,
    updateAsignacionNotificacion
  }
}
