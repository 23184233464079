import React from 'react';
import moment from "moment"
import { FilterReportAp, TableReportAp } from '../../components/Skyap';
import { useAp } from '../../hooks';

export function ReportApPage() {
    const { getAps, aps, loadingAp } = useAp();

    const onResponseForm = async (dataForm) => {
        const fechaInicio = new Date(dataForm.RangoFechas[0]);
        const fechaFinal = new Date(dataForm.RangoFechas[1]);

        await getAps(
            dataForm.Cuenta,
            moment(fechaInicio).format("YYYY-MM-DD"),
            moment(fechaFinal).format("YYYY-MM-DD"),
            dataForm.TipoAp,
            dataForm.Empleado,
            dataForm.NumAp
        );
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte de AP</h5>
                        <p>Aqui puedes ver el reporte de ap y ademas aprobarlas o rechazarlas</p>

                        <FilterReportAp responseForm={onResponseForm} />
                    </div>
                </div>
            </div>
            {loadingAp ? (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h5>Tabla de Ap</h5>
                            <p>Aqui aparecera el resultado de la busqueda con todas las AP que apliquen dichos filtros</p>
                            <TableReportAp listAp={aps} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
