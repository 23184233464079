import React, { useState, useEffect } from 'react';
import { FilterRoster, TableMasterRoster } from '../../components/Skyroster';
import { useMasterRoster } from '../../hooks';

export function MasterRosterPage() {
    const { empleados, loadingMasterRoster, getEmpleadosMasterRoster, datosConsultados, setDatosConsultados } = useMasterRoster();

    const [segundosTranscurridos, setSegundosTranscurridos] = useState(0);

    const onResponseForm = async (formData) => {
        setDatosConsultados(0);
        setSegundosTranscurridos(0);

        await getEmpleadosMasterRoster(formData.Cuenta, formData.Lob, formData.Semana);
    };

    useEffect(() => {
        if (datosConsultados > 0) {
            const timer = setTimeout(() => {
                setDatosConsultados((prev) => prev - 1);
                setSegundosTranscurridos((prev) => prev + 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [datosConsultados]);

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte de Roster Update</h5>
                        <p>Reporte de todos los roster updates creados por lo supervisores</p>

                        <FilterRoster
                            showField={{
                                Semana: true,
                                Cuenta: true,
                                Lob: true,
                                Supervisor: false,
                                CuentaRequired:true
                            }}
                            responseForm={onResponseForm}
                        />
                    </div>
                </div>
            </div>

            {loadingMasterRoster ? (
                <div className="grid mt-5">
                    <div className="col-12 text-center">
                        <div className="relative">
                            <div className="absolute top-0 left-0">
                                <h6>Tiempo Restante Estimado: {datosConsultados.toFixed(0)} seg</h6>
                            </div>
                            <div className="absolute top-0 right-0">
                                <h6>Tiempo Transcurrido: {segundosTranscurridos.toFixed(0)} seg</h6>
                            </div>
                        </div>
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                empleados && (
                    <div className="grid mt-5">
                        <div className="col-12">
                            <div className="card">
                                <TableMasterRoster listEmpleados={empleados} />
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
}
