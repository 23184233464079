import React, { useEffect, useRef, useState } from 'react';
import { size } from 'lodash';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useCommon, useException, useSkytracking, useUploadFiles } from '../../hooks';

export function FormInsertExceptionsMultiple({ rowSelected, onCloseSelf }) {
    const toast = useRef(null);

    const accept = () => {
        toast.current.show({
            severity: 'info',
            summary: 'Procesando cambios',
            detail: 'Se estan aplicando los cambios al roster',
            life: 3000
        });

        formik.submitForm();
    };

    const reject = () => {
        toast.current.show({
            severity: 'warn',
            summary: 'Cambios Rechazados',
            detail: 'No se Aplicaran los cambios',
            life: 3000
        });
    };

    const confirm = () => {
        confirmDialog({
            message: `Seguro que quieres aplicar estos cambios a ${size(rowSelected)} empleados`,
            header: 'Cambios Masivos',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'reject',
            accept,
            reject
        });
    };

    const { getPausasFilter, listPausas } = useCommon();
    const { insertException_wfm, getMarcaException, insertDetalleMarcaExcepcion } = useException();
    const { onAprobarHorasPersonales, onChangeStateMarcaRechazada } = useSkytracking();
    const { calcAuxMarca } = useUploadFiles();

    useEffect(() => {
        (async () => {
            await getPausasFilter();
        })();
    }, []);

    const formik = useFormik({
        initialValues: initialValues(rowSelected),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            console.log(formValue);

            let fecha_inicio = new Date(formValue.RangoFechas[0]);
            let fecha_final = new Date(formValue.RangoFechas[1]);

            for await (const empleado of formValue.Empleados) {
                
                const response_exception = await insertException_wfm({
                    TipoMarca: formValue.Pausa.id_pausa,
                    Motivo: formValue.Comentario,
                    Empleado: empleado.IdEmpleado,
                    FechaInicio: moment(fecha_inicio).format('YYYY-MM-DD'),
                    FechaFin: moment(fecha_final).format('YYYY-MM-DD'),
                    ValorExcepcion: formValue.Horas,
                    EstadoExcepcion: 0
                })

                while (fecha_inicio <= fecha_final){
    
                    const response_marca = await getMarcaException(empleado.Badge, moment(fecha_inicio).format('YYYY-MM-DD'));
    
                    if (size(response_marca) > 0) {
                        await onAprobarHorasPersonales(response_marca.id, null);
                        await insertDetalleMarcaExcepcion(response_marca.id, formValue.Pausa.id_pausa, formValue.Horas, response_exception.id);
                        await onChangeStateMarcaRechazada(response_marca.id, 1);
    
                        await calcAuxMarca(response_marca.id);
                    }
    
                    fecha_inicio.setDate(fecha_inicio.getDate() + 1)
                }

                fecha_inicio = new Date(formValue.RangoFechas[0])
                
            }

            onCloseSelf()

        }
    });

    return (
        <form className="py-3" onSubmit={formik.handleSubmit}>
            <Toast ref={toast} />
            <ConfirmDialog />
            <h5>Agregar Excepciones Masivas</h5>
            <p>Llena el formulario y aqui puedes ingresar excepciones para multiples dias y para multiples empleados</p>
            <div className="grid">
                <div className="col-12 md:col-8">
                    <div className="grid my-5">
                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <Calendar
                                    className={`w-full ${formik.errors.RangoFechas ? 'p-invalid' : ''}`}
                                    id="RangoFechas"
                                    name="RangoFechas"
                                    selectionMode="range"
                                    value={formik.values.RangoFechas}
                                    onChange={(e) => formik.setFieldValue('RangoFechas', e.value)}
                                />
                                <label htmlFor="RangoFechas">Fecha</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown id="Pausa" name="Pausa" className="w-full" options={listPausas} optionLabel="name" value={formik.values.Pausa} onChange={(e) => formik.setFieldValue('Pausa', e.value)} filter />
                                <label htmlFor="Pausa">Pausa</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputTextarea rows={5} cols={5} id="Comentario" name="Comentario" className="w-full" value={formik.values.Comentario} onChange={formik.handleChange} />
                                <label htmlFor="Comentario">Comentario</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText id="Horas" name="Horas" className="w-full" value={formik.values.Horas} onChange={formik.handleChange} />
                                <label htmlFor="Horas">Horas</label>
                            </span>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <Button type="button" onClick={confirm} size="large" severity="success">
                                Agregar Excepciones
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <DataTable value={formik.values.Empleados}>
                        <Column field="Badge" header="Badge"></Column>
                        <Column field="2" header="Nombre"></Column>
                    </DataTable>
                </div>
            </div>
        </form>
    );
}

function initialValues(rowSelected) {
    return {
        Pausa: '',
        Comentario: '',
        Horas: '',
        Empleados: rowSelected,
        RangoFechas: ''
    };
}

function validationSchema() {
    return {
        Pausa: Yup.object().required(true),
        Comentario: Yup.string().required(true),
        Horas: Yup.string().required(true),
        Empleados: Yup.array().required(true),
        RangoFechas: Yup.array().required(true)
    };
}
