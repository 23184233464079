import { BASE_API } from '../../utilities/constants';

export async function getTipoMarcasApi(token, Tipo = '', MostrarModal = '', MostrarExcepcion = '') {
    try {
        const tipoFilter = `Tipo=${Tipo}`;
        const mostrarModalFilter = `MostrarModal=${MostrarModal}`;
        const mostrarExcepcionFilter = `MostrarExcepcion=${MostrarExcepcion}`;

        const url = `${BASE_API}v1/TipoMarca/?${tipoFilter}&${mostrarModalFilter}&${mostrarExcepcionFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addTipoMarcaApi(data, token) {
    try {
        const url = `${BASE_API}v1/TipoMarca/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateTipoMarcaApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/TipoMarca/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
