import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { filter, size } from 'lodash';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useCommonSia } from '../../hooks';
import { propiedadesSia } from '../../utilities/constants';

export function ComponentePage() {
    const [listSeriales, setListSeriales] = useState([]);

    const { updateContador, getTiposComponentes, listTiposComponentes, getMarcas, listMarcas, getModelos, listModelos, getEstados, listEstados, getUbicaciones, listUbicaciones, addComponente, addDetalleComponente, validSerialComponente } = useCommonSia();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),

        onSubmit: async (formValue) => {
            let contador = formValue.TipoComponente.correlativo + 1;

            for await (const serial of listSeriales) {
                let data_save = {
                    Correlativo: `${formValue.TipoComponente.prefijo}${contador}`,
                    FechaCompra: moment(formValue.FechaCompra).format('YYYY-MM-DD'),
                    Garantia: formValue.Garantia,
                    UrlCoa: 'TBE',
                    Serial: serial.Serial,
                    Modelo: formValue.Modelo,
                    TipoComponente: formValue.TipoComponente.data,
                    EstadoComponente: formValue.Estado,
                    Ubicacion: formValue.Ubicacion
                };

                const dataComponent = await addComponente(data_save);

                for await (const prop of Object.keys(propiedadesSia)) {
                    const idPropiedad = propiedadesSia[prop];

                    if (prop in formValue) {
                        const valorPropiedad = formValue[prop];

                        if (valorPropiedad != '') {
                            await addDetalleComponente({
                                Valor: formValue[prop],
                                Componente: dataComponent.id,
                                PropiedadComponente: idPropiedad
                            });
                        }
                    }
                }

                contador += 1;
            }

            //Aumentar el auto contador
            await updateContador(contador - 1, formValue.TipoComponente.data);

            window.location.reload();
            
        }
    });

    useEffect(() => {
        (async () => {
            await getTiposComponentes();
            await getMarcas();
            await getEstados();
            await getUbicaciones();
        })();
    }, []);

    const onSaveSerial = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const inputValue = e.target.value;
            let listSerialesTemp = listSeriales;

            const validation = filter(listSeriales, ["Serial", inputValue]);

            if (!(await validSerialComponente(inputValue)) && size(validation) == 0) {
                listSerialesTemp.push({
                    Serial: inputValue
                });
            }

            setListSeriales(listSerialesTemp);

            formik.setFieldValue('Serial', '');
        }
    };

    const onDeleteSerial = (rowValue) => {
        const newListSeriales = filter(listSeriales, (o) => {
            return o.Serial != rowValue.Serial;
        });

        setListSeriales(newListSeriales);
    };

    const templateAction = (rowData) => {
        return <Button type="button" icon="pi pi-times" rounded severity="danger" aria-label="Cancel" onClick={() => onDeleteSerial(rowData)} />;
    };

    const listSociedad = [
        { name: 'SkyCom Latinoamerica', value: 'SkyCom Latinoamerica' },
        { name: 'SkyCom El Salvador', value: 'SkyCom El Salvador' }
    ];

    const onPasteExcel = async (e) => {
        const valor_recorrer = e.clipboardData.getData('text');
        const array_serial_temp = valor_recorrer.split('\n');

        let listSerialesTemp = listSeriales;

        for (const serial of array_serial_temp) {

            
            if (serial != '') {

                const validation = filter(listSeriales, ["Serial", serial]);

                if (!(await validSerialComponente(serial)) && size(validation) == 0) {
                    listSerialesTemp.push({
                        Serial: serial
                    });
                }
            }
        }

        setListSeriales(listSerialesTemp);
    };

    return (
        <>
            <div className="grid my-3">
                <div className="col-12">
                    <div className="card">
                        <h5>Agregar Componente</h5>
                        <p>Agregar Componentes para SIA</p>

                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid my-3">
                                <div className="col-12 md:col-6">
                                    <span className="p-float-label">
                                        <InputText className="w-full" name="CodigoComponente" id="CodigoComponente" value={formik.values.CodigoComponente} onChange={formik.handleChange} readOnly />
                                        <label htmlFor="CodigoComponente">Codigo Componente</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.CodigoComponente && 'Este Campo es Requerido'}</label>
                                </div>
                                <div className="col-12 md:col-6">
                                    <span className="p-float-label">
                                        <InputTextarea className="w-full" name="DescripcionCorta" id="DescripcionCorta" value={formik.values.DescripcionCorta} onChange={formik.handleChange} />
                                        <label htmlFor="DescripcionCorta">Descripcion Corta</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.DescripcionCorta && 'Este Campo es Requerido'}</label>
                                </div>
                            </div>
                            <div className="grid my-3">
                                <div className="col-12">
                                    <span className="p-float-label">
                                        <InputTextarea className="w-full" name="DescripcionDetallada" id="DescripcionDetallada" value={formik.values.DescripcionDetallada} onChange={formik.handleChange} />
                                        <label htmlFor="DescripcionDetallada">Descripcion Detallada</label>
                                    </span>
                                </div>
                            </div>
                            <div className="grid my-3">
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <Dropdown
                                            filter
                                            className="w-full"
                                            id="TipoComponente"
                                            name="TipoComponente"
                                            value={formik.values.TipoComponente}
                                            onChange={(e) => {
                                                formik.setFieldValue('TipoComponente', e.value);
                                                formik.setFieldValue('CodigoComponente', e.value.codigoComponente);
                                            }}
                                            options={listTiposComponentes}
                                            optionLabel="name"
                                        />
                                        <label htmlFor="TipoComponente">Tipo de Componente</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.TipoComponente && 'Este Campo es Requerido'}</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <Calendar className="w-full" name="FechaCompra" id="FechaCompra" value={formik.values.FechaCompra} onChange={(e) => formik.setFieldValue('FechaCompra', e.value)} />
                                        <label htmlFor="FechaCompra">Fecha Compra</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.FechaCompra && 'Este Campo es Requerido'}</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <Dropdown
                                            filter
                                            className="w-full"
                                            id="Marca"
                                            name="Marca"
                                            value={formik.values.Marca}
                                            onChange={async (e) => {
                                                formik.setFieldValue('Marca', e.value);
                                                await getModelos(e.value);
                                            }}
                                            options={listMarcas}
                                            optionLabel="name"
                                        />
                                        <label htmlFor="Marca">Marca</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.Marca && 'Este Campo es Requerido'}</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <Dropdown filter className="w-full" id="Modelo" name="Modelo" value={formik.values.Modelo} onChange={(e) => formik.setFieldValue('Modelo', e.value)} options={listModelos} optionLabel="name" />
                                        <label htmlFor="Modelo">Modelo</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.Modelo && 'Este Campo es Requerido'}</label>
                                </div>
                            </div>
                            <div className="grid my-3">
                                <div className="col-12 md:col-6">
                                    <span className="p-float-label">
                                        <InputText
                                            className="w-full"
                                            name="Serial"
                                            id="Serial"
                                            value={formik.values.Serial}
                                            onBlur={(e) => formik.setFieldValue('Serial', '')}
                                            onChange={formik.handleChange}
                                            onKeyDown={onSaveSerial}
                                            onPaste={onPasteExcel}
                                        />
                                        <label htmlFor="Serial">Serial de Componente</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-6">
                                    <DataTable value={listSeriales}>
                                        <Column header="Serial" field="Serial" sortable></Column>
                                        <Column header="" body={templateAction}></Column>
                                    </DataTable>
                                </div>
                            </div>
                            <div className="grid my-3">
                                <div className="col-12 md:col-4">
                                    <span className="p-float-label">
                                        <InputText className="w-full" name="Garantia" id="Garantia" value={formik.values.Garantia} onChange={formik.handleChange} />
                                        <label htmlFor="Garantia">Garantia</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.Garantia && 'Este Campo es Requerido'}</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <span className="p-float-label">
                                        <Dropdown filter className="w-full" id="Estado" name="Estado" value={formik.values.Estado} onChange={(e) => formik.setFieldValue('Estado', e.value)} options={listEstados} optionLabel="name" />
                                        <label htmlFor="Estado">Estado</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.Estado && 'Este Campo es Requerido'}</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <span className="p-float-label">
                                        <Dropdown filter className="w-full" id="Ubicacion" name="Ubicacion" value={formik.values.Ubicacion} onChange={(e) => formik.setFieldValue('Ubicacion', e.value)} options={listUbicaciones} optionLabel="name" />
                                        <label htmlFor="Ubicacion">Ubicacion</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.Ubicacion && 'Este Campo es Requerido'}</label>
                                </div>
                            </div>
                            <div className="grid my-3">
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <InputText id="Costo" name="Costo" className="w-full" value={formik.values.Costo} onChange={formik.handleChange} />
                                        <label htmlFor="Costo">Costo</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.Costo && 'Este Campo es Requerido'}</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <InputText id="NoFactura" name="NoFactura" className="w-full" value={formik.values.NoFactura} onChange={formik.handleChange} />
                                        <label htmlFor="NoFactura">No. Factura</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.NoFactura && 'Este Campo es Requerido'}</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <InputText id="Proveedor" name="Proveedor" className="w-full" value={formik.values.Proveedor} onChange={formik.handleChange} />
                                        <label htmlFor="Proveedor">Proveedor</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.Proveedor && 'Este Campo es Requerido'}</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <Dropdown filter name="Sociedad" id="Sociedad" className="w-full" value={formik.values.Sociedad} onChange={(e) => formik.setFieldValue('Sociedad', e.value)} options={listSociedad} optionLabel="name" />
                                        <label htmlFor="Sociedad">Sociedad</label>
                                    </span>
                                    <label className="text-orange-500">{formik.errors.Sociedad && 'Este Campo es Requerido'}</label>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 text-right">
                                    <Button type="submit" severity="success" size="large">
                                        Guardar Componente
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

function initialValues() {
    return {
        CodigoComponente: '',
        DescripcionCorta: '',
        DescripcionDetallada: '',
        TipoComponente: '',
        FechaCompra: '',
        Marca: '',
        Modelo: '',
        Serial: '',
        Garantia: '',
        Estado: '',
        Ubicacion: '',
        Costo: '',
        NoFactura: '',
        Proveedor: '',
        Sociedad: ''
    };
}

function validationSchema() {
    return {
        CodigoComponente: Yup.string().required(true),
        DescripcionCorta: Yup.string().required(true),
        DescripcionDetallada: Yup.string(),
        TipoComponente: Yup.object().required(true),
        FechaCompra: Yup.string().required(true),
        Marca: Yup.string().required(true),
        Modelo: Yup.string().required(true),
        Serial: Yup.string(),
        Garantia: Yup.string().required(true),
        Estado: Yup.string().required(true),
        Ubicacion: Yup.string().required(true),
        Costo: Yup.string().required(true),
        NoFactura: Yup.string().required(true),
        Proveedor: Yup.string().required(true),
        Sociedad: Yup.string().required(true)
    };
}
