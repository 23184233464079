import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { map, find, size, filter } from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import { useAp, useCommon } from '../../hooks';
import "./css/styleAp.css"

const ApDetailPrint = () => {
    const [horario, setHorario] = useState({});

    const { numAp } = useParams();
    const {getCuenta, listCuentas, getPlazas, listPlazas, getLob, listLob} = useCommon()

    const print = () => {
        window.print();
    };

    const { apHeader, apDetail, dataEmpleado, getDetailAp, loadingAp } = useAp();

    useEffect(() => {
        (async () => {
            await getDetailAp(numAp);
            await getCuenta();
            await getPlazas();
            await getLob("")
        })();
        
    }, []);

    useEffect(() => {

        const horario_str = find(apDetail, { PropiedadAp: 20 });

        if(size(horario_str) > 0){

            setHorario(JSON.parse(horario_str.Valor))
        }

        
    }, [apDetail])

    return (
        <>
            <Button label="Print" icon="pi pi-print" onClick={print} style={{ display: 'block', marginBottom: '20px', marginLeft: '6px' }}></Button>
            <div className="grid">
                <div className="col-12">
                    <div className="card" id="invoice-content">
                        {loadingAp ? (
                            <div className="grid py-5">
                                <div className="col-12 text-center">
                                    <i className="pi pi-spinner pi-spin" style={{ style: '5rem' }}></i>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="grid p-1">
                                    <div className="col-8">
                                        <img src="assets/layout/images/logo-sc.png" alt="skycom-layout" style={{ width: '20rem' }} />
                                    </div>
                                    <div className="col-4 text-right">
                                        <h5>Detalle de AP # {numAp}</h5>
                                        <p style={{ fontSize: '1.5rem' }}>{apHeader?.tipoAp_data.Nombre}</p>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-12">
                                        <h5>
                                            Codigo Empleado: {dataEmpleado?.Badge}
                                        </h5>
                                        <h5>
                                            Nombre: {dataEmpleado?.Nombre}
                                        </h5>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-12">
                                        <h5>Cuenta: {dataEmpleado?.Cuenta}</h5>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-12">
                                        <h5>Linea de Negocio: {dataEmpleado?.Lob}</h5>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid py-1">
                                    <div className="col-12">
                                        <h5>Detalle de AP</h5>
                                    </div>
                                </div>
                                {map(apDetail, (detalle, index) => (
                                    <>
                                        {detalle.propiedadAp_data.Nombre == 'Horario' ? (
                                            <div className="grid text-center" key={index}>
                                                <div className="col-12 text-center">
                                                    <table className='table-horario'>
                                                        <thead>
                                                            <tr>
                                                                <td>Dia</td>
                                                                <td>Hora Inicio</td>
                                                                <td>Hora Final</td>
                                                                <td>Almuerzo</td>
                                                                <td>Programadas Diurnas</td>
                                                                <td>Programadas Nocturnas</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {map(horario, (dia, index_horario) => (
                                                                <tr>
                                                                    <td>{index_horario}</td>
                                                                    <td>{moment(dia.HoraInicio).format("HH:mm:ss")}</td>
                                                                    <td>{moment(dia.HoraFinal).format("HH:mm:ss")}</td>
                                                                    <td>{dia.Almuerzo}</td>
                                                                    {dia.HorasProgramadas > 0 ? (
                                                                        <>
                                                                        {dia.horasNocturnas > dia.HorasProgramadas ? (
                                                                            <>
                                                                            <td>0</td>
                                                                            <td>{dia.horasNocturnas - dia.Almuerzo}</td>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                            <td>{dia.HorasProgramadas - dia.horasNocturnas}</td>
                                                                            <td>{dia.horasNocturnas}</td>
                                                                            </>
                                                                        )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                            {detalle.propiedadAp_data.Nombre == "Observacion" ?(
                                                <div className="grid px-5 text-center" key={index}>
                                                    <div className="col-12">{detalle.propiedadAp_data.Nombre}</div>
                                                    <div className="col-12">{detalle.propiedadAp_data.Nombre.includes('Fecha') ? moment(detalle.Valor).format('DD/MMM/YYYY') : detalle.Valor}</div>
                                                </div>

                                            ) : (
                                                <>
                                                {(detalle.propiedadAp_data.Nombre == "Cuenta Destino" || detalle.propiedadAp_data.Nombre == "Lob" || detalle.propiedadAp_data.Nombre == "Plaza") ? (

                                                    <div className="grid px-5 text-center" key={index}>
                                                        <div className="col-6 md:col-6">{detalle.propiedadAp_data.Nombre}</div>
                                                        <div className="col-6 md:col-6">
                                                            {size(filter(listCuentas, ["value", parseInt(detalle.Valor)])) > 0 && detalle.propiedadAp_data.Nombre == "Cuenta Destino" && filter(listCuentas, ["value", parseInt(detalle.Valor)])[0].name}
                                                            {size(filter(listPlazas, ["value", parseInt(detalle.Valor)])) > 0 && detalle.propiedadAp_data.Nombre == "Plaza" && filter(listPlazas, ["value", parseInt(detalle.Valor)])[0].name}
                                                            {size(filter(listLob, ["value", parseInt(detalle.Valor)])) > 0 && detalle.propiedadAp_data.Nombre == "Lob" && filter(listLob, ["value", parseInt(detalle.Valor)])[0].name}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                    {(detalle.propiedadAp_data.Nombre != "Tipo de Despido") && (

                                                        <div className="grid px-5 text-center" key={index}>
                                                            <div className="col-6 md:col-6">{detalle.propiedadAp_data.Nombre}</div>
                                                            <div className="col-6 md:col-6">{detalle.propiedadAp_data.Nombre.includes('Fecha') ? moment(detalle.Valor).format('DD/MMM/YYYY') : detalle.Valor}</div>
                                                        </div>

                                                    )}
                                                    </>
                                                )}
                                                </>
                                            )}
                                            </>
                                        )}
                                    </>
                                ))}
                                <hr />
                                <div className="grid py-3">
                                    <div className="col-12">
                                        <h5>Firmas de Aprobacion</h5>
                                    </div>
                                </div>
                                <div className="grid text-center py-5">
                                    <div className="col-3 md:col-3">
                                        <hr />
                                        <br />
                                        {dataEmpleado?.Badge} - {dataEmpleado?.Nombre}
                                    </div>
                                    {dataEmpleado?.Supervisor && (
                                        <div className="col-3 md:col-3">
                                            <hr />
                                            <br />
                                            {dataEmpleado?.Supervisor}
                                        </div>
                                    )}
                                    {dataEmpleado?.Account && (
                                        <div className="col-3 md:col-3">
                                            <hr />
                                            <br />
                                            {dataEmpleado?.Account}
                                        </div>
                                    )}
                                    {dataEmpleado?.Director != 'null - undefined' && (
                                        <div className="col-3 md:col-3">
                                            <hr />
                                            <br />
                                            {dataEmpleado?.Director}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ApDetailPrint;
