import React, { useState } from 'react';
import { size, map, filter } from 'lodash';
import { useAuth } from './useAuth';
import { getEmpleadosApi } from '../api/skyroster/empleado';
import { getDetallesEmpleadoApi, getEmpleadoByFilterApi } from '../api/skyroster/detalleempleado';
import { getCuentasApi } from '../api/skyroster/cuenta';
import { getLobsApi } from '../api/skyroster/lob';
import { getSubLobsApi } from '../api/skyroster/sublob';
import { weekYearDate } from '../utilities/constants';

export function useProfile() {
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [error, setError] = useState(false);
    const [empleadoData, setEmpleadoData] = useState(null);

    const { auth } = useAuth();

    const onGetProfileData = async () => {
        try {
            setLoadingProfile(true);

            const response_empleado = await getEmpleadosApi(auth.token, weekYearDate(), auth.me.username);
            let profileData = {};

            if (size(response_empleado) > 0) {
                const response_detalleEmpleado = await getDetallesEmpleadoApi(auth.token, response_empleado[0].id);

                if (size(response_detalleEmpleado) > 0) {
                    profileData['Badge'] = response_empleado[0].Badge;
                    profileData['Nombre'] = filter(response_detalleEmpleado, ['Propiedad', 2])[0]?.Valor;

                    const idCuenta = filter(response_detalleEmpleado, ['Propiedad', 3])[0]?.Valor;
                    const response_cuenta = await getCuentasApi(auth.token, 1, idCuenta);
                    profileData['Cuenta'] = size(response_cuenta) > 0 ? response_cuenta[0].Nombre : 'Cuenta No Configurada';

                    const idLob = filter(response_detalleEmpleado, ['Propiedad', 5])[0]?.Valor;
                    const response_lob = await getLobsApi(auth.token, true, '', `${idLob}/`);
                    profileData['Lob'] = size(response_lob) > 0 ? response_lob.Lob : 'Lob No Configurado';

                    const idSubLob = filter(response_detalleEmpleado, ['Propiedad', 17])[0]?.Valor;
                    if (idSubLob != undefined) {
                        const response_subLob = await getSubLobsApi(auth.token, true, '', `${idSubLob}/`);
                        profileData['SubLob'] = size(response_subLob) > 0 ? response_subLob.SubLob : 'Lob 2 No Configurado';
                    } else {
                        profileData['SubLob'] = 'Lob 2 No Configurado';
                    }

                    const idSupervisor = filter(response_detalleEmpleado, ['Propiedad', 10])[0]?.Valor;
                    const response_supervisor = await getEmpleadoByFilterApi(auth.token, '', 2, weekYearDate(), idSupervisor, '');
                    profileData['Supervisor'] = size(response_supervisor) > 0 ? `${idSupervisor} - ${response_supervisor[0]?.Valor}` : 'Supervisor No Configurado';

                    const idAccount = filter(response_detalleEmpleado, ['Propiedad', 11])[0]?.Valor;
                    const response_account = await getEmpleadoByFilterApi(auth.token, '', 2, weekYearDate(), idAccount, '');
                    profileData['account'] = size(response_account) > 0 ? `${idAccount} - ${response_account[0]?.Valor}` : 'Account Manager No Configurado';

                    const idDirector = filter(response_detalleEmpleado, ['Propiedad', 12])[0]?.Valor;
                    if (size(idDirector) > 0) {
                        const response_director = await getEmpleadoByFilterApi(auth.token, '', 2, weekYearDate(), idDirector, '');
                        profileData['director'] = size(response_director) > 0 ? `${idDirector} - ${response_director[0]?.Valor}` : 'Director No Configurado';
                    } else {
                        profileData['director'] = 'Director No Configurado';
                    }
                }
            }

            setEmpleadoData(profileData);

            setLoadingProfile(false);
        } catch (error) {
            console.log(error);
            setLoadingProfile(false);
            setError(error);
        }
    };

    const getNameProfile = async () => {
        try {
            setLoadingProfile(true)
            const response_nombre = await getEmpleadoByFilterApi(auth.token, "", 2, weekYearDate(), auth.me.username)
            setLoadingProfile(false)

            return response_nombre
        } catch (error) {
            setLoadingProfile(false)
            setError(error)
        }
    }

    return {
        loadingProfile,
        error,
        empleadoData,
        onGetProfileData,
        getNameProfile
    };
}
