import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { size, filter, includes } from 'lodash';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useSkytracking, useCommon, useMasterRoster } from '../../hooks';

export function FacturacionPage() {
    const [pausas, setPausas] = useState(null);
    const [listHorasFactura, setHorasFactura] = useState(null);

    const { getStaffTime, horasPlanilla, loadingSkytracking } = useSkytracking();
    const { getPausasFilter_response } = useCommon();
    const { getSkyEmpleadosViewPerso, empleados } = useMasterRoster();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (FormValue) => {
            await getStaffTime(moment(FormValue.Fechas[0]).format('YYYY-MM-DD'), moment(FormValue.Fechas[1]).format('YYYY-MM-DD'));
        }
    });

    useEffect(() => {
        if (horasPlanilla != null) {
            (async () => {
                let result_data = horasPlanilla;
                let contador = 0;

                for await (const marca of horasPlanilla) {
                    const datos_empleados = filter(empleados, ['Badge.Badge', marca.Badge.Badge]);

                    result_data[contador]['Fecha'] = marca.Marca.Fecha;

                    if (size(datos_empleados) > 0) {
                        result_data[contador]['Badge'] = datos_empleados[0].Badge.Badge;
                        result_data[contador]['Nombre'] = datos_empleados[0][2];
                        result_data[contador]['Cuenta'] = datos_empleados[0].Cuenta.Nombre;
                    } else {
                        result_data[contador]['Badge'] = 'Sin Data';
                        result_data[contador]['Nombre'] = 'Sin Data';
                        result_data[contador]['Cuenta'] = 'Sin Data';
                    }

                    if (1 in marca) {
                        result_data[contador]['HorasProgramadas_day'] = marca[1];
                    } else {
                        result_data[contador]['HorasProgramadas_day'] = 0;
                    }

                    if (62 in marca) {
                        result_data[contador]['HorasProgramadas_night'] = marca[62];
                    } else {
                        result_data[contador]['HorasProgramadas_night'] = 0;
                    }

                    result_data[contador]['HorasProgramadas'] = result_data[contador]['HorasProgramadas_day'] + result_data[contador]['HorasProgramadas_night'];

                    result_data[contador]['HorasDevengadasOrigen'] = 0;
                    result_data[contador]['HorasDevengadasOrigen_day'] = 0;
                    result_data[contador]['HorasDevengadasOrigen_night'] = 0;
                    result_data[contador]['HorasExtra'] = 0;
                    result_data[contador]['HorasExtra_day'] = 0;
                    result_data[contador]['HorasExtra_night'] = 0;
                    result_data[contador]['HorasAdicionales'] = 0;
                    result_data[contador]['HorasAdicionales_day'] = 0;
                    result_data[contador]['HorasAdicionales_night'] = 0;
                    result_data[contador]['HorasDevengadasPorExcepcion'] = 0;
                    result_data[contador]['HorasDevengadasPorExcepcion_day'] = 0;
                    result_data[contador]['HorasDevengadasPorExcepcion_night'] = 0;
                    result_data[contador]['HorasNoTrabajadasJustificadas'] = 0;
                    result_data[contador]['HorasNoTrabajadasJustificadas_day'] = 0;
                    result_data[contador]['HorasNoTrabajadasJustificadas_night'] = 0;
                    result_data[contador]['HorasPagadasExcepcionLey'] = 0;
                    result_data[contador]['HorasPagadasExcepcionLey_day'] = 0;
                    result_data[contador]['HorasPagadasExcepcionLey_night'] = 0;
                    result_data[contador]['HorasNoTrabajadasPagadasExcepcion'] = 0;
                    result_data[contador]['HorasNoTrabajadasPagadasExcepcion_day'] = 0;
                    result_data[contador]['HorasNoTrabajadasPagadasExcepcion_night'] = 0;

                    for await (const detalle_marca of Object.keys(marca)) {
                        const pausa_tipo = filter(pausas, ['id', parseInt(detalle_marca)]);

                        if (size(pausa_tipo) > 0) {
                            switch (pausa_tipo[0].Tipo) {
                                case 2:
                                    result_data[contador]['HorasDevengadasOrigen'] += marca[detalle_marca];
                                    if (pausa_tipo[0].NombreTipoMarca.includes('Night')) {
                                        result_data[contador]['HorasDevengadasOrigen_night'] += marca[detalle_marca];
                                    } else {
                                        result_data[contador]['HorasDevengadasOrigen_day'] += marca[detalle_marca];
                                    }
                                    break;

                                case 3:
                                    result_data[contador]['HorasExtra'] += marca[detalle_marca];
                                    if (pausa_tipo[0].NombreTipoMarca.includes('Night')) {
                                        result_data[contador]['HorasExtra_night'] += marca[detalle_marca];
                                    } else {
                                        result_data[contador]['HorasExtra_day'] += marca[detalle_marca];
                                    }
                                    break;
                                case 4:
                                    result_data[contador]['HorasAdicionales'] += marca[detalle_marca];
                                    if (pausa_tipo[0].NombreTipoMarca.includes('Night')) {
                                        result_data[contador]['HorasAdicionales_night'] += marca[detalle_marca];
                                    } else {
                                        result_data[contador]['HorasAdicionales_day'] += marca[detalle_marca];
                                    }

                                    break;
                                case 5:
                                    result_data[contador]['HorasDevengadasPorExcepcion'] += marca[detalle_marca];
                                    if (pausa_tipo[0].NombreTipoMarca.includes('Night')) {
                                        result_data[contador]['HorasDevengadasPorExcepcion_night'] += marca[detalle_marca];
                                    } else {
                                        result_data[contador]['HorasDevengadasPorExcepcion_day'] += marca[detalle_marca];
                                    }

                                    break;
                                case 6:
                                    result_data[contador]['HorasNoTrabajadasJustificadas'] += marca[detalle_marca];
                                    if (pausa_tipo[0].NombreTipoMarca.includes('Night')) {
                                        result_data[contador]['HorasNoTrabajadasJustificadas_night'] += marca[detalle_marca];
                                    } else {
                                        result_data[contador]['HorasNoTrabajadasJustificadas_day'] += marca[detalle_marca];
                                    }

                                    break;
                                case 8:
                                    result_data[contador]['HorasPagadasExcepcionLey'] += marca[detalle_marca];
                                    if (pausa_tipo[0].NombreTipoMarca.includes('Night')) {
                                        result_data[contador]['HorasPagadasExcepcionLey_night'] += marca[detalle_marca];
                                    } else {
                                        result_data[contador]['HorasPagadasExcepcionLey_day'] += marca[detalle_marca];
                                    }

                                    break;
                                case 9:
                                    result_data[contador]['HorasNoTrabajadasPagadasExcepcion'] += marca[detalle_marca];
                                    if (pausa_tipo[0].NombreTipoMarca.includes('Night')) {
                                        result_data[contador]['HorasNoTrabajadasPagadasExcepcion_night'] += marca[detalle_marca];
                                    } else {
                                        result_data[contador]['HorasNoTrabajadasPagadasExcepcion_day'] += marca[detalle_marca];
                                    }

                                    break;

                                default:
                                    break;
                            }
                        }
                    }

                    result_data[contador]['HorasDevengadas'] = result_data[contador]['HorasDevengadasOrigen'] + result_data[contador]['HorasDevengadasPorExcepcion'] + result_data[contador]['HorasNoTrabajadasPagadasExcepcion'];
                    result_data[contador]['HorasDevengadas'] = result_data[contador]['HorasDevengadas'] > result_data[contador]['HorasProgramadas'] ? result_data[contador]['HorasProgramadas'] : result_data[contador]['HorasDevengadas'];
                    let lateness = 57 in marca ? marca[57] : 0;
                    let lateness_night = 64 in marca ? marca[64] : 0;
                    let NoCallNoShow = 52 in marca ? marca[52] : 0;
                    let unpaidExcessLogin = 30 in marca ? marca[30] : 0;
                    let unpaidExcessLogin_night = 65 in marca ? marca[65] : 0;
                    result_data[contador]['Ausencia'] = result_data[contador]['HorasNoTrabajadasJustificadas'] + lateness + lateness_night + NoCallNoShow;
                    result_data[contador]['Unpaid'] = unpaidExcessLogin + unpaidExcessLogin_night;
                    result_data[contador]['Test'] = result_data[contador]['HorasDevengadas'] + result_data[contador]['Ausencia'] + result_data[contador]['HorasPagadasExcepcionLey'] - result_data[contador]['HorasProgramadas'];

                    contador += 1;
                }

                setHorasFactura(result_data);
            })();
        }
    }, [horasPlanilla]);

    useEffect(() => {
        (async () => {
            const response_pausas = await getPausasFilter_response('', '', '');
            setPausas(response_pausas);
            await getSkyEmpleadosViewPerso();
        })();
    }, []);

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(listHorasFactura);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'HorasFacturacion');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
        </div>
    );

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte de Facturacion</h5>
                        <p>Selecciona el rango de fechas de las horas que necesitas buscar</p>

                        <form className="my-4" onSubmit={formik.handleSubmit}>
                            <div className="grid">
                                <div className="col-12">
                                    <span className="p-float-label">
                                        <Calendar className="w-full" value={formik.values.Fechas} onChange={(e) => formik.setFieldValue('Fechas', e.value)} id="Fechas" name="Fechas" selectionMode="range" readOnlyInput />
                                        <label htmlFor="Fechas">Rango de Fechas</label>
                                    </span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12">
                                    <Button severity="primary" type="submit">
                                        Consultar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {loadingSkytracking ? (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h5>Tabla de resultado</h5>

                            <DataTable
                                value={listHorasFactura}
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25, 50, 500, 1000, 5000, 10000]}
                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                header={header}
                            >
                                <Column filter sortable field="Badge" header="Badge"></Column>
                                <Column filter sortable field="Nombre" header="Nombre Empleado"></Column>
                                <Column filter sortable field="Marca.Fecha" header="Fecha"></Column>
                                <Column filter sortable field="Cuenta" header="Cuenta"></Column>
                                <Column filter sortable field="HorasProgramadas" header="HorasProgramadas"></Column>
                                <Column filter sortable field="HorasProgramadas_day" header="HorasProgramadas_day"></Column>
                                <Column filter sortable field="HorasProgramadas_night" header="HorasProgramadas_night"></Column>
                                <Column filter sortable field="HorasDevengadas" header="HorasDevengadas"></Column>
                                <Column filter sortable field="HorasDevengadas_day" header="HorasDevengadas_day"></Column>
                                <Column filter sortable field="HorasDevengadas_night" header="HorasDevengadas_night"></Column>
                                <Column filter sortable field="HorasExtra" header="HorasExtra"></Column>
                                <Column filter sortable field="HorasExtra_day" header="HorasExtra_day"></Column>
                                <Column filter sortable field="HorasExtra_night" header="HorasExtra_night"></Column>
                                <Column filter sortable field="HorasAdicionales" header="HorasAdicionales"></Column>
                                <Column filter sortable field="HorasAdicionales_day" header="HorasAdicionales_day"></Column>
                                <Column filter sortable field="HorasAdicionales_night" header="HorasAdicionales_night"></Column>
                                <Column filter sortable field="HorasDevengadasPorExcepcion" header="HorasDevengadasPorExcepcion"></Column>
                                <Column filter sortable field="HorasDevengadasPorExcepcion_day" header="HorasDevengadasPorExcepcion_day"></Column>
                                <Column filter sortable field="HorasDevengadasPorExcepcion_night" header="HorasDevengadasPorExcepcion_night"></Column>
                                <Column filter sortable field="HorasNoTrabajadasJustificadas" header="HorasNoTrabajadasJustificadas"></Column>
                                <Column filter sortable field="HorasNoTrabajadasJustificadas_day" header="HorasNoTrabajadasJustificadas_day"></Column>
                                <Column filter sortable field="HorasNoTrabajadasJustificadas_night" header="HorasNoTrabajadasJustificadas_night"></Column>
                                <Column filter sortable field="HorasPagadasExcepcionLey" header="HorasPagadasExcepcionLey"></Column>
                                <Column filter sortable field="HorasPagadasExcepcionLey_day" header="HorasPagadasExcepcionLey_day"></Column>
                                <Column filter sortable field="HorasPagadasExcepcionLey_night" header="HorasPagadasExcepcionLey_night"></Column>
                                <Column filter sortable field="HorasNoTrabajadasPagadasExcepcion" header="HorasNoTrabajadasPagadasExcepcion"></Column>
                                <Column filter sortable field="HorasNoTrabajadasPagadasExcepcion_day" header="HorasNoTrabajadasPagadasExcepcion_day"></Column>
                                <Column filter sortable field="HorasNoTrabajadasPagadasExcepcion_night" header="HorasNoTrabajadasPagadasExcepcion_night"></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function initialValues() {
    return {
        Fechas: ''
    };
}

function validationSchema() {
    return {
        Fechas: Yup.array().required(true)
    };
}
