import React, { useState } from 'react';
import moment from 'moment';
import { size, map, find } from 'lodash';
import { ProgressBar } from 'primereact/progressbar';
import { FilterHorasProgramadas } from '../../components/Skypanel';
import { weekYearDate } from '../../utilities/constants';
import { useRosterUpdate, useUploadFiles } from '../../hooks';

export function CalcularProgramadasPage() {
    const [progressBar, setProgressBar] = useState(0);
    const [listErrores, setListErrores] = useState(null);

    const { getHorariosFilters } = useRosterUpdate();
    const { insertMarca, insertDetalleMarca, calcAuxMarca } = useUploadFiles();

    const onResponseForm = async (formData) => {
        const total_badges = size(formData.Badges) * (formData.Semanas + 1);
        let list_errores_function = [];
        let porcentaje = 0;
        let contador = 0;
        //const semana_inicial = weekYearDate();
        const semana_inicial = moment().week();
        const semana_final = semana_inicial + formData.Semanas;
        const dias = ["D","L", "M", "X", "J", "V", "S"]

        for await (const badge of formData.Badges) {
            const response_horarios = await getHorariosFilters(badge, semana_inicial);

            if (size(response_horarios) > 0) {
                for (let i = semana_inicial-1; i <= semana_final; i++) {
                    const dias_semana = moment().week(i);

                    for (let k = 0; k < 7; k++) {
                        let fecha_insert = dias_semana.day(k).format('YYYY-MM-DD');
                        const horas_insertar_prog = find(response_horarios, {Dia: dias[k]})

                        const response_marca = await insertMarca(badge, fecha_insert);

                        if (size(response_marca) > 0 && size(horas_insertar_prog) > 0) {
                            const response_marca_detalle = await insertDetalleMarca({
                                1: parseFloat(horas_insertar_prog.TotalHorasDia),
                                62: parseFloat(horas_insertar_prog.HorasNocturnas),
                            }, response_marca[0].id);

                            const response_calc_aux = await calcAuxMarca(response_marca[0].id);
                        }
                    }

                    contador += 1;
                }
            } else {
                list_errores_function.push({
                    badgeMsg: badge,
                    errorMsg: 'Empleado no tiene horario programado para esta semana'
                });

                contador += formData.Semanas + 1;
            }

            porcentaje = (contador / total_badges) * 100;
            setProgressBar(porcentaje.toFixed(2));
        }

        setListErrores(list_errores_function);
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Calculo de Horas Programadas</h5>
                        <p>Calcula las horas programadas de una cuenta, Lob o todas las cuentas con solo seleccionar un rango de fechas. Este modulo calculara tambien los aux de cada marca por dia</p>

                        <div className="grid my-3">
                            <div className="col-12">
                                <FilterHorasProgramadas response={onResponseForm} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 md:col-8">
                    <div className="card">
                        <h5>Progreso %</h5>
                        <p>Aqui puedes ver el proceso de la carga de horas programadas para los empleados ingresados</p>
                        <ProgressBar value={progressBar} />
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div className="card">
                        <h5>Errores</h5>
                        <p>Seccion donde te mostrara los errores y las posibles soluciones a dichos errores</p>
                        {map(listErrores, (errMsg, index) => (
                            <p key={index}>
                                {errMsg.badgeMsg} - {errMsg.errorMsg}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
