import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from "moment";
import { filter } from 'lodash';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useCommon, usePrestamoSia, useCommonSia } from '../../hooks';

export function PrestamoPage() {
    const [listEmpleadosComponente, setListEmpleadoComponente] = useState(null);
    const [switchEmpleadoCuenta, setSwitchEmpleadoCuenta] = useState(true);
    const [listComponentesPrestamos, setListComponentesPrestamos] = useState([]);

    const { getSkyViewEmpleados, getCuenta, listCuentas } = useCommon();
    const { getComponentes, listComponentes, addPrestamo } = usePrestamoSia();
    const { getUbicaciones, listUbicaciones } = useCommonSia();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (dataValue) => {
            console.log(dataValue)

            let badge_str = "";
            let cuenta_str = "";

            if (switchEmpleadoCuenta){
                badge_str = dataValue.Empleado;
            }else{
                cuenta_str = dataValue.Cuenta;
            }

            if (parseFloat(dataValue.Vga) > 0){
                await addPrestamo({
                    Badge: badge_str,
                    Cuenta: cuenta_str,
                    Comentario: dataValue.Vga,
                    FechaSalida: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    FechaRetorno: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    Pagare: dataValue.ValorPagare,
                    Componente: 33,
                    TipoPrestamo: 1
                });
            }
            if (parseFloat(dataValue.Ac) > 0){
                await addPrestamo({
                    Badge: badge_str,
                    Cuenta: cuenta_str,
                    Comentario: dataValue.Ac,
                    FechaSalida: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    FechaRetorno: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    Pagare: dataValue.ValorPagare,
                    Componente: 34,
                    TipoPrestamo: 1
                });
            }
            if (parseFloat(dataValue.AdaptadorVga) > 0){
                await addPrestamo({
                    Badge: badge_str,
                    Cuenta: cuenta_str,
                    Comentario: dataValue.AdaptadorVga,
                    FechaSalida: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    FechaRetorno: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    Pagare: dataValue.ValorPagare,
                    Componente: 35,
                    TipoPrestamo: 1
                });
            }
            if (parseFloat(dataValue.Red) > 0){
                await addPrestamo({
                    Badge: badge_str,
                    Cuenta: cuenta_str,
                    Comentario: dataValue.Red,
                    FechaSalida: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    FechaRetorno: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    Pagare: dataValue.ValorPagare,
                    Componente: 36,
                    TipoPrestamo: 1
                });
            }
            if (parseFloat(dataValue.Bolsa) > 0){
                await addPrestamo({
                    Badge: badge_str,
                    Cuenta: cuenta_str,
                    Comentario: dataValue.Bolsa,
                    FechaSalida: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    FechaRetorno: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    Pagare: dataValue.ValorPagare,
                    Componente: 37,
                    TipoPrestamo: 1
                });
            }

            for await (const component of listComponentesPrestamos){
                await addPrestamo({
                    Badge: badge_str,
                    Cuenta: cuenta_str,
                    Comentario: "N/A",
                    FechaSalida: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    FechaRetorno: moment(dataValue.FechaSalida).format("YYYY-MM-DD"),
                    Pagare: dataValue.ValorPagare,
                    Componente: component.ID_Componente,
                    TipoPrestamo: 1
                }, component.ID_Ubicacion);


            }

            window.location.reload();
        }
    });

    useEffect(() => {
        (async () => {
            await getCuenta();
            await getComponentes();
            await getUbicaciones();

            const response_empleados = await getSkyViewEmpleados();
            let format_empleados = [];

            for (const empleado of response_empleados) {
                format_empleados.push({
                    name: `${empleado.Badge} - ${empleado.NombreEmpleado} - ${empleado.Cuenta}`,
                    value: empleado.id
                });
            }

            setListEmpleadoComponente(format_empleados);
        })();
    }, []);

    const onSaveDetalle = () => {
        const componente_temp = filter(listComponentes, ['value', formik.values.Componente]);
        const ubicacion_temp = filter(listUbicaciones, ['value', formik.values.Ubicacion]);
        const add_temp = {
            ID_Componente: componente_temp[0]?.value,
            ID_Ubicacion: ubicacion_temp[0]?.value,
            Componente: componente_temp[0]?.name,
            Ubicacion: ubicacion_temp[0]?.name
        };

        setListComponentesPrestamos((e) => [...e, add_temp]);

        formik.setFieldValue('Componente', '');
        
        //formik.setFieldValue('Ubicacion', '');
    };

    const eliminarLista = (row_data) => {
        const new_lisComponentes = filter(listComponentesPrestamos, (x) => {
            return x.ID_Componente != row_data.ID_Componente;
        });
        setListComponentesPrestamos(new_lisComponentes);
        console.log(row_data);
    };

    const accionesEliminarBodyTemplate = (row) => {
        return (
            <Button severity="error" type="button" onClick={(e) => eliminarLista(row)}>
                X
            </Button>
        );
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h5>Prestamo</h5>
                            <p>Prestamo para Empleado o Cuenta en SIA</p>

                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <label>Cuenta</label>
                                    <InputSwitch checked={switchEmpleadoCuenta} onChange={(e) => setSwitchEmpleadoCuenta(e.value)} />
                                    <label>Empleado</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <Dropdown
                                            filter
                                            className="w-full"
                                            id="Empleado"
                                            name="Empleado"
                                            value={formik.values.Empleado}
                                            onChange={(e) => formik.setFieldValue('Empleado', e.value)}
                                            options={listEmpleadosComponente}
                                            optionLabel="name"
                                            disabled={!switchEmpleadoCuenta}
                                        />
                                        <label htmlFor="Empleado">Empleado</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-3">
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="w-full"
                                            filter
                                            id="Cuenta"
                                            name="Cuenta"
                                            value={formik.values.Cuenta}
                                            onChange={(e) => formik.setFieldValue('Cuenta', e.value)}
                                            options={listCuentas}
                                            optionLabel="name"
                                            disabled={switchEmpleadoCuenta}
                                        />
                                        <label htmlFor="Cuenta">Cuenta</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-2">
                                    <span className="p-float-label">
                                        <Calendar className="w-full" name="FechaSalida" id="FechaSalida" value={formik.values.FechaSalida} onChange={(e) => formik.setFieldValue('FechaSalida', e.value)} />
                                        <label htmlFor="FechaSalida">Fecha de Salida</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-2">
                                    <span className="p-float-label">
                                        <InputText className="w-full" id="ValorPagare" name="ValorPagare" value={formik.values.ValorPagare} onChange={formik.handleChange} />
                                        <label htmlFor="ValorPagare">Valor de Pagare</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h5>Detalle Prestamo</h5>
                            <p>Selecciones los componentes para asignarlos a el empleado</p>

                            <div className="grid my-3">
                                <div className="col-12 md:col-6">
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="w-full"
                                            filter
                                            id="Componente"
                                            name="Componente"
                                            value={formik.values.Componente}
                                            onChange={(e) => formik.setFieldValue('Componente', e.value)}
                                            options={listComponentes}
                                            optionLabel="name"
                                        />
                                        <label htmlFor="Componente">Componente</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-6">
                                    <span className="p-float-label">
                                        <Dropdown className="w-full" filter id="Ubicacion" name="Ubicacion" value={formik.values.Ubicacion} onChange={(e) => formik.setFieldValue('Ubicacion', e.value)} options={listUbicaciones} optionLabel="name" />
                                        <label htmlFor="Ubicacion">Ubicacion</label>
                                    </span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 text-right">
                                    <Button severity="primary" type="button" onClick={onSaveDetalle}>
                                        Agregar
                                    </Button>
                                </div>
                            </div>
                            <div className="grid my-3">
                                <div className="col-12">
                                    <h5>Tabla Detalle de Prestamo</h5>
                                    <DataTable value={listComponentesPrestamos}>
                                        <Column header="Serial" field="Componente"></Column>
                                        <Column header="Ubicacion" field="Ubicacion"></Column>
                                        <Column header="Acciones" body={accionesEliminarBodyTemplate}></Column>
                                    </DataTable>
                                </div>
                            </div>
                            <div className="grid my-3">
                                <div className="col-12 md:col-1"></div>
                                <div className="col-12 md:col-2">
                                    <span className="p-float-label">
                                        <InputText className="w-full" id="Vga" name="Vga" value={formik.values.Vga} onChange={formik.handleChange} />
                                        <label htmlFor="Vga">Cable VGA</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-2">
                                    <span className="p-float-label">
                                        <InputText className="w-full" id="Ac" name="Ac" value={formik.values.Ac} onChange={formik.handleChange} />
                                        <label htmlFor="Ac">Cable de Poder</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-2">
                                    <span className="p-float-label">
                                        <InputText className="w-full" id="AdaptadorVga" name="AdaptadorVga" value={formik.values.AdaptadorVga} onChange={formik.handleChange} />
                                        <label htmlFor="AdaptadorVga">Adaptador Displayport a VGA</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-2">
                                    <span className="p-float-label">
                                        <InputText className="w-full" id="Red" name="Red" value={formik.values.Red} onChange={formik.handleChange} />
                                        <label htmlFor="Red">Cable de red (Metros)</label>
                                    </span>
                                </div>
                                <div className="col-12 md:col-2">
                                    <span className="p-float-label">
                                        <InputText className="w-full" id="Bolsa" name="Bolsa" value={formik.values.Bolsa} onChange={formik.handleChange} />
                                        <label htmlFor="Bolsa">Bolsa</label>
                                    </span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 text-right">
                                    <Button severity="primary" type="submit">
                                        Guardar Prestamo
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

function initialValues() {
    return {
        Empleado: '',
        Cuenta: '',
        FechaSalida: '',
        ValorPagare: '',
        Vga: 0,
        Ac: 0,
        AdaptadorVga: 0,
        Red: 0,
        Bolsa: 0,
        Componente: '',
        Ubicacion: ''
    };
}

function validationSchema() {
    return {
        Empleado: Yup.string(),
        Cuenta: Yup.string(),
        FechaSalida: Yup.string(),
        ValorPagare: Yup.string(),
        Vga: Yup.string(),
        Ac: Yup.string(),
        AdaptadorVga: Yup.string(),
        Red: Yup.string(),
        Bolsa: Yup.string(),
        Componente: Yup.string(),
        Ubicacion: Yup.string()
    };
}
