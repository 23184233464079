import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { map } from 'lodash';
import { useSkytracking } from '../../hooks';

export function TableEditarMarcas({ listDetalle }) {
    const toast = useRef();

    const formik = useFormik({
        initialValues: initialValues(listDetalle),
        validationSchema: Yup.object(validationSchema(listDetalle)),
        validateOnChange: false,
        onSubmit: (formValue) => {
            console.log(formValue);
        }
    });

    const { deleteMarcasEditar, updateMarcasEditar } = useSkytracking();

    const onEditarHoras = async (data) => {
        await updateMarcasEditar(formik['values'][data.tipoMarca_data.id], data.id);

        toast.current.show({
            severity: 'warn',
            summary: 'Horas',
            detail: 'Horas Modificadas con exito',
            life: 3000
        });
    };

    const onEliminarHoras = async (data, event) => {
        const fila = event.target.parentNode.parentNode.parentNode.parentNode;

        await deleteMarcasEditar(data.id);

        fila.parentNode.removeChild(fila);

        toast.current.show({
            severity: 'error',
            summary: 'Horas',
            detail: 'Horas Eliminadas con exito',
            life: 3000
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="grid">
                    <div className="col-12">
                        <Button type="button" severity="warning" aria-label="X" onClick={(e) => onEliminarHoras(rowData, e)}>
                            X
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const actionHoras = (rowData) => {
        return (
            <React.Fragment>
                <div className="grid">
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText className="w-full" value={formik['values'][rowData.tipoMarca_data.id]} onChange={(e) => formik.setFieldValue(rowData.tipoMarca_data.id, e.target.value)} onBlur={() => onEditarHoras(rowData)} />
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    return (
        <>
            <Toast ref={toast} />
            <DataTable value={listDetalle}>
                <Column field="tipoMarca_data.NombreTipoMarca" header="Pausa"></Column>
                <Column field="Horas" header="Horas"></Column>
                <Column body={actionHoras} exportable={false} header="Acciones"></Column>
                <Column body={actionBodyTemplate} exportable={false} header="Acciones"></Column>
            </DataTable>
        </>
    );
}

function initialValues(data) {
    let initialValues_response = {};
    map(data, (item) => {
        initialValues_response[item.tipoMarca_data.id] = item.Horas;
    });

    return initialValues_response;
}

function validationSchema(data) {
    let validationSchema_response = {};

    map(data, (item) => {
        validationSchema_response[item.tipoMarca_data.id] = Yup.string();
    });

    return validationSchema_response;
}
