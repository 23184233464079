import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export function TableMarcasRechazadas({ listMarcasRechazadas }) {
    return (
        <DataTable value={listMarcasRechazadas}>
            <Column field="Empleado" header="Empleado"></Column>
            <Column field="Fecha" header="Fecha Marca"></Column>
            <Column field="Motivo" header="Motivo de Rechazo"></Column>
        </DataTable>
    );
}
