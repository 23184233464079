import React, { useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCommon, useUploadFiles } from '../../hooks';

export function FormAddPausa({ response, marca }) {
    const { listPausas, getPausasFilter } = useCommon();
    const {calcAuxMarca} = useUploadFiles()

    useEffect(() => {
        (async () => {
            await getPausasFilter([], '', '');
        })();
    }, []);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            response(formValue);
        }
    });

    const calcpausa = async () => {
        await calcAuxMarca(marca)
        const btnAccion = document.getElementById('btnBuscarDetalle');

        btnAccion.click();
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid my-3">
                <div className="col-12 md:col-6">
                    <span className="p-float-label">
                        <Dropdown filter className="w-full" id="Pausa" name="Pausa" options={listPausas} optionLabel="name" value={formik.values.Pausa} onChange={(e) => formik.setFieldValue('Pausa', e.value)} />
                        <label htmlFor="Pausa">Pausa</label>
                    </span>
                </div>
                <div className="col-12 md:col-6">
                    <span className="p-float-label">
                        <InputText className="w-full" id="Horas" name="Horas" value={formik.values.Horas} onChange={formik.handleChange} />
                        <label htmlFor="Horas">Horas</label>
                    </span>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 text-right">
                    <Button severity="secondary" size="large" type="submit" label="Insertar" className='mx-3' />
                    <Button severity="warn" size="large" type="button" label="Calc Aux" className='mx-3' onClick={calcpausa} />
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        Pausa: '',
        Horas: ''
    };
}

function validationSchema() {
    return {
        Pausa: Yup.object().required(),
        Horas: Yup.string().required()
    };
}
