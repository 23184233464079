import React, { useRef } from 'react';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUser } from '../../../hooks';

export function ResetPasswordTab() {
    const toast = useRef();

    const { changePassword } = useUser();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            if (formValue.pass === formValue.rep_pass) {
                await changePassword(formValue.pass);
                toast.current.show({
                    severity: 'success',
                    summary: 'Contraseña',
                    detail: 'Contraseña Cambiada Correctamente',
                    life: 3000
                });
            } else {
                toast.current.show({
                    severity: 'warn',
                    summary: 'Contraseña',
                    detail: 'Las contraseñas no coinciden',
                    life: 3000
                });
            }
        }
    });

    return (
        <div className="grid my-5">
            <div className="col-12">
                <Toast ref={toast} />

                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <div className="md:col-6 col-12">
                            <span className="p-float-label">
                                <Password className="w-full" id="pass" name="pass" onChange={(e) => formik.setFieldValue('pass', e.target.value)} />
                                <label htmlFor="pass">Contraseña</label>
                            </span>
                        </div>
                        <div className="md:col-6 col-12">
                            <span className="p-float-label">
                                <Password className="w-full" id="rep_pass" name="re_pass" onChange={(e) => formik.setFieldValue('rep_pass', e.target.value)} />
                                <label htmlFor="re_pass">Repetir Contraseña</label>
                            </span>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 text-right">
                            <Button severity="primary" type="submit">
                                Cambiar Contraseña
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

function initialValues() {
    return {
        pass: '',
        rep_pass: ''
    };
}

function validationSchema() {
    return {
        pass: Yup.string().required(true),
        rep_pass: Yup.string().required(true)
    };
}
