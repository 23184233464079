import { useState } from "react"
import {getEstadoAplicanteSamsApi} from "../api/skyapplicantmanagement/estadoaplicantesam"
import {getPautaSamsApi} from "../api/skyapplicantmanagement/pautasam"
import {useAuth} from "./useAuth"

export function useCommonSam () {

    const [error, setError] = useState(false)
    const [estadoAplicante, setEstadoAplicante] = useState(null)
    const [pautas, setPautas] = useState(null)
    const [loadingCommonSam, setLoadingCommonSam] = useState(false)

    const {auth} = useAuth()

    const getEstadoAplicante = async () => {
        try {
            setLoadingCommonSam(true)

            const list_estados = await getEstadoAplicanteSamsApi(auth.token)
            let format_estados = []

            for await (const estado of list_estados){
                format_estados.push({
                    name: estado.Estado,
                    value: estado.id
                })
            }

            setEstadoAplicante(format_estados)

            setLoadingCommonSam(false)
        } catch (error) {
            setLoadingCommonSam(false)
            setError(error)
        }
    }

    const getPautas = async () => {
        try {
            setLoadingCommonSam(true)

            const list_pautas = await getPautaSamsApi(auth.token)
            let format_pautas = []

            for await (const pauta of list_pautas){
                format_pautas.push({
                    name: pauta.Pauta,
                    value: pauta.id
                })
            }

            setPautas(format_pautas)

            setLoadingCommonSam(false)
        } catch (error) {
            setLoadingCommonSam(false)
            setError(error)
        }
    }

    return {

        error,
        estadoAplicante,
        pautas,
        loadingCommonSam,
        getEstadoAplicante,
        getPautas

    }
}