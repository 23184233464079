import { BASE_API } from '../../utilities/constants';

export async function getRosterUpdateDetallesApi(token, Week, Badge = '', Id = '', Propiedad = '') {
    try {
        const weekFilter = `Week=${Week}`;
        const badgeFilter = `Empleado=${Badge}`;
        const idFilter = `id=${Id}`;
        const propiedadFilter = `Propiedad=${Propiedad}`;

        const url = `${BASE_API}v1/RosterUpdateDetalle/?${weekFilter}&${badgeFilter}&${idFilter}&${propiedadFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getRosterUpdateDetalleIdApi(token, idRosterUpdateDetalle) {
    try {
        const idRosterUpdateDetalleFilter = `id=${idRosterUpdateDetalle}`;
        const url = `${BASE_API}v1/RosterUpdateDetalle/?${idRosterUpdateDetalleFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getRosterUpdateDetalleApi(token) {
    try {
        const url = `${BASE_API}v1/RosterUpdateDetalle/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addRosterUpdateDetalleApi(data, token) {
    try {
        const url = `${BASE_API}v1/RosterUpdateDetalle/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateRosterUpdateDetalleApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/RosterUpdateDetalle/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
