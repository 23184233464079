import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FilterReportAp, TableAutomateReportAp } from '../../components/Skyap';
import { useAp, useCommon } from '../../hooks';

export function ReportApAutoPage() {
    const [listaAp, setListaAp] = useState(null);
    const [rolSystem, setRolSystem] = useState(null);

    const { getApAutomateReport, loadingAp } = useAp();
    const {getRolSystem} = useCommon();

    useEffect(() => {
        (async () => {
            setRolSystem(await getRolSystem());
        })()
    }, [])

    const responseForm = async (dataForm) => {

        const FechaInicio = moment(dataForm.RangoFechas[0]).format('YYYY-MM-DD');
        const FechaFinal = moment(dataForm.RangoFechas[1]).format('YYYY-MM-DD');

        const report_ap_state = await getApAutomateReport(FechaInicio, FechaFinal, dataForm.Cuenta, dataForm.TipoAp, dataForm.Empleado, dataForm.ApPendientes, dataForm.NumAp);

        setListaAp(report_ap_state);
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte de AP Optimizado</h5>

                        <FilterReportAp responseForm={responseForm} />
                    </div>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <div className='card'>
                        <h5>Reporte de AP Automatizado</h5>
                        {loadingAp ? (
                            <div className='grid my-4'>
                                <div className='col-12 text-center'>
                                    <i className='pi pi-spin pi-spinner' style={{fontSize:'5rem'}}></i>
                                </div>
                            </div>
                        ) : (

                            <TableAutomateReportAp ListAp={listaAp} Rol={rolSystem} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
