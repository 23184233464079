import React, { useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { loginApi } from '../../api/login/user';
import { useAuth } from '../../hooks';

export const Login = () => {
    const toast = useRef(null);

    const navigate = useNavigate();

    const { login, auth } = useAuth();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formData) => {

            try {
                const response = await loginApi(formData);
                const { access } = response;
                login(access);

                localStorage.setItem("badge", formData.username);

                toast.current.show({
                    severity: 'success',
                    summary: 'Bienvenido',
                    detail: 'Redireccionando segun el perfil',
                    life: 2000
                });
                setTimeout(() => {
                    navigate('/');
                }, 500);
            } catch (error) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Usuario o contraseña incorrecto',
                    life: 2000
                });
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast} />
            <div className="login-body">
                <div className="login-wrapper">
                    <div className="login-panel">
                        <button onClick={() => navigate('/')} type="button" className="logo p-link">
                            <img src="assets/layout/images/logo-sc.png" alt="skycom-logo" />
                        </button>

                        <InputText id="username" placeholder="Badge" value={formik.values.username} onChange={formik.handleChange} />
                        <Password id="password" placeholder="Password" value={formik.values.password} onChange={(e) => formik.setFieldValue('password', e.target.value)} />

                        <Button label="LOGIN" type="submit"></Button>

                        <button className="p-link forget-password">forget password?</button>
                    </div>
                    <div className="login-footer">
                        <h4>SkyVerse</h4>
                        <h6>Copyright 2023 &copy; SkyCom | WebExperience - Danilo Rauda</h6>
                    </div>
                </div>
            </div>
        </form>
    );
};

function initialValues() {
    return {
        username: '',
        password: ''
    };
}

function validationSchema() {
    return {
        username: Yup.string().required(true),
        password: Yup.string().required(true)
    };
}
