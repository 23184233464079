import React, { useState } from 'react';
import moment from 'moment';
import {Button} from "primereact/button"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { FiltrosSia } from '../../components/skyinventoryadministrator';
import { usePrestamoSia } from '../../hooks';

export function ReportePrestamosPage() {
    const [listPrestamos, setListPrestamos] = useState([]);

    const { getPrestamosSiaReport, loadingPrestamo } = usePrestamoSia();

    const list_filtros = {
        FechaSalida: true,
        FechaEntrega: true,
        FechaCompra: true,
        Empleado: true,
        Cuenta: true,
        EstadoPrestamo: true,
        TipoComponente: true,
        EstadoComponente: true,
        Marca: true,
        Ubicacion: true
    };

    const onResponsePrestamos = async (formValue) => {
        setListPrestamos(await getPrestamosSiaReport(formValue));
    };

    const onRedirectRecibir = (rowData) => {

        const asignacion_cuenta_str = rowData.Asignacion_Cuenta != '' ? rowData.Asignacion_Cuenta : 0;
        const asignacion_empleado_str = rowData.Asignacion_Empleado != '' ? rowData.Asignacion_Empleado : 0;

        window.open(`/#/SIA/prestamo/${asignacion_cuenta_str}/${asignacion_empleado_str}`, '_blank', 'noopener,noreferrer');
        
    }

    const templateDevolucion = (rowData) => {
        return (
            <>
            <Button type="button" onClick={(e) => onRedirectRecibir(rowData)}>Recibir Prestamo</Button>
            </>
        )
    }

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte Prestamos de Empleados</h5>
                        <p>Seleciona la fecha de prestamos, aqui puedes encontrar el detalle de prestamos y filtrar los prestamos</p>

                        <FiltrosSia filtros={list_filtros} responseForm={onResponsePrestamos} />
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        {loadingPrestamo ? (
                            <div className="text-center">
                                <i className="pi pi-spin pi-spinner" style={{ fontSize: '5rem' }}></i>
                            </div>
                        ) : (
                            <>
                                <DataTable
                                    value={listPrestamos}
                                    paginator
                                    rows={25}
                                    rowsPerPageOptions={[5, 10, 25, 50, 500, 1000]}
                                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                >
                                    <Column filter sortable header="ID_Prestamo" field="ID_Prestamo"></Column>
                                    <Column filter sortable header="Asignacion_Cuenta" field="Asignacion_Cuenta"></Column>
                                    <Column filter sortable header="Asignacion_Empleado" field="Asignacion_Empleado"></Column>
                                    <Column filter sortable header="Empleado" field="Empleado"></Column>
                                    <Column filter sortable header="FechaSalida" field="FechaSalida"></Column>
                                    <Column filter sortable header="FechaRetorno" field="FechaRetorno"></Column>
                                    <Column filter sortable header="FechaCompra" field="FechaCompra"></Column>
                                    <Column filter sortable header="TipoComponente" field="TipoComponente"></Column>
                                    <Column filter sortable header="Marca" field="Marca"></Column>
                                    <Column filter sortable header="Modelo" field="Modelo"></Column>
                                    <Column filter sortable header="Correlativo" field="Correlativo"></Column>
                                    <Column filter sortable header="Serial" field="Serial"></Column>
                                    <Column filter sortable header="EstadoComponente" field="EstadoComponente"></Column>
                                    <Column filter sortable header="TipoPrestamo" field="TipoPrestamo"></Column>
                                    <Column filter sortable header="Ubicacion" field="Ubicacion"></Column>
                                    <Column filter sortable header="Pagare" field="Pagare"></Column>
                                    <Column filter sortable header="CreacionComponente" field="CreacionComponente"></Column>
                                    <Column filter sortable header="UsuarioPrestamo" field="UsuarioPrestamo"></Column>
                                    <Column filter sortable header="AP Baja" field=""></Column>
                                    <Column filter sortable header="Fecha Creacion AP" field=""></Column>

                                    <Column filter sortable header="CreacionPrestamo" field="CreacionPrestamo"></Column>

                                    <Column header="Acciones" body={templateDevolucion}></Column>

                                    {/* <Column header="ID_Componente" field="ID_Componente"></Column>
                                    <Column header="ID_Marca" field="ID_Marca"></Column>
                                    <Column header="ID_Modelo" field="ID_Modelo"></Column>
                                    <Column header="ID_TipoComponente" field="ID_TipoComponente"></Column>
                                    <Column header="ID_Ubicacion" field="ID_Ubicacion"></Column>
                                    <Column header="ID_EstadoComponente" field="ID_EstadoComponente"></Column>
                                    <Column header="ID_UsuarioPrestamo" field="ID_UsuarioPrestamo"></Column>
                                    <Column header="ID_TipoPrestamo" field="ID_TipoPrestamo"></Column> */}
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
