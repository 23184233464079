import { BASE_API } from '../../utilities/constants';

export async function getAsignacionCuentasApi(token, usuario = '', cuenta = '') {
    try {
        const usuarioFilter = `Usuario=${usuario}`;
        const cuentaFilter = `Cuenta=${cuenta}`;

        const url = `${BASE_API}v1/AsignacionCuenta/?${usuarioFilter}&${cuentaFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addAsignacionCuentaApi(data, token) {
    try {
        const url = `${BASE_API}v1/AsignacionCuenta/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateAsignacionCuentaApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/AsignacionCuenta/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
