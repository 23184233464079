import React, { useEffect, useState } from 'react';
import { filter, size } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useCommon, useMasterRoster } from '../../hooks';
import { useAuth } from '../../hooks/useAuth';

export function TableMasterRosterReport({ ListRoster }) {

    const [listEmpleados, setListEmpleados] = useState(null)
    const [visibleModal, setVisibleModal] = useState(false)

    const { getCuenta, listCuentas, getLob, listLob, getPlazas, listPlazas, getSkyViewEmpleados } = useCommon();
    const {getHorarioEmpleado, horarioEmpleado} = useMasterRoster()
    const {auth} = useAuth()

    useEffect(() => {
        (async () => {
            await getCuenta();
            await getLob();
            await getPlazas();

            const response_view = await getSkyViewEmpleados()
            setListEmpleados(response_view)
        })();
    }, []);

    const templateCuenta = (rowData) => {
        const nombre_cuenta = filter(listCuentas, { value: parseInt(rowData.Cuenta) });
        if (size(nombre_cuenta) > 0) {
            return nombre_cuenta[0].name;
        } else {
            return <p className="text-orange-500">{rowData.Cuenta}</p>;
        }
    };

    const templateLob = (rowData) => {
        const nombre_lob = filter(listLob, { value: parseInt(rowData.Lob) });
        if (size(nombre_lob) > 0) {
            return nombre_lob[0].name;
        } else {
            return <p className="text-orange-500">{rowData.Lob}</p>;
        }
    };

    const templatePlaza = (rowData) => {
        const nombre_plaza = filter(listPlazas, { value: parseInt(rowData.Plaza) });
        if (size(nombre_plaza) > 0) {
            return nombre_plaza[0].name;
        } else {
            return <p className="text-orange-500">{rowData.Plaza}</p>;
        }
    };

    const templateSupervisor = (rowData) => {
        const supervisor_name = filter(listEmpleados, { Badge: rowData.Supervisor });
        if (size(supervisor_name) > 0) {
            return `${supervisor_name[0].Badge} - ${supervisor_name[0].NombreEmpleado}`;
        } else {
            //return <p className="text-orange-500">{rowData.Supervisor}</p>;
            return (
                <>
                <i className='pi pi-spin pi-spinner'></i>
                </>
            )
        }
    };

    const templateAccount = (rowData) => {
        const account_name = filter(listEmpleados, { Badge: rowData.Account_Manager });
        if (size(account_name) > 0) {
            return `${account_name[0].Badge} - ${account_name[0].NombreEmpleado}`;
        } else {
            //return <p className="text-orange-500">{rowData.Account_Manager}</p>;
            return (
                <>
                <i className='pi pi-spin pi-spinner'></i>
                </>
            )
        }
    };

    const templateDirector = (rowData) => {
        const director_name = filter(listEmpleados, { Badge: rowData.Director });
        if (size(director_name) > 0) {
            return `${director_name[0].Badge} - ${director_name[0].NombreEmpleado}`;
        } else {
            //return <p className="text-orange-500">{rowData.Director}</p>;
            return (
                <>
                <i className='pi pi-spin pi-spinner'></i>
                </>
            )
        }
    };

    const onFichaEmpleado = (rowData) => {
        /* navigate(`/Skyroster/RosterUpdate/${rowData.id_empleado}`) */

        window.open(`/#/Skyroster/FichaEmpleado/${rowData.id}`);
    };

    const templateAccion = (rowData) => {

        return (
            <React.Fragment>
                <div className="grid my-3">
                    <div className="col-12">
                        <Button type="button" onClick={() => window.open(`/#/Skyroster/RosterUpdate/${rowData.id}`)}>
                            Roster Update
                        </Button>
                    </div>
                </div>
                <div className="grid my-3">
                    <div className="col-12">
                        <Button type="button" onClick={async () => {
                            setVisibleModal(true)
                            await getHorarioEmpleado(rowData.id)
                            }}>
                            Horario
                        </Button>
                    </div>
                </div>
                {auth.me.is_staff && (

                    <div className="grid my-3">
                        <div className="col-12">
                            <Button type="button" onClick={() => onFichaEmpleado(rowData)}>
                                Ficha de Empleado
                            </Button>
                        </div>
                    </div>
                    
                )}
            </React.Fragment>
        )

    }

    const templateDay = (rowData) => {

        const dias ={
            "L": "Lunes",
            "M": "Martes",
            "X": "Miercoles",
            "J": "Jueves",
            "V": "Viernes",
            "S": "Sabado",
            "D": "Domingo",
        }

        return (
            <>{dias[rowData.Dia]}</>
        )
    }

    const exportExcel = () => {

        let config_roster_format = []

        for (const empleado of ListRoster){

            const nombre_plaza = filter(listPlazas, { value: parseInt(empleado.Plaza) });

            const nombre_cuenta = filter(listCuentas, { value: parseInt(empleado.Cuenta) });
            const nombre_lob = filter(listLob, { value: parseInt(empleado.Lob) });
            const supervisor_name = filter(listEmpleados, { Badge: empleado.Supervisor });
            const account_name = filter(listEmpleados, { Badge: empleado.Account_Manager });
            const director_name = filter(listEmpleados, { Badge: empleado.Director });

            config_roster_format.push({
                "Badge": empleado.Badge,
                "Nombre": empleado.Nombre,
                "Plaza": size(nombre_plaza) > 0 ? nombre_plaza[0].name : empleado.Plaza,
                "ACD": empleado.ACD,
                "ACD_ID": empleado.ACD_ID,
                "ACD_Name": empleado.ACD_Name,
                "ACD_Skill": empleado.ACD_Skill,
                "Supervisor": size(supervisor_name) > 0 ? `${supervisor_name[0].Badge} - ${supervisor_name[0].NombreEmpleado}` : empleado.Supervisor,
                "Account_Manager": size(account_name) > 0 ? `${account_name[0].Badge} - ${account_name[0].NombreEmpleado}` : empleado.Account_Manager,
                "Director": size(director_name) > 0 ? `${director_name[0].Badge} - ${director_name[0].NombreEmpleado}` : empleado.Director,
                "Tipo_de_Horario": empleado.Tipo_de_Horario,
                "Fecha_de_Ingreso": empleado.Fecha_de_Ingreso,
                "Fecha_de_Entrenamiento": empleado.Fecha_de_Entrenamiento,
                "Fecha_de_Produccion": empleado.Fecha_de_Produccion,
                "Cuenta": size(nombre_cuenta) > 0 ? nombre_cuenta[0].name : empleado.Cuenta,
                "Lob": size(nombre_lob) > 0 ? nombre_lob[0].name : empleado.Lob,
                "Lob_2": empleado.Lob_2,
                "Lob_3": empleado.Lob_3,
                "Fecha_Lob_2": empleado.Fecha_Lob_2,
                "Fecha_Lob_3": empleado.Fecha_Lob_3,
                "Sociedad": empleado.Sociedad,
                "Pais": empleado.Pais,
                "Departamento": empleado.Departamento,
                "Municipio": empleado.Municipio,
                "Edificio": empleado.Edificio,
                "BYOD": empleado.BYOD,
                "Access_Card_Number": empleado.Access_Card_Number,
                "Windows_User": empleado.Windows_User,
                "Correo_Interno": empleado.Correo_Interno,
                "Sexo": empleado.Sexo,
                "Parent": empleado.Parent,
                "Correo_Electronico": empleado.Correo_Electronico,
                "Numero_de_Contacto": empleado.Numero_de_Contacto,
                "Numero_Contacto_2": empleado.Numero_Contacto_2,
                "Numero_Contacto_3": empleado.Numero_Contacto_3,
                "Codigo_AnyDesk": empleado.Codigo_AnyDesk,
                "Direccion": empleado.Direccion,
                "Nombre_Contacto_Emergencia": empleado.Nombre_Contacto_Emergencia,
                "Numero_Contacto_Emergencia": empleado.Numero_Contacto_Emergencia,
                "Seguro_de_Emergencias": empleado.Seguro_de_Emergencias,

            })
        }
        
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(config_roster_format);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'HorasPlanilla');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
        </div>
    );

    return (
        <>
        <Dialog header="Horario de Empleado" visible={visibleModal} style={{width: "50vw"}} onHide={() => setVisibleModal(false)}>
                <DataTable value={horarioEmpleado}>
                    <Column field='Dia' body={templateDay}></Column>
                    <Column header="Hora de Inicio" field='HoraInicio'></Column>
                    <Column header="Hora de Final" field='HoraFinal'></Column>
                    <Column header="Almuerzo" field='Almuerzo'></Column>
                </DataTable>
            </Dialog>
            <DataTable
            scrollable
            scrollHeight="600px"
            tableStyle={{minWidth: '50rem'}}
                value={ListRoster}
                paginator
                header={header}
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
                <Column header="Acciones" body={templateAccion} frozen ></Column>
                <Column field="Badge" header="Badge" filter frozen></Column>
                <Column field="Nombre" header="Nombre" filter frozen></Column>
                <Column header="Plaza" body={templatePlaza} filter></Column>
                <Column field="ACD" header="ACD" filter></Column>
                <Column field="ACD_ID" header="ACD_ID" filter></Column>
                <Column field="ACD_Name" header="ACD_Name" filter></Column>
                <Column field="ACD_Skill" header="ACD_Skill" filter></Column>
                <Column header="Supervisor" body={templateSupervisor} filter></Column>
                <Column header="Account_Manager" body={templateAccount} filter></Column>
                <Column header="Director" body={templateDirector} filter></Column>
                <Column field="Tipo_de_Horario" header="Tipo_de_Horario" filter></Column>
                <Column field="Fecha_de_Ingreso" header="Fecha_de_Ingreso" filter></Column>
                <Column field="Fecha_de_Entrenamiento" header="Fecha_de_Entrenamiento" filter></Column>
                <Column field="Fecha_de_Produccion" header="Fecha_de_Produccion" filter></Column>
                <Column header="Cuenta" body={templateCuenta} filter></Column>
                <Column header="Lob" body={templateLob} filter></Column>
                <Column field="Lob_2" header="Lob_2" filter></Column>
                <Column field="Lob_3" header="Lob_3" filter></Column>
                <Column field="Fecha_Lob_2" header="Fecha_Lob_2" filter></Column>
                <Column field="Fecha_Lob_3" header="Fecha_Lob_3" filter></Column>
                <Column field="Sociedad" header="Sociedad" filter></Column>
                <Column field="Pais" header="Pais" filter></Column>
                <Column field="Departamento" header="Departamento" filter></Column>
                <Column field="Municipio" header="Municipio" filter></Column>
                <Column field="Edificio" header="Edificio" filter></Column>
                <Column field="BYOD" header="BYOD" filter></Column>
                <Column field="Access_Card_Number" header="Access_Card_Number" filter></Column>
                <Column field="Windows_User" header="Windows_User" filter></Column>
                <Column field="Correo_Interno" header="Correo_Interno" filter></Column>
                <Column field="Sexo" header="Sexo" filter></Column>
                <Column field="Parent" header="Parent" filter></Column>
                <Column field="Correo_Electronico" header="Correo_Electronico" filter></Column>
                <Column field="Numero_de_Contacto" header="Numero_de_Contacto" filter></Column>
                <Column field="Numero_Contacto_2" header="Numero_Contacto_2" filter></Column>
                <Column field="Numero_Contacto_3" header="Numero_Contacto_3" filter></Column>
                <Column field="Codigo_AnyDesk" header="Codigo_AnyDesk" filter></Column>
                <Column field="Direccion" header="Direccion" filter></Column>
                <Column field="Nombre_Contacto_Emergencia" header="Nombre_Contacto_Emergencia" filter></Column>
                <Column field="Numero_Contacto_Emergencia" header="Numero_Contacto_Emergencia" filter></Column>
                <Column field="Seguro_de_Emergencias" header="Seguro_de_Emergencias" filter></Column>

                {/* <Column field="Salario_Ordinario" header="Salario_Ordinario"></Column>
                <Column field="Estado_de_Empleado" header="Estado_de_Empleado"></Column>
                <Column field="Numero_AFP" header="Numero_AFP"></Column>
                <Column field="DUI" header="DUI"></Column>
                <Column field="NIT" header="NIT"></Column>
                <Column field="Carnet_de_Minoridad" header="Carnet_de_Minoridad"></Column>
                <Column field="Fecha_de_Nacimiento" header="Fecha_de_Nacimiento"></Column>
                <Column field="Profesion" header="Profesion"></Column>
                <Column field="Numero_ASSA" header="Numero_ASSA"></Column>
                <Column field="Bono" header="Bono"></Column>
                <Column field="Comision" header="Comision"></Column>
                <Column field="Banco" header="Banco"></Column>
                <Column field="Numero_Cuenta" header="Numero_Cuenta"></Column>
                <Column field="ISSS" header="ISSS"></Column>
                <Column field="NUP" header="NUP"></Column>
                <Column field="Tipo_AFP" header="Tipo_AFP"></Column>
                <Column field="IPSFA" header="IPSFA"></Column>
                <Column field="Documento_de_Identidad" header="Documento_de_Identidad"></Column> */}
            </DataTable>
        </>
    );
}
