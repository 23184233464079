import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image } from 'primereact/image';
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import {Dropdown} from "primereact/dropdown"
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import * as Yup from "yup";

export function SancionesFormAp({ response }) {

  const [previewImage, setPreviewImage] = useState(null);
  const [disabledState, setDisabledState] = useState(false);

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile[0];
        formik.setFieldValue('ImagenAp', file);
        setPreviewImage(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    });

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: (formValue) => {
      setDisabledState(true)
      response(formValue);
    },
  });

  const listTipoFalta = [
    {name: "Incumplimiento de Horario", value: "Incumplimiento de Horario"},
    {name: "Falta Diciplinaria", value: "Falta Diciplinaria"},
    {name: "Mal Procedimiento", value: "Mal Procedimiento"}
  ]

  const listTipoSancion = [
    {name: "Verbal", value: "Verbal"},
    {name: "Escrita", value: "Escrita"},
    {name: "Escrita Final", value: "Escrita Final"},
    {name: "Suspencion", value: "Suspencion"},
  ]

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid">
        <div className="col-12 md:col-4">
          <label>Fecha Efectiva</label>
          <Calendar
            dateFormat="yy-mm-dd"
            className="w-full"
            name="FechaEfectiva"
            value={formik.values.FechaEfectiva}
            onChange={(e) => formik.setFieldValue("FechaEfectiva", e.value)}
          />
        </div>
        <div className="col-12 md:col-4">
          <label>Tipo de Falta</label>
          <Dropdown
            options={listTipoFalta}
            optionLabel="name"
            className="w-full"
            name="TipoFalta"
            value={formik.values.TipoFalta}
            onChange={(e) => formik.setFieldValue("TipoFalta", e.value)}
          />
        </div>
        <div className="col-12 md:col-4">
          <label>Tipo de Sancion</label>
          <Dropdown
            options={listTipoSancion}
            optionLabel="name"
            className="w-full"
            name="TipoSancion"
            value={formik.values.TipoSancion}
            onChange={(e) => formik.setFieldValue("TipoSancion", e.value)}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <label>Observacion</label>
          <InputTextarea
            cols={30}
            rows={5}
            className="w-full"
            name="Observacion"
            value={formik.values.Observacion}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="grid">
                <div className="col-6">
                    <Button type="button" severity="primary" size="large" {...getRootProps()}>
                        Subir Imagen para la AP
                    </Button>
                    <input {...getInputProps()} />
                </div>
                <div className="col-6">
                    <Image src={previewImage} width="100%" />
                </div>
            </div>
      <div className="grid">
        <div className="col-12">
          <Button severity="success" type="submit" disabled={disabledState}>
            Guardar Ap
          </Button>
        </div>
      </div>
    </form>
  );
}

function initialValues() {
  return {
    FechaEfectiva: "",
    TipoFalta: "",
    TipoSancion: "",
    Observacion: "",
    ImagenAp: ''
  };
}

function validationSchema() {
  return {
    FechaEfectiva: Yup.string().required(true),
    TipoFalta: Yup.string().required(true),
    TipoSancion: Yup.string().required(true),
    Observacion: Yup.string().required(true),
    ImagenAp: Yup.string()
  };
}
