import React, { useEffect } from 'react';
import { useProfile } from '../../../hooks';

export function DatosPersonalesTab() {
    const { loadingProfile, onGetProfileData, empleadoData } = useProfile();

    useEffect(() => {
        (async () => {
            await onGetProfileData();
        })();
    }, []);

    return (
        <>
            {loadingProfile ? (
                <div className="grid my-5">
                    <div className="col-12 text-center">
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '3rem' }}></i>
                    </div>
                </div>
            ) : (
                <>
                    <div className="grid my-3 text-center">
                        <div className="col-6 md:col-6">
                            <h6>Badge</h6>
                            <h5>{empleadoData?.Badge}</h5>
                        </div>
                        <div className="col-6 md:col-6">
                            <h6>Nombre</h6>
                            <h5>{empleadoData?.Nombre}</h5>
                        </div>
                    </div>
                    <hr />
                    <div className="grid my-3 text-center">
                        <div className="col-6 md:col-4">
                            <h6>Cuenta</h6>
                            <h5>{empleadoData?.Cuenta}</h5>
                        </div>
                        <div className="col-6 md:col-4">
                            <h6>Lob</h6>
                            <h5>{empleadoData?.Lob}</h5>
                        </div>
                        <div className="col-6 md:col-4">
                            <h6>Lob 2</h6>
                            <h5>{empleadoData?.SubLob}</h5>
                        </div>
                    </div>
                    <hr />
                    <div className="grid my-3 text-center">
                        <div className="col-6 md:col-3">
                            <h6>Supervisor</h6>
                            <h5>{empleadoData?.Supervisor}</h5>
                        </div>
                        <div className="col-6 md:col-3">
                            <h6>Account Manager</h6>
                            <h5>{empleadoData?.account}</h5>
                        </div>
                        <div className="col-6 md:col-3">
                            <h6>Director</h6>
                            <h5>{empleadoData?.director}</h5>
                        </div>
                        <div className="col-6 md:col-3">
                            <h5>HR Asignado</h5>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
