import { useState } from 'react';
import { size, filter, map } from 'lodash';
import moment from "moment"
import { getEmpleadoByFilterApi, getDetallesEmpleadoApi, getDetalleEmpleadoApi, addDetalleEmpleadoApi } from '../api/skyroster/detalleempleado';
import { getEmpleadoIdApi, getEmpleadosApi, addEmpleadoApi, getSkyEmpleadosPersoApi, getMasterRosterAutomate } from '../api/skyroster/empleado';
import { getHorarioApi } from '../api/skyroster/horario';
import { getCuentasApi } from '../api/skyroster/cuenta';
import { getLobsApi } from '../api/skyroster/lob';
import { getPlazasApi } from '../api/skyroster/plaza';
import { useAuth } from './useAuth';

export function useMasterRoster() {
    const [loadingMasterRoster, setLoadingMasterRoster] = useState(false);
    const [error, setError] = useState(false);
    const [empleados, setEmpleados] = useState(null);
    const [empleado, setEmpleado] = useState(null);
    const [datosConsultados, setDatosConsultados] = useState(0);
    const [horarioEmpleado, setHorarioEmpleado] = useState(0);
    const [tiempoEstimado, setTiempoEstimado] = useState(null)
    const [empleadosProcesados, setEmpleadosProcesados] = useState(0)
    const [empleadosProcesar, setEmpleadosProcesar] = useState(0)

    const { auth } = useAuth();

    const getMasterRosterAutomateReport = async (Semana, Cuenta, Lob) => {
        try {
            setLoadingMasterRoster(true)

            const master_roster_response = await getMasterRosterAutomate(auth.token, Semana, Cuenta, Lob)

            setLoadingMasterRoster(false)

            return master_roster_response
        } catch (error) {
            setLoadingMasterRoster(true)
            setError(error)
        }
    }

    const getEmpleadosMasterRoster = async (Cuenta, Lob, Semana) => {
        try {
            const datos_default = (113*275) / 591;
            setDatosConsultados(datos_default);

            setLoadingMasterRoster(true);

            let response_master_roster = [];
            let response_empleado = [];

            if (Lob == '') {
                response_empleado = await getEmpleadoByFilterApi(auth.token, Cuenta, 3, Semana);
            } else {
                response_empleado = await getEmpleadoByFilterApi(auth.token, Lob, 5, Semana);
            }

            for await (const empleado of response_empleado) {
                let append_detalle_empleado = [];

                const response_detalle_empleado = await getDetallesEmpleadoApi(auth.token, empleado.Empleado);

                append_detalle_empleado['id_empleado'] = empleado.Empleado;
                append_detalle_empleado[1] = empleado.empleado_data.Badge;

                for await (const de_empleado of response_detalle_empleado) {
                    switch (de_empleado.Propiedad) {
                        case 3:
                            const response_cuenta = await getCuentasApi(auth.token, 1, de_empleado.Valor);
                            append_detalle_empleado['Cuenta'] = response_cuenta[0].Nombre;
                            append_detalle_empleado[de_empleado.Propiedad] = de_empleado.Valor;
                            break;
                        case 5:
                            const response_lob = await getLobsApi(auth.token, true, '', `${de_empleado.Valor}/`);
                            append_detalle_empleado['Lob1'] = response_lob.Lob;

                            append_detalle_empleado[de_empleado.Propiedad] = de_empleado.Valor;
                            break;
                        case 6:
                            let response_plaza = 'No Configurado';
                            if (de_empleado.Valor != '') {
                                response_plaza = await getPlazasApi(auth.token, '', `${de_empleado.Valor}/`);
                                append_detalle_empleado['Plaza'] = response_plaza.Nombre;
                                append_detalle_empleado[de_empleado.Propiedad] = de_empleado.Valor;
                            } else {
                                append_detalle_empleado['Plaza'] = response_plaza;
                                append_detalle_empleado[de_empleado.Propiedad] = de_empleado.Valor;
                            }
                            break;
                        case 10:
                            const response_supervisor = await getEmpleadoByFilterApi(auth.token, '', 2, Semana, de_empleado.Valor, '');
                            append_detalle_empleado[de_empleado.Propiedad] = de_empleado.Valor;
                            if (size(response_supervisor) > 0) {
                                append_detalle_empleado['NombreSupervisor'] = response_supervisor[0].Valor;
                            } else {
                                append_detalle_empleado['NombreSupervisor'] = 'Data no Cargada';
                            }
                            break;
                        case 11:
                            const response_account = await getEmpleadoByFilterApi(auth.token, '', 2, Semana, de_empleado.Valor, '');
                            append_detalle_empleado[de_empleado.Propiedad] = de_empleado.Valor;
                            if (size(response_account) > 0) {
                                append_detalle_empleado['NombreAccount'] = response_account[0].Valor;
                            } else {
                                append_detalle_empleado['NombreAccount'] = 'Data no Cargada';
                            }
                            break;
                        case 12:
                            const response_director = await getEmpleadoByFilterApi(auth.token, '', 2, Semana, de_empleado.Valor, '');
                            append_detalle_empleado[de_empleado.Propiedad] = de_empleado.Valor;
                            if (size(response_director) > 0) {
                                append_detalle_empleado['NombreDirector'] = response_director[0].Valor;
                            } else {
                                append_detalle_empleado['NombreDirector'] = 'Data no Cargada';
                            }
                            break;
                        default:
                            append_detalle_empleado[de_empleado.Propiedad] = de_empleado.Valor;
                            break;
                    }
                }

                response_master_roster.push(append_detalle_empleado);
            }

            setEmpleados(response_master_roster);

            setLoadingMasterRoster(false);
        } catch (error) {
            setLoadingMasterRoster(false);
            setError(error);
        }
    };

    const getHorarioEmpleado = async (Empleado) => {
        try {
            setLoadingMasterRoster(true)
            const response_horario = await getHorarioApi(auth.token, Empleado, "")
            setHorarioEmpleado(response_horario)
            setLoadingMasterRoster(false)
        } catch (error) {
            setLoadingMasterRoster(false)
            setError(error)
        }
    }

    const getEmpleadoUpdate = async (Empleado) => {
        try {
            setLoadingMasterRoster(true);

            const response_detalle_empleado = await getDetallesEmpleadoApi(auth.token, Empleado);
            const response_badge = await getEmpleadoIdApi(auth.token, Empleado);
            let format_update_empleado = [];

            format_update_empleado['Payroll'] = response_badge[0].Badge;
            format_update_empleado['idEmpleado'] = response_badge[0].id;

            for await (const detalle_empleado of response_detalle_empleado) {
                format_update_empleado[detalle_empleado.Propiedad] = detalle_empleado.Valor;
            }

            setEmpleado(format_update_empleado);

            setLoadingMasterRoster(false);
        } catch (error) {
            setLoadingMasterRoster(false);
            setError(error);
        }
    };

    const getEmpleadoComparativeRosterUpdate = async (Badge, Week) => {
        try {
            setLoadingMasterRoster(true);

            const response_roster_update = await getEmpleadoByFilterApi(auth.token, "", "", Week, Badge)

            setLoadingMasterRoster(false);

            return response_roster_update
        } catch (error) {
            setLoadingMasterRoster(false);
            setError(error);
        }
    };

    const getEmpleadoUpdateResponse = async (Empleado) => {
        try {
            setLoadingMasterRoster(true);

            const response_detalle_empleado = await getDetallesEmpleadoApi(auth.token, Empleado);
            const response_badge = await getEmpleadoIdApi(auth.token, Empleado);
            let format_update_empleado = [];

            format_update_empleado['Payroll'] = response_badge[0].Badge;
            format_update_empleado['idEmpleado'] = response_badge[0].id;

            for await (const detalle_empleado of response_detalle_empleado) {
                format_update_empleado[detalle_empleado.Propiedad] = detalle_empleado.Valor;
            }

            setLoadingMasterRoster(false);

            return format_update_empleado
        } catch (error) {
            setLoadingMasterRoster(false);
            setError(error);
        }
    };

    const getMasterRosterPanel = async (Semana) => {
        try {
            const datos_default = (1073 * 38) / 591;
            setDatosConsultados(datos_default);
            setLoadingMasterRoster(true);

            const response_empleados = await getEmpleadoByFilterApi(auth.token, '', '', Semana);
            /* const datos_reales = size(response_empleados) / 591;
            setDatosConsultados(datos_default - datos_reales); */
            const response_cuentas = await getCuentasApi(auth.token, 1);
            const response_lob = await getLobsApi(auth.token, true);
            const response_plaza = await getPlazasApi(auth.token, true, '');
            let list_empleados = [];
            let registrados = [];

            for await (const detalle_empleado of response_empleados) {
                let register_employee = registrados.includes(detalle_empleado.Empleado);

                if (!register_employee) {
                    registrados.push(detalle_empleado.Empleado);

                    let detalle_empleado_push = filter(response_empleados, ['Empleado', detalle_empleado.Empleado]);
                    let detalle_empleado_format = [];

                    detalle_empleado_format['Badge'] = detalle_empleado_push[0]?.empleado_data.Badge;
                    detalle_empleado_format['IdEmpleado'] = detalle_empleado.Empleado;
                    let cuenta_id = 0;

                    map(detalle_empleado_push, (de, index) => {
                        switch (de.Propiedad) {
                            case 3:
                                cuenta_id = parseInt(de.Valor);
                                const filter_cuenta = filter(response_cuentas, ['id', cuenta_id]);
                                detalle_empleado_format['Cuenta'] = filter_cuenta[0]?.Nombre ? filter_cuenta[0]?.Nombre : de.Valor;
                                break;

                            case 5:
                                const filter_lob = filter(response_lob, ['id', parseInt(de.Valor)]);
                                detalle_empleado_format['Lob'] = filter_lob[0]?.Lob ? filter_lob[0]?.Lob : de.Valor;
                                break;

                            case 6:
                                const filter_plaza = filter(response_plaza, ['id', parseInt(de.Valor)]);
                                detalle_empleado_format['Plaza'] = filter_plaza[0]?.Nombre ? filter_plaza[0]?.Nombre : de.Valor;
                                break;

                            case 10:
                                const filter_sup_detalle = filter(response_empleados, ['empleado_data.Badge', de.Valor]);
                                const filter_sup_nombre = filter(filter_sup_detalle, ['Propiedad', 2]);
                                detalle_empleado_format['NombreSupervisor'] = filter_sup_nombre[0]?.Valor ? filter_sup_nombre[0]?.Valor : de.Valor;
                                break;

                            case 11:
                                const filter_account_detalle = filter(response_empleados, ['empleado_data.Badge', de.Valor]);
                                const filter_account_nombre = filter(filter_account_detalle, ['Propiedad', 2]);
                                detalle_empleado_format['NombreAccount'] = filter_account_nombre[0]?.Valor ? filter_account_nombre[0]?.Valor : de.Valor;
                                break;

                            case 12:
                                const filter_director_detalle = filter(response_empleados, ['empleado_data.Badge', de.Valor]);
                                const filter_director_nombre = filter(filter_director_detalle, ['Propiedad', 2]);
                                detalle_empleado_format['NombreDirector'] = filter_director_nombre[0]?.Valor ? filter_director_nombre[0]?.Valor : de.Valor;
                                break;

                            default:
                                break;
                        }

                        detalle_empleado_format[de.Propiedad] = de.Valor;
                    });

                    list_empleados.push(detalle_empleado_format);
                }
            }

            setEmpleados(list_empleados);

            setLoadingMasterRoster(false);
        } catch (error) {
            console.log(error);
            setLoadingMasterRoster(false);
            setError(error);
        }
    };

    const getEmpleadosLoad = async (Week) => {
        try {
            setLoadingMasterRoster(true)

            const fecha_hoy = new Date()
            const semana = moment(fecha_hoy).week()
            const empleados_semana = await getEmpleadosApi(auth.token,Week)
            const calcTiempo = (size(empleados_semana) * 120) / 591
            setEmpleadosProcesar(size(empleados_semana))
            let contador = 1

            setTiempoEstimado(calcTiempo.toFixed(0))

            for await (const detalle_empleado of empleados_semana){
                setEmpleadosProcesados(contador)

                const exist_empleado_week = await getEmpleadosApi(auth.token,semana, detalle_empleado.Badge)
                const response_detalle_empleado = await getDetalleEmpleadoApi(auth.token, "", detalle_empleado.id)
                let id_empleado = 0
                
                if (size(exist_empleado_week) > 0){
                    
                    id_empleado = exist_empleado_week[0].id

                    for await (const update_insert_detalle of response_detalle_empleado){

                        const exists_propiedad_empleado = await getDetalleEmpleadoApi(auth.token, update_insert_detalle.Propiedad, id_empleado)

                        if (size(exists_propiedad_empleado) > 0){

                        }else{
                            await addDetalleEmpleadoApi({
                                Propiedad: update_insert_detalle.Propiedad,
                                Empleado: id_empleado,
                                Valor: update_insert_detalle.Valor
                            }, auth.token)
                        }

                    }
                    
                }else{

                    const add_empleado = await addEmpleadoApi({
                        Badge: detalle_empleado.Badge,
                        Week: semana,
                        Year: 2024
                    }, auth.token)

                    id_empleado = add_empleado.id

                    for await (const insert_detalle of response_detalle_empleado){

                        await addDetalleEmpleadoApi({
                            Propiedad: insert_detalle.Propiedad,
                            Empleado: id_empleado,
                            Valor: insert_detalle.Valor
                        }, auth.token)

                    }

                }




                contador += 1
            }

            setLoadingMasterRoster(false)
        } catch (error) {
            setLoadingMasterRoster(false)
            setError(error)
        }
    }

    const getDashboardSkyRoster = async () => {
        try {
            setLoadingMasterRoster(true)
            
            if (auth.me.is_staff){
                
            }else{

            }

            setLoadingMasterRoster(false)
        } catch (error) {
            setLoadingMasterRoster(false)
            setError(error)
        }
    }

    const getSkyEmpleadosViewPerso = async () => {
        try {
            setLoadingMasterRoster(true)
            const response_empleados = await getSkyEmpleadosPersoApi(auth.token)

            setEmpleados(response_empleados);
            setLoadingMasterRoster(false)
        } catch (error) {
            setLoadingMasterRoster(false)
            setError(error)
        }
    }

    const getSkyEmpleadosViewPersoResponse = async () => {
        try {
            setLoadingMasterRoster(true)
            const response_empleados = await getSkyEmpleadosPersoApi(auth.token)

            setLoadingMasterRoster(false)
            return response_empleados
        } catch (error) {
            setLoadingMasterRoster(false)
            setError(error)
        }
    }

    return {
        error,
        loadingMasterRoster,
        empleados,
        empleado,
        datosConsultados,
        horarioEmpleado,
        tiempoEstimado,
        empleadosProcesados,
        empleadosProcesar,
        getEmpleadosMasterRoster,
        getEmpleadoUpdate,
        getMasterRosterPanel,
        setDatosConsultados,
        getHorarioEmpleado,
        getEmpleadosLoad,
        setTiempoEstimado,
        getDashboardSkyRoster,
        getSkyEmpleadosViewPerso,
        getSkyEmpleadosViewPersoResponse,
        getMasterRosterAutomateReport,
        getEmpleadoUpdateResponse,
        getEmpleadoComparativeRosterUpdate
    };
}
