import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import {InputTextarea} from "primereact/inputtextarea"
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRosterUpdate, useUploadFiles } from '../../hooks';
import { listProfesiones, listTieneHijos, listSeguros, listAcdName, listEquipoTrabajo, listPropiedadesFormik, listPais, listEdificios, listDepartamentos, listMunicipios, listBancos } from '../../utilities/options';

export function FormFichaEmpleado({ empleado }) {
    const toast = useRef(null);
    const [rosterUpdatePendiente, setRosterUpdatePendiente] = useState(false);

    const { existRosterUpdate } = useRosterUpdate();
    const {insertDetalleEmpleado} = useUploadFiles()

    useEffect(() => {
        (async () => {
            setRosterUpdatePendiente(await existRosterUpdate(empleado.Payroll));
        })();
    }, []);

    const formik = useFormik({
        initialValues: initialValues(empleado),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {

            toast.current.show({
                severity: 'info',
                summary: 'Update',
                detail: 'Guardando Informacion de empleado',
                life: 3000
            });

            for await (const prop of Object.keys(formValue)){

                if (prop != "" && listPropiedadesFormik[prop] != undefined && listPropiedadesFormik[prop] != ""){

                    const value_insert = formValue[prop]
                    const id_prop = listPropiedadesFormik[prop]

                    await insertDetalleEmpleado(empleado.idEmpleado, id_prop, value_insert)

                }


            }

            toast.current.show({
                severity: 'success',
                summary: 'Update',
                detail: 'Empleado modificado correctamente',
                life: 3000
            });

            setRosterUpdatePendiente(true);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <h5>
                        Ficha de Empleado de - {empleado.Payroll} {empleado[2]}
                    </h5>
                </div>
            </div>

            <>
                <div className="grid">

                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputText className="w-full" name="Nombre" id="Nombre" value={formik.values.Nombre} onChange={formik.handleChange} />
                            <label htmlFor="Nombre">Nombre</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <Dropdown
                                options={listPais}
                                optionLabel="name"
                                name="Pais"
                                id="Pais"
                                className="w-full"
                                value={formik.values.Pais}
                                onChange={(e) => {
                                    formik.setFieldValue('Pais', e.value);
                                }}
                            />
                            <label htmlFor="Pais">Pais</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <Dropdown options={listEdificios[formik.values.Pais]} optionLabel="name" name="Site" id="Site" className="w-full" value={formik.values.Site} onChange={(e) => formik.setFieldValue('Site', e.value)} />
                            <label htmlFor="Site">Site</label>
                        </span>
                    </div>

                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown
                                options={listDepartamentos}
                                optionLabel="name"
                                name="Departamento"
                                id="Departamento"
                                className="w-full"
                                editable
                                value={formik.values.Departamento}
                                onChange={(e) => formik.setFieldValue('Departamento', e.value)}
                            />
                            <label htmlFor="Departamento">Provincia</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown
                                options={listMunicipios[formik.values.Departamento]}
                                optionLabel="name"
                                name="Municipio"
                                id="Municipio"
                                className="w-full"
                                filter
                                editable
                                value={formik.values.Municipio}
                                onChange={(e) => formik.setFieldValue('Municipio', e.value)}
                            />
                            <label htmlFor="Municipio">Ciudad</label>
                        </span>
                    </div>
                    <div className="field col-12">
                        <span className="p-float-label">
                            <InputTextarea className="w-full" name="Direccion" id="Direccion" cols={30} rows={5} value={formik.values.Direccion} onChange={formik.handleChange} />
                            <label htmlFor="Direccion">Address</label>
                        </span>
                    </div>

                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <Calendar className="w-full" name="HireDT" id="HireDT" dateFormat="yy-mm-dd" value={formik.values.HireDT} onChange={(e) => formik.setFieldValue('HireDT', e.value)} />
                            <label htmlFor="HireDT">Hire Date</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <Calendar className="w-full" name="TrainingDT" id="TrainingDT" dateFormat="yy-mm-dd" value={formik.values.TrainingDT} onChange={(e) => formik.setFieldValue('TrainingDT', e.value)} />
                            <label htmlFor="TrainingDT">Training Date</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <Calendar className="w-full" name="ProductionDT" id="ProductionDT" dateFormat="yy-mm-dd" value={formik.values.ProductionDT} onChange={(e) => formik.setFieldValue('ProductionDT', e.value)} />
                            <label htmlFor="ProductionDT">Production Date</label>
                        </span>
                    </div>

                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" options={listProfesiones} optionLabel="name" id="Profesion" name="Profesion" value={formik.values.Profesion} filter onChange={(e) => formik.setFieldValue('Profesion', e.value)} />
                            <label htmlFor="Profesion">Profesion</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" options={listTieneHijos} optionLabel="name" id="Parent" name="Parent" value={formik.values.Parent} filter onChange={(e) => formik.setFieldValue('Parent', e.value)} />
                            <label htmlFor="Parent">Parent</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputMask className="w-full" name="Phone" id="Phone" autoClear={false} value={formik.values.Phone} onChange={formik.handleChange} mask="( + 999 ) 9999 9999 9999" />
                            <label htmlFor="Phone">Phone</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputMask className="w-full" name="Phone_2" id="Phone_2" autoClear={false} value={formik.values.Phone_2} onChange={formik.handleChange} mask="( + 999 ) 9999 9999 9999" />
                            <label htmlFor="Phone_2">Contact Phone 1</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputMask className="w-full" name="Phone_3" id="Phone_3" autoClear={false} value={formik.values.Phone_3} onChange={formik.handleChange} mask="( + 999 ) 9999 9999 9999" />
                            <label htmlFor="Phone_3">Contact Phone 2</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" options={listBancos} optionLabel="name" name="Banco" id="Banco" filter value={formik.values.Banco} onChange={(e) => formik.setFieldValue('Banco', e.value)} />
                            <label htmlFor="Banco">Banco</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputNumber className="w-full" maxFractionDigits={2} name="CuentaBanco" id="CuentaBanco" useGrouping={false} value={formik.values.CuentaBanco} onChange={(e) => formik.setFieldValue('CuentaBanco', e.value)} />
                            <label htmlFor="CuentaBanco">Cuenta Bancaria</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputText className="w-full" name="EmergencyName" id="EmergencyName" value={formik.values.EmergencyName} onChange={formik.handleChange} />
                            <label htmlFor="EmergencyName">Emergency Contact Name</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputMask className="w-full" name="EmergencyPhone" id="EmergencyPhone" autoClear={false} value={formik.values.EmergencyPhone} onChange={formik.handleChange} mask="( + 999 ) 9999 9999 9999" />
                            <label htmlFor="EmergencyPhone">Emergency Contact Number</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <Dropdown className="w-full" options={listSeguros} optionLabel="name" id="Seguro" name="Seguro" value={formik.values.Seguro} filter onChange={(e) => formik.setFieldValue('Seguro', e.value)} />
                            <label htmlFor="Seguro">Emergency Pref</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <Dropdown className="w-full" options={listAcdName} optionLabel="name" id="Acd" name="Acd" value={formik.values.Acd} filter onChange={(e) => formik.setFieldValue('Acd', e.value)} />
                            <label htmlFor="Acd">ACD</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AcdID" id="AcdID" value={formik.values.AcdID} onChange={formik.handleChange} />
                            <label htmlFor="AcdID">ACDID</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AcdName" id="AcdName" value={formik.values.AcdName} onChange={formik.handleChange} />
                            <label htmlFor="AcdName">ACD Name</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-3">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AcdSkill" id="AcdSkill" value={formik.values.AcdSkill} onChange={formik.handleChange} />
                            <label htmlFor="AcdSkill">ACD Skill</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Calendar dateFormat="yy-mm-dd" name="Lob2DT" id="Lob2DT" className="w-full" value={formik.values.Lob2DT} onChange={(e) => formik.setFieldValue('Lob2DT', e.value)} />
                            <label htmlFor="Lob2DT">Lob 2 Date</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Calendar dateFormat="yy-mm-dd" name="Lob3DT" id="Lob3DT" className="w-full" value={formik.values.Lob3DT} onChange={(e) => formik.setFieldValue('Lob3DT', e.value)} />
                            <label htmlFor="Lob3DT">Lob 3 Date</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <Dropdown className="w-full" options={listEquipoTrabajo} optionLabel="name" id="Byod" name="Byod" value={formik.values.Byod} filter onChange={(e) => formik.setFieldValue('Byod', e.value)} />
                            <label htmlFor="Byod">BYOD</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputText className="w-full" name="InternalEmail" id="InternalEmail" value={formik.values.InternalEmail} onChange={formik.handleChange} />
                            <label htmlFor="InternalEmail">Internal Email</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputText className="w-full" name="PersonalEmail" id="PersonalEmail" value={formik.values.PersonalEmail} onChange={formik.handleChange} />
                            <label htmlFor="PersonalEmail">Personal Email</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AccessCard" id="AccessCard" value={formik.values.AccessCard} onChange={formik.handleChange} />
                            <label htmlFor="AccessCard">Access Card N</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputText className="w-full" name="WindowsUser" id="WindowsUser" value={formik.values.WindowsUser} onChange={formik.handleChange} />
                            <label htmlFor="WindowsUser">Windows User</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-4">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AnyDesk" id="AnyDesk" value={formik.values.AnyDesk} onChange={formik.handleChange} />
                            <label htmlFor="AnyDesk">AnyDesk Code</label>
                        </span>
                    </div>
                </div>

                <div className="grid mt-3 mb-5">
                    <div className="col-12 text-right">
                        <Button severity="success" type="submit" size="large">
                            Guardar Empleado
                        </Button>
                    </div>
                </div>
            </>
        </form>
    );
}

function initialValues(empleado) {

    const hireDT = empleado[14] ? new Date(empleado[14]) : '';
    const trainingDT = empleado[15] ? new Date(empleado[15]) : ''
    const productionDT = empleado[16] ? new Date(empleado[16]) : ''

    return {
        EmergencyName: empleado[36] ? empleado[36] : '',
        AcdID: empleado[7] ? empleado[7] : '',
        AcdName: empleado[8] ? empleado[8] : '',
        AcdSkill: empleado[9] ? empleado[9] : '',
        InternalEmail: empleado[25] ? empleado[25] : '',
        PersonalEmail: empleado[27] ? empleado[27] : '',
        AccessCard: empleado[23] ? empleado[23] : '',
        WindowsUser: empleado[24] ? empleado[24] : '',
        AnyDesk: empleado[32] ? empleado[32] : '',
        Phone: empleado[28] ? empleado[28] : '',
        Phone_2: empleado[29] ? empleado[29] : '',
        Phone_3: empleado[30] ? empleado[30] : '',
        Banco: empleado[51] ? empleado[51] : '',
        CuentaBanco: empleado[52] ? empleado[52] : '',
        EmergencyPhone: empleado[31] ? empleado[31] : '',
        Lob2DT: empleado[19] ? empleado[19] : '',
        Lob3DT: empleado[20] ? empleado[20] : '',
        Profesion: empleado[1] ? empleado[1] : '',
        Parent: empleado[26] ? empleado[26] : '',
        Seguro: empleado[37] ? empleado[37] : '',
        Acd: empleado[1] ? empleado[1] : '',
        Byod: empleado[22] ? empleado[22] : '',
        Afp: empleado[40] ? empleado[40] : '',
        Nombre: empleado[2] ? empleado[2] : '',
        Pais: empleado[4] ? empleado[4] : '',
        HireDT: hireDT,
        TrainingDT: trainingDT,
        ProductionDT: productionDT,
        Site: empleado[21] ? empleado[21] : '',
        Direccion: empleado[33] ? empleado[33] : '',
        Departamento: empleado[34] ? empleado[34] : '',
        Municipio: empleado[35] ? empleado[35] : '',
    };
}

function validationSchema() {
    return {
        EmergencyName: Yup.string(),
        AcdID: Yup.string(),
        AcdName: Yup.string(),
        AcdSkill: Yup.string(),
        InternalEmail: Yup.string(),
        PersonalEmail: Yup.string(),
        AccessCard: Yup.string(),
        WindowsUser: Yup.string(),
        AnyDesk: Yup.string(),
        Phone: Yup.string(),
        Phone_2: Yup.string(),
        Phone_3: Yup.string(),
        Banco: Yup.string(),
        CuentaBanco: Yup.number(),
        EmergencyPhone: Yup.string(),
        Lob2DT: Yup.string(),
        Lob3DT: Yup.string(),
        Profesion: Yup.string(),
        Parent: Yup.string(),
        Seguro: Yup.string(),
        Acd: Yup.string(),
        Byod: Yup.string(),
        Afp: Yup.string(),
        Nombre: Yup.string(),
        Pais: Yup.string(),
        HireDT: Yup.string(),
        TrainingDT: Yup.string(),
        ProductionDT: Yup.string(),
        Site: Yup.string(),
        Direccion: Yup.string(),
        Departamento: Yup.string(),
        Municipio: Yup.string(),
    };
}
