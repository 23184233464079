import React, { useEffect, useState } from "react";
import {useFormik} from "formik"
import * as Yup from "yup"
import moment from "moment";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {InputMask} from "primereact/inputmask";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {useMasterRoster, useUploadFiles} from "../../hooks"
import {Sexo, listTieneHijos, listSeguros, listDepartamentos, listMunicipios} from "../../utilities/options"

export function FormSurveyUpdate () {

    const [visible, setVisible] = useState(false);
    const [dataEmpleado, setDataEmpleado] = useState(null);
    const [idEmpleado, setIdEmpleado] = useState(0);

    const {getEmpleadoComparativeRosterUpdate} = useMasterRoster();
    const {insertDetalleEmpleado} = useUploadFiles();

    useEffect(() => {
        (async () => {
            const response_empleado = await getEmpleadoComparativeRosterUpdate(localStorage.getItem("badge"), moment().week());
            let data_empleado_format = {}

            for await (const de of response_empleado){
                setIdEmpleado(de.Empleado)
                data_empleado_format[de.Propiedad] = de.Valor;
            }

            setDataEmpleado(data_empleado_format)
        })()
    }, [])

    const formik = useFormik({
        initialValues: initialValues(dataEmpleado),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {

            let data_save_dictionary = [
                {Propiedad: 44, Valor: formValue.Gender},
                {Propiedad: 26, Valor: formValue.Parent},
                {Propiedad: 63, Valor: formValue.NumberChildren},
                {Propiedad: 64, Valor: formValue.DateBirth},
                {Propiedad: 27, Valor: formValue.PersonalEmail},
                {Propiedad: 28, Valor: formValue.PhoneOne},
                {Propiedad: 29, Valor: formValue.PhoneTwo},
                {Propiedad: 30, Valor: formValue.PhoneThree},
                {Propiedad: 36, Valor: formValue.EmergencyContact},
                {Propiedad: 31, Valor: formValue.EmergencyNumber},
                {Propiedad: 37, Valor: formValue.EmergencyPref},
                {Propiedad: 34, Valor: formValue.State},
                {Propiedad: 35, Valor: formValue.Municipality},
                {Propiedad: 33, Valor: formValue.HomeAdrress},
            ]

            for await (const detalle_save of data_save_dictionary){
                if(detalle_save.Valor != undefined){

                    await insertDetalleEmpleado(idEmpleado, detalle_save.Propiedad, detalle_save.Valor);

                }
            }

            setVisible(false)

        }
    })

    useEffect(() => {

        let dateBirth_format = new Date(dataEmpleado?.[64]);

        if (
            dataEmpleado?.[44] &&
            dataEmpleado?.[26] &&
            dataEmpleado?.[34] &&
            dataEmpleado?.[35] &&
            dataEmpleado?.[27]
        ){
            setVisible(false)
        }

        formik.setFieldValue("DateBirth", dateBirth_format);
        formik.setFieldValue("Gender", dataEmpleado?.[44]);
        formik.setFieldValue("Parent", dataEmpleado?.[26]);
        formik.setFieldValue("NumberChildren", parseInt(dataEmpleado?.[63]));
        formik.setFieldValue("State", dataEmpleado?.[34]);
        formik.setFieldValue("Municipality", dataEmpleado?.[35]);
        formik.setFieldValue("PersonalEmail", dataEmpleado?.[27]);
        formik.setFieldValue("PhoneOne", dataEmpleado?.[28]);
        formik.setFieldValue("PhoneTwo", dataEmpleado?.[29]);
        formik.setFieldValue("PhoneThree", dataEmpleado?.[30]);
        formik.setFieldValue("EmergencyContact", dataEmpleado?.[36]);
        formik.setFieldValue("EmergencyNumber", dataEmpleado?.[31]);
        formik.setFieldValue("EmergencyPref", dataEmpleado?.[37]);
        formik.setFieldValue("HomeAdrress", dataEmpleado?.[33]);

    }, [dataEmpleado])

    return (
        <>
        <div className="card flex justify-content-center">
            <Dialog header="Actualizacion de Datos Skyverse" visible={visible} maximizable style={{ width: '50vw' }} onHide={() => {if (!visible) return; setVisible(true); }}>
                <form className="p-3" onSubmit={formik.handleSubmit}>

                    <div className="grid my-3">
                        <div className="col-4">
                            <span className="p-float-label">
                                <Dropdown id="Gender" name="Gender" className="w-full" value={formik.values.Gender} options={Sexo} optionLabel="name" onChange={(e) => formik.setFieldValue("Gender", e.value)} />
                                <label htmlFor="Gender">Gender</label>
                            </span>
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <Dropdown id="Parent" name="Parent" className="w-full" value={formik.values.Parent} options={listTieneHijos} optionLabel="name" onChange={(e) => formik.setFieldValue("Parent", e.value)} />
                                <label htmlFor="Parent">Parent</label>
                            </span>
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <InputNumber id="NumberChildren" name="NumberChildren" className="w-full" value={formik.values.NumberChildren} onChange={(e) => formik.setFieldValue("NumberChildren", e.value)} />
                                <label htmlFor="NumberChildren">Number of Children</label>
                            </span>
                        </div>
                    </div>
                    <div className="grid my-3">
                        <div className="col-6">
                            <span className="p-float-label">
                                <Calendar id="DateBirth" name="DateBirth" className="w-full" value={formik.values.DateBirth} placeholder="MM/DD/YYYY" onChange={(e) => formik.setFieldValue("DateBirth", e.value)} />
                                <label htmlFor="DateBirth">Date of Birth</label>
                            </span>
                        </div>
                        <div className="col-6">
                            <span className="p-float-label">
                                <InputText id="PersonalEmail" name="PersonalEmail" className="w-full" value={formik.values.PersonalEmail} onChange={formik.handleChange} />
                                <label htmlFor="PersonalEmail">Personal Email</label>
                            </span>
                        </div>
                    </div>
                    <div className="grid my-3">
                        <div className="col-4">
                            <span className="p-float-label">
                                <InputMask id="PhoneOne" name="PhoneOne" className="w-full" value={formik.values.PhoneOne} onChange={(e) => formik.setFieldValue("PhoneOne", e.target.value)} mask="(+999) 9999 9999 9999" autoClear={false} />
                                <label htmlFor="PhoneOne">Phone Number 1</label>
                            </span>
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <InputMask id="PhoneTwo" name="PhoneTwo" className="w-full" value={formik.values.PhoneTwo} onChange={(e) => formik.setFieldValue("PhoneTwo", e.target.value)} mask="(+999) 9999 9999 9999" autoClear={false} />
                                <label htmlFor="PhoneTwo">Phone Number 2</label>
                            </span>
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <InputMask id="PhoneThree" name="PhoneThree" className="w-full" value={formik.values.PhoneThree} onChange={(e) => formik.setFieldValue("PhoneThree", e.target.value)} mask="(+999) 9999 9999 9999" autoClear={false} />
                                <label htmlFor="PhoneThree">Phone Number 3</label>
                            </span>
                        </div>
                    </div>
                    <div className="grid my-3">
                        <div className="col-4">
                            <span className="p-float-label">
                                <InputText id="EmergencyContact" name="EmergencyContact" className="w-full" value={formik.values.EmergencyContact} onChange={formik.handleChange} />
                                <label htmlFor="EmergencyContact">Emergency Contact Name</label>
                            </span>
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <InputMask id="EmergencyContact" name="EmergencyContact" className="w-full" value={formik.values.EmergencyNumber} mask="(+999) 9999 9999 9999" onChange={(e) => formik.setFieldValue("EmergencyNumber", e.target.value)} autoClear={false} />
                                <label htmlFor="EmergencyContact">Emergency Contract Phone</label>
                            </span>
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <Dropdown id="EmergencyPref" name="EmergencyPref" className="w-full" value={formik.values.EmergencyPref} options={listSeguros} optionLabel="name" onChange={(e) => formik.setFieldValue("EmergencyPref", e.value)} />
                                <label htmlFor="EmergencyPref">Emergency Preferences</label>
                            </span>
                        </div>
                    </div>
                    <div className="grid my-3">
                        <div className="col-6">
                            <span className="p-float-label">
                                <Dropdown filter editable id="State" name="State" className="w-full" value={formik.values.State} options={listDepartamentos} optionLabel="name" onChange={(e) => formik.setFieldValue("State", e.value)} />
                                <label htmlFor="State">State</label>
                            </span>
                        </div>
                        <div className="col-6">
                            <span className="p-float-label">
                                <Dropdown filter editable id="Municipality" name="Municipality" className="w-full" value={formik.values.Municipality} options={listMunicipios[formik.values.State]} optionLabel="name" onChange={(e) => formik.setFieldValue("Municipality", e.value)} />
                                <label htmlFor="Municipality">Municipality</label>
                            </span>
                        </div>
                    </div>
                    <div className="grid my-3">
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputTextarea id="HomeAdrress" name="HomeAdrress" className="w-full" value={formik.values.HomeAdrress} onChange={formik.handleChange} />
                                <label htmlFor="HomeAdrress">Home Address</label>
                            </span>
                        </div>
                    </div>
                    <div className="grid my-3">
                        <div className="col-12 text-right">
                            <Button severity="primary" size="large">Save</Button>
                        </div>
                    </div>

                </form>
            </Dialog>
        </div>
        
        </>
    )
}

async function initialValues(dataEmpleado){

    return {
        DateBirth: "",
        Gender: "",
        Parent: "",
        NumberChildren: "",
        State: "",
        Municipality: "",
        PersonalEmail: "",
        PhoneOne: "",
        PhoneTwo: "",
        PhoneThree: "",
        EmergencyContact: "",
        EmergencyNumber: "",
        EmergencyPref: "",
        HomeAdrress: "",
    }
}
function validationSchema(){
    return {
        DateBirth: Yup.string(),
        Gender: Yup.string(),
        Parent: Yup.string(),
        NumberChildren: Yup.number(),
        State: Yup.string(),
        Municipality: Yup.string(),
        PersonalEmail: Yup.string(),
        PhoneOne: Yup.string(),
        PhoneTwo: Yup.string(),
        PhoneThree: Yup.string(),
        EmergencyContact: Yup.string(),
        EmergencyNumber: Yup.string(),
        EmergencyPref: Yup.string(),
        HomeAdrress: Yup.string(),
    }
}