import { BASE_API } from '../../utilities/constants';

export async function getDetalleMarcasApi(token) {
    try {
        const url = `${BASE_API}v1/DetalleMarca/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getDetalleMarcaByMarcaFilterApi(token, Badge, Fecha, Pausa = '', FechaMayor = '', FechaMenor = '') {
    try {
        const badgeFilter = `Marca__Empleado__Badge__in=${Badge}`;
        const fechaFilter = `Marca__Fecha=${Fecha}`;
        const pausaFilter = `TipoMarca=${Pausa}`;

        const fechaGteFilter = `Marca__Fecha__gte=${FechaMayor}`;
        const fechaLteFilter = `Marca__Fecha__lte=${FechaMenor}`;

        const url = `${BASE_API}v1/DetalleMarca/?${badgeFilter}&${fechaFilter}&${pausaFilter}&${fechaGteFilter}&${fechaLteFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getDetalleMarcaByFilterApi(token, idMarca) {
    try {
        const idMarcaFilter = `Marca=${idMarca}`;

        const url = `${BASE_API}v1/DetalleMarca/?${idMarcaFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getDetalleMarcaExcepcion(token, idMarca = '', idPausa = '') {
    try {
        const idMarcaFilter = `Marca=${idMarca}`;
        const idPausaFilter = `TipoMarca=${idPausa}`;

        const url = `${BASE_API}v1/DetalleMarca/?${idMarcaFilter}&${idPausaFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addDetalleMarcaApi(data, token) {
    try {
        const url = `${BASE_API}v1/DetalleMarca/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateDetalleMarcaApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/DetalleMarca/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function deleteDetalleMarcaApi(id, token) {
    try {
        const url = `${BASE_API}v1/DetalleMarca/${id}/`;
        const params = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
