import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { size } from 'lodash';
import { FilterHoras } from '../../components/Skytracking';
import { TableHorasPlanilla } from '../../components/Skypanel';
import { useSkytracking } from '../../hooks';

export function HorasPlanillaPage() {
    const { getPlanillaHoras, loadingSkytracking, horasPlanilla, datosConsultadosHoras, setDatosConsultadosHoras } = useSkytracking();

    const [segundosTranscurridos, setSegundosTranscurridos] = useState(0);

    const onResponseForm = async (formData) => {

        const FechaInicio = moment(formData.RangoFechas[0]).format('YYYY-MM-DD');
        const FechaFinal = moment(formData.RangoFechas[1]).format('YYYY-MM-DD');

        await getPlanillaHoras(FechaInicio, FechaFinal, size(formData.Fechas), formData.Cuenta);
    };

    useEffect(() => {
        if (datosConsultadosHoras > 0) {
            const timer = setTimeout(() => {
                setDatosConsultadosHoras((prev) => prev - 1);
                setSegundosTranscurridos((prev) => prev + 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [datosConsultadosHoras]);

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Horas Para Planilla</h5>
                        <p>Selecciona el rango de fecha y se mostraran las horas de planilla con todos los empleados de skycom</p>

                        <FilterHoras
                            requiredFields={{}}
                            showFields={{
                                RangoFechas: true,
                                Cuenta: true
                            }}
                            responseForm={onResponseForm}
                        />
                    </div>
                </div>
            </div>
            {loadingSkytracking ? (
                <div className="grid my-5">
                    <div className="col-12 text-center">
                        <div className="relative">
                            <div className="absolute top-0 left-0">
                                <h6>Tiempo Restante Estimado: {datosConsultadosHoras.toFixed(0)} seg</h6>
                            </div>
                            <div className="absolute top-0 right-0">
                                <h6>Tiempo Transcurrido: {segundosTranscurridos.toFixed(0)} seg</h6>
                            </div>
                        </div>
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <div className="grid my-3">
                    <div className="col-12">
                        <div className="card">
                            <h5>Tabla de Horas Planilla</h5>
                            <p>Aqui se muestra el resultado de la busqueda con fecha inicio y fecha final</p>
                            <TableHorasPlanilla listHours={horasPlanilla} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
