import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export function FormExcepcionReject({ idExcepcion, response, elemento }) {
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            response(formValue.MotivoRechazo, idExcepcion, elemento);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid mt-5 mb-3">
                <div className="col-12">
                    <span className="p-float-label">
                        <InputTextarea className="w-full" cols={30} rows={5} id="MotivoRechazo" name="MotivoRechazo" value={formik.values.MotivoRechazo} onChange={formik.handleChange} />
                        <label htmlFor="MotivoRechazo">Motivo de Rechazo</label>
                    </span>
                </div>
            </div>
            <div className="grid mb-5">
                <div className="col-12 text-right">
                    <Button severity="primary" type="submit" size="large">
                        Rechazar
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        MotivoRechazo: ''
    };
}

function validationSchema() {
    return {
        MotivoRechazo: Yup.string().required(true)
    };
}
