import React, { useState, useRef } from 'react';
import { Toast } from 'primereact/toast'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { SkyFormExcepciones } from '../Skytracking';

export function TableHoursSimply({ Horas }) {

    const toast = useRef()

    const [visible, setVisible] = useState(false);
    const [titleModal, setTitleModal] = useState('Sin Titulo');
    const [contentModal, setContentModal] = useState(null);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">{titleModal}</span>
        </div>
    );

    const onCloseModal = () => {
        setVisible(false)
        toast.current.show({ severity: 'success', summary: 'Correcto', detail: 'Excepcion ingresada correctamente' });
    }

    const calcTrabajadas = () => {
        let total = 0;
        for (let hora of Horas) {
            total += hora.HorasPagar;
        }

        return total.toFixed(2);
    };

    const calcProgramadas = () => {
        let total = 0;
        for (let hora of Horas) {
            total += hora.TotalProgramadas;
        }

        return total.toFixed(2);
    };

    const calcProductive = () => {
        let total = 0;
        for (let hora of Horas) {
            total += hora['2'];
        }

        return total.toFixed(2);
    };
    const calcAvailable = () => {
        let total = 0;
        for (let hora of Horas) {
            total += hora['121'];
        }

        return total.toFixed(2);
    };

    const getCellStyle = (rowData) => {
        if (rowData[6] > 0.5) {
            return { backgroundColor: 'red', color: 'white' };
        }
    };

    const templateBreak = (rowData) => {
        return <div style={getCellStyle(rowData)}>{rowData[6]}</div>;
    };

    const getCellStyleLunch = (rowData) => {
        if (rowData[31] > 1.0) {
            return { backgroundColor: 'red', color: 'white' };
        }
    };

    const templateLunch = (rowData) => {
        return <div style={getCellStyleLunch(rowData)}>{rowData[31]}</div>;
    };

    const getCellStylePersonal = (rowData) => {
        if (rowData[8] > 0.17) {
            return { backgroundColor: 'red', color: 'white' };
        }
    };

    const templatePersonal = (rowData) => {
        return <div style={getCellStylePersonal(rowData)}>{rowData[8]}</div>;
    };

    const templateExcepcion = (rowData) => {
        return (
            <>
                <Button
                    severity="primary"
                    type="button"
                    onClick={() => {
                        setVisible(true);
                        setContentModal(<SkyFormExcepciones formatMarca={rowData} onSwitch={onCloseModal} />);
                        setTitleModal(`Excepciones para: ${rowData.Badge} - ${rowData.Nombre} - ${rowData.Fecha}`);
                    }}
                >
                    Excepcion
                </Button>
            </>
        );
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(Horas);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'HorasCorte');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const headerTable = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
        </div>
    );

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Badge" rowSpan={2} frozen filter />
                <Column header="Nombre" rowSpan={2} frozen filter />
                <Column header="Cuenta" rowSpan={2} frozen filter />
                <Column header="Excepcion" rowSpan={2} frozen filter />
                <Column header="Fecha" rowSpan={2} frozen filter />
                <Column header="Horas Trabajadas" colSpan={3} style={{textAlign: "center"}} />
                <Column header="Horas Programadas" colSpan={3} headerStyle={{textAlign: "center"}} />
                <Column header="Productive Time" rowSpan={2} />
                <Column header="Available Time (Iddle)" rowSpan={2} />
                <Column header="Break" rowSpan={2} />
                <Column header="Lunch" rowSpan={2} />
                <Column header="Personal / Bathroom" rowSpan={2} />
                <Column header="One on One" rowSpan={2} />
                <Column header="Pausa Activa" rowSpan={2} />
                <Column header="QA Feedback" rowSpan={2} />
                <Column header="Training - Skycom" rowSpan={2} />
                <Column header="Training - Client" rowSpan={2} />
                <Column header="System Issue - Internal" rowSpan={2} />
                <Column header="System Issue - External" rowSpan={2} />
                <Column header="Team Huddle" rowSpan={2} />
                <Column header="Project" rowSpan={2} />
                <Column header="Support" rowSpan={2} />
            </Row>
            <Row>
                <Column header="Total" />
                <Column header="Dia" />
                <Column header="Noche" />
                <Column header="Total" />
                <Column header="Dia" />
                <Column header="Noche" />
            </Row>
        </ColumnGroup>
    )

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={5} footerStyle={{ textAlign: 'right', fontSize: '2rem' }} />
                <Column footer={calcTrabajadas} colSpan={3} footerStyle={{ fontSize: '2rem' }} />
                <Column footer={calcProgramadas} colSpan={3} footerStyle={{ fontSize: '2rem' }} />
                <Column footer={calcProductive} footerStyle={{ fontSize: '2rem' }} />
                <Column footer={calcAvailable} footerStyle={{ fontSize: '2rem' }} />
                <Column colSpan={14} footerStyle={{ textAlign: 'right', fontSize: '2rem' }} />
            </Row>
        </ColumnGroup>
    );

    return (
        <>
        <Toast ref={toast} />
            <Dialog
                visible={visible}
                modal
                header={headerElement}
                style={{ width: '70rem' }}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
            >
                {contentModal}
            </Dialog>
            <DataTable
                value={Horas}
                headerColumnGroup={headerGroup}
                footerColumnGroup={footerGroup}
                header={headerTable}
                showGridlines
                scrollable
                tableStyle={{ minWidth: '250rem' }}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
                <Column filter frozen header="Badge" field="Badge"></Column>
                <Column filter frozen header="Nombre" field="Nombre"></Column>
                <Column filter frozen header="Cuenta" field="Cuenta"></Column>
                <Column filter frozen header="Excepcion" body={templateExcepcion}></Column>
                <Column filter frozen header="Fecha" field="Fecha"></Column>
                <Column sortable header="Horas a Pagar" field="HorasPagar"></Column>
                <Column sortable header="Horas a Pagar" field="HorasTrabajadasCrudo_dia"></Column>
                <Column sortable header="Horas a Pagar" field="HorasTrabajadsaCrudo_noche"></Column>
                {/* <Column sortable header="Horas Trabajadas" field="HorasTrabajadas"></Column> */}
                <Column sortable header="Horas Programadas" field="TotalProgramadas"></Column>
                <Column sortable header="Horas Programadas" field="HorasProgramadas"></Column>
                <Column sortable header="Horas Programadas" field="HorasProgramadas_noche"></Column>
                <Column sortable header="Productive Time" field="2"></Column>
                <Column sortable header="Available Time (Iddle)" field="121"></Column>
                {/* <Column header="Day - Login Time - Holiday" field="4"></Column> */}
                <Column header="Break" field="6" body={templateBreak}></Column>
                {/* <Column header="Break 2" field="7"></Column> */}
                <Column header="Lunch" field="31" body={templateLunch}></Column>
                <Column header="Personal / Bathroom" field="8" body={templatePersonal}></Column>
                <Column header="One on One" field="9"></Column>
                <Column header="Pausa Activa" field="18"></Column>
                <Column header="QA Feedback	" field="10"></Column>
                <Column header="Training - Skycom" field="12"></Column>
                <Column header="Training - Client" field="13"></Column>
                <Column header="System Issue - Internal" field="14"></Column>
                <Column header="System Issue - External" field="15"></Column>
                <Column header="Team Huddle	" field="11"></Column>
                <Column header="Project" field="16"></Column>
                <Column header="Support" field="17"></Column>
            </DataTable>
        </>
    );
}
