import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FormHorario } from '../FormHorario';
import { listAcdName } from '../../../utilities/options';

export function HoursTab({ formik }) {
    return (
        <div className="grid mt-5 p-3">
            <div className="col-12">
                <h3>Horario de Empleado</h3>

                <div className="grid mt-5">
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" name="Acd" id="Acd" options={listAcdName} optionLabel="name" value={formik.values.Acd} onChange={(e) => formik.setFieldValue('Acd', e.value)} />
                            <label htmlFor="Acd">ACD</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AcdID" id="AcdID" value={formik.values.AcdID} onChange={formik.handleChange} />
                            <label htmlFor="AcdID">ACDID</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AcdName" id="AcdName" value={formik.values.AcdName} onChange={formik.handleChange} />
                            <label htmlFor="AcdName">ACD Name</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AcdSkill" id="AcdSkill" value={formik.values.AcdSkill} onChange={formik.handleChange} />
                            <label htmlFor="AcdSkill">ACD Skill</label>
                        </span>
                    </div>
                </div>

                <FormHorario formik={formik} />
            </div>
        </div>
    );
}
