import React, { useEffect, useState } from "react"
import {useFormik} from "formik"
import * as Yup from "yup"
import moment from "moment"
import {filter, size} from "lodash"
import {InputText} from "primereact/inputtext"
import {InputSwitch} from "primereact/inputswitch"
import {Button} from "primereact/button"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Dropdown} from "primereact/dropdown"
import { useCommon } from "../../hooks"


export function EditarEmpleadoPage () {

    const [listDetalleEmpleado, setListDetalleEmpleado] = useState(null);

    const {getPropiedades, listPropiedades, getDetalleEmpleado, loadingCommon, getCuenta, listCuentas, getLob, listLob, getPlazas, listPlazas} = useCommon();

    useEffect(() => {
        (async () => {
            await getPropiedades();
            await getCuenta();
            await getLob();
            await getPlazas();
        })()
    }, [])

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {
            const response_detalle_empleado = await getDetalleEmpleado(formValue.Badge, formValue.Week);

            let data_save_detalle_empleado = []

            for await (const emp of response_detalle_empleado){

                const prop_info = filter(listPropiedades, ["value", emp.Propiedad])

                data_save_detalle_empleado.push({
                    Propiedad_id: emp.Propiedad,
                    Propiedad: prop_info[0].name,
                    Valor: emp.Valor,
                    idDetalle: emp.id,
                })
            }

            setListDetalleEmpleado(data_save_detalle_empleado)
        }
    })

    const formikInsert = useFormik({
        initialValues: initialValuesInsert(),
        validationSchema: Yup.object(validationSchemaInsert()),
        onSubmit: async (formValue) => {

        }
    })

    const templateDataProperty = (rowData) => {

        let dato_return = "";

        switch (rowData.Propiedad_id) {
            case 3:
                const filter_cuenta = filter(listCuentas, ["value", parseInt(rowData.Valor)])
                if (size(filter_cuenta) > 0){
                    dato_return = filter_cuenta[0].name;
                }else{
                    dato_return = "Sin Cuenta";
                }
                break;
            case 5:
                const filter_lob = filter(listLob, ["value", parseInt(rowData.Valor)])
                if (size(filter_lob) > 0){
                    dato_return = filter_lob[0].name;
                }else{
                    dato_return = "Sin Lob";
                }
                break;
            case 6:
                const filter_plaza = filter(listPlazas, ["value", parseInt(rowData.Valor)])
                if (size(filter_plaza) > 0){
                    dato_return = filter_plaza[0].name;
                }else{
                    dato_return = "Sin Lob";
                }
                break;
            default:
                dato_return = rowData.Valor;
                break;
        }
        
        return (
            <p>{dato_return}</p>
        )
    }

    const templateInputProperty = () => {

        return (
            <p>Input</p>
        )
    }

    return (
        <>
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Editar Empleado</h5>
                    <p>Reporte para editar empleado Roster</p>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid my-5">
                            <div className="col-3">
                                <span className="p-float-label">
                                    <InputText className="w-full" id="Badge" name="Badge" value={formik.values.Badge} onChange={formik.handleChange} />
                                    <label htmlFor="Badge">Badge</label>
                                </span>
                            </div>
                            <div className="col-3">
                                <span className="p-float-label">
                                    <InputText className="w-full" id="Week" name="Week" value={formik.values.Week} onChange={formik.handleChange} />
                                    <label htmlFor="Week">Week</label>
                                </span>
                            </div>
                            <div className="col-3">
                                <span className="p-float-label">
                                    <InputText className="w-full" id="Year" name="Year" value={formik.values.Year} onChange={formik.handleChange} />
                                    <label htmlFor="Year">Year</label>
                                </span>
                            </div>
                            <div className="col-1">
                                <InputSwitch checked={formik.values.Actualidad} onChange={(e) => formik.setFieldValue("Actualidad", e.value)} />
                            </div>
                            <div className="col-2">
                                <Button type="submit" severity="primary">Consultar</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="grid">
            <div className="col-8">
                {loadingCommon ? (

                    <div className="grid" >
                        <div className="col-12">
                            <i className="pi pi-spinner pi-spin" style={{fontSize: '5rem'}}></i>
                        </div>
                    </div>

                ) : (

                    <div className="card">
                        <h5>Detalle de Propiedades Empleado</h5>
                        <DataTable value={listDetalleEmpleado}>
                            <Column header="Propiedad" field="Propiedad" />
                            <Column header="Valor" body={templateDataProperty} />
                            <Column header="Edit" body={templateInputProperty} />
                        </DataTable>
                    </div>
                )
                }
            </div>
            <div className="col-4">
                <div className="card">
                    <h5>Ingreso de Propiedad</h5>
                    <form>

                        <div className="grid my-5">
                            <div className="col-6">
                                <span className="p-float-label">
                                    <Dropdown id="Propiedad" name="Propiedad" className="w-full" value={listPropiedades} onChange={(e) => formik.setFieldValue("Propiedad", e.value)} optionLabel="name" />
                                    <label htmlFor="Propiedad">Propiedades</label>
                                </span>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
        </>
    )
}

function initialValues(){
    return {
        Badge: "",
        Week: moment().week(),
        Year: moment().format("YYYY"),
        Actualidad: false,
    }
}

function validationSchema(){
    return {
        Badge: Yup.string().required(true),
        Week: Yup.string().required(true),
        Year: Yup.string().required(true),
        Actualidad: Yup.boolean(),
    }
}

function initialValuesInsert (){
    return {
        Empleado: "",
        Propiedad: "",
        Valor: "",
    }
}

function validationSchemaInsert(){
    return {
        Empleado: Yup.string(),
        Propiedad: Yup.string(),
        Valor: Yup.string(),
    }
}