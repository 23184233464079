import React, { useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useAp } from '../../hooks';

export function TableReportAp({ listAp }) {
    const toast = useRef();

    const { rejectAp, approvedAp, ejecutarHoras, ejecutarRoster } = useAp();

    const onAprobarAp = async (ap_data, event) => {
        toast.current.show({
            severity: 'success',
            summary: 'Ap',
            detail: 'Aprobando AP',
            life: 3000
        });

        const padre = event.target.parentNode.parentNode;

        padre.parentNode.removeChild(padre);

        const estado_ap = await approvedAp(ap_data.NumAp);

        if (estado_ap) {
            await ejecutarHoras(ap_data.idTipoAp, ap_data.NumAp, ap_data.Badge);
            await ejecutarRoster(ap_data.NumAp, ap_data.Badge, ap_data.idEmpleado);

            toast.current.show({
                severity: 'success',
                summary: 'Ap',
                detail: 'Ap Aprobada y Ejecutada',
                life: 3000
            });
        } else {
            toast.current.show({
                severity: 'success',
                summary: 'Ap',
                detail: 'Ap Aprobada',
                life: 3000
            });
        }
    };

    const onRechazarAp = async (ap_data, event) => {
        const padre = event.target.parentNode.parentNode;

        padre.parentNode.removeChild(padre);

        await rejectAp(ap_data.NumAp);

        toast.current.show({
            severity: 'warn',
            summary: 'Ap',
            detail: 'Ap Rechazada',
            life: 3000
        });
    };

    const onVerDetalleAp = (ap_data) => {
        window.open(`/#/Skyap/${ap_data.NumAp}`);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="grid">
                    <div className="col-4">
                        <Button type="button" severity="info" aria-label="Rechazar" onClick={(e) => onVerDetalleAp(rowData)}>
                            Ver Detalle
                        </Button>
                    </div>
                    {rowData.showButton && (
                        <>
                            <div className="col-4">
                                <Button type="button" severity="success" aria-label="Aprobar" onClick={(e) => onAprobarAp(rowData, e)}>
                                    Aprobar
                                </Button>
                            </div>
                            <div className="col-4">
                                <Button type="button" severity="danger" aria-label="Rechazar" onClick={(e) => onRechazarAp(rowData, e)}>
                                    Rechazar
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </React.Fragment>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <DataTable
                value={listAp}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
                <Column field="NumAp" header="# Ap" filter />
                <Column field="Badge" header="Badge" filter />
                <Column field="Nombre" header="Nombre" filter />
                <Column field="TipoAp" header="Tipo de Ap" filter />
                <Column body={actionBodyTemplate} exportable={false} header="Acciones"></Column>

                <Column field="SUPERVISOR" header="SUPERVISOR" filter className="text-center" />
                <Column field="ACCOUNT MANAGER" header="ACCOUNT MANAGER" filter className="text-center" />
                <Column field="HR GENERALIST" header="HR GENERALIST" filter className="text-center" />
                <Column field="GERENCIA" header="GERENCIA" filter className="text-center" />
                <Column field="RTA" header="RTA" filter className="text-center" />
            </DataTable>
        </>
    );
}
