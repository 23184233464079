import React from 'react';
import { FormAddEmployee } from '../../components/Skyroster';

export function AddEmployeePage() {
    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Agregar Empleado</h5>
                        <p>Agregar un empleado a Skyverse. Cuando se complete toda la informacion de el formulario con su horario este empleado aparecera dentro de Skyverse Skyrsoter</p>
                    </div>
                </div>
            </div>
            <FormAddEmployee />
        </>
    );
}
