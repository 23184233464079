export const listRosterEstados = [
    { name: 'Pendiente', value: 0 },
    { name: 'Revisado', value: 1 },
    { name: 'Aprobado', value: 2 },
    { name: 'Rechazado', value: -1 }
];

export const listExcepcionesEstados = [
    { name: 'Pendiente', value: 0 },
    { name: 'Aprobada', value: 1 },
    { name: 'Rechazada', value: 2 }
];

export const listEquipoTrabajo = [
    {
        name: 'Equipo Propio',
        value: 'PROPIO'
    },
    {
        name: 'Equipo Skycom',
        value: 'SKYCOM'
    },
    {
        name: 'Equipo del Cliente',
        value: 'CLIENT'
    },
    {
        name: 'Equipo Third Party',
        value: 'THIRDPARTY'
    }
];

export const Sexo = [
    {
        name: 'Masculino',
        value: 'MASCULINO'
    },
    {
        name: 'Femenino',
        value: 'FEMENINO'
    }
];

export const listTieneHijos = [
    {
        name: 'Si',
        value: 'SI'
    },
    {
        name: 'No',
        value: 'NO'
    }
];

export const listIdiomaSam = [
    {
        name: 'Ingles 70%',
        value: 'E70'
    },
    {
        name: 'Ingles 80%',
        value: 'E80'
    },
    {
        name: 'Ingles 90%',
        value: 'E90'
    },
    {
        name: 'Ingles Nativo',
        value: 'EN'
    },
    {
        name: 'Portugués Nativo',
        value: 'PN'
    },
    {
        name: 'Frances Nativo',
        value: 'FN'
    },
    {
        name: 'Español Nativo',
        value: 'SN'
    }
];

export const listIdiomaFiltroSam = [
    {
        name: 'Ingles',
        value: 'US'
    },
    {
        name: 'Portugués',
        value: 'PT'
    },
    {
        name: 'Frances',
        value: 'FR'
    },
    {
        name: 'Español',
        value: 'SV'
    }
];

export const listNivelIdiomaFiltroSam = {
    US: [
        { name: 'Ingles 70%', value: 'E70' },
        { name: 'Ingles 80%', value: 'E80' },
        { name: 'Ingles 90%', value: 'E90' },
        { name: 'Ingles Nativo', value: 'EN' }
    ],
    PT: [{ name: 'Portugués Nativo', value: 'PN' }],
    FR: [{ name: 'Frances Nativo', value: 'FN' }],
    SV: [{ name: 'Español Nativo', value: 'SN' }]
};

export const listEstadosEmpleado = [
    {
        name: 'Activo',
        value: 'ACTIVO'
    },
    {
        name: 'Inactivo',
        value: 'INACTIVO'
    }
];

export const listJobSam = [
    {
        name: 'Customer Services',
        value: 'CUSTOMER SERVICES'
    },
    {
        name: 'Sales',
        value: 'SALES'
    },
    {
        name: 'Healthcare Specialist',
        value: 'HEALTHCARE SPECIALIST'
    },
    {
        name: 'Back Office',
        value: 'BACK OFFICE'
    },
    {
        name: 'IT Technical Services',
        value: 'IT TECHNICAL SERVICES'
    }
];

export const listProfesiones = [
    { name: 'SIN PROFESION', value: 'SIN PROFESION' },
    { name: 'DESEMPLEADO(A)', value: 'DESEMPLEADO(A)' },
    { name: 'ARTE Y ARQUITECTURA', value: 'ARTE Y ARQUITECTURA' },
    { name: 'TEC. EN ARQUITECTURA', value: 'TEC. EN ARQUITECTURA' },
    { name: 'TEC. EN DISEÑO GRAFICO', value: 'TEC. EN DISEÑO GRAFICO' },
    { name: 'LIC.(A) EN ARTES PLASTICAS', value: 'LIC.(A) EN ARTES PLASTICAS' },
    { name: 'LIC.(A) EN DISEÑO AMBIENTAL', value: 'LIC.(A) EN DISEÑO AMBIENTAL' },
    { name: 'LIC.(A) EN DISEÑO ARTESANAL', value: 'LIC.(A) EN DISEÑO ARTESANAL' },
    { name: 'LIC.(A) EN DISEÑO GRAFICO', value: 'LIC.(A) EN DISEÑO GRAFICO' },
    { name: 'ARQUITECTO(A)', value: 'ARQUITECTO(A)' },
    { name: 'DISEÑADOR(A) DE INTERIORES', value: 'DISEÑADOR(A) DE INTERIORES' },
    { name: 'TEC. EN ARQUITECTURA Y CONSTRUCCION', value: 'TEC. EN ARQUITECTURA Y CONSTRUCCION' },
    { name: 'TEC. EN DISEÑO Y CONSTRUCCION', value: 'TEC. EN DISEÑO Y CONSTRUCCION' },
    { name: 'DISEÑADOR GRAFICO', value: 'DISEÑADOR GRAFICO' },
    { name: 'ACTOR', value: 'ACTOR' },
    { name: 'TEC. EN CONSTRUCCION CIVIL', value: 'TEC. EN CONSTRUCCION CIVIL' },
    { name: 'LIC. RELACIONES PUBLIC.Y COMUNICACIONES', value: 'LIC. RELACIONES PUBLIC.Y COMUNICACIONES' },
    { name: 'ACTRIZ', value: 'ACTRIZ' },
    { name: 'DOCTOR EN ARTES', value: 'DOCTOR EN ARTES' },
    { name: 'LIC. EN ARTES', value: 'LIC. EN ARTES' },
    { name: 'DISEÑADOR ARTESANAL', value: 'DISEÑADOR ARTESANAL' },
    { name: 'TEC. EN TECNOLOGIA ARQUITECTONICA', value: 'TEC. EN TECNOLOGIA ARQUITECTONICA' },
    { name: 'PROFESOR(A) DE ARTES', value: 'PROFESOR(A) DE ARTES' },
    { name: 'MAESTRO EN ARTES VISUALES', value: 'MAESTRO EN ARTES VISUALES' },
    { name: 'LIC.(A) EN CIENCIAS Y ARTES', value: 'LIC.(A) EN CIENCIAS Y ARTES' },
    { name: 'ARTES PLASTICAS', value: 'ARTES PLASTICAS' },
    { name: 'ING. EN CONSTRUCCION', value: 'ING. EN CONSTRUCCION' },
    { name: 'LIC.(A) EN HISTORIA DEL ARTE', value: 'LIC.(A) EN HISTORIA DEL ARTE' },
    { name: 'TEC. EN ING. DE LA CONSTRUCCION', value: 'TEC. EN ING. DE LA CONSTRUCCION' },
    { name: 'LIC.(A) EN ARTES Y LETRAS', value: 'LIC.(A) EN ARTES Y LETRAS' },
    { name: 'TEC. EN DISEÑO GRAFICO Y ANIMACION', value: 'TEC. EN DISEÑO GRAFICO Y ANIMACION' },
    { name: 'LICENCIATURA EN ARQUITECTURA', value: 'LICENCIATURA EN ARQUITECTURA' },
    { name: 'LICENCIADO EN CIENCIAS RELIGIOSAS', value: 'LICENCIADO EN CIENCIAS RELIGIOSAS' },
    { name: 'LIC(A) EN ARQUIT. DE INTERIORES', value: 'LIC(A) EN ARQUIT. DE INTERIORES' },
    { name: 'LIC.(A) EN DISEÑO DEL PRODUCTO ARTESANAL', value: 'LIC.(A) EN DISEÑO DEL PRODUCTO ARTESANAL' },
    { name: 'TECNICO EN IDIOMA INGLES', value: 'TECNICO EN IDIOMA INGLES' },
    { name: 'LIC.(A) EN IDIOMA C/ESP/ ENS. DEL INGLES', value: 'LIC.(A) EN IDIOMA C/ESP/ ENS. DEL INGLES' },
    { name: 'LIC. ARTES PLASTICAS OPC.DISEÑO GRAFICO', value: 'LIC. ARTES PLASTICAS OPC.DISEÑO GRAFICO' },
    { name: 'ECONOMIA, ADMON Y COMERCIO', value: 'ECONOMIA, ADMON Y COMERCIO' },
    { name: 'TEC. EN ADMON DE EMPRESAS', value: 'TEC. EN ADMON DE EMPRESAS' },
    { name: 'TEC. EN FINANZAS', value: 'TEC. EN FINANZAS' },
    { name: 'TEC. EN CONTADURIA PUBLICA', value: 'TEC. EN CONTADURIA PUBLICA' },
    { name: 'TEC. EN COMERCIO INTER.', value: 'TEC. EN COMERCIO INTER.' },
    { name: 'TEC. EN MERCADEO', value: 'TEC. EN MERCADEO' },
    { name: 'CONTADOR PUBLICO CERTIFICADO', value: 'CONTADOR PUBLICO CERTIFICADO' },
    { name: 'LIC.(A) EN ADMON BANCARIA', value: 'LIC.(A) EN ADMON BANCARIA' },
    { name: 'LIC.(A) EN ADMON DE EMPRESAS', value: 'LIC.(A) EN ADMON DE EMPRESAS' },
    { name: 'LIC.(A) EN ADMON DE VENTAS', value: 'LIC.(A) EN ADMON DE VENTAS' },
    { name: 'LIC.(A) EN ADMON FINANCIERA', value: 'LIC.(A) EN ADMON FINANCIERA' },
    { name: 'LIC.(A) EN CONTADURIA PUBLICA', value: 'LIC.(A) EN CONTADURIA PUBLICA' },
    { name: 'LIC.(A) EN ECONOMIA', value: 'LIC.(A) EN ECONOMIA' },
    { name: 'LIC.(A) EN MERCADOTECNIA', value: 'LIC.(A) EN MERCADOTECNIA' },
    { name: 'MS. EN MERCADEO', value: 'MS. EN MERCADEO' },
    { name: 'MS. EN ADMON DE EMPRESAS', value: 'MS. EN ADMON DE EMPRESAS' },
    { name: 'MS. EN ADMON FINANCIERA', value: 'MS. EN ADMON FINANCIERA' },
    { name: 'MS. EN ADMON PUBLICA', value: 'MS. EN ADMON PUBLICA' },
    { name: 'LIC.(A) EN ADMINISTRACION AGRICOLA', value: 'LIC.(A) EN ADMINISTRACION AGRICOLA' },
    { name: 'CONTADOR', value: 'CONTADOR' },
    { name: 'LIC.(A) EN MERCADEO', value: 'LIC.(A) EN MERCADEO' },
    { name: 'ECONOMISTA', value: 'ECONOMISTA' },
    { name: 'CONSULTOR EN ECONOMIA', value: 'CONSULTOR EN ECONOMIA' },
    { name: 'ANALISTA FINANCIERO', value: 'ANALISTA FINANCIERO' },
    { name: 'CONSULTOR(A)', value: 'CONSULTOR(A)' },
    { name: 'AUDITOR', value: 'AUDITOR' },
    { name: 'LIC.(A) EN ADMINISTRACION PUBLICA', value: 'LIC.(A) EN ADMINISTRACION PUBLICA' },
    { name: 'LIC.(A) EN COMERCIO INTERNACIONAL', value: 'LIC.(A) EN COMERCIO INTERNACIONAL' },
    { name: 'LIC.(A) EN  MERCADOTECNIA Y PUBLICIDAD', value: 'LIC.(A) EN  MERCADOTECNIA Y PUBLICIDAD' },
    { name: 'TEC. SUPERIOR EN ADMON. PUBLICA', value: 'TEC. SUPERIOR EN ADMON. PUBLICA' },
    { name: 'GERENTE', value: 'GERENTE' },
    { name: 'CONTADOR PUBLICO', value: 'CONTADOR PUBLICO' },
    { name: 'LIC.(A) EN ECONOMIA ADUANERA', value: 'LIC.(A) EN ECONOMIA ADUANERA' },
    { name: 'LIC. EN ECONOMIA Y NEGOCIOS', value: 'LIC. EN ECONOMIA Y NEGOCIOS' },
    { name: 'MASTER EN ADMON. Y DIRECCION DE EMPRESAS', value: 'MASTER EN ADMON. Y DIRECCION DE EMPRESAS' },
    { name: 'LIC. RR.PP Y COMUNICACIONES', value: 'LIC. RR.PP Y COMUNICACIONES' },
    { name: 'TEC. UNIVER. AVANZADO EN MERCADOTECNIA', value: 'TEC. UNIVER. AVANZADO EN MERCADOTECNIA' },
    { name: 'LIC ADMON DE EMPRESAS C/ ESPEC. EN COMPU', value: 'LIC ADMON DE EMPRESAS C/ ESPEC. EN COMPU' },
    { name: 'TEC. ADMINISTRATIVO', value: 'TEC. ADMINISTRATIVO' },
    { name: 'MAESTRIA EN DOCENCIA DE LA EDUC SUPERIOR', value: 'MAESTRIA EN DOCENCIA DE LA EDUC SUPERIOR' },
    { name: 'ADMINISTRADOR TEC. DE EMPRESAS INDUSTRIA', value: 'ADMINISTRADOR TEC. DE EMPRESAS INDUSTRIA' },
    { name: 'TEC. EN ADMON. FINANCIERA', value: 'TEC. EN ADMON. FINANCIERA' },
    { name: 'TEC. EN ADMON. PUBLICA', value: 'TEC. EN ADMON. PUBLICA' },
    { name: 'LIC(A) EN CIENCIAS ECONOMICAS', value: 'LIC(A) EN CIENCIAS ECONOMICAS' },
    { name: 'MS EN ADMON DE RECURSOS HUMANOS', value: 'MS EN ADMON DE RECURSOS HUMANOS' },
    { name: 'DR. EN ECONOMIA Y COMERCIO', value: 'DR. EN ECONOMIA Y COMERCIO' },
    { name: 'DOCUMENTOLOGO', value: 'DOCUMENTOLOGO' },
    { name: 'LIC. EN SISTEMAS DE COMP. ADMINISTRATIVA', value: 'LIC. EN SISTEMAS DE COMP. ADMINISTRATIVA' },
    { name: 'LIC(A) EN MERCADOTECNIA Y PUBLICIDAD', value: 'LIC(A) EN MERCADOTECNIA Y PUBLICIDAD' },
    { name: 'MASTER EN ECONOMIA EMPRESARIAL', value: 'MASTER EN ECONOMIA EMPRESARIAL' },
    { name: 'TEC. EN ANALISIS FINANCIERO', value: 'TEC. EN ANALISIS FINANCIERO' },
    { name: 'PERITO VALUADOR', value: 'PERITO VALUADOR' },
    { name: 'TECNICO FINANCIERO', value: 'TECNICO FINANCIERO' },
    { name: 'TECNICO EN CIENCIAS COMERCIALES', value: 'TECNICO EN CIENCIAS COMERCIALES' },
    { name: 'TEC. EN COMP. ADMINISTRATIVA EMPRESARIAL', value: 'TEC. EN COMP. ADMINISTRATIVA EMPRESARIAL' },
    { name: 'LIC.(A) EN FINANZAS', value: 'LIC.(A) EN FINANZAS' },
    { name: 'LIC.(A) EN NEGOCIOS INTERNACIONALES', value: 'LIC.(A) EN NEGOCIOS INTERNACIONALES' },
    { name: 'FINANCISTA', value: 'FINANCISTA' },
    { name: 'LIC.(A) EN CONTADURIA', value: 'LIC.(A) EN CONTADURIA' },
    { name: 'LIC.(A) EN ADMON. DE AGRONEGOCIOS', value: 'LIC.(A) EN ADMON. DE AGRONEGOCIOS' },
    { name: 'LIC.(A) EN HUMANIDADES CLASICAS', value: 'LIC.(A) EN HUMANIDADES CLASICAS' },
    { name: 'ASESOR DE EMPRESAS', value: 'ASESOR DE EMPRESAS' },
    { name: 'ESTUDIANTE', value: 'ESTUDIANTE' },
    { name: 'CONTADOR PUBLICO Y AUDITOR', value: 'CONTADOR PUBLICO Y AUDITOR' },
    { name: 'MAESTRIA EN DIRECCION DE EMPRESAS', value: 'MAESTRIA EN DIRECCION DE EMPRESAS' },
    { name: 'BANQUERO', value: 'BANQUERO' },
    { name: 'TEC. EN COMERCIO Y ADMINISTRACION', value: 'TEC. EN COMERCIO Y ADMINISTRACION' },
    { name: 'LIC.(A) EN DESARROLLO INTERNACIONAL', value: 'LIC.(A) EN DESARROLLO INTERNACIONAL' },
    { name: 'ING.(A) EN AGRONEGOCIOS', value: 'ING.(A) EN AGRONEGOCIOS' },
    { name: 'LIC.(A) EN MERCADEO Y PUBLICIDAD', value: 'LIC.(A) EN MERCADEO Y PUBLICIDAD' },
    { name: 'TEC. EN COMPUTACION ADMINISTRATIVA', value: 'TEC. EN COMPUTACION ADMINISTRATIVA' },
    { name: 'LIC. EN PUBLICIDAD Y RELACIONES PUBLICAS', value: 'LIC. EN PUBLICIDAD Y RELACIONES PUBLICAS' },
    { name: 'TEC. EN MICROEMPRESA', value: 'TEC. EN MICROEMPRESA' },
    { name: 'EJECUTIVO DE NEGOCIOS', value: 'EJECUTIVO DE NEGOCIOS' },
    { name: 'MASTER EN ECONOMIA', value: 'MASTER EN ECONOMIA' },
    { name: 'MAESTRIA EN ADMON. DE EMPRESAS', value: 'MAESTRIA EN ADMON. DE EMPRESAS' },
    { name: 'BACHILLER TEC. COMERCIAL', value: 'BACHILLER TEC. COMERCIAL' },
    { name: 'MAESTRIA EN FINANZAS', value: 'MAESTRIA EN FINANZAS' },
    { name: 'BACHILLER TEC. VOCACIONAL COMERCIAL', value: 'BACHILLER TEC. VOCACIONAL COMERCIAL' },
    { name: 'MAESTRIA EN NEGOCIOS INTERNACIONALES', value: 'MAESTRIA EN NEGOCIOS INTERNACIONALES' },
    { name: 'MAESTRIA EN CONSULTORIA EMPRESARIAL', value: 'MAESTRIA EN CONSULTORIA EMPRESARIAL' },
    { name: 'LIC.(A) EN ADMON DE EMPRESAS TURISTICAS', value: 'LIC.(A) EN ADMON DE EMPRESAS TURISTICAS' },
    { name: 'MS. EN DIRECCION ESTRATEGICA DE EMPRESAS', value: 'MS. EN DIRECCION ESTRATEGICA DE EMPRESAS' },
    { name: 'LIC.(A)  PEDAGOGIA Y CIENCIAS DE LA EDUC', value: 'LIC.(A)  PEDAGOGIA Y CIENCIAS DE LA EDUC' },
    { name: 'TECNICA EN MERCADEO Y VENTAS', value: 'TECNICA EN MERCADEO Y VENTAS' },
    { name: 'BACH TEC INDUSTRIAL OPC MEC AUTO', value: 'BACH TEC INDUSTRIAL OPC MEC AUTO' },
    { name: 'SALUD', value: 'SALUD' },
    { name: 'TEC. EN ENFERMERIA ', value: 'TEC. EN ENFERMERIA ' },
    { name: 'TEC. EN LABORATORIO CLINICO', value: 'TEC. EN LABORATORIO CLINICO' },
    { name: 'TEC. EN OPTOMETRIA', value: 'TEC. EN OPTOMETRIA' },
    { name: 'TEC. EN TRABAJO SOCIAL', value: 'TEC. EN TRABAJO SOCIAL' },
    { name: 'TECNOLOGO(A) EN ANESTESIOLOGIA', value: 'TECNOLOGO(A) EN ANESTESIOLOGIA' },
    { name: 'TECNOLOGO(A) EN ENFERMERIA', value: 'TECNOLOGO(A) EN ENFERMERIA' },
    { name: 'TECNOLOGO(A) EN FISIOTERAPIA', value: 'TECNOLOGO(A) EN FISIOTERAPIA' },
    { name: 'LIC.(A) EN ANESTESIOLOGIA', value: 'LIC.(A) EN ANESTESIOLOGIA' },
    { name: 'LIC.(A) EN ECOTECNOLOGIA', value: 'LIC.(A) EN ECOTECNOLOGIA' },
    { name: 'LIC.(A) EN ENFERMERIA', value: 'LIC.(A) EN ENFERMERIA' },
    { name: 'LIC.(A) EN FISIOTERAPIA', value: 'LIC.(A) EN FISIOTERAPIA' },
    { name: 'LIC.(A) EN LABORATORIO CLINICO', value: 'LIC.(A) EN LABORATORIO CLINICO' },
    { name: 'LIC.(A) EN NUTRICION', value: 'LIC.(A) EN NUTRICION' },
    { name: 'LIC.(A) EN PSICOLOGIA', value: 'LIC.(A) EN PSICOLOGIA' },
    { name: 'LIC.(A) EN RADIOLOGIA', value: 'LIC.(A) EN RADIOLOGIA' },
    { name: 'LIC.(A) EN SALUD', value: 'LIC.(A) EN SALUD' },
    { name: 'LIC.(A) EN TRABAJO SOCIAL', value: 'LIC.(A) EN TRABAJO SOCIAL' },
    { name: 'LIC.(A) EN EDUC. ESPECIAL', value: 'LIC.(A) EN EDUC. ESPECIAL' },
    { name: 'MS. EN SALUD PUBLICA', value: 'MS. EN SALUD PUBLICA' },
    { name: 'DR.(A) EN CIRUGIA DENTAL', value: 'DR.(A) EN CIRUGIA DENTAL' },
    { name: 'DR.(A) EN MEDICINA', value: 'DR.(A) EN MEDICINA' },
    { name: 'TECNICO EN EQUIPO DE RAYOS X', value: 'TECNICO EN EQUIPO DE RAYOS X' },
    { name: 'DOC.(A) EN QUIMICA Y FARMACIA', value: 'DOC.(A) EN QUIMICA Y FARMACIA' },
    { name: 'NUTRICIONISTA', value: 'NUTRICIONISTA' },
    { name: 'PEDIATRA', value: 'PEDIATRA' },
    { name: 'TEC. EN LABORATORIO QUIMICO', value: 'TEC. EN LABORATORIO QUIMICO' },
    { name: 'PROFESIONAL DE TRABAJO SOCIAL', value: 'PROFESIONAL DE TRABAJO SOCIAL' },
    { name: 'TECNOLOGO(A) MATERNO INFANTIL', value: 'TECNOLOGO(A) MATERNO INFANTIL' },
    { name: 'TEC. INSTRUMENTAL MEDICO QUIRURJICO', value: 'TEC. INSTRUMENTAL MEDICO QUIRURJICO' },
    { name: 'KINESIOLOGO', value: 'KINESIOLOGO' },
    { name: 'OPTOMETRISTA', value: 'OPTOMETRISTA' },
    { name: 'OFTALMOLOGO(A)', value: 'OFTALMOLOGO(A)' },
    { name: 'LIC.(A) EN RADIOLOGIA E IMAGENES', value: 'LIC.(A) EN RADIOLOGIA E IMAGENES' },
    { name: 'ODONTOLOGO(A)', value: 'ODONTOLOGO(A)' },
    { name: 'CITOTECNOLOGO', value: 'CITOTECNOLOGO' },
    { name: 'LIC.(A) EN FISIOTERAPIA Y TERAP. OCUPAC.', value: 'LIC.(A) EN FISIOTERAPIA Y TERAP. OCUPAC.' },
    { name: 'LICENCIATURA EN SALUD MATERNO INFANTIL', value: 'LICENCIATURA EN SALUD MATERNO INFANTIL' },
    { name: 'TEC. EN RADIOLOGIA', value: 'TEC. EN RADIOLOGIA' },
    { name: 'TECNOLOGO(A) EN  TERAPIA OCUPACIONAL', value: 'TECNOLOGO(A) EN  TERAPIA OCUPACIONAL' },
    { name: 'PSICOLOGO(A)', value: 'PSICOLOGO(A)' },
    { name: 'LIC. EN DIETOLOGIA Y NUTRICION', value: 'LIC. EN DIETOLOGIA Y NUTRICION' },
    { name: 'MEDICO CIRUJANO', value: 'MEDICO CIRUJANO' },
    { name: 'DOCTOR(A) EN PSICOLOGIA', value: 'DOCTOR(A) EN PSICOLOGIA' },
    { name: 'FISIOTERAPISTA', value: 'FISIOTERAPISTA' },
    { name: 'TECNOLOGO MEDICO ANESTESISTA', value: 'TECNOLOGO MEDICO ANESTESISTA' },
    { name: 'LIC.(A EN ANESTESIOLOGIA E INHALOTERAPIA', value: 'LIC.(A EN ANESTESIOLOGIA E INHALOTERAPIA' },
    { name: 'TECNICO EN ESTERILIZACION', value: 'TECNICO EN ESTERILIZACION' },
    { name: 'TEC. PRACTICO EN AUDIOMETRIA', value: 'TEC. PRACTICO EN AUDIOMETRIA' },
    { name: 'MEDICO ANESTESIOLOGO', value: 'MEDICO ANESTESIOLOGO' },
    { name: 'MEDICO NATURISTA', value: 'MEDICO NATURISTA' },
    { name: 'REGENTE DE FARMACIA', value: 'REGENTE DE FARMACIA' },
    { name: 'TEC. EN ELECTROCARDIOGRAMA', value: 'TEC. EN ELECTROCARDIOGRAMA' },
    { name: 'ESTOMATOLOGO', value: 'ESTOMATOLOGO' },
    { name: 'TECNOLOGO MEDICO', value: 'TECNOLOGO MEDICO' },
    { name: 'ING. BIOQUIMICO', value: 'ING. BIOQUIMICO' },
    { name: 'TERAPISTA', value: 'TERAPISTA' },
    { name: 'ANESTESISTA', value: 'ANESTESISTA' },
    { name: 'LIC. EN NUTRICION Y DIETETICA', value: 'LIC. EN NUTRICION Y DIETETICA' },
    { name: 'TECNICO EN ENFERMERIA E HIGIENE DENTAL', value: 'TECNICO EN ENFERMERIA E HIGIENE DENTAL' },
    { name: 'DOCTOR (A) EN MICROBIOLOGIA', value: 'DOCTOR (A) EN MICROBIOLOGIA' },
    { name: 'LABORATORISTA CLINICO', value: 'LABORATORISTA CLINICO' },
    { name: 'TEC. EN ELECTROCARDIOGRAFIA', value: 'TEC. EN ELECTROCARDIOGRAFIA' },
    { name: 'MAESTRIA EN PSICOLOGIA CLINICA', value: 'MAESTRIA EN PSICOLOGIA CLINICA' },
    { name: 'LIC. EN OPTOMETRIA', value: 'LIC. EN OPTOMETRIA' },
    { name: 'MEDICO GINECO-OBSTETRICIA', value: 'MEDICO GINECO-OBSTETRICIA' },
    { name: 'DOCTOR(A) EN AGRICULTURA', value: 'DOCTOR(A) EN AGRICULTURA' },
    { name: 'TEC. AUDIOMETRISTA', value: 'TEC. AUDIOMETRISTA' },
    { name: 'TECNOLOGO(A) EN PUERICULTURA', value: 'TECNOLOGO(A) EN PUERICULTURA' },
    { name: 'TEC. EN ANATOMIA PATOLOGICA', value: 'TEC. EN ANATOMIA PATOLOGICA' },
    { name: 'TEC. EN CARDIOLOGIA', value: 'TEC. EN CARDIOLOGIA' },
    { name: 'INSEMINADOR ARTIFICIAL', value: 'INSEMINADOR ARTIFICIAL' },
    { name: 'TECNOLOGO(A) MEDICO EN LAB. CLINICO', value: 'TECNOLOGO(A) MEDICO EN LAB. CLINICO' },
    { name: 'DR.(A) EN PSICOLOGIA CLINICA', value: 'DR.(A) EN PSICOLOGIA CLINICA' },
    { name: 'ENFERMERA DENTAL', value: 'ENFERMERA DENTAL' },
    { name: 'DERMATOLOGO', value: 'DERMATOLOGO' },
    { name: 'DR.(A) CON POSTGDO EN MEDICINA BIOLOGICA', value: 'DR.(A) CON POSTGDO EN MEDICINA BIOLOGICA' },
    { name: 'PARASITOLOGO MEDICO', value: 'PARASITOLOGO MEDICO' },
    { name: 'MEDICO IRIDOLOGO', value: 'MEDICO IRIDOLOGO' },
    { name: 'LIC. EN BIOLOGIA Y QUIMICA', value: 'LIC. EN BIOLOGIA Y QUIMICA' },
    { name: 'LIC. EN ADMINISTRACION DE SALUD', value: 'LIC. EN ADMINISTRACION DE SALUD' },
    { name: 'TEC. EN SALUD - HIGIENE DENTAL', value: 'TEC. EN SALUD - HIGIENE DENTAL' },
    { name: 'TEC. EN PSICOLOGIA', value: 'TEC. EN PSICOLOGIA' },
    { name: 'TECNOLOGO MEDICO EN FISIOTERAPIA', value: 'TECNOLOGO MEDICO EN FISIOTERAPIA' },
    { name: 'MEDICO RADIOLOGO', value: 'MEDICO RADIOLOGO' },
    { name: 'UROLOGO', value: 'UROLOGO' },
    { name: 'TECNICO EN RAYOS X', value: 'TECNICO EN RAYOS X' },
    { name: 'TECNOLOGO(A) EN SALUD MATERNO INFANTIL', value: 'TECNOLOGO(A) EN SALUD MATERNO INFANTIL' },
    { name: 'DR(A). EN MEDICINA Y CIRUGIA', value: 'DR(A). EN MEDICINA Y CIRUGIA' },
    { name: 'DOCTORADO EN AUDIOLOGIA', value: 'DOCTORADO EN AUDIOLOGIA' },
    { name: 'LIC.(A) EN PSICOLOGIA CLINICA', value: 'LIC.(A) EN PSICOLOGIA CLINICA' },
    { name: 'MEDICO GASTROENTEROLOGO (A)', value: 'MEDICO GASTROENTEROLOGO (A)' },
    { name: 'NEUROCIRUJANO', value: 'NEUROCIRUJANO' },
    { name: 'DR.(A) EN CIRUGIA DENT/ESP. ODONT PEDIAT', value: 'DR.(A) EN CIRUGIA DENT/ESP. ODONT PEDIAT' },
    { name: 'MS. EN ADMON. DE SERVICIOS DE SALUD', value: 'MS. EN ADMON. DE SERVICIOS DE SALUD' },
    { name: 'TECNOLOGO(A) EN RADIOLOGIA E IMAGENES', value: 'TECNOLOGO(A) EN RADIOLOGIA E IMAGENES' },
    { name: 'LIC(A). EN EDUC. FUN. BIOLOGIA Y QUIMICA', value: 'LIC(A). EN EDUC. FUN. BIOLOGIA Y QUIMICA' },
    { name: 'MAESTRA EN PSICOLOGIA ORGANIZACIONAL', value: 'MAESTRA EN PSICOLOGIA ORGANIZACIONAL' },
    { name: 'LIC(A).EN EDUC.ESP.EN BIOLOGIA Y QUIMICA', value: 'LIC(A).EN EDUC.ESP.EN BIOLOGIA Y QUIMICA' },
    { name: 'TECNOLOGO EN LABORATORIO CLINICO', value: 'TECNOLOGO EN LABORATORIO CLINICO' },
    { name: 'MAESTRO EN PSICOLOGIA ORGANIZACIONAL', value: 'MAESTRO EN PSICOLOGIA ORGANIZACIONAL' },
    { name: 'MAESTRO (A) EN GESTION AMBIENTAL', value: 'MAESTRO (A) EN GESTION AMBIENTAL' },
    { name: 'MAESTRA EN DOCENCIA UNIVERSITARIA', value: 'MAESTRA EN DOCENCIA UNIVERSITARIA' },
    { name: 'TECNICO EN QUIMICA INDUSTRIAL', value: 'TECNICO EN QUIMICA INDUSTRIAL' },
    { name: 'LIC. EN TEC. DE LA SALUD: TRAUMATOLOGIA', value: 'LIC. EN TEC. DE LA SALUD: TRAUMATOLOGIA' },
    { name: 'CIENCIAS', value: 'CIENCIAS' },
    { name: 'LIC.(A) EN BIOLOGIA', value: 'LIC.(A) EN BIOLOGIA' },
    { name: 'LIC.(A) EN BIOLOGIA MARINA', value: 'LIC.(A) EN BIOLOGIA MARINA' },
    { name: 'LIC.(A) EN ESTADISTICA', value: 'LIC.(A) EN ESTADISTICA' },
    { name: 'LIC.(A) EN FISICA', value: 'LIC.(A) EN FISICA' },
    { name: 'LIC.(A) EN MATEMATICAS', value: 'LIC.(A) EN MATEMATICAS' },
    { name: 'LIC.(A) EN QUIMICA', value: 'LIC.(A) EN QUIMICA' },
    { name: 'LIC.(A) EN QUIMICA Y FARMACIA', value: 'LIC.(A) EN QUIMICA Y FARMACIA' },
    { name: 'LIC.(A) EN GEOQUIMICA', value: 'LIC.(A) EN GEOQUIMICA' },
    { name: 'ING.(A) QUIMICO(A)', value: 'ING.(A) QUIMICO(A)' },
    { name: 'MS. EN QUIMICA', value: 'MS. EN QUIMICA' },
    { name: 'LIC.(A) EN QUIMICA AGRICOLA', value: 'LIC.(A) EN QUIMICA AGRICOLA' },
    { name: 'ESTADISTICO', value: 'ESTADISTICO' },
    { name: 'LIC. EN QUIMICA BIOLOGICA', value: 'LIC. EN QUIMICA BIOLOGICA' },
    { name: 'BIOLOGO', value: 'BIOLOGO' },
    { name: 'ING. QUIMICO AGRICOLA', value: 'ING. QUIMICO AGRICOLA' },
    { name: 'PARAPSICOLOGIA', value: 'PARAPSICOLOGIA' },
    { name: 'METEOROLOGO', value: 'METEOROLOGO' },
    { name: 'QUIMICO FARMACEUTICO', value: 'QUIMICO FARMACEUTICO' },
    { name: 'LICENCIADO EN METEREOLOGIA', value: 'LICENCIADO EN METEREOLOGIA' },
    { name: 'LIC.(A) EN MATEMATICA', value: 'LIC.(A) EN MATEMATICA' },
    { name: 'LIC.(A) EN GEOFISICA', value: 'LIC.(A) EN GEOFISICA' },
    { name: 'MEDICO ESPECIALISTA', value: 'MEDICO ESPECIALISTA' },
    { name: 'ORTOPEDA Y TRAUMATOLOGIA', value: 'ORTOPEDA Y TRAUMATOLOGIA' },
    { name: 'ING. FISICO', value: 'ING. FISICO' },
    { name: 'LIC.(A) EN CIENCIAS', value: 'LIC.(A) EN CIENCIAS' },
    { name: 'DR(A) EN QUIMICA Y FARMACIA', value: 'DR(A) EN QUIMICA Y FARMACIA' },
    { name: 'LIC.(A)EN EDUC.PARA LA ENS.DE LA QUIMICA', value: 'LIC.(A)EN EDUC.PARA LA ENS.DE LA QUIMICA' },
    { name: 'GEOQUIMICO', value: 'GEOQUIMICO' },
    { name: 'QUIMICO MINERO', value: 'QUIMICO MINERO' },
    { name: 'DIETISTA', value: 'DIETISTA' },
    { name: 'TEC. EN ARSENAL QUIRURGICO', value: 'TEC. EN ARSENAL QUIRURGICO' },
    { name: 'DOCTORADO EN DEMOGRAFIA', value: 'DOCTORADO EN DEMOGRAFIA' },
    { name: 'DR.(A) EN QUIMICA INDUSTRIAL', value: 'DR.(A) EN QUIMICA INDUSTRIAL' },
    { name: 'LABORATORISTA', value: 'LABORATORISTA' },
    { name: 'QUIMICO TEXTIL', value: 'QUIMICO TEXTIL' },
    { name: 'DR.(A) EN SALUD PUBLICA', value: 'DR.(A) EN SALUD PUBLICA' },
    { name: 'IRIDOLOGA', value: 'IRIDOLOGA' },
    { name: 'TEC. EN MANTTO. Y REP. DE EQ. BIOMEDICO', value: 'TEC. EN MANTTO. Y REP. DE EQ. BIOMEDICO' },
    { name: 'DR. EN QUIMICA INDUSTRIAL Y FARMACIA', value: 'DR. EN QUIMICA INDUSTRIAL Y FARMACIA' },
    { name: 'LIC.(A) CONTADOR PUBLICO Y AUDITORIA', value: 'LIC.(A) CONTADOR PUBLICO Y AUDITORIA' },
    { name: 'OTORRINOLARINGOLOGO', value: 'OTORRINOLARINGOLOGO' },
    { name: 'DR.(A) EN QUIMICA', value: 'DR.(A) EN QUIMICA' },
    { name: 'MEDICO', value: 'MEDICO' },
    { name: 'ING. OCEANICO', value: 'ING. OCEANICO' },
    { name: 'OCEANOLOGO', value: 'OCEANOLOGO' },
    { name: 'LIC. EN CIENCIAS QUIMICAS', value: 'LIC. EN CIENCIAS QUIMICAS' },
    { name: 'PSIQUIATRA', value: 'PSIQUIATRA' },
    { name: 'AUXILIAR EN ODONTOLOGIA', value: 'AUXILIAR EN ODONTOLOGIA' },
    { name: 'FISICO', value: 'FISICO' },
    { name: 'DR. EN METALURGIA Y CC. DE LOS MAT.', value: 'DR. EN METALURGIA Y CC. DE LOS MAT.' },
    { name: 'PALEONTOLOGO(A)', value: 'PALEONTOLOGO(A)' },
    { name: 'METEOROLOGO(A)', value: 'METEOROLOGO(A)' },
    { name: 'LIC.(A) EN QUIMICA Y FARMACIA BIOLOGIA', value: 'LIC.(A) EN QUIMICA Y FARMACIA BIOLOGIA' },
    { name: 'TEC. EN ING. BIOMEDICA', value: 'TEC. EN ING. BIOMEDICA' },
    { name: 'LIC. EN ORTESIS Y PROTESIS', value: 'LIC. EN ORTESIS Y PROTESIS' },
    { name: 'AGRICULT/GANAD/PISCICULT/MEDIO AMB', value: 'AGRICULT/GANAD/PISCICULT/MEDIO AMB' },
    { name: 'TEC. EN AGRONOMIA', value: 'TEC. EN AGRONOMIA' },
    { name: 'TEC. EN CONSERVACION DEL MEDIO AMBIENTE', value: 'TEC. EN CONSERVACION DEL MEDIO AMBIENTE' },
    { name: 'TEC. EN VETERINARIA', value: 'TEC. EN VETERINARIA' },
    { name: 'TEC. EN INGENIERIA AGRICOLA', value: 'TEC. EN INGENIERIA AGRICOLA' },
    { name: 'TEC. EN ZOOLOGIA', value: 'TEC. EN ZOOLOGIA' },
    { name: 'LIC.(A) EN VETERINARIA', value: 'LIC.(A) EN VETERINARIA' },
    { name: 'ING.(A) AGRONOMO', value: 'ING.(A) AGRONOMO' },
    { name: 'ING.(A) EN AGROECOLOGIA', value: 'ING.(A) EN AGROECOLOGIA' },
    { name: 'ING.(A) EN ENTOMOLOGIA', value: 'ING.(A) EN ENTOMOLOGIA' },
    { name: 'ING.(A) AGROINDUSTRIAL', value: 'ING.(A) AGROINDUSTRIAL' },
    { name: 'MS. EN MEDIO AMB. Y REC. NAT', value: 'MS. EN MEDIO AMB. Y REC. NAT' },
    { name: 'LIC.(A) EN GEOGRAFIA', value: 'LIC.(A) EN GEOGRAFIA' },
    { name: 'TEC. EN METEREOLOGIA', value: 'TEC. EN METEREOLOGIA' },
    { name: 'ING.(A) EN PESCA', value: 'ING.(A) EN PESCA' },
    { name: 'AGRONOMO', value: 'AGRONOMO' },
    { name: 'MEDICO VETERINARIO', value: 'MEDICO VETERINARIO' },
    { name: 'TEC. EN PRODUCCION AGROPECUARIA', value: 'TEC. EN PRODUCCION AGROPECUARIA' },
    { name: 'TEC. AGRICOLA', value: 'TEC. AGRICOLA' },
    { name: 'TEC. GEOGRAFO', value: 'TEC. GEOGRAFO' },
    { name: 'MEDICO VETERINARIO Y ZOOTECNISTA', value: 'MEDICO VETERINARIO Y ZOOTECNISTA' },
    { name: 'TEC. METEOROLOGO', value: 'TEC. METEOROLOGO' },
    { name: 'MAESTRIA EN  CIENCIAS AGROPECUARIAS', value: 'MAESTRIA EN  CIENCIAS AGROPECUARIAS' },
    { name: 'TEC. ADMINISTRADOR AGROPECUARIO', value: 'TEC. ADMINISTRADOR AGROPECUARIO' },
    { name: 'TEC. EN ING. AGROINDUSTRIAL', value: 'TEC. EN ING. AGROINDUSTRIAL' },
    { name: 'TEC. COMPUTACION', value: 'TEC. COMPUTACION' },
    { name: 'ECOLOGO', value: 'ECOLOGO' },
    { name: 'ESPECIALISTA FORESTAL', value: 'ESPECIALISTA FORESTAL' },
    { name: 'ZOOTECNISTA', value: 'ZOOTECNISTA' },
    { name: 'TEC. EN INGENIERIA AGRONOMICA', value: 'TEC. EN INGENIERIA AGRONOMICA' },
    { name: 'LIC(A) EN TECNOLOGIA AGROINDUSTRIAL', value: 'LIC(A) EN TECNOLOGIA AGROINDUSTRIAL' },
    { name: 'SOCIOLOGO(A) SANITARISTA', value: 'SOCIOLOGO(A) SANITARISTA' },
    { name: 'MS ADMON. Y COMERC. AGROPECUARIA', value: 'MS ADMON. Y COMERC. AGROPECUARIA' },
    { name: 'VETERINARIO', value: 'VETERINARIO' },
    { name: 'ECONOMISTA AMBIENTAL', value: 'ECONOMISTA AMBIENTAL' },
    { name: 'INGENIERO AGROECOLOGO(A)', value: 'INGENIERO AGROECOLOGO(A)' },
    { name: 'LIC(A) ZOOTECNISTA', value: 'LIC(A) ZOOTECNISTA' },
    { name: 'TEC. AGROPECUARIO', value: 'TEC. AGROPECUARIO' },
    { name: 'MASTER EN CIENCIAS AGRICOLAS', value: 'MASTER EN CIENCIAS AGRICOLAS' },
    { name: 'AUXILIAR DE VETERINARIO', value: 'AUXILIAR DE VETERINARIO' },
    { name: 'TEC. EN TECNOLGIA  Y MEDIO AMBIENTE', value: 'TEC. EN TECNOLGIA  Y MEDIO AMBIENTE' },
    { name: 'DASONOMO', value: 'DASONOMO' },
    { name: 'ING. AGRONOMO FITOTECNISTA', value: 'ING. AGRONOMO FITOTECNISTA' },
    { name: 'AGROPECUARIO(A)', value: 'AGROPECUARIO(A)' },
    { name: 'HORTICULTOR', value: 'HORTICULTOR' },
    { name: 'TEC. AVICOLA', value: 'TEC. AVICOLA' },
    { name: 'TECNICO FORESTAL', value: 'TECNICO FORESTAL' },
    { name: 'METEOROLOGO', value: 'METEOROLOGO' },
    { name: 'PERITO AGRONOMO', value: 'PERITO AGRONOMO' },
    { name: 'TEC. MEDIO AGROPECUARIO', value: 'TEC. MEDIO AGROPECUARIO' },
    { name: 'ING.(A) AGRICOLA', value: 'ING.(A) AGRICOLA' },
    { name: 'LIC.(A) EN ECOLOGIA', value: 'LIC.(A) EN ECOLOGIA' },
    { name: 'AGRONOMO VETERINARIO', value: 'AGRONOMO VETERINARIO' },
    { name: 'TEC. EN REDES Y TECNOLOGIAS INFORMATICAS', value: 'TEC. EN REDES Y TECNOLOGIAS INFORMATICAS' },
    { name: 'TEC. AGRONOMO', value: 'TEC. AGRONOMO' },
    { name: 'CONSULTOR AMBIENTAL Y RURAL', value: 'CONSULTOR AMBIENTAL Y RURAL' },
    { name: 'FRUTICULTOR', value: 'FRUTICULTOR' },
    { name: 'ING.AGRONOMO GENERALISTA', value: 'ING.AGRONOMO GENERALISTA' },
    { name: 'TEC EN MEDICINA VETERINARIA Y ZOOTECNIA', value: 'TEC EN MEDICINA VETERINARIA Y ZOOTECNIA' },
    { name: 'ING. EN DES. SOCIOECONOMICO Y AMBIENTE', value: 'ING. EN DES. SOCIOECONOMICO Y AMBIENTE' },
    { name: 'LIC.(A) EN MEDIC VETERINARIA Y ZOOTECNIA', value: 'LIC.(A) EN MEDIC VETERINARIA Y ZOOTECNIA' },
    { name: 'MAESTRIA EN AGRICULTURA SOSTENIBLE', value: 'MAESTRIA EN AGRICULTURA SOSTENIBLE' },
    { name: 'TEC.USO Y MANEJO DE PLANTAS MEDICINALES', value: 'TEC.USO Y MANEJO DE PLANTAS MEDICINALES' },
    { name: 'LIC.(A) EN MEDICINA VETERINARIA', value: 'LIC.(A) EN MEDICINA VETERINARIA' },
    { name: 'MS. SEG Y GESTION DE RIESGOS INFORM.', value: 'MS. SEG Y GESTION DE RIESGOS INFORM.' },
    { name: 'ESTUDIANTE', value: 'ESTUDIANTE' },
    { name: 'DERECHO', value: 'DERECHO' },
    { name: 'LIC.(A) EN CIENCIAS JURIDICAS', value: 'LIC.(A) EN CIENCIAS JURIDICAS' },
    { name: 'LIC.(A) EN RELACIONES INTERNACIONALES', value: 'LIC.(A) EN RELACIONES INTERNACIONALES' },
    { name: 'DR.(A) JURISPRUDENCIA Y C.C. SOCIALES', value: 'DR.(A) JURISPRUDENCIA Y C.C. SOCIALES' },
    { name: 'ABOGADO(A)', value: 'ABOGADO(A)' },
    { name: 'ABOGADO(A) Y NOTARIO(A)', value: 'ABOGADO(A) Y NOTARIO(A)' },
    { name: 'FISCAL', value: 'FISCAL' },
    { name: 'FISCAL AUXILIAR', value: 'FISCAL AUXILIAR' },
    { name: 'DEFENSOR PUBLICO', value: 'DEFENSOR PUBLICO' },
    { name: 'LIC. EN DERECHO CANONICO', value: 'LIC. EN DERECHO CANONICO' },
    { name: 'COLABORADOR JUDICIAL', value: 'COLABORADOR JUDICIAL' },
    { name: 'ESPECIALISTA EN DERECHOS HUMANOS', value: 'ESPECIALISTA EN DERECHOS HUMANOS' },
    { name: 'DR. EN DERECHO INTERNACIONAL', value: 'DR. EN DERECHO INTERNACIONAL' },
    { name: 'MASTER EN DERECHOS HUMANOS', value: 'MASTER EN DERECHOS HUMANOS' },
    { name: 'LIC.(A)EN DERECHO', value: 'LIC.(A)EN DERECHO' },
    { name: 'MAESTRO EN DERECHO PENAL', value: 'MAESTRO EN DERECHO PENAL' },
    { name: 'ASESOR', value: 'ASESOR' },
    { name: 'MAESTRE EN DERECHO INT. PUBLICO', value: 'MAESTRE EN DERECHO INT. PUBLICO' },
    { name: 'DR(A) EN CC. POLITICAS Y SOC.', value: 'DR(A) EN CC. POLITICAS Y SOC.' },
    { name: 'DOCTOR EN DERECHO PRIVADO', value: 'DOCTOR EN DERECHO PRIVADO' },
    { name: 'HUMANIDADES', value: 'HUMANIDADES' },
    { name: 'TEC. EN ARTES LIBERALES', value: 'TEC. EN ARTES LIBERALES' },
    { name: 'TEC. EN BIBLIOTECOLOGIA', value: 'TEC. EN BIBLIOTECOLOGIA' },
    { name: 'TEC. EN DEMOGRAFIA', value: 'TEC. EN DEMOGRAFIA' },
    { name: 'LIC.(A) EN ARQUEOLOGIA', value: 'LIC.(A) EN ARQUEOLOGIA' },
    { name: 'LIC.(A) EN FILOSOFIA', value: 'LIC.(A) EN FILOSOFIA' },
    { name: 'LIC.(A) EN IDIOMAS', value: 'LIC.(A) EN IDIOMAS' },
    { name: 'LIC.(A) EN LETRAS', value: 'LIC.(A) EN LETRAS' },
    { name: 'LIC.(A) EN TEOLOGIA', value: 'LIC.(A) EN TEOLOGIA' },
    { name: 'MS. EN TEOLOGIA', value: 'MS. EN TEOLOGIA' },
    { name: 'DR.(A) EN FILOSOFIA', value: 'DR.(A) EN FILOSOFIA' },
    { name: 'LIC.(A) EN GEOLOGIA Y FISICA', value: 'LIC.(A) EN GEOLOGIA Y FISICA' },
    { name: 'ANTROPOLOGO(A)', value: 'ANTROPOLOGO(A)' },
    { name: 'TECNICO EN IDIOMAS', value: 'TECNICO EN IDIOMAS' },
    { name: 'LIC. (A) EN TRADUCCION E INTERPRETACION', value: 'LIC. (A) EN TRADUCCION E INTERPRETACION' },
    { name: 'LIC. IDIOMA INGLES', value: 'LIC. IDIOMA INGLES' },
    { name: 'LIC. EN EDUC. C/FUND. EN MAT. Y FISICA', value: 'LIC. EN EDUC. C/FUND. EN MAT. Y FISICA' },
    { name: 'ING. GEOLOGO', value: 'ING. GEOLOGO' },
    { name: 'LIC. EN HUMANIDADES', value: 'LIC. EN HUMANIDADES' },
    { name: 'PROFESORADO EN IDIOMAS', value: 'PROFESORADO EN IDIOMAS' },
    { name: 'TEC. HISTOPATOLOGICO', value: 'TEC. HISTOPATOLOGICO' },
    { name: 'LIC. EN DEMOGRAFIA', value: 'LIC. EN DEMOGRAFIA' },
    { name: 'INVESTIGADOR ARQUEOLOGICO', value: 'INVESTIGADOR ARQUEOLOGICO' },
    { name: 'DOCTOR EN SOCIOLOGIA', value: 'DOCTOR EN SOCIOLOGIA' },
    { name: 'ARQUEOLOGO', value: 'ARQUEOLOGO' },
    { name: 'FILOSOFO (A)', value: 'FILOSOFO (A)' },
    { name: 'LIC.(A) EN BIBLIOTECOLOGIA', value: 'LIC.(A) EN BIBLIOTECOLOGIA' },
    { name: 'LIC.(A) EN ANTROPOLOGIA', value: 'LIC.(A) EN ANTROPOLOGIA' },
    { name: 'TECNICO EN IDIOMA INGLES', value: 'TECNICO EN IDIOMA INGLES' },
    { name: 'TEC. OPERADOR EN WINDOWS', value: 'TEC. OPERADOR EN WINDOWS' },
    { name: 'LIC.(A) EN ANTROPOLOGIA SOCIOCULTURAL', value: 'LIC.(A) EN ANTROPOLOGIA SOCIOCULTURAL' },
    { name: 'TEOLOGO(A)', value: 'TEOLOGO(A)' },
    { name: 'TECNOLOGIA', value: 'TECNOLOGIA' },
    { name: 'TEC. AGROINDUSTRIAL', value: 'TEC. AGROINDUSTRIAL' },
    { name: 'TEC. AUTOMOTRIZ', value: 'TEC. AUTOMOTRIZ' },
    { name: 'TEC. EN BIOMEDICA', value: 'TEC. EN BIOMEDICA' },
    { name: 'TEC. EN COMPUTACION', value: 'TEC. EN COMPUTACION' },
    { name: 'TEC. EN CONFECCION INDUSTRIAL', value: 'TEC. EN CONFECCION INDUSTRIAL' },
    { name: 'TEC. EN ELECTRICIDAD', value: 'TEC. EN ELECTRICIDAD' },
    { name: 'TEC. EN ELECTRONICA', value: 'TEC. EN ELECTRONICA' },
    { name: 'TEC. EN INGENIERIA CIVIL', value: 'TEC. EN INGENIERIA CIVIL' },
    { name: 'TEC. EN MECANICA', value: 'TEC. EN MECANICA' },
    { name: 'TEC. EN ORTESIS Y PROTESIS', value: 'TEC. EN ORTESIS Y PROTESIS' },
    { name: 'TEC. EN PROCESAMIENTO DE ALIMENTOS', value: 'TEC. EN PROCESAMIENTO DE ALIMENTOS' },
    { name: 'TEC. EN PRODUCCION DE RADIO Y TV', value: 'TEC. EN PRODUCCION DE RADIO Y TV' },
    { name: 'TEC. INDUSTRIAL', value: 'TEC. INDUSTRIAL' },
    { name: 'TEC. EN TOPOGRAFIA', value: 'TEC. EN TOPOGRAFIA' },
    { name: 'TEC. EN ARQUITECTURA', value: 'TEC. EN ARQUITECTURA' },
    { name: 'TEC. EN CONSTRUCCION', value: 'TEC. EN CONSTRUCCION' },
    { name: 'TEC. EN DISEÑO', value: 'TEC. EN DISEÑO' },
    { name: 'TEC. EN INGENIERIA ', value: 'TEC. EN INGENIERIA ' },
    { name: 'TEC. EN RADIO Y TELEVISION', value: 'TEC. EN RADIO Y TELEVISION' },
    { name: 'TEC. ANALISTA EN PROG DE SISTEMAS', value: 'TEC. ANALISTA EN PROG DE SISTEMAS' },
    { name: 'LIC.(A) EN COMPUTACION', value: 'LIC.(A) EN COMPUTACION' },
    { name: 'LIC.(A) EN RELACIONES INDUSTRIALES', value: 'LIC.(A) EN RELACIONES INDUSTRIALES' },
    { name: 'ING.(A) BIOMEDICO (A)', value: 'ING.(A) BIOMEDICO (A)' },
    { name: 'ING.(A) CIVIL', value: 'ING.(A) CIVIL' },
    { name: 'ING.(A) EN ALIMENTOS', value: 'ING.(A) EN ALIMENTOS' },
    { name: 'ING.(A) ELECTRICO', value: 'ING.(A) ELECTRICO' },
    { name: 'ING.(A) EN ELECTRONICA', value: 'ING.(A) EN ELECTRONICA' },
    { name: 'ING.(A) EN COMPUTACION', value: 'ING.(A) EN COMPUTACION' },
    { name: 'ING.(A) INDUSTRIAL', value: 'ING.(A) INDUSTRIAL' },
    { name: 'ING.(A) MECANICO', value: 'ING.(A) MECANICO' },
    { name: 'ING.(A) CONSTRUCTOR NAVAL', value: 'ING.(A) CONSTRUCTOR NAVAL' },
    { name: 'ING.(A) HIDROGRAFICO', value: 'ING.(A) HIDROGRAFICO' },
    { name: 'ING.(A) TEXTIL', value: 'ING.(A) TEXTIL' },
    { name: 'TEC. EN INGENIERIA MECANICA', value: 'TEC. EN INGENIERIA MECANICA' },
    { name: 'TEC. EN DESARROLLO RURAL', value: 'TEC. EN DESARROLLO RURAL' },
    { name: 'TEC. EN INGENIERIA ELECTRICA', value: 'TEC. EN INGENIERIA ELECTRICA' },
    { name: 'TEC. EN INGENIERIA ELECTRONICA', value: 'TEC. EN INGENIERIA ELECTRONICA' },
    { name: 'ING.(A) METALURGICO', value: 'ING.(A) METALURGICO' },
    { name: 'DOC.(A) EN INGENIERIA INDUSTRIAL', value: 'DOC.(A) EN INGENIERIA INDUSTRIAL' },
    { name: 'LIC. EN TECNOLOGIA DE ALIMENTOS', value: 'LIC. EN TECNOLOGIA DE ALIMENTOS' },
    { name: 'TEC. EN INGENIERIA INDUSTRIAL', value: 'TEC. EN INGENIERIA INDUSTRIAL' },
    { name: 'TOPOGRAFO', value: 'TOPOGRAFO' },
    { name: 'ING.(A) ZOOTECNISTA', value: 'ING.(A) ZOOTECNISTA' },
    { name: 'ING.(A) ELECTRICISTA', value: 'ING.(A) ELECTRICISTA' },
    { name: 'TEC. EN ING. EN SISTEMAS COMPUTACIONALES', value: 'TEC. EN ING. EN SISTEMAS COMPUTACIONALES' },
    { name: 'ING.(A) EN SISTEMAS', value: 'ING.(A) EN SISTEMAS' },
    { name: 'ING.(A) EN TELECOMUNICACIONES', value: 'ING.(A) EN TELECOMUNICACIONES' },
    { name: 'TEC. EN ELECTRONICA Y BIOMEDICA', value: 'TEC. EN ELECTRONICA Y BIOMEDICA' },
    { name: 'INGENIERO(A)', value: 'INGENIERO(A)' },
    { name: 'TEC. EN TELEFONIA', value: 'TEC. EN TELEFONIA' },
    { name: 'TECNICO TEXTIL', value: 'TECNICO TEXTIL' },
    { name: 'TEC. ADMON MUNICIPAL', value: 'TEC. ADMON MUNICIPAL' },
    { name: 'TEC. EN AUDIOLOGIA', value: 'TEC. EN AUDIOLOGIA' },
    { name: 'TEC. EN ING. DE REF. Y AIRE ACOND.', value: 'TEC. EN ING. DE REF. Y AIRE ACOND.' },
    { name: 'TEC. EN ING. AUTOMOTRIZ', value: 'TEC. EN ING. AUTOMOTRIZ' },
    { name: 'ING. EN CIENCIAS DE LA COMPUTACION', value: 'ING. EN CIENCIAS DE LA COMPUTACION' },
    { name: 'TECNOLOGO(A) INDUSTRIAL', value: 'TECNOLOGO(A) INDUSTRIAL' },
    { name: 'LIC.(A) EN CRIMINOLOGIA', value: 'LIC.(A) EN CRIMINOLOGIA' },
    { name: 'ING. DE SISTEMAS INFORMATICOS', value: 'ING. DE SISTEMAS INFORMATICOS' },
    { name: 'TEC. EN ING. DE LA CONFECCION INDUSTRIAL', value: 'TEC. EN ING. DE LA CONFECCION INDUSTRIAL' },
    { name: 'ING. EN SISTEMAS Y COMPUTACION', value: 'ING. EN SISTEMAS Y COMPUTACION' },
    { name: 'TECNICO', value: 'TECNICO' },
    { name: 'TEC. EN PRODUCTIVIDAD INDUSTRIAL', value: 'TEC. EN PRODUCTIVIDAD INDUSTRIAL' },
    { name: 'LIC. EN COMPUTACION ADMTIVA EMPRESARIAL', value: 'LIC. EN COMPUTACION ADMTIVA EMPRESARIAL' },
    { name: 'TEC. EN INGENIERIA ELECTROMECANICA', value: 'TEC. EN INGENIERIA ELECTROMECANICA' },
    { name: 'DOCTOR EN INFORMATICA', value: 'DOCTOR EN INFORMATICA' },
    { name: 'INGENIERO FORESTAL', value: 'INGENIERO FORESTAL' },
    { name: 'TEC. EN SISTEMAS DE COMPUTACION', value: 'TEC. EN SISTEMAS DE COMPUTACION' },
    { name: 'TEC. EN ING. CIVIL Y CONSTRUCCION', value: 'TEC. EN ING. CIVIL Y CONSTRUCCION' },
    { name: 'TEC. EN ING. TOPOGRAFICA', value: 'TEC. EN ING. TOPOGRAFICA' },
    { name: 'LIC. EN INFORMATICA', value: 'LIC. EN INFORMATICA' },
    { name: 'ING. MECANICO INDUSTRIAL', value: 'ING. MECANICO INDUSTRIAL' },
    { name: 'TEC. EN MAQUINA INDUSTRIAL', value: 'TEC. EN MAQUINA INDUSTRIAL' },
    { name: 'TEC. EN INGENIERIA EN COMPUTACION', value: 'TEC. EN INGENIERIA EN COMPUTACION' },
    { name: 'TEC. EN ING. EN COMPUTACION', value: 'TEC. EN ING. EN COMPUTACION' },
    { name: 'TEC. ELECTROMECANICO', value: 'TEC. ELECTROMECANICO' },
    { name: 'TEC. SUPERIOR EN ING. CIVIL', value: 'TEC. SUPERIOR EN ING. CIVIL' },
    { name: 'GERENCIA INDUSTRIAL', value: 'GERENCIA INDUSTRIAL' },
    { name: 'LIC.(A) EN CIENCIAS DE LA COMPUTACION', value: 'LIC.(A) EN CIENCIAS DE LA COMPUTACION' },
    { name: 'TEC. INGENIERIA DE CONTROL DE CALIDAD', value: 'TEC. INGENIERIA DE CONTROL DE CALIDAD' },
    { name: 'ING. ELECTRICO INDUSTRIAL', value: 'ING. ELECTRICO INDUSTRIAL' },
    { name: 'TEC.PROGRAMADOR ANALISTA', value: 'TEC.PROGRAMADOR ANALISTA' },
    { name: 'AUXILIAR DE TOPOGRAFIA', value: 'AUXILIAR DE TOPOGRAFIA' },
    { name: 'DOCTOR EN INGENIERIA', value: 'DOCTOR EN INGENIERIA' },
    { name: 'ING. AUTOMOTRIZ', value: 'ING. AUTOMOTRIZ' },
    { name: 'TECNICO EN INFORMATICA', value: 'TECNICO EN INFORMATICA' },
    { name: 'TEC EN MECANIZACION AGRICOLA INDUSTRIAL', value: 'TEC EN MECANIZACION AGRICOLA INDUSTRIAL' },
    { name: 'INGENIERIA GENETICA', value: 'INGENIERIA GENETICA' },
    { name: 'TEC. VALUADOR', value: 'TEC. VALUADOR' },
    { name: 'ING. EN COMUNICACIONES Y ELECTRONICA', value: 'ING. EN COMUNICACIONES Y ELECTRONICA' },
    { name: 'TECNICO EN CONTADURIA', value: 'TECNICO EN CONTADURIA' },
    { name: 'TEC. EN MANTENIMIENTO INDUSTRIAL', value: 'TEC. EN MANTENIMIENTO INDUSTRIAL' },
    { name: 'INGENIERO DE PRODUCCION', value: 'INGENIERO DE PRODUCCION' },
    { name: 'INGENIERO(A) AGRONOMO ZOOTECNISTA', value: 'INGENIERO(A) AGRONOMO ZOOTECNISTA' },
    { name: 'TEC. EN ELECTRONICA DIGITAL', value: 'TEC. EN ELECTRONICA DIGITAL' },
    { name: 'TEC. EN PROGRAMACION DE COMPUTADORAS', value: 'TEC. EN PROGRAMACION DE COMPUTADORAS' },
    { name: 'INGENIERO(A) ELECTRICISTA', value: 'INGENIERO(A) ELECTRICISTA' },
    { name: 'ING.(A) AGRONOMO EN DESARROLLO RURAL', value: 'ING.(A) AGRONOMO EN DESARROLLO RURAL' },
    { name: 'ELECTROMECANICO INDUSTRIAL', value: 'ELECTROMECANICO INDUSTRIAL' },
    { name: 'TEC. EN ARTES GRAFICAS', value: 'TEC. EN ARTES GRAFICAS' },
    { name: 'TEC. EN ALIMENTOS', value: 'TEC. EN ALIMENTOS' },
    { name: 'TEC. EN SISTEMAS HIDRAULICOS', value: 'TEC. EN SISTEMAS HIDRAULICOS' },
    { name: 'TEC. EN INGENIERIA TEXTIL', value: 'TEC. EN INGENIERIA TEXTIL' },
    { name: 'TEC. EN SIST. INFORMATICOS ADMINIS.', value: 'TEC. EN SIST. INFORMATICOS ADMINIS.' },
    { name: 'TEC. ELECTRICISTA', value: 'TEC. ELECTRICISTA' },
    { name: 'TEC. OPERADOR DE COMPUTADORAS', value: 'TEC. OPERADOR DE COMPUTADORAS' },
    { name: 'TEC. EN ING. MECANICA Y ELECTRICA', value: 'TEC. EN ING. MECANICA Y ELECTRICA' },
    { name: 'TEC. SISMOLOGO', value: 'TEC. SISMOLOGO' },
    { name: 'ING. EN RADIO-COMUNICACIONES', value: 'ING. EN RADIO-COMUNICACIONES' },
    { name: 'TEC. EN ADMON. DE EMPRESAS', value: 'TEC. EN ADMON. DE EMPRESAS' },
    { name: 'TEC. EN INGENIERIA DE CONTROL', value: 'TEC. EN INGENIERIA DE CONTROL' },
    { name: 'TEC. AVANZADO EN ING. TOPOGRAFICA', value: 'TEC. AVANZADO EN ING. TOPOGRAFICA' },
    { name: 'ING.(A)  AGRONOMO FITOTECNISTA', value: 'ING.(A)  AGRONOMO FITOTECNISTA' },
    { name: 'ING.(A) INDUSTRIAL Y SISTEMAS', value: 'ING.(A) INDUSTRIAL Y SISTEMAS' },
    { name: 'TEC. EN RADIO-COMUNICACIONES', value: 'TEC. EN RADIO-COMUNICACIONES' },
    { name: 'TEC. EN ARSENAL Y CENTRAL DE EQUIPOS', value: 'TEC. EN ARSENAL Y CENTRAL DE EQUIPOS' },
    { name: 'TEC. EN CONTROL DE CALIDAD', value: 'TEC. EN CONTROL DE CALIDAD' },
    { name: 'TEC. EN MANTENIMIENTO', value: 'TEC. EN MANTENIMIENTO' },
    { name: 'ING.(A) ELECTRO-MECANICO', value: 'ING.(A) ELECTRO-MECANICO' },
    { name: 'TEC. SUB-MARINISTA', value: 'TEC. SUB-MARINISTA' },
    { name: 'TEC. EN EQUIPO DE OFICINA', value: 'TEC. EN EQUIPO DE OFICINA' },
    { name: 'ING. TEC. MECANICO', value: 'ING. TEC. MECANICO' },
    { name: 'TEC. ING. DE SISTEMAS Y REDES INFORMAT.', value: 'TEC. ING. DE SISTEMAS Y REDES INFORMAT.' },
    { name: 'ING.(A) AGRIMENSOR', value: 'ING.(A) AGRIMENSOR' },
    { name: 'ING. TERMO-ENERGETICO', value: 'ING. TERMO-ENERGETICO' },
    { name: 'LIC. EN ESTADISTICA Y COMPUTACION', value: 'LIC. EN ESTADISTICA Y COMPUTACION' },
    { name: 'MAESTRIA EN MANEJO SUST. DE LOS RRNN.', value: 'MAESTRIA EN MANEJO SUST. DE LOS RRNN.' },
    { name: 'TEC. SUPERIOR EN ADMON INDUSTRIAL', value: 'TEC. SUPERIOR EN ADMON INDUSTRIAL' },
    { name: 'TEC. EN MECANICA AUTOMOTRIZ', value: 'TEC. EN MECANICA AUTOMOTRIZ' },
    { name: 'LIC. EN ADMON DE SISTEMAS INFORMATICOS', value: 'LIC. EN ADMON DE SISTEMAS INFORMATICOS' },
    { name: 'TECNICO OPERADOR Y PROGRAMADOR ANALISTA', value: 'TECNICO OPERADOR Y PROGRAMADOR ANALISTA' },
    { name: 'TEC. EN ING. DE LAS COMUN. ELECTRONICAS', value: 'TEC. EN ING. DE LAS COMUN. ELECTRONICAS' },
    { name: 'ING.EN AUTOMATIZACION', value: 'ING.EN AUTOMATIZACION' },
    { name: 'ING.(A) DE ALIMENTOS', value: 'ING.(A) DE ALIMENTOS' },
    { name: 'ING.(A) EN SISTEMAS COMPUTACIONALES', value: 'ING.(A) EN SISTEMAS COMPUTACIONALES' },
    { name: 'TEC. EN SOFTWARE ADMINISTRATIVO', value: 'TEC. EN SOFTWARE ADMINISTRATIVO' },
    { name: 'TEC ADMON Y OPERACION PORTUARIA', value: 'TEC ADMON Y OPERACION PORTUARIA' },
    { name: 'TEC. EN ADMON DE EMPRESAS GASTRONOMICAS', value: 'TEC. EN ADMON DE EMPRESAS GASTRONOMICAS' },
    { name: 'TEC ING ELECTRONICA OPC MTTO Y SERV COMP', value: 'TEC ING ELECTRONICA OPC MTTO Y SERV COMP' },
    { name: 'TEC. EN ING. DE REDES INFORMATICAS', value: 'TEC. EN ING. DE REDES INFORMATICAS' },
    { name: 'ING(A) EN ELECTRONICA Y COMUNICACIONES', value: 'ING(A) EN ELECTRONICA Y COMUNICACIONES' },
    { name: 'TEC. EN INGENIERIA DE HARDWARE', value: 'TEC. EN INGENIERIA DE HARDWARE' },
    { name: 'ING.(A) AGROECONOMISTA', value: 'ING.(A) AGROECONOMISTA' },
    { name: 'TEC. OPERADOR SOFTWARE', value: 'TEC. OPERADOR SOFTWARE' },
    { name: 'TECNICO EN CIENCIAS DE LA COMPUTACION', value: 'TECNICO EN CIENCIAS DE LA COMPUTACION' },
    { name: 'TEC. EN FOTOGRAFIA', value: 'TEC. EN FOTOGRAFIA' },
    { name: 'LIC. EN ORTESIS Y PROTESIS', value: 'LIC. EN ORTESIS Y PROTESIS' },
    { name: 'TEC. EN MANTENIMIENTO  AERONAUTICO.', value: 'TEC. EN MANTENIMIENTO  AERONAUTICO.' },
    { name: 'TEC. EN AUDITORIA', value: 'TEC. EN AUDITORIA' },
    { name: 'LIC(A) EN INGENIERIA CIVIL', value: 'LIC(A) EN INGENIERIA CIVIL' },
    { name: 'LIC(A) PERIODISMO Y COMUNIC. AUDIOVISUAL', value: 'LIC(A) PERIODISMO Y COMUNIC. AUDIOVISUAL' },
    { name: 'TECNICO EN INGENIERIA MECATRONICA', value: 'TECNICO EN INGENIERIA MECATRONICA' },
    { name: 'TEC. SUP. GESTION Y DESARROLLO TURISTICO', value: 'TEC. SUP. GESTION Y DESARROLLO TURISTICO' },
    { name: 'ING.(A) MECATRONICA', value: 'ING.(A) MECATRONICA' },
    { name: 'LIC.(A) EN DISEÑO INDUSTRIAL Y DE PRODUC', value: 'LIC.(A) EN DISEÑO INDUSTRIAL Y DE PRODUC' },
    { name: 'TEC. SUPERIOR EN ACUICULTURA', value: 'TEC. SUPERIOR EN ACUICULTURA' },
    { name: 'TEC. EN MERCADEO Y VENTAS', value: 'TEC. EN MERCADEO Y VENTAS' },
    { name: 'TEC. EN INGENIERIA EN MEC. AUTOMOTRIZ', value: 'TEC. EN INGENIERIA EN MEC. AUTOMOTRIZ' },
    { name: 'TECNICO EN RADIO Y TV DIGITAL A COLOR', value: 'TECNICO EN RADIO Y TV DIGITAL A COLOR' },
    { name: 'TEC. EN PRODUCTIVIDAD Y CALIDAD', value: 'TEC. EN PRODUCTIVIDAD Y CALIDAD' },
    { name: 'TEC EN DESARROLLO LOCAL Y GESTION MUNIC.', value: 'TEC EN DESARROLLO LOCAL Y GESTION MUNIC.' },
    { name: 'RESIDENTE DE ASILO', value: 'RESIDENTE DE ASILO' },
    { name: 'EDUC.', value: 'EDUC.' },
    { name: 'PROF.(A) EN ADMON DE LA EDUCACION', value: 'PROF.(A) EN ADMON DE LA EDUCACION' },
    { name: 'PROF.(A) EN BIOLOGIA Y QUIMICA', value: 'PROF.(A) EN BIOLOGIA Y QUIMICA' },
    { name: 'PROF.(A) EN CIENCIAS COMERCIALES', value: 'PROF.(A) EN CIENCIAS COMERCIALES' },
    { name: 'PROF.(A) EN CIENCIAS NATURALES', value: 'PROF.(A) EN CIENCIAS NATURALES' },
    { name: 'PROF.(A) EN CIENCIAS SOCIALES', value: 'PROF.(A) EN CIENCIAS SOCIALES' },
    { name: 'PROF.(A) EN COMPUTACION', value: 'PROF.(A) EN COMPUTACION' },
    { name: 'PROF.(A) EN EDUC. BASICA 1º Y 2º CICLO', value: 'PROF.(A) EN EDUC. BASICA 1º Y 2º CICLO' },
    { name: 'PROF.(A) EN EDUC. ESPECIAL', value: 'PROF.(A) EN EDUC. ESPECIAL' },
    { name: 'PROF.(A) EN EDUC. FISICA', value: 'PROF.(A) EN EDUC. FISICA' },
    { name: 'PROF.(A) EN EDUC. MEDIA', value: 'PROF.(A) EN EDUC. MEDIA' },
    { name: 'PROF.(A) EN FISICA Y MATEMATICA', value: 'PROF.(A) EN FISICA Y MATEMATICA' },
    { name: 'PROF.(A) EN INGLES', value: 'PROF.(A) EN INGLES' },
    { name: 'PROF.(A) EN LENGUAJE Y LITERATURA', value: 'PROF.(A) EN LENGUAJE Y LITERATURA' },
    { name: 'PROF.(A) EN LETRAS Y ESTETICA', value: 'PROF.(A) EN LETRAS Y ESTETICA' },
    { name: 'PROF.(A) EN EDUCACION PARVULARIA', value: 'PROF.(A) EN EDUCACION PARVULARIA' },
    { name: 'PROF.(A) EN TEOLOGIA', value: 'PROF.(A) EN TEOLOGIA' },
    { name: 'LIC.(A) EN CIENCIAS DE LA EDUC.', value: 'LIC.(A) EN CIENCIAS DE LA EDUC.' },
    { name: 'LIC.(A) EN EDUC. FISICA Y DEPORTES', value: 'LIC.(A) EN EDUC. FISICA Y DEPORTES' },
    { name: 'LIC.(A) EN INGLES', value: 'LIC.(A) EN INGLES' },
    { name: 'LIC.(A) EN EDUC. ESPECIAL', value: 'LIC.(A) EN EDUC. ESPECIAL' },
    { name: 'LIC.(A) EN EDUC. PARVULARIA', value: 'LIC.(A) EN EDUC. PARVULARIA' },
    { name: 'MS. EN ADMON. DE CURRICULO', value: 'MS. EN ADMON. DE CURRICULO' },
    { name: 'MS. EN EDUC. UNIVERSITARIA', value: 'MS. EN EDUC. UNIVERSITARIA' },
    { name: 'PROF.(A) EN QUIMICA', value: 'PROF.(A) EN QUIMICA' },
    { name: 'PROF.(A) EN LETRAS', value: 'PROF.(A) EN LETRAS' },
    { name: 'PROF.(A) EN MUSICA', value: 'PROF.(A) EN MUSICA' },
    { name: 'PROF.(A) EN RELIGION', value: 'PROF.(A) EN RELIGION' },
    { name: 'CATEDRATICO', value: 'CATEDRATICO' },
    { name: 'PROFESOR(A)', value: 'PROFESOR(A)' },
    { name: 'LIC. EN EDUCACION EN POBLACION', value: 'LIC. EN EDUCACION EN POBLACION' },
    { name: 'MS. EN ADMON. Y DOCENCIA UNIVERSITARIA', value: 'MS. EN ADMON. Y DOCENCIA UNIVERSITARIA' },
    { name: 'LIC.(A) EN EDUCACION', value: 'LIC.(A) EN EDUCACION' },
    { name: 'LIC.(A) EN EDUCACION PARA LA SALUD', value: 'LIC.(A) EN EDUCACION PARA LA SALUD' },
    { name: 'PROF.(A) EN BIOLOGIA', value: 'PROF.(A) EN BIOLOGIA' },
    { name: 'LIC.(A) EN EDUCACION DE ADULTOS', value: 'LIC.(A) EN EDUCACION DE ADULTOS' },
    { name: 'PROF.(A) EN CIENCIAS DE LA EDUCACION', value: 'PROF.(A) EN CIENCIAS DE LA EDUCACION' },
    { name: 'ASESOR EDUCATIVO', value: 'ASESOR EDUCATIVO' },
    { name: 'MASTER EN RECURSOS HUMANOS', value: 'MASTER EN RECURSOS HUMANOS' },
    { name: 'DIRECTOR DE CENTRO DE DESARROLLO', value: 'DIRECTOR DE CENTRO DE DESARROLLO' },
    { name: 'DIRECTOR(A)', value: 'DIRECTOR(A)' },
    { name: 'DOCTOR(A) EN CIENCIAS DE LA EDUCACION', value: 'DOCTOR(A) EN CIENCIAS DE LA EDUCACION' },
    { name: 'PROFESOR(A) EN EDUCACION  BASICA', value: 'PROFESOR(A) EN EDUCACION  BASICA' },
    { name: 'LIC. EN CIENCIAS DE LA EDUCACION', value: 'LIC. EN CIENCIAS DE LA EDUCACION' },
    { name: 'PEDAGOGO', value: 'PEDAGOGO' },
    { name: 'DOCTORADO EN QUIMICA BIOLOGICA', value: 'DOCTORADO EN QUIMICA BIOLOGICA' },
    { name: 'DOCTOR EN QUIMICA BIOLOGICA', value: 'DOCTOR EN QUIMICA BIOLOGICA' },
    { name: 'MASTER EN CIENCIAS DE LA MATE. APLICADA', value: 'MASTER EN CIENCIAS DE LA MATE. APLICADA' },
    { name: 'ALFABETIZADOR(A)', value: 'ALFABETIZADOR(A)' },
    { name: 'LIC.(A) EN ADMNISTRACION DE EDUCACION', value: 'LIC.(A) EN ADMNISTRACION DE EDUCACION' },
    { name: 'PROFESOR TECNICO', value: 'PROFESOR TECNICO' },
    { name: 'STENOGRAFA', value: 'STENOGRAFA' },
    { name: 'LIC. EN CC. DE LA EDUC. ESP.  INGLES', value: 'LIC. EN CC. DE LA EDUC. ESP.  INGLES' },
    { name: 'TECNICO EN RECURSOS HUMANOS', value: 'TECNICO EN RECURSOS HUMANOS' },
    { name: 'RECTOR (A)', value: 'RECTOR (A)' },
    { name: 'SUB-DIRECTOR', value: 'SUB-DIRECTOR' },
    { name: 'PROMOTOR CULTURAL', value: 'PROMOTOR CULTURAL' },
    { name: 'LIC. EN ORIENTACION PROFESIONAL', value: 'LIC. EN ORIENTACION PROFESIONAL' },
    { name: 'PROFESOR EN MATEMATICA', value: 'PROFESOR EN MATEMATICA' },
    { name: 'LIC. EN BACTERIOLOGIA Y C.C. NATURALES', value: 'LIC. EN BACTERIOLOGIA Y C.C. NATURALES' },
    { name: 'LIC. EN EDUCACION FISICA Y DEPORTE', value: 'LIC. EN EDUCACION FISICA Y DEPORTE' },
    { name: 'LIC. EN ORIENTACION EDUCATIVA', value: 'LIC. EN ORIENTACION EDUCATIVA' },
    { name: 'MAESTRO(A) EN ADMON. DE TEC. E INF.', value: 'MAESTRO(A) EN ADMON. DE TEC. E INF.' },
    { name: 'MAESTRA EN SALUD PUBLICA', value: 'MAESTRA EN SALUD PUBLICA' },
    { name: 'DOCTOR EN HISTORIA', value: 'DOCTOR EN HISTORIA' },
    { name: 'LIC. EN CIENCIAS NATURALES', value: 'LIC. EN CIENCIAS NATURALES' },
    { name: 'DIPLO. EN HIGIENE Y SEGURIDAD INDUSTRIAL', value: 'DIPLO. EN HIGIENE Y SEGURIDAD INDUSTRIAL' },
    { name: 'MS. EN CIENCIAS', value: 'MS. EN CIENCIAS' },
    { name: 'DR. EN ADMINISTRACION DE EMPRESAS', value: 'DR. EN ADMINISTRACION DE EMPRESAS' },
    { name: 'MASTER EN EDUCACION', value: 'MASTER EN EDUCACION' },
    { name: 'MASTER EN EDUCACION SUPERIOR', value: 'MASTER EN EDUCACION SUPERIOR' },
    { name: 'MAESTRIA EN EDUCACION SUPERIOR', value: 'MAESTRIA EN EDUCACION SUPERIOR' },
    { name: 'DR.(A) EN LETRAS Y HUMANIDADES', value: 'DR.(A) EN LETRAS Y HUMANIDADES' },
    { name: 'LIC.(A) EN LENGUAJE Y LITERATURA', value: 'LIC.(A) EN LENGUAJE Y LITERATURA' },
    { name: 'PROF. EN EDUC. MEDIA P/EL IDIOMA INGLES', value: 'PROF. EN EDUC. MEDIA P/EL IDIOMA INGLES' },
    { name: 'LIC(A) DE CIENCIAS DEL LENGUAJE', value: 'LIC(A) DE CIENCIAS DEL LENGUAJE' },
    { name: 'MAESTRO(A) EN EDUCACION PRIMARIA', value: 'MAESTRO(A) EN EDUCACION PRIMARIA' },
    { name: 'PROF.(A) DE EDUC. PRIMARIA', value: 'PROF.(A) DE EDUC. PRIMARIA' },
    { name: 'LIC(A). EN ESTUDIOS LATINOAMERICANOS', value: 'LIC(A). EN ESTUDIOS LATINOAMERICANOS' },
    { name: 'MAESTRIA EN DESARROLLO LOCAL', value: 'MAESTRIA EN DESARROLLO LOCAL' },
    { name: 'PROF(A). CC. SOC. EDUC. BASICA Y MEDIA', value: 'PROF(A). CC. SOC. EDUC. BASICA Y MEDIA' },
    { name: 'MS EN DIDACTICA Y FORMAC DEL PROFESORADO', value: 'MS EN DIDACTICA Y FORMAC DEL PROFESORADO' },
    { name: 'LIC(A) CC. DE EDUC CON ESP EN PARVULARIA', value: 'LIC(A) CC. DE EDUC CON ESP EN PARVULARIA' },
    { name: 'PROF. EDUC. MEDIA TEC. ENSEÑ. MEC. AUTO', value: 'PROF. EDUC. MEDIA TEC. ENSEÑ. MEC. AUTO' },
    { name: 'MS PROFESIONALIZACION DE LA DOCENCIA SUP', value: 'MS PROFESIONALIZACION DE LA DOCENCIA SUP' },
    { name: 'PROF(A) CC NATURALES T.C.B.Y EDUC. MEDIA', value: 'PROF(A) CC NATURALES T.C.B.Y EDUC. MEDIA' },
    { name: 'PROF(A). CC. SOC. T.C.B.Y EDUC. MEDIA', value: 'PROF(A). CC. SOC. T.C.B.Y EDUC. MEDIA' },
    { name: 'PROF(A) EN EDUC MED CAMPO ELECTROTECNIA', value: 'PROF(A) EN EDUC MED CAMPO ELECTROTECNIA' },
    { name: 'LIC(A) CC. DE EDUC CON ESP EN CC. NAT.', value: 'LIC(A) CC. DE EDUC CON ESP EN CC. NAT.' },
    { name: 'LIC(A) CC DE EDUC ESP EN LENG. Y LITERAT', value: 'LIC(A) CC DE EDUC ESP EN LENG. Y LITERAT' },
    { name: 'PROFESOR DE ESTUDIOS SOCIALES', value: 'PROFESOR DE ESTUDIOS SOCIALES' },
    { name: 'MAESTRIA EN METODOS Y TEC E INVESTIG SOC', value: 'MAESTRIA EN METODOS Y TEC E INVESTIG SOC' },
    { name: 'MS. EN DER. HUM. Y EDUC. PARA LA PAZ', value: 'MS. EN DER. HUM. Y EDUC. PARA LA PAZ' },
    { name: 'LIC(A) CC DE EDUC CON ESP EN MATEMATICAS', value: 'LIC(A) CC DE EDUC CON ESP EN MATEMATICAS' },
    { name: 'PROF(A) EN EDUC MED ENSEÑANZA DE CC. SOC', value: 'PROF(A) EN EDUC MED ENSEÑANZA DE CC. SOC' },
    { name: 'LIC(A) CC EDUC CON ESP EN EDUC ESPECIAL', value: 'LIC(A) CC EDUC CON ESP EN EDUC ESPECIAL' },
    { name: 'MASTER EN TECNOLOGIA EDUCATIVA', value: 'MASTER EN TECNOLOGIA EDUCATIVA' },
    { name: 'MS. ADMON. EMPRESAS Y CONSULTORIA EMPRES', value: 'MS. ADMON. EMPRESAS Y CONSULTORIA EMPRES' },
    { name: 'LIC(A) CC EDUC ESP EDUC FIS DEP Y RECREA', value: 'LIC(A) CC EDUC ESP EDUC FIS DEP Y RECREA' },
    { name: 'PROF.(A) EN EDUC. ESP. PARA NIVEL BASICO', value: 'PROF.(A) EN EDUC. ESP. PARA NIVEL BASICO' },
    { name: 'MAESTRA EN COMUNICACION ORGANIZACIONAL', value: 'MAESTRA EN COMUNICACION ORGANIZACIONAL' },
    { name: 'PROF.(A) DE FILOSOFIA Y LETRAS', value: 'PROF.(A) DE FILOSOFIA Y LETRAS' },
    { name: 'PROF(A).INGLES 3° CICLO EDU BAS Y MED', value: 'PROF(A).INGLES 3° CICLO EDU BAS Y MED' },
    { name: 'PROF. EDUC. MEDIA TEC ENSEÑ. ELECTRONICA', value: 'PROF. EDUC. MEDIA TEC ENSEÑ. ELECTRONICA' },
    { name: 'PROF(A) EN MAT 3° CICLO EDUC BAS Y MEDIA', value: 'PROF(A) EN MAT 3° CICLO EDUC BAS Y MEDIA' },
    { name: 'LIC(A) EN LENG MODER ESP FRANCES/INGLES', value: 'LIC(A) EN LENG MODER ESP FRANCES/INGLES' },
    { name: 'PROF. EN EDUC. MED. P. EL IDIOMA FRANCES', value: 'PROF. EN EDUC. MED. P. EL IDIOMA FRANCES' },
    { name: 'MS. EN ASESORIA EDUCATIVA', value: 'MS. EN ASESORIA EDUCATIVA' },
    { name: 'LIC(A) CC ESP LENG/MAT 1°Y 2º ED BAS', value: 'LIC(A) CC ESP LENG/MAT 1°Y 2º ED BAS' },
    { name: 'LIC(A) CC DE EDUC ESP 1°Y 2º C. EDU BAS.', value: 'LIC(A) CC DE EDUC ESP 1°Y 2º C. EDU BAS.' },
    { name: 'PROF(A) ENSEÑANZA EN EDUC. MEDIA CC EDUC', value: 'PROF(A) ENSEÑANZA EN EDUC. MEDIA CC EDUC' },
    { name: 'MAESTRO EN DOCENCIA UNIVERSITARIA', value: 'MAESTRO EN DOCENCIA UNIVERSITARIA' },
    { name: 'MAESTRIA EN ADMON. DE LA EDUCACION', value: 'MAESTRIA EN ADMON. DE LA EDUCACION' },
    { name: 'MAESTRIA EN ADMON. DE NEGOCIOS', value: 'MAESTRIA EN ADMON. DE NEGOCIOS' },
    { name: 'PROF(A) EN EDUC MED ENSEÑ. DE LA MAT.', value: 'PROF(A) EN EDUC MED ENSEÑ. DE LA MAT.' },
    { name: 'MAESTRIA EN COMUNICACION', value: 'MAESTRIA EN COMUNICACION' },
    { name: 'DR.(A) EN FILOSOFIA IBEROAMERICANA', value: 'DR.(A) EN FILOSOFIA IBEROAMERICANA' },
    { name: 'PROF.(A) DE EDUC MEDIA EN EL CAMPO CCIAL', value: 'PROF.(A) DE EDUC MEDIA EN EL CAMPO CCIAL' },
    { name: 'PROF. EDUC. MED. ENSEÑANZA DE LAS LETRAS', value: 'PROF. EDUC. MED. ENSEÑANZA DE LAS LETRAS' },
    { name: 'MS. EN NUEVAS TECNOLOGIAS DE LA COMUNIC', value: 'MS. EN NUEVAS TECNOLOGIAS DE LA COMUNIC' },
    { name: 'MAESTRIA EN ADMON FINANCIERA', value: 'MAESTRIA EN ADMON FINANCIERA' },
    { name: 'MAGISTER EN GESTION Y POLITICAS PUBLICAS', value: 'MAGISTER EN GESTION Y POLITICAS PUBLICAS' },
    { name: 'MAESTRO(A) EN LIDERAZGO GERENCIAL EDUC.', value: 'MAESTRO(A) EN LIDERAZGO GERENCIAL EDUC.' },
    { name: 'MAESTRA(O) EN DIRECCION DE EMPRESAS', value: 'MAESTRA(O) EN DIRECCION DE EMPRESAS' },
    { name: 'PROF.(A) LENG Y LIT PARA 3° C EDU B Y ME', value: 'PROF.(A) LENG Y LIT PARA 3° C EDU B Y ME' },
    { name: 'PROF EN ED MED TEC EN LA ESP DE ING ELEC', value: 'PROF EN ED MED TEC EN LA ESP DE ING ELEC' },
    { name: 'PROF(A) EN EDU MED TEC ENS ELECTROTECNIA', value: 'PROF(A) EN EDU MED TEC ENS ELECTROTECNIA' },
    { name: 'LIC.(A) CC EDUC ESP EN EDUC FISICA Y DEP', value: 'LIC.(A) CC EDUC ESP EN EDUC FISICA Y DEP' },
    { name: 'CIENCIAS SOCIALES', value: 'CIENCIAS SOCIALES' },
    { name: 'TEC. EN COMUNICACIONES', value: 'TEC. EN COMUNICACIONES' },
    { name: 'TEC. EN PERIODISMO', value: 'TEC. EN PERIODISMO' },
    { name: 'TEC. EN RELACIONES PUBLICAS', value: 'TEC. EN RELACIONES PUBLICAS' },
    { name: 'TEC. EN PUBLICIDAD', value: 'TEC. EN PUBLICIDAD' },
    { name: 'LIC.(A) EN CIENCIAS DE LA COMUNICACIÓN', value: 'LIC.(A) EN CIENCIAS DE LA COMUNICACIÓN' },
    { name: 'LIC.(A) EN CIENCIAS POLITICAS', value: 'LIC.(A) EN CIENCIAS POLITICAS' },
    { name: 'LIC.(A) EN COMUNICACIONES', value: 'LIC.(A) EN COMUNICACIONES' },
    { name: 'LIC.(A) EN PUBLICIDAD', value: 'LIC.(A) EN PUBLICIDAD' },
    { name: 'LIC.(A) EN RELACIONES PUBLICAS', value: 'LIC.(A) EN RELACIONES PUBLICAS' },
    { name: 'LIC.(A) EN SOCIOLOGIA', value: 'LIC.(A) EN SOCIOLOGIA' },
    { name: 'LIC.(A) EN PERIODISMO', value: 'LIC.(A) EN PERIODISMO' },
    { name: 'MS. EN CIENCIAS POLITICAS', value: 'MS. EN CIENCIAS POLITICAS' },
    { name: 'LIC(A) EN COMUNICACIÓN Y PERIODISMO', value: 'LIC(A) EN COMUNICACIÓN Y PERIODISMO' },
    { name: 'LIC. EN REL. PUBLICAS Y COMUNICACIONES', value: 'LIC. EN REL. PUBLICAS Y COMUNICACIONES' },
    { name: 'TEC. EN TELECOMUNICACIONES', value: 'TEC. EN TELECOMUNICACIONES' },
    { name: 'LIC. EN RELACIONES PUBLICAS Y PUBLICIDAD', value: 'LIC. EN RELACIONES PUBLICAS Y PUBLICIDAD' },
    { name: 'LIC. EN COMUNICACION SOCIAL', value: 'LIC. EN COMUNICACION SOCIAL' },
    { name: 'LIC. EN  CIENCIAS SOCIALES', value: 'LIC. EN  CIENCIAS SOCIALES' },
    { name: 'BIBLIOTECOLOGO', value: 'BIBLIOTECOLOGO' },
    { name: 'M.SC EN RELACIONES INTERNACIONALES', value: 'M.SC EN RELACIONES INTERNACIONALES' },
    { name: 'LIC. EN CIENCIAS ACTUARIALES', value: 'LIC. EN CIENCIAS ACTUARIALES' },
    { name: 'DIRECTOR(A) DE CINE Y TV.', value: 'DIRECTOR(A) DE CINE Y TV.' },
    { name: 'CORRESPONSAL', value: 'CORRESPONSAL' },
    { name: 'TEC. EN RELACIONES PUB. Y COMUNICACIONES', value: 'TEC. EN RELACIONES PUB. Y COMUNICACIONES' },
    { name: 'ASESOR EN COMUNICACIONES', value: 'ASESOR EN COMUNICACIONES' },
    { name: 'TECNICO UNIVERSITARIO EN PERIODISMO', value: 'TECNICO UNIVERSITARIO EN PERIODISMO' },
    { name: 'TEC. EN RELACIONES PUBLICAS Y PUBLICIDAD', value: 'TEC. EN RELACIONES PUBLICAS Y PUBLICIDAD' },
    { name: 'LIC.CIENCIAS DE EDUC.ESP.CIEN.SOCIALES', value: 'LIC.CIENCIAS DE EDUC.ESP.CIEN.SOCIALES' },
    { name: 'MILITAR Y SEGURIDAD', value: 'MILITAR Y SEGURIDAD' },
    { name: 'OFICIAL MILITAR', value: 'OFICIAL MILITAR' },
    { name: 'MILITAR', value: 'MILITAR' },
    { name: 'LIC. EN CIENCIAS Y ARTES MILITARES', value: 'LIC. EN CIENCIAS Y ARTES MILITARES' },
    { name: 'OFICIAL DE POLICIA', value: 'OFICIAL DE POLICIA' },
    { name: 'OFICIAL DE MARINA', value: 'OFICIAL DE MARINA' },
    { name: 'CAPITAN', value: 'CAPITAN' },
    { name: 'LICENCIADO EN ADMINISTRACION MILITAR', value: 'LICENCIADO EN ADMINISTRACION MILITAR' },
    { name: 'RELIGIOSO', value: 'RELIGIOSO' },
    { name: 'SACERDOTE', value: 'SACERDOTE' },
    { name: 'PASTOR RELIGIOSO', value: 'PASTOR RELIGIOSO' },
    { name: 'OBISPO ANGLICANO', value: 'OBISPO ANGLICANO' },
    { name: 'OBISPO CATOLICO', value: 'OBISPO CATOLICO' },
    { name: 'SACERDOTE CATOLICO', value: 'SACERDOTE CATOLICO' },
    { name: 'PARROCO', value: 'PARROCO' },
    { name: 'PASTOR(A) EVANGELICO(A)', value: 'PASTOR(A) EVANGELICO(A)' },
    { name: 'PASTOR EXHORTADOR', value: 'PASTOR EXHORTADOR' },
    { name: 'REVERENDO', value: 'REVERENDO' },
    { name: 'SACERDOTE ANGLICANO', value: 'SACERDOTE ANGLICANO' },
    { name: 'PASTOR ADVENTISTA', value: 'PASTOR ADVENTISTA' },
    { name: 'SACERDOTE LUTERANO', value: 'SACERDOTE LUTERANO' },
    { name: 'MISIONERO EVANGELICO', value: 'MISIONERO EVANGELICO' },
    { name: 'LIC.(A) EN ESTUDIOS ECLESIASTICOS', value: 'LIC.(A) EN ESTUDIOS ECLESIASTICOS' },
    { name: 'DR. EN TEOLOGIA', value: 'DR. EN TEOLOGIA' },
    { name: 'DIACONO', value: 'DIACONO' },
    { name: 'PRESBITERO(A)', value: 'PRESBITERO(A)' },
    { name: 'SACERDOTE DIOCESANO', value: 'SACERDOTE DIOCESANO' },
    { name: 'OTRO PROFESIONAL', value: 'OTRO PROFESIONAL' },
    { name: 'ING.(A) EN AERONAUTICA', value: 'ING.(A) EN AERONAUTICA' },
    { name: 'PILOTO AVIADOR', value: 'PILOTO AVIADOR' },
    { name: 'AEROTECNICO', value: 'AEROTECNICO' },
    { name: 'PILOTO COMERCIAL', value: 'PILOTO COMERCIAL' },
    { name: 'PILOTO NAVAL', value: 'PILOTO NAVAL' },
    { name: 'TECNICO AERONAUTICO', value: 'TECNICO AERONAUTICO' },
    { name: 'ING. AEROESPACIAL', value: 'ING. AEROESPACIAL' },
    { name: 'DESPACHADOR DE AERONAVES', value: 'DESPACHADOR DE AERONAVES' },
    { name: 'INSPECTOR AERONAUTICO', value: 'INSPECTOR AERONAUTICO' },
    { name: 'LIC. INGENIERIA NAUTICA', value: 'LIC. INGENIERIA NAUTICA' },
    { name: 'LIC. EN AERONAUTICA PROFESIONAL', value: 'LIC. EN AERONAUTICA PROFESIONAL' },
    { name: 'PILOTO AVIADOR COMERCIAL', value: 'PILOTO AVIADOR COMERCIAL' },
    { name: 'TRIPULANTE DE CABINA', value: 'TRIPULANTE DE CABINA' },
    { name: 'TEC. EJECUTIVO EN ASISTENCIA GERENCIAL.', value: 'TEC. EJECUTIVO EN ASISTENCIA GERENCIAL.' },
    { name: 'MECANICO DE AVIONES RECIPROCOS', value: 'MECANICO DE AVIONES RECIPROCOS' },
    { name: 'BACHILLER', value: 'BACHILLER' },
    { name: 'BACH. COMERCIAL-CONTADOR', value: 'BACH. COMERCIAL-CONTADOR' },
    { name: 'BACH. COMERCIAL-SECRETARIA', value: 'BACH. COMERCIAL-SECRETARIA' },
    { name: 'BACH. ACADEMICO', value: 'BACH. ACADEMICO' },
    { name: 'BACH. AGRONOMO', value: 'BACH. AGRONOMO' },
    { name: 'BACH. INDUSTRIAL', value: 'BACH. INDUSTRIAL' },
    { name: 'BACH. EN MECANICA', value: 'BACH. EN MECANICA' },
    { name: 'BACH. EN ELECTRICIDAD', value: 'BACH. EN ELECTRICIDAD' },
    { name: 'BACH. AUTOMOTRIZ', value: 'BACH. AUTOMOTRIZ' },
    { name: 'BACH. EN HOSTELERIA Y TURISMO', value: 'BACH. EN HOSTELERIA Y TURISMO' },
    { name: 'BACH. EN SALUD', value: 'BACH. EN SALUD' },
    { name: 'BACH. EN COMPUTACION', value: 'BACH. EN COMPUTACION' },
    { name: 'BACH. EN ARTE', value: 'BACH. EN ARTE' },
    { name: 'BACH. PEDAGOGICO', value: 'BACH. PEDAGOGICO' },
    { name: 'BACH. EN ARQUITECTURA', value: 'BACH. EN ARQUITECTURA' },
    { name: 'BACH. EN CIENCIAS Y ARTES', value: 'BACH. EN CIENCIAS Y ARTES' },
    { name: 'BACH. AGRICOLA', value: 'BACH. AGRICOLA' },
    { name: 'BACHILLER EN COMERCIO Y ADMON.', value: 'BACHILLER EN COMERCIO Y ADMON.' },
    { name: 'BACH. INDUS ESP. EN MECANICA DE AVIACION', value: 'BACH. INDUS ESP. EN MECANICA DE AVIACION' },
    { name: 'BACHILLER EN ORIENTACION DOCENTE', value: 'BACHILLER EN ORIENTACION DOCENTE' },
    { name: 'BACH. EN ELECTRONICA', value: 'BACH. EN ELECTRONICA' },
    { name: 'BACH. GENERAL', value: 'BACH. GENERAL' },
    { name: 'BACH.TEC.VOC.INDUSTRIAL AERONAUTICO', value: 'BACH.TEC.VOC.INDUSTRIAL AERONAUTICO' },
    { name: 'OFICIOS RELAT. AL ARTE Y ARQUITECTURA', value: 'OFICIOS RELAT. AL ARTE Y ARQUITECTURA' },
    { name: 'ARREGLISTA', value: 'ARREGLISTA' },
    { name: 'MUSICO', value: 'MUSICO' },
    { name: 'FILARMONICO', value: 'FILARMONICO' },
    { name: 'TROBADOR', value: 'TROBADOR' },
    { name: 'CANTANTE', value: 'CANTANTE' },
    { name: 'CARTOGRAFICO', value: 'CARTOGRAFICO' },
    { name: 'COREOGRAFO', value: 'COREOGRAFO' },
    { name: 'DECORADOR', value: 'DECORADOR' },
    { name: 'DIBUJANTE', value: 'DIBUJANTE' },
    { name: 'DISEÑADOR DE GRAFICOS', value: 'DISEÑADOR DE GRAFICOS' },
    { name: 'ESCRITOR(A)', value: 'ESCRITOR(A)' },
    { name: 'ESCULTOR', value: 'ESCULTOR' },
    { name: 'ARTISTA PINTOR', value: 'ARTISTA PINTOR' },
    { name: 'POETA', value: 'POETA' },
    { name: 'ACROBATA', value: 'ACROBATA' },
    { name: 'ARTISTA', value: 'ARTISTA' },
    { name: 'ARTISTA DE RADIO Y TV', value: 'ARTISTA DE RADIO Y TV' },
    { name: 'CIRCENSE', value: 'CIRCENSE' },
    { name: 'PAYASO', value: 'PAYASO' },
    { name: 'SERIGRAFO', value: 'SERIGRAFO' },
    { name: 'CRISTALOGRAFO(A)', value: 'CRISTALOGRAFO(A)' },
    { name: 'ALFARERO(A)', value: 'ALFARERO(A)' },
    { name: 'ALISTADOR', value: 'ALISTADOR' },
    { name: 'APRENDIZ ', value: 'APRENDIZ ' },
    { name: 'ARTESANO(A)', value: 'ARTESANO(A)' },
    { name: 'AYUDANTE', value: 'AYUDANTE' },
    { name: 'BALDOCERO', value: 'BALDOCERO' },
    { name: 'BORDADORA', value: 'BORDADORA' },
    { name: 'CARROCERO', value: 'CARROCERO' },
    { name: 'CERAMISTA', value: 'CERAMISTA' },
    { name: 'CURTIDOR DE PIELES', value: 'CURTIDOR DE PIELES' },
    { name: 'EBANISTA', value: 'EBANISTA' },
    { name: 'ENZUELADOR', value: 'ENZUELADOR' },
    { name: 'ZAPATERO(A)', value: 'ZAPATERO(A)' },
    { name: 'TENERO', value: 'TENERO' },
    { name: 'FABRICANTE DE SOMBREROS', value: 'FABRICANTE DE SOMBREROS' },
    { name: 'FLORISTA', value: 'FLORISTA' },
    { name: 'HERRERO', value: 'HERRERO' },
    { name: 'HOJALATERO', value: 'HOJALATERO' },
    { name: 'MARMOLISTA', value: 'MARMOLISTA' },
    { name: 'MARROQUINERO(A)', value: 'MARROQUINERO(A)' },
    { name: 'OBRA DE BANCO', value: 'OBRA DE BANCO' },
    { name: 'ORFEBRE', value: 'ORFEBRE' },
    { name: 'PIÑATERO', value: 'PIÑATERO' },
    { name: 'PINTOR(A)', value: 'PINTOR(A)' },
    { name: 'PIROTECNICO', value: 'PIROTECNICO' },
    { name: 'PLATERO', value: 'PLATERO' },
    { name: 'TALABARTERO', value: 'TALABARTERO' },
    { name: 'TALLADOR', value: 'TALLADOR' },
    { name: 'TAPICERO', value: 'TAPICERO' },
    { name: 'TEJEDOR', value: 'TEJEDOR' },
    { name: 'TELERISTA', value: 'TELERISTA' },
    { name: 'DECORADOR DE AUTOS', value: 'DECORADOR DE AUTOS' },
    { name: 'MODELO', value: 'MODELO' },
    { name: 'AUTOR', value: 'AUTOR' },
    { name: 'COMPOSITOR', value: 'COMPOSITOR' },
    { name: 'CONTORCIONISTA', value: 'CONTORCIONISTA' },
    { name: 'BAILARIN(A)', value: 'BAILARIN(A)' },
    { name: 'CORISTA', value: 'CORISTA' },
    { name: 'TITIRITERO', value: 'TITIRITERO' },
    { name: 'GALVANIZADOR', value: 'GALVANIZADOR' },
    { name: 'DIRECTOR EN ARTE DRAMATICO', value: 'DIRECTOR EN ARTE DRAMATICO' },
    { name: 'LIC. EN ARTES VISUALES', value: 'LIC. EN ARTES VISUALES' },
    { name: 'VOCALISTA', value: 'VOCALISTA' },
    { name: 'CANTAUTOR', value: 'CANTAUTOR' },
    { name: 'DEMOLEDOR DE ROCAS', value: 'DEMOLEDOR DE ROCAS' },
    { name: 'TEC. CONSTRUCTOR', value: 'TEC. CONSTRUCTOR' },
    { name: 'ENCHAPADOR', value: 'ENCHAPADOR' },
    { name: 'PIÑATERA', value: 'PIÑATERA' },
    { name: 'DRAMATURGO', value: 'DRAMATURGO' },
    { name: 'OFICIOS ECONOMIA/ADMON/COMERCIO', value: 'OFICIOS ECONOMIA/ADMON/COMERCIO' },
    { name: 'ADMINISTRADOR', value: 'ADMINISTRADOR' },
    { name: 'AUXILIAR DE CONTADOR', value: 'AUXILIAR DE CONTADOR' },
    { name: 'CARDISTA', value: 'CARDISTA' },
    { name: 'VENDEDOR(A)', value: 'VENDEDOR(A)' },
    { name: 'VENDEDOR AMBULANTE', value: 'VENDEDOR AMBULANTE' },
    { name: 'AYUDANTE DE VENDEDOR', value: 'AYUDANTE DE VENDEDOR' },
    { name: 'PROMOTOR DE VENTAS', value: 'PROMOTOR DE VENTAS' },
    { name: 'AGENTE DE SEGUROS', value: 'AGENTE DE SEGUROS' },
    { name: 'EJECUTIVO(A) DE VENTAS', value: 'EJECUTIVO(A) DE VENTAS' },
    { name: 'GESTOR DE VENTAS', value: 'GESTOR DE VENTAS' },
    { name: 'ASESOR DE VENTAS', value: 'ASESOR DE VENTAS' },
    { name: 'VENDEDOR PROFESIONAL', value: 'VENDEDOR PROFESIONAL' },
    { name: 'AGENTE VENDEDOR', value: 'AGENTE VENDEDOR' },
    { name: 'AGENTE VIAJERO', value: 'AGENTE VIAJERO' },
    { name: 'REPRESENTANTE DE VENTAS', value: 'REPRESENTANTE DE VENTAS' },
    { name: 'EJECUTIVO EMPRESARIAL', value: 'EJECUTIVO EMPRESARIAL' },
    { name: 'EJECUTIVO HOTELERO', value: 'EJECUTIVO HOTELERO' },
    { name: 'EMPRESARIO(A)', value: 'EMPRESARIO(A)' },
    { name: 'ESPECIALISTA  COMERC. INTER.', value: 'ESPECIALISTA  COMERC. INTER.' },
    { name: 'PLANILLERO(A)', value: 'PLANILLERO(A)' },
    { name: 'REPRESENTANTE CASAS EXTRANJERAS', value: 'REPRESENTANTE CASAS EXTRANJERAS' },
    { name: 'SECRETARIA(O)', value: 'SECRETARIA(O)' },
    { name: 'SECRETARIA EJECUTIVA', value: 'SECRETARIA EJECUTIVA' },
    { name: 'SECRETARIA EJECUTIVA BILINGÜE', value: 'SECRETARIA EJECUTIVA BILINGÜE' },
    { name: 'GESTOR DE SERVICIOS', value: 'GESTOR DE SERVICIOS' },
    { name: 'PLANIFICADOR', value: 'PLANIFICADOR' },
    { name: 'TEC. BANCARIO', value: 'TEC. BANCARIO' },
    { name: 'TEC. EN HOSTELERIA Y TURISMO', value: 'TEC. EN HOSTELERIA Y TURISMO' },
    { name: 'OFICINISTA', value: 'OFICINISTA' },
    { name: 'TEC. OFICINISTA', value: 'TEC. OFICINISTA' },
    { name: 'TENEDOR DE LIBROS', value: 'TENEDOR DE LIBROS' },
    { name: 'AUXILIAR DE OFICINA', value: 'AUXILIAR DE OFICINA' },
    { name: 'PELUQUERO(A)', value: 'PELUQUERO(A)' },
    { name: 'BARBERO', value: 'BARBERO' },
    { name: 'COSMETOLOGA(O)', value: 'COSMETOLOGA(O)' },
    { name: 'ESTILISTA', value: 'ESTILISTA' },
    { name: 'MANICURISTA', value: 'MANICURISTA' },
    { name: 'MASAJISTA', value: 'MASAJISTA' },
    { name: 'COMERCIANTE', value: 'COMERCIANTE' },
    { name: 'COMERCIANTE EN PEQUEÑO', value: 'COMERCIANTE EN PEQUEÑO' },
    { name: 'NEGOCIANTE', value: 'NEGOCIANTE' },
    { name: 'MERCERO(A)', value: 'MERCERO(A)' },
    { name: 'PRESTAMISTA', value: 'PRESTAMISTA' },
    { name: 'RECEPCIONISTA', value: 'RECEPCIONISTA' },
    { name: 'MECANOGRAFO(A)', value: 'MECANOGRAFO(A)' },
    { name: 'TAQUIMECANOGRAFO(A)', value: 'TAQUIMECANOGRAFO(A)' },
    { name: 'CAJERO(A)', value: 'CAJERO(A)' },
    { name: 'EMPLEADO(A) BANCARIO(A)', value: 'EMPLEADO(A) BANCARIO(A)' },
    { name: 'TALLADOR OPTICO', value: 'TALLADOR OPTICO' },
    { name: 'TIPOGRAFO', value: 'TIPOGRAFO' },
    { name: 'IMPRESOR', value: 'IMPRESOR' },
    { name: 'LINOTIPISTA', value: 'LINOTIPISTA' },
    { name: 'AUXILIAR DE ALMACEN', value: 'AUXILIAR DE ALMACEN' },
    { name: 'DEPENDIENTE', value: 'DEPENDIENTE' },
    { name: 'DEPENDIENTE DE ALMACEN', value: 'DEPENDIENTE DE ALMACEN' },
    { name: 'DEPENDIENTE DE FARMACIA', value: 'DEPENDIENTE DE FARMACIA' },
    { name: 'IMPULSADOR(A)', value: 'IMPULSADOR(A)' },
    { name: 'BODEGUERO', value: 'BODEGUERO' },
    { name: 'AYUDANTE DE BODEGA', value: 'AYUDANTE DE BODEGA' },
    { name: 'BARRETERO', value: 'BARRETERO' },
    { name: 'BARRENDERO', value: 'BARRENDERO' },
    { name: 'BUHONERO', value: 'BUHONERO' },
    { name: 'CARGADOR DE BATERIAS', value: 'CARGADOR DE BATERIAS' },
    { name: 'CARNICERO', value: 'CARNICERO' },
    { name: 'MATARIFE', value: 'MATARIFE' },
    { name: 'CERRAJERO', value: 'CERRAJERO' },
    { name: 'COLCHONERO', value: 'COLCHONERO' },
    { name: 'CORTE Y CONFECCION', value: 'CORTE Y CONFECCION' },
    { name: 'COSTURERO(A)', value: 'COSTURERO(A)' },
    { name: 'COSTERO', value: 'COSTERO' },
    { name: 'SASTRE', value: 'SASTRE' },
    { name: 'MODISTA', value: 'MODISTA' },
    { name: 'DULCERO(A)', value: 'DULCERO(A)' },
    { name: 'EMPACADOR(A)', value: 'EMPACADOR(A)' },
    { name: 'ENCERADOR', value: 'ENCERADOR' },
    { name: 'ENCUADERNADOR', value: 'ENCUADERNADOR' },
    { name: 'ESTAMPADOR', value: 'ESTAMPADOR' },
    { name: 'GUARDALUZ', value: 'GUARDALUZ' },
    { name: 'HARINERO', value: 'HARINERO' },
    { name: 'HORNERO', value: 'HORNERO' },
    { name: 'HOTELERO', value: 'HOTELERO' },
    { name: 'BOTONES', value: 'BOTONES' },
    { name: 'MESERO(A)', value: 'MESERO(A)' },
    { name: 'RECAMARERA', value: 'RECAMARERA' },
    { name: 'INSTALADOR', value: 'INSTALADOR' },
    { name: 'JARDINERO', value: 'JARDINERO' },
    { name: 'LAMINADOR', value: 'LAMINADOR' },
    { name: 'LUSTRADOR', value: 'LUSTRADOR' },
    { name: 'MENSAJERO', value: 'MENSAJERO' },
    { name: 'MINERO', value: 'MINERO' },
    { name: 'MOLINERO', value: 'MOLINERO' },
    { name: 'MONTAJISTA', value: 'MONTAJISTA' },
    { name: 'OFICIOS VARIOS', value: 'OFICIOS VARIOS' },
    { name: 'ORDENANZA ', value: 'ORDENANZA ' },
    { name: 'PANADERO(A)', value: 'PANADERO(A)' },
    { name: 'PANIFICADOR(A)', value: 'PANIFICADOR(A)' },
    { name: 'REPOSTERO(A)', value: 'REPOSTERO(A)' },
    { name: 'PARRILLERO(A)', value: 'PARRILLERO(A)' },
    { name: 'PELETERO', value: 'PELETERO' },
    { name: 'PLOMERO', value: 'PLOMERO' },
    { name: 'PRENSISTA', value: 'PRENSISTA' },
    { name: 'RECORTADOR', value: 'RECORTADOR' },
    { name: 'RELOJERO (A)', value: 'RELOJERO (A)' },
    { name: 'JOYERO(A)', value: 'JOYERO(A)' },
    { name: 'SEPULTURERO', value: 'SEPULTURERO' },
    { name: 'SORBETERO', value: 'SORBETERO' },
    { name: 'PALETERO', value: 'PALETERO' },
    { name: 'MINUTERO', value: 'MINUTERO' },
    { name: 'SUPERNUMERARIO(A)', value: 'SUPERNUMERARIO(A)' },
    { name: 'TENDERO(A)', value: 'TENDERO(A)' },
    { name: 'TINTORERO(A)', value: 'TINTORERO(A)' },
    { name: 'TRAMITADOR(A)', value: 'TRAMITADOR(A)' },
    { name: 'TRAMOYISTA', value: 'TRAMOYISTA' },
    { name: 'TROQUELERO', value: 'TROQUELERO' },
    { name: 'TUBERO', value: 'TUBERO' },
    { name: 'ADUANERO', value: 'ADUANERO' },
    { name: 'ARCHIVISTA', value: 'ARCHIVISTA' },
    { name: 'AYUDANTE DE COBRADOR', value: 'AYUDANTE DE COBRADOR' },
    { name: 'SUPERVISOR DE VENTAS', value: 'SUPERVISOR DE VENTAS' },
    { name: 'BARTENDER', value: 'BARTENDER' },
    { name: 'VIDRIERO', value: 'VIDRIERO' },
    { name: 'COSTURERA INDUSTRIAL', value: 'COSTURERA INDUSTRIAL' },
    { name: 'SASTRE INDUSTRIAL', value: 'SASTRE INDUSTRIAL' },
    { name: 'ESCULTOR INDUSTRIAL', value: 'ESCULTOR INDUSTRIAL' },
    { name: 'OBRERO TEXTIL', value: 'OBRERO TEXTIL' },
    { name: 'INSTALADOR DE TECHO', value: 'INSTALADOR DE TECHO' },
    { name: 'INSTALADOR DE AZULEJOS', value: 'INSTALADOR DE AZULEJOS' },
    { name: 'OSTRERO', value: 'OSTRERO' },
    { name: 'SALONERO', value: 'SALONERO' },
    { name: 'DESPACHADOR', value: 'DESPACHADOR' },
    { name: 'CASEADOR', value: 'CASEADOR' },
    { name: 'CARTERO', value: 'CARTERO' },
    { name: 'PRENSISTA DE TIPOGRAFIA', value: 'PRENSISTA DE TIPOGRAFIA' },
    { name: 'CHALAN', value: 'CHALAN' },
    { name: 'CASETERO', value: 'CASETERO' },
    { name: 'ECONOMISTA AGRICOLA', value: 'ECONOMISTA AGRICOLA' },
    { name: 'BRUCERO', value: 'BRUCERO' },
    { name: 'AGENTE DE VIAJES', value: 'AGENTE DE VIAJES' },
    { name: 'TEC. EN SEGUROS', value: 'TEC. EN SEGUROS' },
    { name: 'SECRETARIO(A) MUNICIPAL', value: 'SECRETARIO(A) MUNICIPAL' },
    { name: 'MICROEMPRESARIO', value: 'MICROEMPRESARIO' },
    { name: 'AGENTE PREVISIONAL', value: 'AGENTE PREVISIONAL' },
    { name: 'INSTALADOR DE ALARMAS', value: 'INSTALADOR DE ALARMAS' },
    { name: 'ASISTENTE ADMINISTRATIVO', value: 'ASISTENTE ADMINISTRATIVO' },
    { name: 'SECRETARIA COMERCIAL', value: 'SECRETARIA COMERCIAL' },
    { name: 'PASTELERO(A)', value: 'PASTELERO(A)' },
    { name: 'TEC. EN COMERCIALIZACION', value: 'TEC. EN COMERCIALIZACION' },
    { name: 'LIC. EN COMERCIALIZACION', value: 'LIC. EN COMERCIALIZACION' },
    { name: 'ASESOR DE MERCADEO', value: 'ASESOR DE MERCADEO' },
    { name: 'JOYERO Y RELOJERO', value: 'JOYERO Y RELOJERO' },
    { name: 'TEC. EN ADMINISTRACION DE PERSONAL', value: 'TEC. EN ADMINISTRACION DE PERSONAL' },
    { name: 'INDUSTRIAL SALINERO', value: 'INDUSTRIAL SALINERO' },
    { name: 'CORREDOR(A) DE SEGUROS', value: 'CORREDOR(A) DE SEGUROS' },
    { name: 'TEC. EN PANIFICACION', value: 'TEC. EN PANIFICACION' },
    { name: 'TEC. EN HOSTELERIA', value: 'TEC. EN HOSTELERIA' },
    { name: 'SECRETARIA EN COMPUTACION', value: 'SECRETARIA EN COMPUTACION' },
    { name: 'CAMARERO (A)', value: 'CAMARERO (A)' },
    { name: 'TEC EN SECRETARIADO SUPERIOR ADTIVO.', value: 'TEC EN SECRETARIADO SUPERIOR ADTIVO.' },
    { name: 'ADMINISTRACION DE PERSONAL', value: 'ADMINISTRACION DE PERSONAL' },
    { name: 'SECRETARIA MERCANTIL', value: 'SECRETARIA MERCANTIL' },
    { name: 'AGENTE ADUANAL', value: 'AGENTE ADUANAL' },
    { name: 'TEC. EN TURISMO', value: 'TEC. EN TURISMO' },
    { name: 'CORREDOR DE BOLSA', value: 'CORREDOR DE BOLSA' },
    { name: 'JARDINERO INJERTADOR', value: 'JARDINERO INJERTADOR' },
    { name: 'DISEÑADOR(A) DE MODA', value: 'DISEÑADOR(A) DE MODA' },
    { name: 'INTERMEDIARIO DE SEGUROS', value: 'INTERMEDIARIO DE SEGUROS' },
    { name: 'SECRETARIA BILINGÜE', value: 'SECRETARIA BILINGÜE' },
    { name: 'LIC. EN DISEÑO DE TEXTILES', value: 'LIC. EN DISEÑO DE TEXTILES' },
    { name: 'SECRETARIA EJECUTIVA EN LEYES', value: 'SECRETARIA EJECUTIVA EN LEYES' },
    { name: 'ING.(A) COMERCIAL', value: 'ING.(A) COMERCIAL' },
    { name: 'SALINERO', value: 'SALINERO' },
    { name: 'MASTER EN FINANZAS EMPRESARIALES', value: 'MASTER EN FINANZAS EMPRESARIALES' },
    { name: 'TEC. ADMINISTRACION TURISTICA', value: 'TEC. ADMINISTRACION TURISTICA' },
    { name: 'TEC. EN GUIA TURISTICO', value: 'TEC. EN GUIA TURISTICO' },
    { name: 'SECRETARIA GERENCIAL EN INFORMATICA', value: 'SECRETARIA GERENCIAL EN INFORMATICA' },
    { name: 'OFICIOS RELAT. A SALUD', value: 'OFICIOS RELAT. A SALUD' },
    { name: 'QUINESIOLOGO', value: 'QUINESIOLOGO' },
    { name: 'ACUPUNTURISTA', value: 'ACUPUNTURISTA' },
    { name: 'ENFERMERO(A)', value: 'ENFERMERO(A)' },
    { name: 'ENFERMERO(A) AUXILIAR', value: 'ENFERMERO(A) AUXILIAR' },
    { name: 'AYUDANTE DE ENFERMERIA', value: 'AYUDANTE DE ENFERMERIA' },
    { name: 'ESPECIALISTA EN RADIOLOGIA', value: 'ESPECIALISTA EN RADIOLOGIA' },
    { name: 'TEC. DENTAL', value: 'TEC. DENTAL' },
    { name: 'MECANICO DENTAL', value: 'MECANICO DENTAL' },
    { name: 'HIGIENISTA DENTAL', value: 'HIGIENISTA DENTAL' },
    { name: 'ASISTENTE DENTAL', value: 'ASISTENTE DENTAL' },
    { name: 'QUIROPRACTICO', value: 'QUIROPRACTICO' },
    { name: 'MASAJISTA', value: 'MASAJISTA' },
    { name: 'APRENDIZ EN MECANICA DENTAL', value: 'APRENDIZ EN MECANICA DENTAL' },
    { name: 'TEC. EN OPTICA', value: 'TEC. EN OPTICA' },
    { name: 'TECNOLOGO ORTOPEDA', value: 'TECNOLOGO ORTOPEDA' },
    { name: 'TERAPISTA DE LENGUA', value: 'TERAPISTA DE LENGUA' },
    { name: 'TERAPISTA OCUPACIONAL', value: 'TERAPISTA OCUPACIONAL' },
    { name: 'DELEGADO DE SALUD', value: 'DELEGADO DE SALUD' },
    { name: 'INSPECTOR SANITARIO', value: 'INSPECTOR SANITARIO' },
    { name: 'PARTERA AUTORIZADA', value: 'PARTERA AUTORIZADA' },
    { name: 'VACUNADOR', value: 'VACUNADOR' },
    { name: 'VISITADOR MEDICO', value: 'VISITADOR MEDICO' },
    { name: 'NATUROPATA', value: 'NATUROPATA' },
    { name: 'FARMACEUTICO', value: 'FARMACEUTICO' },
    { name: 'IDONEO EN FARMACIA', value: 'IDONEO EN FARMACIA' },
    { name: 'TEC. EN LABORATORIO', value: 'TEC. EN LABORATORIO' },
    { name: 'AUXILIAR DE LABORATORIO', value: 'AUXILIAR DE LABORATORIO' },
    { name: 'PROMOTOR(A) SOCIAL', value: 'PROMOTOR(A) SOCIAL' },
    { name: 'PROMOTOR(A) DE SALUD', value: 'PROMOTOR(A) DE SALUD' },
    { name: 'TRABAJADOR(A) SOCIAL', value: 'TRABAJADOR(A) SOCIAL' },
    { name: 'EDUCADORA SOCIAL', value: 'EDUCADORA SOCIAL' },
    { name: 'TRAMITADOR(A) SOCIAL', value: 'TRAMITADOR(A) SOCIAL' },
    { name: 'EDUCADOR EN SALUD', value: 'EDUCADOR EN SALUD' },
    { name: 'PARAMEDICO', value: 'PARAMEDICO' },
    { name: 'INSPECTOR VETERINARIO', value: 'INSPECTOR VETERINARIO' },
    { name: 'ENFERMERA(O) GRADUADA(O)', value: 'ENFERMERA(O) GRADUADA(O)' },
    { name: 'EDUCADOR(A) AMBIENTAL', value: 'EDUCADOR(A) AMBIENTAL' },
    { name: 'TEC. OPTICO', value: 'TEC. OPTICO' },
    { name: 'TERAPISTA RESPIRATORIO', value: 'TERAPISTA RESPIRATORIO' },
    { name: 'TEC. HISIOTERAPISTA', value: 'TEC. HISIOTERAPISTA' },
    { name: 'TECNOLOGO EN TERAPIA DE LENGUAJE', value: 'TECNOLOGO EN TERAPIA DE LENGUAJE' },
    { name: 'MEDICO PEDIATRA', value: 'MEDICO PEDIATRA' },
    { name: 'DOCTOR EN NATUROPATIA', value: 'DOCTOR EN NATUROPATIA' },
    { name: 'INSPECTOR DE OPERACIONES MEDICAS', value: 'INSPECTOR DE OPERACIONES MEDICAS' },
    { name: 'AUXILIAR DE ENFERMERIA', value: 'AUXILIAR DE ENFERMERIA' },
    { name: 'TEC. EN FARMACIA', value: 'TEC. EN FARMACIA' },
    { name: 'TEC.EN SALUD Y SANEAMIENTO AMBIENTAL', value: 'TEC.EN SALUD Y SANEAMIENTO AMBIENTAL' },
    { name: 'EDUCADOR (A)', value: 'EDUCADOR (A)' },
    { name: 'AYUDANTE EN SALUD', value: 'AYUDANTE EN SALUD' },
    { name: 'HOMEOPATA', value: 'HOMEOPATA' },
    { name: 'TECNICA DE HERBARIO', value: 'TECNICA DE HERBARIO' },
    { name: 'DR. (A) EN QUIROPRACTICA', value: 'DR. (A) EN QUIROPRACTICA' },
    { name: 'OFICIOS AGRIC/GANAD/PISCICULT/MEDIO AMB.', value: 'OFICIOS AGRIC/GANAD/PISCICULT/MEDIO AMB.' },
    { name: 'AGRICULTOR (A)', value: 'AGRICULTOR (A)' },
    { name: 'AGRICULTOR (A) EN PEQUEÑO', value: 'AGRICULTOR (A) EN PEQUEÑO' },
    { name: 'EMPLEADO AGRICOLA', value: 'EMPLEADO AGRICOLA' },
    { name: 'SEMBRADOR', value: 'SEMBRADOR' },
    { name: 'APICULTOR', value: 'APICULTOR' },
    { name: 'AVICULTOR(A)', value: 'AVICULTOR(A)' },
    { name: 'CAFICULTOR(A)', value: 'CAFICULTOR(A)' },
    { name: 'COOPERATIVISTA', value: 'COOPERATIVISTA' },
    { name: 'CUNICULICULTOR', value: 'CUNICULICULTOR' },
    { name: 'GANADERO (A)', value: 'GANADERO (A)' },
    { name: 'GRANJERO', value: 'GRANJERO' },
    { name: 'PERICULTOR', value: 'PERICULTOR' },
    { name: 'PERITO CAFICULTOR', value: 'PERITO CAFICULTOR' },
    { name: 'PISCICULTOR', value: 'PISCICULTOR' },
    { name: 'TEC. EN CONTROL DE PLAGAS', value: 'TEC. EN CONTROL DE PLAGAS' },
    { name: 'CABALLERANGO', value: 'CABALLERANGO' },
    { name: 'CAMPISTA', value: 'CAMPISTA' },
    { name: 'CAPORAL', value: 'CAPORAL' },
    { name: 'MANDADOR', value: 'MANDADOR' },
    { name: 'PEON AGRICOLA', value: 'PEON AGRICOLA' },
    { name: 'CORRALERO', value: 'CORRALERO' },
    { name: 'CORTADOR', value: 'CORTADOR' },
    { name: 'ESCRIBIENTE', value: 'ESCRIBIENTE' },
    { name: 'HENERO', value: 'HENERO' },
    { name: 'INSEMINADOR', value: 'INSEMINADOR' },
    { name: 'JORNALERO', value: 'JORNALERO' },
    { name: 'LABRADOR', value: 'LABRADOR' },
    { name: 'MAQUINARIO AGRICOLA', value: 'MAQUINARIO AGRICOLA' },
    { name: 'MONTANERO', value: 'MONTANERO' },
    { name: 'TRACTORISTA', value: 'TRACTORISTA' },
    { name: 'PATRON DE BARCO PESQUERO', value: 'PATRON DE BARCO PESQUERO' },
    { name: 'TEC. PESQUERO', value: 'TEC. PESQUERO' },
    { name: 'MARINO MERCANTE', value: 'MARINO MERCANTE' },
    { name: 'MARINERO', value: 'MARINERO' },
    { name: 'PESCADOR(A)', value: 'PESCADOR(A)' },
    { name: 'FLORICULTOR', value: 'FLORICULTOR' },
    { name: 'CAÑICULTOR', value: 'CAÑICULTOR' },
    { name: 'COLOIADOR', value: 'COLOIADOR' },
    { name: 'FUMIGADOR', value: 'FUMIGADOR' },
    { name: 'MECANICO AGRICOLA', value: 'MECANICO AGRICOLA' },
    { name: 'RASTRILLADOR', value: 'RASTRILLADOR' },
    { name: 'TRAPICHERO', value: 'TRAPICHERO' },
    { name: 'TRABAJADOR(A) AGRICOLA', value: 'TRABAJADOR(A) AGRICOLA' },
    { name: 'GUARDABOSQUES', value: 'GUARDABOSQUES' },
    { name: 'MARINO ELECTRICISTA', value: 'MARINO ELECTRICISTA' },
    { name: 'INSPECTOR TEC. EN SANEAMIENTO AMBIENTAL', value: 'INSPECTOR TEC. EN SANEAMIENTO AMBIENTAL' },
    { name: 'PERITO AGRICOLA', value: 'PERITO AGRICOLA' },
    { name: 'LIC. EN AGROINDUSTRIA', value: 'LIC. EN AGROINDUSTRIA' },
    { name: 'INGENIERO AGRICOLA', value: 'INGENIERO AGRICOLA' },
    { name: 'ING.(A) AVICOLA', value: 'ING.(A) AVICOLA' },
    { name: 'PESCADOR(A) ARTESANAL', value: 'PESCADOR(A) ARTESANAL' },
    { name: 'ACUICULTOR', value: 'ACUICULTOR' },
    { name: 'BUZO COMERCIAL', value: 'BUZO COMERCIAL' },
    { name: 'MARINERO DE BARCO PESQUERO', value: 'MARINERO DE BARCO PESQUERO' },
    { name: 'MARINO DE BARCO', value: 'MARINO DE BARCO' },
    { name: 'ING. EN MAQUINARIA AGRICOLA', value: 'ING. EN MAQUINARIA AGRICOLA' },
    { name: 'LARVICULTOR', value: 'LARVICULTOR' },
    { name: 'MAQUINISTA DE EMBARCACION PESQUERA', value: 'MAQUINISTA DE EMBARCACION PESQUERA' },
    { name: 'OFICIOS RELAT. A DERECHO', value: 'OFICIOS RELAT. A DERECHO' },
    { name: 'OFICIAL PUBLICO DE JUEZ EJECUTOR', value: 'OFICIAL PUBLICO DE JUEZ EJECUTOR' },
    { name: 'NOTIFICADOR', value: 'NOTIFICADOR' },
    { name: 'COLABORADOR JURIDICO', value: 'COLABORADOR JURIDICO' },
    { name: 'EJECUTOR DE EMBARGO', value: 'EJECUTOR DE EMBARGO' },
    { name: 'ASESOR JURIDICO', value: 'ASESOR JURIDICO' },
    { name: 'CONTRATISTA NOTARIAL', value: 'CONTRATISTA NOTARIAL' },
    { name: 'JUEZ (A)', value: 'JUEZ (A)' },
    { name: 'OFICIOS RELAT. A TECNOLOGIA', value: 'OFICIOS RELAT. A TECNOLOGIA' },
    { name: 'DISEÑADOR(A) DE TRAZOS', value: 'DISEÑADOR(A) DE TRAZOS' },
    { name: 'TOPOGRAFO EMPIRICO', value: 'TOPOGRAFO EMPIRICO' },
    { name: 'CADENERO', value: 'CADENERO' },
    { name: 'CONSTRUCTOR EMPIRICO', value: 'CONSTRUCTOR EMPIRICO' },
    { name: 'CONTRATISTA', value: 'CONTRATISTA' },
    { name: 'DIBUJANTE ARQUITECTONICO', value: 'DIBUJANTE ARQUITECTONICO' },
    { name: 'DIBUJANTE DE INGENIERIA', value: 'DIBUJANTE DE INGENIERIA' },
    { name: 'DISEÑADOR(A)', value: 'DISEÑADOR(A)' },
    { name: 'IMPRESOR LITOGRAFICO', value: 'IMPRESOR LITOGRAFICO' },
    { name: 'LITOGRAFO(A)', value: 'LITOGRAFO(A)' },
    { name: 'MAESTRO DE OBRA', value: 'MAESTRO DE OBRA' },
    { name: 'OBRERO(A)', value: 'OBRERO(A)' },
    { name: 'ALBAÑIL', value: 'ALBAÑIL' },
    { name: 'AYUDANTE DE ALBAÑIL', value: 'AYUDANTE DE ALBAÑIL' },
    { name: 'PERFORISTA', value: 'PERFORISTA' },
    { name: 'PEON DE CONSTRUCCION', value: 'PEON DE CONSTRUCCION' },
    { name: 'MOTONIVELADOR', value: 'MOTONIVELADOR' },
    { name: 'OPERADOR DE EQUIPO PESADO', value: 'OPERADOR DE EQUIPO PESADO' },
    { name: 'LADRILLERO', value: 'LADRILLERO' },
    { name: 'ENCIELADOR', value: 'ENCIELADOR' },
    { name: 'FONTANERO', value: 'FONTANERO' },
    { name: 'PLOMERO', value: 'PLOMERO' },
    { name: 'APRENDIZ DE OFICIO', value: 'APRENDIZ DE OFICIO' },
    { name: 'AYUDANTE DE OFICIO', value: 'AYUDANTE DE OFICIO' },
    { name: 'ASERRADOR', value: 'ASERRADOR' },
    { name: 'BARNIZADOR', value: 'BARNIZADOR' },
    { name: 'CARPINTERO', value: 'CARPINTERO' },
    { name: 'AYUDANTE DE MAQUINARIA PESADA', value: 'AYUDANTE DE MAQUINARIA PESADA' },
    { name: 'ENDEREZADOR Y PINTOR', value: 'ENDEREZADOR Y PINTOR' },
    { name: 'ENGRASADOR', value: 'ENGRASADOR' },
    { name: 'ENSAMBLADOR', value: 'ENSAMBLADOR' },
    { name: 'MECANICO DE BANCO', value: 'MECANICO DE BANCO' },
    { name: 'MECANICA FINA', value: 'MECANICA FINA' },
    { name: 'MECANICO', value: 'MECANICO' },
    { name: 'MECANICO AUTOMOTRIZ', value: 'MECANICO AUTOMOTRIZ' },
    { name: 'MECANICO INDUSTRIAL', value: 'MECANICO INDUSTRIAL' },
    { name: 'MECANICO DE AVIACION', value: 'MECANICO DE AVIACION' },
    { name: 'MECANICO DE MOLINOS', value: 'MECANICO DE MOLINOS' },
    { name: 'MECANICO TEXTIL', value: 'MECANICO TEXTIL' },
    { name: 'MECANICO ENDEREZADOR', value: 'MECANICO ENDEREZADOR' },
    { name: 'MECANICO SOLDADOR', value: 'MECANICO SOLDADOR' },
    { name: 'MECANICO TORNERO', value: 'MECANICO TORNERO' },
    { name: 'PINTOR AUTOMOTRIZ', value: 'PINTOR AUTOMOTRIZ' },
    { name: 'SOLDADOR', value: 'SOLDADOR' },
    { name: 'SOLDADOR AUTOMOTRIZ', value: 'SOLDADOR AUTOMOTRIZ' },
    { name: 'APRENDIZ DE MECANICA', value: 'APRENDIZ DE MECANICA' },
    { name: 'AYUDANTE DE ENDEREZADO Y PINTURA', value: 'AYUDANTE DE ENDEREZADO Y PINTURA' },
    { name: 'AYUDANTE DE MECANICO', value: 'AYUDANTE DE MECANICO' },
    { name: 'ENDEREZADOR', value: 'ENDEREZADOR' },
    { name: 'REPARADOR DE BATERIAS', value: 'REPARADOR DE BATERIAS' },
    { name: 'REPARADOR DE LLANTAS', value: 'REPARADOR DE LLANTAS' },
    { name: 'ELECTRICISTA', value: 'ELECTRICISTA' },
    { name: 'ELECTROMECANICO', value: 'ELECTROMECANICO' },
    { name: 'ELECTRONICO AUTOMOTRIZ', value: 'ELECTRONICO AUTOMOTRIZ' },
    { name: 'EMBOBINADOR', value: 'EMBOBINADOR' },
    { name: 'LINIERO MONTADOR', value: 'LINIERO MONTADOR' },
    { name: 'MECANICO EN ELECTRICIDAD', value: 'MECANICO EN ELECTRICIDAD' },
    { name: 'MECANICO EN ELECTRONICA', value: 'MECANICO EN ELECTRONICA' },
    { name: 'RADIOTECNICO', value: 'RADIOTECNICO' },
    { name: 'SOLDADOR ELECTRONICO', value: 'SOLDADOR ELECTRONICO' },
    { name: 'TEC. EN AIRE ACONDICIONADO', value: 'TEC. EN AIRE ACONDICIONADO' },
    { name: 'TEC. EN REFRIGERACION', value: 'TEC. EN REFRIGERACION' },
    { name: 'APRENDIZ DE ELECTRICISTA', value: 'APRENDIZ DE ELECTRICISTA' },
    { name: 'AYUDANTE DE ELECTRICISTA', value: 'AYUDANTE DE ELECTRICISTA' },
    { name: 'PROGRAMADOR DE COMPUTADORAS', value: 'PROGRAMADOR DE COMPUTADORAS' },
    { name: 'OPERADOR DE COMPUTADORAS', value: 'OPERADOR DE COMPUTADORAS' },
    { name: 'INSTRUCTOR DE COMPUTACION', value: 'INSTRUCTOR DE COMPUTACION' },
    { name: 'CONSULTOR DE INFORMATICA', value: 'CONSULTOR DE INFORMATICA' },
    { name: 'ASESOR EN INFORMATICA', value: 'ASESOR EN INFORMATICA' },
    { name: 'DIGITADOR(A)', value: 'DIGITADOR(A)' },
    { name: 'EMPRESARIO INDUSTRIAL', value: 'EMPRESARIO INDUSTRIAL' },
    { name: 'ARMERO', value: 'ARMERO' },
    { name: 'CALDERERO', value: 'CALDERERO' },
    { name: 'FUNDIDOR', value: 'FUNDIDOR' },
    { name: 'OBRERO INDUSTRIAL', value: 'OBRERO INDUSTRIAL' },
    { name: 'OPERARIO(A)', value: 'OPERARIO(A)' },
    { name: 'OPERARIO(A) INDUSTRIAL', value: 'OPERARIO(A) INDUSTRIAL' },
    { name: 'PINTOR INDUSTRIAL', value: 'PINTOR INDUSTRIAL' },
    { name: 'TEC. EN ALUMINIO', value: 'TEC. EN ALUMINIO' },
    { name: 'TEC. EN ARMAS', value: 'TEC. EN ARMAS' },
    { name: 'TEC. EN POLARIZADO', value: 'TEC. EN POLARIZADO' },
    { name: 'TEC. EN SISTEMA DE CABLE', value: 'TEC. EN SISTEMA DE CABLE' },
    { name: 'AUXILIAR DE DIGITADOR', value: 'AUXILIAR DE DIGITADOR' },
    { name: 'AUXILIAR DE OPERADOR', value: 'AUXILIAR DE OPERADOR' },
    { name: 'AYUDANTE DE OBRA DE BANCO', value: 'AYUDANTE DE OBRA DE BANCO' },
    { name: 'QUIMICO INDUSTRIAL', value: 'QUIMICO INDUSTRIAL' },
    { name: 'APRENDIZ DE CONSTRUCTOR', value: 'APRENDIZ DE CONSTRUCTOR' },
    { name: 'LABORATORISTA EN DIESEL', value: 'LABORATORISTA EN DIESEL' },
    { name: 'PULIDOR DE PISO', value: 'PULIDOR DE PISO' },
    { name: 'ARMADOR', value: 'ARMADOR' },
    { name: 'TEJERO', value: 'TEJERO' },
    { name: 'TORNERO DE MADERA', value: 'TORNERO DE MADERA' },
    { name: 'MECANICO NAVAL', value: 'MECANICO NAVAL' },
    { name: 'TRAILERO', value: 'TRAILERO' },
    { name: 'TORNERO', value: 'TORNERO' },
    { name: 'MECANICO MONTADOR', value: 'MECANICO MONTADOR' },
    { name: 'ELECTRICISTA INDUSTRIAL', value: 'ELECTRICISTA INDUSTRIAL' },
    { name: 'VULCANIZADOR', value: 'VULCANIZADOR' },
    { name: 'FREZADOR', value: 'FREZADOR' },
    { name: 'ASESOR TECNICO', value: 'ASESOR TECNICO' },
    { name: 'TEC. EN MANTTO. DE COMPUTADORAS', value: 'TEC. EN MANTTO. DE COMPUTADORAS' },
    { name: 'TECNOLOGO(A) EN RADIOTECNOLOGIA', value: 'TECNOLOGO(A) EN RADIOTECNOLOGIA' },
    { name: 'ELECTRICISTA AUTOMOTRIZ', value: 'ELECTRICISTA AUTOMOTRIZ' },
    { name: 'ING. MECANICO ELECTRICISTA', value: 'ING. MECANICO ELECTRICISTA' },
    { name: 'LAB. DE SUELOS', value: 'LAB. DE SUELOS' },
    { name: 'CARPINTERO SIN TALLER', value: 'CARPINTERO SIN TALLER' },
    { name: 'CONSTRUCTOR', value: 'CONSTRUCTOR' },
    { name: 'QUIMICO', value: 'QUIMICO' },
    { name: 'ING. QUIMICO INDUSTRIAL', value: 'ING. QUIMICO INDUSTRIAL' },
    { name: 'MECANICO EN REFRIGERACION', value: 'MECANICO EN REFRIGERACION' },
    { name: 'TECNICO EN QUIMICOS', value: 'TECNICO EN QUIMICOS' },
    { name: 'TEC. EN BOBINADO', value: 'TEC. EN BOBINADO' },
    { name: 'ENTOMOLOGO', value: 'ENTOMOLOGO' },
    { name: 'LIC. EN ASISTENCIA CIRCULATORIA', value: 'LIC. EN ASISTENCIA CIRCULATORIA' },
    { name: 'TEC. EN SOLDURA ELECTRICO', value: 'TEC. EN SOLDURA ELECTRICO' },
    { name: 'TECNICO MECANICO', value: 'TECNICO MECANICO' },
    { name: 'OPERADOR(A) EN SISTEMAS', value: 'OPERADOR(A) EN SISTEMAS' },
    { name: 'SOLDADOR ELECTRICO', value: 'SOLDADOR ELECTRICO' },
    { name: 'TEC. EN TELEFONIA', value: 'TEC. EN TELEFONIA' },
    { name: 'CARPINTERO TALLADOR', value: 'CARPINTERO TALLADOR' },
    { name: 'TEC. AVANZADA EN ING. DE CONFECCION IND.', value: 'TEC. AVANZADA EN ING. DE CONFECCION IND.' },
    { name: 'INGENIERO EN CONFECCION', value: 'INGENIERO EN CONFECCION' },
    { name: 'TEC. EN REFRIGERACION INDUSTRIAL', value: 'TEC. EN REFRIGERACION INDUSTRIAL' },
    { name: 'LINIERO ELECTRICISTA', value: 'LINIERO ELECTRICISTA' },
    { name: 'DIBUJANTE DE INGENIERIA Y ARQUITECTURA', value: 'DIBUJANTE DE INGENIERIA Y ARQUITECTURA' },
    { name: 'TELEFONISTA', value: 'TELEFONISTA' },
    { name: 'OPERADOR DE MAQUINAS', value: 'OPERADOR DE MAQUINAS' },
    { name: 'ANALISTA DE SISTEMAS', value: 'ANALISTA DE SISTEMAS' },
    { name: 'RADIODIFUSOR', value: 'RADIODIFUSOR' },
    { name: 'VITRALISTA', value: 'VITRALISTA' },
    { name: 'MECANICO EN ENDEREZADO Y PINTURA', value: 'MECANICO EN ENDEREZADO Y PINTURA' },
    { name: 'AYUDANTE DE CARPINTERIA', value: 'AYUDANTE DE CARPINTERIA' },
    { name: 'OFICIOS RELAT. A EDUC.', value: 'OFICIOS RELAT. A EDUC.' },
    { name: 'BIBLIOTECARIO(A)', value: 'BIBLIOTECARIO(A)' },
    { name: 'PROF.(A)', value: 'PROF.(A)' },
    { name: 'INSTRUCTOR(A)', value: 'INSTRUCTOR(A)' },
    { name: 'ESTUDIANTE', value: 'ESTUDIANTE' },
    { name: 'INSTRUCTOR DE OFICIOS', value: 'INSTRUCTOR DE OFICIOS' },
    { name: 'PROF.(A) DE ARTES MARCIALES', value: 'PROF.(A) DE ARTES MARCIALES' },
    { name: 'PROF.(A) DE AEROBICOS', value: 'PROF.(A) DE AEROBICOS' },
    { name: 'INSTRUCTOR DE MANEJO', value: 'INSTRUCTOR DE MANEJO' },
    { name: 'TEC. EN ACUACULTURA', value: 'TEC. EN ACUACULTURA' },
    { name: 'TEC. EN EDUCACION', value: 'TEC. EN EDUCACION' },
    { name: 'INSTRUCTOR(A) EN KARATE', value: 'INSTRUCTOR(A) EN KARATE' },
    { name: 'INSTRUCTOR DE NATACION', value: 'INSTRUCTOR DE NATACION' },
    { name: 'LIC. EN LINGÜISTICA', value: 'LIC. EN LINGÜISTICA' },
    { name: 'MATEMATICO', value: 'MATEMATICO' },
    { name: 'LIC.EN CIENC EDUC ESP EDU FIS DEP Y REC.', value: 'LIC.EN CIENC EDUC ESP EDU FIS DEP Y REC.' },
    { name: 'OFICIOS RELAT. A CIENCIAS SOCIALES', value: 'OFICIOS RELAT. A CIENCIAS SOCIALES' },
    { name: 'DIPLOMATICO (A)', value: 'DIPLOMATICO (A)' },
    { name: 'POLITICO', value: 'POLITICO' },
    { name: 'EMBAJADOR(A)', value: 'EMBAJADOR(A)' },
    { name: 'FUNCIONARIO PUBLICO', value: 'FUNCIONARIO PUBLICO' },
    { name: 'DIPLOMATICO DE CARRERA', value: 'DIPLOMATICO DE CARRERA' },
    { name: 'CONSUL GENERAL', value: 'CONSUL GENERAL' },
    { name: 'PROCURADOR GRAL. DE LA REPUBLICA', value: 'PROCURADOR GRAL. DE LA REPUBLICA' },
    { name: 'LIC. EN DIPLOMACIA', value: 'LIC. EN DIPLOMACIA' },
    { name: 'OFICIOS RELAT. A LA RELIGION', value: 'OFICIOS RELAT. A LA RELIGION' },
    { name: 'CLERIGO LITERARIO', value: 'CLERIGO LITERARIO' },
    { name: 'MINISTRO EVANGELICO', value: 'MINISTRO EVANGELICO' },
    { name: 'MISIONERO(A)', value: 'MISIONERO(A)' },
    { name: 'MONJA', value: 'MONJA' },
    { name: 'SACRISTAN', value: 'SACRISTAN' },
    { name: 'PRECURSOR RELIGIOSO', value: 'PRECURSOR RELIGIOSO' },
    { name: 'RELIGIOSO(A)', value: 'RELIGIOSO(A)' },
    { name: 'PREDICADOR', value: 'PREDICADOR' },
    { name: 'EVANGELIZADOR', value: 'EVANGELIZADOR' },
    { name: 'SEMINARISTA', value: 'SEMINARISTA' },
    { name: 'HERMANA RELIGIOSA', value: 'HERMANA RELIGIOSA' },
    { name: 'CATEQUISTA', value: 'CATEQUISTA' },
    { name: 'MINISTRO RELIGIOSO', value: 'MINISTRO RELIGIOSO' },
    { name: 'MINISTRO CRISTIANO', value: 'MINISTRO CRISTIANO' },
    { name: 'LIC. EN SIST. INFORMATICOS ADMINIS', value: 'LIC. EN SIST. INFORMATICOS ADMINIS' },
    { name: 'OFICIOS MILITARES O DE SEGURIDAD', value: 'OFICIOS MILITARES O DE SEGURIDAD' },
    { name: 'POLICIA MILITAR', value: 'POLICIA MILITAR' },
    { name: 'POLICIA NACIONAL CIVIL', value: 'POLICIA NACIONAL CIVIL' },
    { name: 'SOLDADO', value: 'SOLDADO' },
    { name: 'POLICIA DE ADUANA', value: 'POLICIA DE ADUANA' },
    { name: 'AGENTE DE SEGURIDAD PRIVADA', value: 'AGENTE DE SEGURIDAD PRIVADA' },
    { name: 'BOMBERO', value: 'BOMBERO' },
    { name: 'GUARDAESPALDA', value: 'GUARDAESPALDA' },
    { name: 'POLICIA MUNICIPAL', value: 'POLICIA MUNICIPAL' },
    { name: 'SALVAVIDAS', value: 'SALVAVIDAS' },
    { name: 'SERENO', value: 'SERENO' },
    { name: 'VIGILANTE', value: 'VIGILANTE' },
    { name: 'MILITAR RETIRADO', value: 'MILITAR RETIRADO' },
    { name: 'AGENTE DE SEGURIDAD PUBLICA', value: 'AGENTE DE SEGURIDAD PUBLICA' },
    { name: 'DETECTIVE', value: 'DETECTIVE' },
    { name: 'AGENTE PENITENCIARIO', value: 'AGENTE PENITENCIARIO' },
    { name: 'AGENTE METROPOLITANO', value: 'AGENTE METROPOLITANO' },
    { name: 'POLICIA BANCARIO', value: 'POLICIA BANCARIO' },
    { name: 'CUSTODIO', value: 'CUSTODIO' },
    { name: 'GUARDAVIDAS', value: 'GUARDAVIDAS' },
    { name: 'COMISIONADO', value: 'COMISIONADO' },
    { name: 'LIC (A) EN ADMON PUBLICA POLICIAL', value: 'LIC (A) EN ADMON PUBLICA POLICIAL' },
    { name: 'OTROS OFICIOS', value: 'OTROS OFICIOS' },
    { name: 'CHEF', value: 'CHEF' },
    { name: 'COCINERO(A)', value: 'COCINERO(A)' },
    { name: 'AMA DE CASA', value: 'AMA DE CASA' },
    { name: 'LAVANDERO(A)', value: 'LAVANDERO(A)' },
    { name: 'NIÑERA', value: 'NIÑERA' },
    { name: 'DOMESTICA', value: 'DOMESTICA' },
    { name: 'AEROMOZA', value: 'AEROMOZA' },
    { name: 'SOBRECARGO', value: 'SOBRECARGO' },
    { name: 'DELEGADO DE TRANSPORTE', value: 'DELEGADO DE TRANSPORTE' },
    { name: 'TRANSPORTISTA', value: 'TRANSPORTISTA' },
    { name: 'COBRADOR', value: 'COBRADOR' },
    { name: 'CAMIONERO', value: 'CAMIONERO' },
    { name: 'CARRETONERO', value: 'CARRETONERO' },
    { name: 'ESTIVADOR', value: 'ESTIVADOR' },
    { name: 'FERROCARRILERO', value: 'FERROCARRILERO' },
    { name: 'FOGONERO', value: 'FOGONERO' },
    { name: 'MAQUINISTA', value: 'MAQUINISTA' },
    { name: 'MOTOCICLISTA', value: 'MOTOCICLISTA' },
    { name: 'MOTORISTA', value: 'MOTORISTA' },
    { name: 'TAXISTA', value: 'TAXISTA' },
    { name: 'ENTRENADOR(A)', value: 'ENTRENADOR(A)' },
    { name: 'ARBITRO', value: 'ARBITRO' },
    { name: 'ATLETA', value: 'ATLETA' },
    { name: 'BASQUETBOLISTA', value: 'BASQUETBOLISTA' },
    { name: 'FUTBOLISTA', value: 'FUTBOLISTA' },
    { name: 'BEISBOLISTA', value: 'BEISBOLISTA' },
    { name: 'SOFTBOLISTA', value: 'SOFTBOLISTA' },
    { name: 'MOTOCICLISTA DEPORTIVO', value: 'MOTOCICLISTA DEPORTIVO' },
    { name: 'DEPORTISTA', value: 'DEPORTISTA' },
    { name: 'ANIMADOR(A)', value: 'ANIMADOR(A)' },
    { name: 'PRESENTADOR(A) DE NOTICIAS', value: 'PRESENTADOR(A) DE NOTICIAS' },
    { name: 'LOCUTOR(A)', value: 'LOCUTOR(A)' },
    { name: 'OPERADOR(A) DE RADIO', value: 'OPERADOR(A) DE RADIO' },
    { name: 'CRONISTA DEPORTIVO', value: 'CRONISTA DEPORTIVO' },
    { name: 'REPORTERO(A)', value: 'REPORTERO(A)' },
    { name: 'CAMAROGRAFO', value: 'CAMAROGRAFO' },
    { name: 'AYUDANTE DE CAMAROGRAFO', value: 'AYUDANTE DE CAMAROGRAFO' },
    { name: 'ESCENOGRAFO', value: 'ESCENOGRAFO' },
    { name: 'OPERADOR DE CAMARA', value: 'OPERADOR DE CAMARA' },
    { name: 'OPERADOR DE MAQUINA DE CINE', value: 'OPERADOR DE MAQUINA DE CINE' },
    { name: 'REALIZADOR DE PROGRAMAS', value: 'REALIZADOR DE PROGRAMAS' },
    { name: 'CARICATURISTA', value: 'CARICATURISTA' },
    { name: 'DIBUJANTE COMERCIAL', value: 'DIBUJANTE COMERCIAL' },
    { name: 'ROTULISTA', value: 'ROTULISTA' },
    { name: 'PINTOR ROTULISTA', value: 'PINTOR ROTULISTA' },
    { name: 'EDITOR(A)', value: 'EDITOR(A)' },
    { name: 'PERIODISTA', value: 'PERIODISTA' },
    { name: 'PUBLICISTA', value: 'PUBLICISTA' },
    { name: 'FOTOGRAFO(A)', value: 'FOTOGRAFO(A)' },
    { name: 'TEC. EN FOTOCOMPOSICION', value: 'TEC. EN FOTOCOMPOSICION' },
    { name: 'TEC. FOTOMECANICO', value: 'TEC. FOTOMECANICO' },
    { name: 'TEC. EN MICROFILM', value: 'TEC. EN MICROFILM' },
    { name: 'TEC. EN SONIDO', value: 'TEC. EN SONIDO' },
    { name: 'VOCERO', value: 'VOCERO' },
    { name: 'RADIOTELEGRAFISTA', value: 'RADIOTELEGRAFISTA' },
    { name: 'PENSIONADO O JUBILADO', value: 'PENSIONADO O JUBILADO' },
    { name: 'TELEGRAFISTA', value: 'TELEGRAFISTA' },
    { name: 'EMPLEADO(A)', value: 'EMPLEADO(A)' },
    { name: 'EMPLEADO(A) PUBLICO(A)', value: 'EMPLEADO(A) PUBLICO(A)' },
    { name: 'MECAPALERO(A)', value: 'MECAPALERO(A)' },
    { name: 'EMPLEADO DE ASEO', value: 'EMPLEADO DE ASEO' },
    { name: 'COMUNICADOR SOCIAL', value: 'COMUNICADOR SOCIAL' },
    { name: 'AUXILIAR DE SERVICIO', value: 'AUXILIAR DE SERVICIO' },
    { name: 'GASOLINERO', value: 'GASOLINERO' },
    { name: 'DISEÑADOR COMERCIAL', value: 'DISEÑADOR COMERCIAL' },
    { name: 'COBRADOR DE BUSES', value: 'COBRADOR DE BUSES' },
    { name: 'SOCORRISTA', value: 'SOCORRISTA' },
    { name: 'DIRECTOR DE CAMARA', value: 'DIRECTOR DE CAMARA' },
    { name: 'MICROSCOPISTA', value: 'MICROSCOPISTA' },
    { name: 'VOCEADOR', value: 'VOCEADOR' },
    { name: 'FOTOLITOGRAFO', value: 'FOTOLITOGRAFO' },
    { name: 'EMPLEADO COMERCIAL', value: 'EMPLEADO COMERCIAL' },
    { name: 'EMPLEADO INDUSTRIAL', value: 'EMPLEADO INDUSTRIAL' },
    { name: 'PERITO', value: 'PERITO' },
    { name: 'PRODUCTOR DE TEATRO', value: 'PRODUCTOR DE TEATRO' },
    { name: 'PRODUCTOR DE TV', value: 'PRODUCTOR DE TV' },
    { name: 'REDACTOR', value: 'REDACTOR' },
    { name: 'TALADOR', value: 'TALADOR' },
    { name: 'CONSERJE', value: 'CONSERJE' },
    { name: 'LUCHADOR', value: 'LUCHADOR' },
    { name: 'MOZO', value: 'MOZO' },
    { name: 'DISEÑADOR DE TATUAJES', value: 'DISEÑADOR DE TATUAJES' },
    { name: 'CATADOR', value: 'CATADOR' },
    { name: 'ACTUARIO', value: 'ACTUARIO' },
    { name: 'JEFE DE PERSONAL', value: 'JEFE DE PERSONAL' },
    { name: 'MAYORDOMO', value: 'MAYORDOMO' },
    { name: 'POLIGRAFISTA', value: 'POLIGRAFISTA' },
    { name: 'SUPERVISOR', value: 'SUPERVISOR' },
    { name: 'OPERARIA', value: 'OPERARIA' },
    { name: 'DOMESTICOS', value: 'DOMESTICOS' },
    { name: 'TORTILLERA', value: 'TORTILLERA' },
    { name: 'ANALISTA', value: 'ANALISTA' },
    { name: 'ING.(A) ELECTROMECÁNICO', value: 'ING.(A) ELECTROMECÁNICO' },
    { name: 'PRODUCTOR CINEMATOGRAFICO', value: 'PRODUCTOR CINEMATOGRAFICO' },
    { name: 'INTERPRETE/TRADUCTOR', value: 'INTERPRETE/TRADUCTOR' },
    { name: 'INSPECTOR DE CALIDAD', value: 'INSPECTOR DE CALIDAD' },
    { name: 'ENFIBRADOR', value: 'ENFIBRADOR' },
    { name: 'AUXILIAR DE INGENIERIA', value: 'AUXILIAR DE INGENIERIA' },
    { name: 'INSPECTOR EN OBRAS CIVILES', value: 'INSPECTOR EN OBRAS CIVILES' },
    { name: 'TEC. EN PREP. Y SERVIC. DE ALIMENTOS', value: 'TEC. EN PREP. Y SERVIC. DE ALIMENTOS' },
    { name: 'COORDINADOR DE TALLERES', value: 'COORDINADOR DE TALLERES' },
    { name: 'PULIDOR DE MUEBLES', value: 'PULIDOR DE MUEBLES' },
    { name: 'PEDICURISTA', value: 'PEDICURISTA' },
    { name: 'FOTOPERIODISTA', value: 'FOTOPERIODISTA' },
    { name: 'CONTROL DE CALIDAD', value: 'CONTROL DE CALIDAD' },
    { name: 'TENOR', value: 'TENOR' },
    { name: 'PIANISTA', value: 'PIANISTA' },
    { name: 'LIC. EN HOTELERIA Y TURISMO', value: 'LIC. EN HOTELERIA Y TURISMO' },
    { name: 'IMPORTADOR', value: 'IMPORTADOR' },
    { name: 'TECNICO EN AVIACION', value: 'TECNICO EN AVIACION' },
    { name: 'CONTROL DE TRANSITO AEREO', value: 'CONTROL DE TRANSITO AEREO' },
    { name: 'AUXILIAR DE CONSTRUCCION', value: 'AUXILIAR DE CONSTRUCCION' },
    { name: 'OPERARIO DE MAQUINAS TERMOFORMADORAS', value: 'OPERARIO DE MAQUINAS TERMOFORMADORAS' },
    { name: 'GUILLOTINISTA', value: 'GUILLOTINISTA' },
    { name: 'TECNICO EMBALSAMADOR', value: 'TECNICO EMBALSAMADOR' },
    { name: 'TEC. EN ESTRUCTURAS METALICAS', value: 'TEC. EN ESTRUCTURAS METALICAS' },
    { name: 'ASESOR', value: 'ASESOR' },
    { name: 'TEC. EN SOLAIRES', value: 'TEC. EN SOLAIRES' },
    { name: 'CORRESPONSAL DE DEPORTE', value: 'CORRESPONSAL DE DEPORTE' },
    { name: 'PRODUCTOR(A) DE TELEVISION', value: 'PRODUCTOR(A) DE TELEVISION' },
    { name: 'QUIMICO COLORISTA', value: 'QUIMICO COLORISTA' },
    { name: 'TECNICO AUDIOLOGO', value: 'TECNICO AUDIOLOGO' },
    { name: 'OPERADOR PROYECCIONISTA', value: 'OPERADOR PROYECCIONISTA' },
    { name: 'AUXILIAR DE BODEGA', value: 'AUXILIAR DE BODEGA' },
    { name: 'TECNICO EN SISTEMA DE CONTROL', value: 'TECNICO EN SISTEMA DE CONTROL' },
    { name: 'LIC. EN QUIMICA INDUSTRIAL', value: 'LIC. EN QUIMICA INDUSTRIAL' },
    { name: 'ARMADOR', value: 'ARMADOR' },
    { name: 'TECNICO DE CINE', value: 'TECNICO DE CINE' },
    { name: 'OPERARIO DE OPTICA', value: 'OPERARIO DE OPTICA' },
    { name: 'TELEOPERADOR(A)', value: 'TELEOPERADOR(A)' },
    { name: 'DISPLAY', value: 'DISPLAY' },
    { name: 'CHEF INTERNACIONAL', value: 'CHEF INTERNACIONAL' },
    { name: 'DIBUJANTE COMERCIAL PUBLICITARIO', value: 'DIBUJANTE COMERCIAL PUBLICITARIO' },
    { name: 'OFICIOS DOMESTICOS', value: 'OFICIOS DOMESTICOS' },
    { name: 'TEC. ARCHIVISTA Y MICROFILM', value: 'TEC. ARCHIVISTA Y MICROFILM' },
    { name: 'PROFESOR DE DANZA', value: 'PROFESOR DE DANZA' },
    { name: 'TEC. EJECUTIVO EN OPER. INTERNACIONALES', value: 'TEC. EJECUTIVO EN OPER. INTERNACIONALES' },
    { name: 'FOTOGRABADOR', value: 'FOTOGRABADOR' },
    { name: 'FOTOMECANICO', value: 'FOTOMECANICO' },
    { name: 'ESTETISISTA', value: 'ESTETISISTA' },
    { name: 'ALTA COSTURA Y DISEÑO', value: 'ALTA COSTURA Y DISEÑO' },
    { name: 'TEC. EN PROCESAMIENTO DE DATOS', value: 'TEC. EN PROCESAMIENTO DE DATOS' },
    { name: 'INSPECTOR DE CAMPO', value: 'INSPECTOR DE CAMPO' },
    { name: 'INGENIERO(A) COMERCIAL', value: 'INGENIERO(A) COMERCIAL' },
    { name: 'TEC. EN NEGOCIOS', value: 'TEC. EN NEGOCIOS' },
    { name: 'MECANICO EN ESTRUCTURAS METALICAS', value: 'MECANICO EN ESTRUCTURAS METALICAS' },
    { name: 'DISK JOCKEY', value: 'DISK JOCKEY' },
    { name: 'SOCIOLOGO (A)', value: 'SOCIOLOGO (A)' },
    { name: 'DESTAZADOR(A)', value: 'DESTAZADOR(A)' },
    { name: 'PERITO EN DACTILOSCOPIA', value: 'PERITO EN DACTILOSCOPIA' },
    { name: 'LICENCIADO(A) EN ADMON. EDUCATIVA', value: 'LICENCIADO(A) EN ADMON. EDUCATIVA' },
    { name: 'TEC. EN OBRA DE BANCO', value: 'TEC. EN OBRA DE BANCO' },
    { name: 'PERFUSIONISTA PRACTICO', value: 'PERFUSIONISTA PRACTICO' },
    { name: 'BUZO SOLDADOR', value: 'BUZO SOLDADOR' },
    { name: 'ENTRENADOR DE FOOTBALL', value: 'ENTRENADOR DE FOOTBALL' },
    { name: 'TEC. EN HOTELERIA Y TURISMO', value: 'TEC. EN HOTELERIA Y TURISMO' },
    { name: 'TECNICO EN REFINERIA', value: 'TECNICO EN REFINERIA' },
    { name: 'DESTILADOR', value: 'DESTILADOR' },
    { name: 'TECNICO EN INGENIERIA DE PLASTICOS', value: 'TECNICO EN INGENIERIA DE PLASTICOS' },
    { name: 'PRESENTADOR DE TV', value: 'PRESENTADOR DE TV' },
    { name: 'HIDROGEOLOGO', value: 'HIDROGEOLOGO' },
    { name: 'LOCUTOR DEPORTIVO', value: 'LOCUTOR DEPORTIVO' },
    { name: 'DINAMITERO', value: 'DINAMITERO' },
    { name: 'LIC. EN IDIOMA INGLES OPCION ENSEÑANZA', value: 'LIC. EN IDIOMA INGLES OPCION ENSEÑANZA' },
    { name: 'LIC(A) CC DE EDUC CON ESP EN EDU BASICA', value: 'LIC(A) CC DE EDUC CON ESP EN EDU BASICA' },
    { name: 'TEC. EN ING. DE REDES COMPUTACIONALES', value: 'TEC. EN ING. DE REDES COMPUTACIONALES' },
    { name: 'PROFESOR(A) EN EDUCACION ARTISTICA', value: 'PROFESOR(A) EN EDUCACION ARTISTICA' },
    { name: 'TEC EN ING ELECTRONICA INDUSTRIAL', value: 'TEC EN ING ELECTRONICA INDUSTRIAL' },
    { name: 'DOCTOR (A) EN EDUCACION', value: 'DOCTOR (A) EN EDUCACION' },
    { name: 'TECNICO EN GASTRONOMIA', value: 'TECNICO EN GASTRONOMIA' },
    { name: 'TECNICO EN INGENIERIA DE MECANICA DIESEL', value: 'TECNICO EN INGENIERIA DE MECANICA DIESEL' },
    { name: 'TEC (A).EN ING. DE SISTEMAS INFORMATICOS', value: 'TEC (A).EN ING. DE SISTEMAS INFORMATICOS' },
    { name: 'LIC.(A) EN HISTORIA', value: 'LIC.(A) EN HISTORIA' },
    { name: 'LIC.(A) EN  ECONOMIA EMPRESARIAL', value: 'LIC.(A) EN  ECONOMIA EMPRESARIAL' },
    { name: 'LIC.(A) EN COMUNIC. INTEG. DE MARKETING', value: 'LIC.(A) EN COMUNIC. INTEG. DE MARKETING' },
    { name: 'COCINERO PROFESIONAL', value: 'COCINERO PROFESIONAL' },
    { name: 'TECNICO(A). EN LOGISTICA Y ADUANAS', value: 'TECNICO(A). EN LOGISTICA Y ADUANAS' },
    { name: 'TECNICO SUPERIOR EN LACTEOS Y CARNICOS', value: 'TECNICO SUPERIOR EN LACTEOS Y CARNICOS' },
    { name: 'MEDICINA INTERNA', value: 'MEDICINA INTERNA' },
    { name: 'TECNICO EN ING. CIVIL Y CONSTRUCCION', value: 'TECNICO EN ING. CIVIL Y CONSTRUCCION' },
    { name: 'TECNICO BILINGÜE INGLES/ESPAÑOL', value: 'TECNICO BILINGÜE INGLES/ESPAÑOL' },
    { name: 'MS EN DIDACTICA PARA LA FORMACION DOC.', value: 'MS EN DIDACTICA PARA LA FORMACION DOC.' },
    { name: 'LIC.(A)  EN ADMON. FINANCIERA Y BANCARIA', value: 'LIC.(A)  EN ADMON. FINANCIERA Y BANCARIA' },
    { name: 'TECNICO EN INGENIERIA DE SOFTWARE', value: 'TECNICO EN INGENIERIA DE SOFTWARE' },
    { name: 'TECNOLOGO(A) EN GESTION INDUSTRIAL', value: 'TECNOLOGO(A) EN GESTION INDUSTRIAL' },
    { name: 'DR.(A) EN GINECOLOGIA Y OBSTETRICIA ', value: 'DR.(A) EN GINECOLOGIA Y OBSTETRICIA ' },
    { name: 'MTRO.(A) EN LA GESTION DE LA INNOVACION', value: 'MTRO.(A) EN LA GESTION DE LA INNOVACION' },
    { name: 'TEC.EN ING. DE LAS TELECOMUNICACIONES', value: 'TEC.EN ING. DE LAS TELECOMUNICACIONES' },
    { name: 'TECNICO EN MERCADOTECNIA', value: 'TECNICO EN MERCADOTECNIA' },
    { name: 'LIC.(A) EN GERENCIA INFORMATICA', value: 'LIC.(A) EN GERENCIA INFORMATICA' },
    { name: 'MTRO.(A) EN GESTION DE LA CALIDAD', value: 'MTRO.(A) EN GESTION DE LA CALIDAD' },
    { name: 'TECNICO SUPERIOR EN LOGISTICA GLOBAL', value: 'TECNICO SUPERIOR EN LOGISTICA GLOBAL' },
    { name: 'PROF.(A) EN EDUCACION FISICA Y DEPORTES', value: 'PROF.(A) EN EDUCACION FISICA Y DEPORTES' },
    { name: 'ING.(A) EN SISTEMAS Y REDES INFORMATICAS', value: 'ING.(A) EN SISTEMAS Y REDES INFORMATICAS' },
    { name: 'TEC.ING.(A) EN SIST. Y REDES INFORMAT.', value: 'TEC.ING.(A) EN SIST. Y REDES INFORMAT.' },
    { name: 'LIC.(A) EN IDIOMA INGLES', value: 'LIC.(A) EN IDIOMA INGLES' },
    { name: 'ING.(A)  EN SISTEMAS INFORMATICOS', value: 'ING.(A)  EN SISTEMAS INFORMATICOS' },
    { name: 'LIC.(A) EN TEOLOGIA C/ESP.E/MISIONOLOGIA', value: 'LIC.(A) EN TEOLOGIA C/ESP.E/MISIONOLOGIA' },
    { name: 'TEC. EN MULTIMEDIA', value: 'TEC. EN MULTIMEDIA' },
    { name: 'MAESTRA(O) EN DISEÑO GRAFICO', value: 'MAESTRA(O) EN DISEÑO GRAFICO' },
    { name: 'TEC. EN ADMON DE EMPRESAS GASTRONOMICA', value: 'TEC. EN ADMON DE EMPRESAS GASTRONOMICA' },
    { name: 'TECNICO EN SALUD AMBIENTAL', value: 'TECNICO EN SALUD AMBIENTAL' },
    { name: 'TECNICO SUPERIOR EN ELECTRONICA', value: 'TECNICO SUPERIOR EN ELECTRONICA' },
    { name: 'LIC(A).EN CIENCIAS DEL LENG Y LITERATURA', value: 'LIC(A).EN CIENCIAS DEL LENG Y LITERATURA' },
    { name: 'LIC.(A) EN MERCADEO INTERNACIONAL', value: 'LIC.(A) EN MERCADEO INTERNACIONAL' },
    { name: 'LIC.(A) EN SALUD AMBIENTAL', value: 'LIC.(A) EN SALUD AMBIENTAL' },
    { name: 'TECNICO BIBLIOTECOLOGO(A)', value: 'TECNICO BIBLIOTECOLOGO(A)' },
    { name: 'MS. EN TRADUCCION. ING. ESP.- ESP. ING', value: 'MS. EN TRADUCCION. ING. ESP.- ESP. ING' },
    { name: 'ING. (A) AGRONOMO C/ORIENT.PROD.AGRICOLA', value: 'ING. (A) AGRONOMO C/ORIENT.PROD.AGRICOLA' },
    { name: 'TECNICO EN ASISTENCIA DENTAL', value: 'TECNICO EN ASISTENCIA DENTAL' },
    { name: 'LIC.(A) EN C.C.DE LA EDUC.ESP.C.SOCIALES', value: 'LIC.(A) EN C.C.DE LA EDUC.ESP.C.SOCIALES' },
    { name: 'LICENCIADA EN CONTADURIA', value: 'LICENCIADA EN CONTADURIA' },
    { name: 'TEC. EN GESTION Y DESARROLLO TURISTICO', value: 'TEC. EN GESTION Y DESARROLLO TURISTICO' },
    { name: 'MS. EN GESTION ESTRATEGICA DE MARKETING', value: 'MS. EN GESTION ESTRATEGICA DE MARKETING' },
    { name: 'LIC.(A) EN DISEÑO ESTRATEGICO', value: 'LIC.(A) EN DISEÑO ESTRATEGICO' },
    { name: 'LIC.(A)EN ADMON DE EMPRESAS Y CONTADURIA', value: 'LIC.(A)EN ADMON DE EMPRESAS Y CONTADURIA' },
    { name: 'TECNICO EN ING DE DESARROLLO DE SOFTWARE', value: 'TECNICO EN ING DE DESARROLLO DE SOFTWARE' },
    { name: 'ESPECIALISTA EN CIRUGIA GENERAL', value: 'ESPECIALISTA EN CIRUGIA GENERAL' },
    { name: 'ING.(A) EN LOGISTICA Y ADUANAS', value: 'ING.(A) EN LOGISTICA Y ADUANAS' },
    { name: 'AUDIOLOGO', value: 'AUDIOLOGO' },
    { name: 'PROF. (A) EN EDUCACION MEDIA TECNICA', value: 'PROF. (A) EN EDUCACION MEDIA TECNICA' },
    { name: 'LIC(A).EN EDUC. C/E EN TEOLOGIA PASTORAL', value: 'LIC(A).EN EDUC. C/E EN TEOLOGIA PASTORAL' },
    { name: 'LIC.(A) EN FINANZAS EMPRESARIALES', value: 'LIC.(A) EN FINANZAS EMPRESARIALES' },
    { name: 'LIC.(A) EN C.EDU ESP DIR/ADMON/ESCOLAR', value: 'LIC.(A) EN C.EDU ESP DIR/ADMON/ESCOLAR' },
    { name: 'LIC. (A) EN TURISMO', value: 'LIC. (A) EN TURISMO' },
    { name: 'ING.(A) EN ADMON DE AGRONEGOCIOS', value: 'ING.(A) EN ADMON DE AGRONEGOCIOS' },
    { name: 'MS. EN POLITICA Y EVALUACION EDUCATIVA', value: 'MS. EN POLITICA Y EVALUACION EDUCATIVA' },
    { name: 'ESPECIALISTA EN MEDICINA PEDIATRICA', value: 'ESPECIALISTA EN MEDICINA PEDIATRICA' },
    { name: 'LIC.(A) EN MERCADEO Y NEGOCIOS INTERNAC.', value: 'LIC.(A) EN MERCADEO Y NEGOCIOS INTERNAC.' },
    { name: 'PROF.(A) EDU.MED.TEC.EN E/SIST.INFORMAT', value: 'PROF.(A) EDU.MED.TEC.EN E/SIST.INFORMAT' },
    { name: 'PROF. (A) EDU MED TEC ESP/LOG Y ADUANAS.', value: 'PROF. (A) EDU MED TEC ESP/LOG Y ADUANAS.' },
    { name: 'MAESTRO/A EN ADMINISTRACION FINANCIERA', value: 'MAESTRO/A EN ADMINISTRACION FINANCIERA' },
    { name: 'INGENIERO (A) DE NEGOCIOS', value: 'INGENIERO (A) DE NEGOCIOS' },
    { name: 'MS. EN INFORMATICA APLICADA A REDES', value: 'MS. EN INFORMATICA APLICADA A REDES' },
    { name: 'LIC.(A) ADMON GLOBAL DE NEGOCIOS', value: 'LIC.(A) ADMON GLOBAL DE NEGOCIOS' },
    { name: 'LIC.(A) EN ARTES PLASTICAS OPC PINTURA', value: 'LIC.(A) EN ARTES PLASTICAS OPC PINTURA' },
    { name: 'LIC. (A) EN ARTES PLASTICAS OP. CERAMICA', value: 'LIC. (A) EN ARTES PLASTICAS OP. CERAMICA' },
    { name: 'LICENCIADO (A) EN GESTION AMBIENTAL', value: 'LICENCIADO (A) EN GESTION AMBIENTAL' },
    { name: 'MAESTRA EN METODOS Y TEC DE INVESTIG SOC', value: 'MAESTRA EN METODOS Y TEC DE INVESTIG SOC' },
    { name: 'PROF. (A) EDU MED TEC EN LA ESP DE COMP.', value: 'PROF. (A) EDU MED TEC EN LA ESP DE COMP.' },
    { name: 'MAESTRO(A) EN ARQUITECTURA DE SOFTWARE', value: 'MAESTRO(A) EN ARQUITECTURA DE SOFTWARE' },
    { name: 'MASTER EN SISTEMAS DE INFORMACION', value: 'MASTER EN SISTEMAS DE INFORMACION' },
    { name: 'LIC(A) EN IDIOMAS CON ESP EN TURISMO', value: 'LIC(A) EN IDIOMAS CON ESP EN TURISMO' },
    { name: 'PROF.(A) DE EDUC MEDIA ESP DE MEC AUTOM.', value: 'PROF.(A) DE EDUC MEDIA ESP DE MEC AUTOM.' },
    { name: 'LIC.(A) EN CIENCIAS APLICADAS', value: 'LIC.(A) EN CIENCIAS APLICADAS' },
    { name: 'TEC. EN MANTTO. Y REP. DE COMPUTADORAS', value: 'TEC. EN MANTTO. Y REP. DE COMPUTADORAS' },
    { name: 'PROF. (A) EDU MED TEC EN ING EN COMP.', value: 'PROF. (A) EDU MED TEC EN ING EN COMP.' },
    { name: 'MAESTRO (A) EN GERENCIA PUBLICA Y SOCIAL', value: 'MAESTRO (A) EN GERENCIA PUBLICA Y SOCIAL' },
    { name: 'MAESTRO (A) EN GERENCIA DE PROYECTOS', value: 'MAESTRO (A) EN GERENCIA DE PROYECTOS' },
    { name: 'LIC (A) EN MATEMATICA OPCION EST - COMP', value: 'LIC (A) EN MATEMATICA OPCION EST - COMP' },
    { name: 'MAESTRO(A) EN LOGISTICA', value: 'MAESTRO(A) EN LOGISTICA' },
    { name: 'ESPECIALISTA EN GINEC. Y OBSTETRICIA', value: 'ESPECIALISTA EN GINEC. Y OBSTETRICIA' },
    { name: 'MAESTRO (A) EN ASESORIA EDUCATIVA', value: 'MAESTRO (A) EN ASESORIA EDUCATIVA' },
    { name: 'MS EN POLITICA ECON C/ENF EN ECON INTER', value: 'MS EN POLITICA ECON C/ENF EN ECON INTER' },
    { name: 'LIC.(A) EN TEC DE LA SALUD,TERA FIS Y RE', value: 'LIC.(A) EN TEC DE LA SALUD,TERA FIS Y RE' },
    { name: 'TECNICO(A) PROYECTISTA', value: 'TECNICO(A) PROYECTISTA' },
    { name: 'ASISTENTE CLINICO DE ENFERMERIA', value: 'ASISTENTE CLINICO DE ENFERMERIA' },
    { name: 'LIC (A) EN NEGOC. DE DISEÑO Y COMUNICAC.', value: 'LIC (A) EN NEGOC. DE DISEÑO Y COMUNICAC.' },
    { name: 'TEC. SUP. EN GESTION DE TURISMO ALTERNA.', value: 'TEC. SUP. EN GESTION DE TURISMO ALTERNA.' },
    { name: 'PROF. DE ENSEÑANZA MEDIA EN FILOSOFIA', value: 'PROF. DE ENSEÑANZA MEDIA EN FILOSOFIA' },
    { name: 'BORDADORA  A DOMICILIO', value: 'BORDADORA  A DOMICILIO' },
    { name: 'ARZOBISPO PRIMADO', value: 'ARZOBISPO PRIMADO' },
    { name: 'LIC(A) EN ADMON DE EMP. CON ENF EN COMP.', value: 'LIC(A) EN ADMON DE EMP. CON ENF EN COMP.' },
    { name: 'MS EN PREV DE LA VIOLENCIA Y CULT DE PAZ', value: 'MS EN PREV DE LA VIOLENCIA Y CULT DE PAZ' },
    { name: 'LIC EN ARTES PLASTICAS OPC. ESCULTURA', value: 'LIC EN ARTES PLASTICAS OPC. ESCULTURA' },
    { name: 'MAESTRO (A) EN DIRECCION DE EMPRESAS', value: 'MAESTRO (A) EN DIRECCION DE EMPRESAS' },
    { name: 'MEDICO (A) CARDIOLOGO', value: 'MEDICO (A) CARDIOLOGO' },
    { name: 'MASTER EN INGENIERIA WEB', value: 'MASTER EN INGENIERIA WEB' },
    { name: 'LICENCIADO EN ADMINISTRACION', value: 'LICENCIADO EN ADMINISTRACION' },
    { name: 'TEC. EN DES. EMP. Y SERV. FINANCIEROS', value: 'TEC. EN DES. EMP. Y SERV. FINANCIEROS' },
    { name: 'TEC. EN DESARROLLO DE APLIC. MOVILES', value: 'TEC. EN DESARROLLO DE APLIC. MOVILES' },
    { name: 'PROF. EN EDU.MEDIA P/ENSEÑ IDIOMA INGLE', value: 'PROF. EN EDU.MEDIA P/ENSEÑ IDIOMA INGLE' },
    { name: 'LIC(A) EN PSICOLOGIA PEDAGOGICA', value: 'LIC(A) EN PSICOLOGIA PEDAGOGICA' },
    { name: 'LIC(A). EN TRAD. E INTER. DEL IDIOMA ING', value: 'LIC(A). EN TRAD. E INTER. DEL IDIOMA ING' },
    { name: 'PROF EDUC FIS Y DEP PARA EDUC BAS Y MED', value: 'PROF EDUC FIS Y DEP PARA EDUC BAS Y MED' },
    { name: 'MAESTRO EN DER. PENAL CONSTITUCIONAL', value: 'MAESTRO EN DER. PENAL CONSTITUCIONAL' },
    { name: 'LIC. (A) EN GES. SOCIAL DEL DESA. LOCAL', value: 'LIC. (A) EN GES. SOCIAL DEL DESA. LOCAL' },
    { name: 'TECNICO EN LOGISTICA Y OPERACIONES', value: 'TECNICO EN LOGISTICA Y OPERACIONES' },
    { name: 'ING. (A) EN AGROINDUSTRIA ALIMENTARIA', value: 'ING. (A) EN AGROINDUSTRIA ALIMENTARIA' },
    { name: 'MAESTRIA EST DE CULTURA CENTROAMERICANA', value: 'MAESTRIA EST DE CULTURA CENTROAMERICANA' },
    { name: 'MAESTRO (A) EN FINANZAS EMPRESARIALES', value: 'MAESTRO (A) EN FINANZAS EMPRESARIALES' },
    { name: 'MAESTRO.(A) EN DESARROLLO TERRITORIAL', value: 'MAESTRO.(A) EN DESARROLLO TERRITORIAL' },
    { name: 'PROF.(A) EN EDUC. INICIAL Y PARVULARIA', value: 'PROF.(A) EN EDUC. INICIAL Y PARVULARIA' },
    { name: 'MAESTRO (A) EN NUEVAS TEC APLIC A LA EDU', value: 'MAESTRO (A) EN NUEVAS TEC APLIC A LA EDU' },
    { name: 'MAESTRO. (A). EN DIR. ESTRATEGICA DE EMP', value: 'MAESTRO. (A). EN DIR. ESTRATEGICA DE EMP' },
    { name: 'MAESTRO (A) EN DESA. LOCAL SOSTENIBLE', value: 'MAESTRO (A) EN DESA. LOCAL SOSTENIBLE' },
    { name: 'MAESTRO EN DIDACTICA DEL IDIOMA INGLES', value: 'MAESTRO EN DIDACTICA DEL IDIOMA INGLES' },
    { name: 'LIC(A) CC. DE EDU ESP/EDU. PARV BILINGUE', value: 'LIC(A) CC. DE EDU ESP/EDU. PARV BILINGUE' },
    { name: 'MASTER PROPIO EN CONFLICTOLOGIA', value: 'MASTER PROPIO EN CONFLICTOLOGIA' },
    { name: 'MAESTRO(A) EN GESTION DEL MEDIO AMBIENTE', value: 'MAESTRO(A) EN GESTION DEL MEDIO AMBIENTE' },
    { name: 'LICENCIADO.(A)  EN NEUROCIENCIA', value: 'LICENCIADO.(A)  EN NEUROCIENCIA' },
    { name: 'TEC. EN ADMINISTRACION DE RESTAURANTES', value: 'TEC. EN ADMINISTRACION DE RESTAURANTES' },
    { name: 'DR (A) EN INVEST E INNOVACION EDUCATIVA', value: 'DR (A) EN INVEST E INNOVACION EDUCATIVA' },
    { name: 'MAESTRO EN CONSULTORIA EMPRESARIAL', value: 'MAESTRO EN CONSULTORIA EMPRESARIAL' },
    { name: 'MAESTRIA EN DIRECCION ESTRAT. DE RRHH', value: 'MAESTRIA EN DIRECCION ESTRAT. DE RRHH' },
    { name: 'PROF EDUC MEDIA ENSEÑ LETRAS Y ESTETICA', value: 'PROF EDUC MEDIA ENSEÑ LETRAS Y ESTETICA' },
    { name: 'TECNICO EN TURISMO SOSTENIBLE', value: 'TECNICO EN TURISMO SOSTENIBLE' },
    { name: 'CAPITAN DE YATE', value: 'CAPITAN DE YATE' },
    { name: 'MAESTRO (A) EN ANTROPOLOGIA SOCIAL', value: 'MAESTRO (A) EN ANTROPOLOGIA SOCIAL' },
    { name: 'ING.(A) EN LOGISTICA Y DISTRIBUCION', value: 'ING.(A) EN LOGISTICA Y DISTRIBUCION' },
    { name: 'MAESTRA EN AUDITORIA FINANCIERA', value: 'MAESTRA EN AUDITORIA FINANCIERA' },
    { name: 'MAESTRA EN AUDITORIA FINANCIERA', value: 'MAESTRA EN AUDITORIA FINANCIERA' },
    { name: 'ESPEC. EN CIRUGIA ORAL Y MAXILOFACIAL', value: 'ESPEC. EN CIRUGIA ORAL Y MAXILOFACIAL' },
    { name: 'MASTER EN ADMON DEL COMERCIO INTL.', value: 'MASTER EN ADMON DEL COMERCIO INTL.' },
    { name: 'LIC. ADMON DE EMP BILINGÜE EN INGLES', value: 'LIC. ADMON DE EMP BILINGÜE EN INGLES' },
    { name: 'MS EN ATENCION INTEGRAL DE 1° INFANCIA', value: 'MS EN ATENCION INTEGRAL DE 1° INFANCIA' },
    { name: 'MAESTRO(A) EN DERECHO DE EMPRESA', value: 'MAESTRO(A) EN DERECHO DE EMPRESA' },
    { name: 'MAESTRIA EN AUDITORIA', value: 'MAESTRIA EN AUDITORIA' },
    { name: 'MAGISTER EN EDUCACION Y APRENDIZAJE', value: 'MAGISTER EN EDUCACION Y APRENDIZAJE' },
    { name: 'MTRO(A) EN METDS. Y TEC. DE INVEST. SOC.', value: 'MTRO(A) EN METDS. Y TEC. DE INVEST. SOC.' },
    { name: 'DOCTORADO EN EDUCACION ESP EN MED PEDAG', value: 'DOCTORADO EN EDUCACION ESP EN MED PEDAG' },
    { name: 'BACH. TEC. VOC. COM. OPCIÓN CONTADURIA', value: 'BACH. TEC. VOC. COM. OPCIÓN CONTADURIA' },
    { name: 'LIC(A) EN GESTION Y DESARROLLO TURISTICO', value: 'LIC(A) EN GESTION Y DESARROLLO TURISTICO' },
    { name: 'TECNICO ESPECIALISTA AIS', value: 'TECNICO ESPECIALISTA AIS' },
    { name: 'ING(A) EN CONTROL ELECTRICO', value: 'ING(A) EN CONTROL ELECTRICO' },
    { name: 'ESPECIALISTA EN MEDICINA INTERNA', value: 'ESPECIALISTA EN MEDICINA INTERNA' },
    { name: 'LICENCIADO(A) EN ANTROPOLOGIA SOCIAL', value: 'LICENCIADO(A) EN ANTROPOLOGIA SOCIAL' },
    { name: 'TEC.ING ELECTRIC AUTOMATIZACION ELECTRIC', value: 'TEC.ING ELECTRIC AUTOMATIZACION ELECTRIC' },
    { name: 'MTER.EN ADMON Y DREC DE COMERC Y DISTRIB', value: 'MTER.EN ADMON Y DREC DE COMERC Y DISTRIB' },
    { name: 'LIC (DA). EN COMUNICACION CORPORATIVA', value: 'LIC (DA). EN COMUNICACION CORPORATIVA' },
    { name: 'TECNICO EN SOFTWARE', value: 'TECNICO EN SOFTWARE' },
    { name: 'BACHILLER INDUSTRIAL OPCION AUTOMOTORES', value: 'BACHILLER INDUSTRIAL OPCION AUTOMOTORES' },
    { name: 'LIC(A) EN CONTABILIDAD Y FINANZAS', value: 'LIC(A) EN CONTABILIDAD Y FINANZAS' },
    { name: 'MASTER INTER EN HDA PUB Y ADMON FCIERA', value: 'MASTER INTER EN HDA PUB Y ADMON FCIERA' },
    { name: 'LIC.(A) EN TECNOLOGIA DE LA INFORMACION', value: 'LIC.(A) EN TECNOLOGIA DE LA INFORMACION' },
    { name: 'LIC(A) EN MERCADEO BILINGÜE', value: 'LIC(A) EN MERCADEO BILINGÜE' },
    { name: 'MAESTRO (A) EN ESTADISTICA', value: 'MAESTRO (A) EN ESTADISTICA' },
    { name: 'LIC(A) EN COMUNICACIÓN CORPORATIVA', value: 'LIC(A) EN COMUNICACIÓN CORPORATIVA' },
    { name: 'MÁSTER EN GERENCIA DE MANTO. INDUSTRIAL', value: 'MÁSTER EN GERENCIA DE MANTO. INDUSTRIAL' },
    { name: 'EVANGELISTA', value: 'EVANGELISTA' },
    { name: 'LIC.(A) EN ECONOMIA Y SOCIOLOGIA', value: 'LIC.(A) EN ECONOMIA Y SOCIOLOGIA' },
    { name: 'MTRO. (A) EN CC EN GES. INGRAL. DEL AGUA', value: 'MTRO. (A) EN CC EN GES. INGRAL. DEL AGUA' },
    { name: 'LIC.(A) EN TEC DE SALUD HIGIENE Y EPIDEM', value: 'LIC.(A) EN TEC DE SALUD HIGIENE Y EPIDEM' },
    { name: 'MAESTRO(A) EN FILOSOFIA IBEROAMERICANA', value: 'MAESTRO(A) EN FILOSOFIA IBEROAMERICANA' },
    { name: 'LIC (A) EN ENTRENAMIENTO DEPORTIVO', value: 'LIC (A) EN ENTRENAMIENTO DEPORTIVO' },
    { name: 'LIC.(A) EN ACTIVIDAD FISICA Y SALUD', value: 'LIC.(A) EN ACTIVIDAD FISICA Y SALUD' },
    { name: 'TECNICA SUPERIOR EN LACTEOS Y CARNICOS', value: 'TECNICA SUPERIOR EN LACTEOS Y CARNICOS' },
    { name: 'MAESTRO(A) EN INFORMATICA EDUCATIVA', value: 'MAESTRO(A) EN INFORMATICA EDUCATIVA' },
    { name: 'MAESTRO (A) EN MARKETING ESTRATEGICO', value: 'MAESTRO (A) EN MARKETING ESTRATEGICO' },
    { name: 'LIC(A) EN TECNOLOGIAS DE LA INFORMACION', value: 'LIC(A) EN TECNOLOGIAS DE LA INFORMACION' },
    { name: 'TECNICO (A) PROGRAMADOR EN SOFTWARE', value: 'TECNICO (A) PROGRAMADOR EN SOFTWARE' },
    { name: 'MAESTRO EN DERECHO FAMILIAR', value: 'MAESTRO EN DERECHO FAMILIAR' },
    { name: 'LIC(A) EN INFORM. Y ADMON. DE NEGOCIOS', value: 'LIC(A) EN INFORM. Y ADMON. DE NEGOCIOS' },
    { name: 'TECNICO ADMINISTRATIVO CONTABLE', value: 'TECNICO ADMINISTRATIVO CONTABLE' },
    { name: 'DOC EN EDU MENCION APRENDIZAJE SOCIAL', value: 'DOC EN EDU MENCION APRENDIZAJE SOCIAL' },
    { name: 'LIC. EN ADMON. DE EMPRESAS AGROPECUARIAS', value: 'LIC. EN ADMON. DE EMPRESAS AGROPECUARIAS' },
    { name: 'MAESTRO EN INGENIERIA ESTRUCTURAL', value: 'MAESTRO EN INGENIERIA ESTRUCTURAL' },
    { name: 'INGENIERO EN TELEMATICA', value: 'INGENIERO EN TELEMATICA' },
    { name: 'LIC.(A) EN COMUNICACIÓN CORPORATIVA', value: 'LIC.(A) EN COMUNICACIÓN CORPORATIVA' },
    { name: 'LICENCIADO(A) EN BIO ANALISIS CLINICO', value: 'LICENCIADO(A) EN BIO ANALISIS CLINICO' },
    { name: 'DR(A). EN DERECHO PRIVADO', value: 'DR(A). EN DERECHO PRIVADO' },
    { name: 'MST GEST CURR, DIDAC Y EVAL POR COMPET.', value: 'MST GEST CURR, DIDAC Y EVAL POR COMPET.' },
    { name: 'LIC.(A) EN MEDICINA HOSPITALARIA', value: 'LIC.(A) EN MEDICINA HOSPITALARIA' },
    { name: 'ING (A) EN GESTION DE AGRONEGOCIOS', value: 'ING (A) EN GESTION DE AGRONEGOCIOS' },
    { name: 'LIC(A) EN EDUCACION, ESP. MATEMATICA', value: 'LIC(A) EN EDUCACION, ESP. MATEMATICA' },
    { name: 'MASTER EN ESTUDIOS PROFESIONALES', value: 'MASTER EN ESTUDIOS PROFESIONALES' },
    { name: 'MTR. EN ENER. RENV. Y MEDIO AMBIENTE', value: 'MTR. EN ENER. RENV. Y MEDIO AMBIENTE' },
    { name: 'MAESTRIA EN ATENC A LA VIOLENC EN LA FAM', value: 'MAESTRIA EN ATENC A LA VIOLENC EN LA FAM' },
    { name: 'MAESTRIA EN BANCA Y FINANZAS', value: 'MAESTRIA EN BANCA Y FINANZAS' },
    { name: 'TECNICA EN GASTRONOMIA', value: 'TECNICA EN GASTRONOMIA' },
    { name: 'ING (A) EN ADMINISTRACION TERRITORIAL', value: 'ING (A) EN ADMINISTRACION TERRITORIAL' },
    { name: 'PROF. EN TEOLOGÍA PASTORAL', value: 'PROF. EN TEOLOGÍA PASTORAL' },
    { name: 'LIC. EN CC DE LA EDUC CON ESP EN CCN', value: 'LIC. EN CC DE LA EDUC CON ESP EN CCN' },
    { name: 'BARBERA', value: 'BARBERA' },
    { name: 'CURILERO', value: 'CURILERO' },
    { name: 'TECNICAS DE ARSENAL QUIRURGICO', value: 'TECNICAS DE ARSENAL QUIRURGICO' },
    { name: 'TEC. ADMINISTRATIVO CONTABLE', value: 'TEC. ADMINISTRATIVO CONTABLE' },
    { name: 'TEC GEST TECNOLOGICA DEL PATRIMONIO CULT', value: 'TEC GEST TECNOLOGICA DEL PATRIMONIO CULT' },
    { name: 'MAESTRA EN METODOLOGIA DE INVESTIG CIENT', value: 'MAESTRA EN METODOLOGIA DE INVESTIG CIENT' },
    { name: 'LIC. EN RELAC. Y NEGOC. INTERNACIONALES', value: 'LIC. EN RELAC. Y NEGOC. INTERNACIONALES' },
    { name: 'MTRO. EN METODOLOGIA DE LA INVESTIGACION', value: 'MTRO. EN METODOLOGIA DE LA INVESTIGACION' },
    { name: 'POLITICÓLOGO (A)', value: 'POLITICÓLOGO (A)' },
    { name: 'MAESTRIA EN DOCENCIA E INVESTIGACION EDU', value: 'MAESTRIA EN DOCENCIA E INVESTIGACION EDU' },
    { name: 'INVESTIGADOR(A) EN CIENCIA Y TECNOLOGIA', value: 'INVESTIGADOR(A) EN CIENCIA Y TECNOLOGIA' },
    { name: 'MTRO. EN METODOLOGÍA DE LA INVES. CIENT.', value: 'MTRO. EN METODOLOGÍA DE LA INVES. CIENT.' },
    { name: 'LIC(A) EN DISEÑO GRAFICO PUBLICITARIO', value: 'LIC(A) EN DISEÑO GRAFICO PUBLICITARIO' },
    { name: 'LIC.(A) EN EDUC. BASICA 1º Y 2º CICLOS', value: 'LIC.(A) EN EDUC. BASICA 1º Y 2º CICLOS' },
    { name: 'LIC(A) EN EDUCACION INICIAL Y PARVULARIA', value: 'LIC(A) EN EDUCACION INICIAL Y PARVULARIA' },
    { name: 'ING(A) EN AGROALIMENTACION', value: 'ING(A) EN AGROALIMENTACION' },
    { name: 'PROF (A) DE EDUC MED EN ORIENT EDUCATIVA', value: 'PROF (A) DE EDUC MED EN ORIENT EDUCATIVA' },
    { name: 'LIC (A) DE EDUC MED EN ORIENT EDUCATIVA', value: 'LIC (A) DE EDUC MED EN ORIENT EDUCATIVA' },
    { name: 'MAESTRO (A) EN MATEMATICA FUNDAMENTAL', value: 'MAESTRO (A) EN MATEMATICA FUNDAMENTAL' },
    { name: 'DOCTORADO EN ECONOMIA', value: 'DOCTORADO EN ECONOMIA' },
    { name: 'LIC (A) EN DISEÑO GRAFICO EDITORIAL', value: 'LIC (A) EN DISEÑO GRAFICO EDITORIAL' },
    { name: 'TEC. EN DISEÑO GRAFICO PUBLICITARIO', value: 'TEC. EN DISEÑO GRAFICO PUBLICITARIO' },
    { name: 'ING.(A) EN TELECOMUNICACIONES Y REDES', value: 'ING.(A) EN TELECOMUNICACIONES Y REDES' },
    { name: 'MTRO(A) EN GEST. ESTRATEG. DE LA COM.', value: 'MTRO(A) EN GEST. ESTRATEG. DE LA COM.' },
    { name: 'MTRO(A) EN GEST. ESTRATEG. DE LA COM.', value: 'MTRO(A) EN GEST. ESTRATEG. DE LA COM.' },
    { name: 'PROF. EDUC.MEDIA TEC ESPEC GASTRONOMIA', value: 'PROF. EDUC.MEDIA TEC ESPEC GASTRONOMIA' },
    { name: 'MAESTRO (A) EN PSICOLOGIA', value: 'MAESTRO (A) EN PSICOLOGIA' },
    { name: 'MAGISTER EN GOBIERNO Y GERENCIA PUBLICA', value: 'MAGISTER EN GOBIERNO Y GERENCIA PUBLICA' }
];

export const listAcdName = [
    {
        name: 'ViciDial',
        value: 'VICIDIAL'
    },
    {
        name: 'DeskTime',
        value: 'DESKTIME'
    },
    {
        name: 'SingleComm',
        value: 'SINGLECOMM'
    },
    {
        name: 'Brightmetrics',
        value: 'BRIGHTMETRICS'
    },
    {
        name: 'Genesys Cloud',
        value: 'GENESYSCLOUD'
    },
    {
        name: 'Verint',
        value: 'VERINT'
    },
    {
        name: 'Frame',
        value: 'FRAME'
    },
    {
        name: 'Five 9',
        value: 'FIVENINE'
    },
    {
        name: 'Nice IEX',
        value: 'NICEIEX'
    },
    {
        name: 'Web Clock',
        value: 'WEBCLOCK'
    }
];

export const listTiposAfp = [
    { name: 'AFP Crecer', value: 'CRECER' },
    { name: 'AFP CONFIA', value: 'CONFIA' },
    { name: 'Otro', value: 'OTRO' }
];

export const listPais = [
    { name: 'El Salvador', value: 'SLV' },
    { name: 'Colombia', value: 'COL' },
    { name: 'Guatemala', value: 'GTM' },
    { name: 'Filipinas', value: 'PHL' },
    { name: 'México', value: 'MEX' }
];

export const listEstadosExcepciones = [
    { name: 'Pendiente', value: '0' },
    { name: 'Aprobada', value: '1' },
    { name: 'Rechazada', value: '2' },
    { name: 'Fallo Ejecucion', value: '3' }
];

export const listSociedades = [
    { name: 'SkyCom Latinoamerica', value: 'LT/LTSC/GT' },
    { name: 'SkyCom El Salvador', value: 'SV' }
];

export const listBancos = [
    { name: 'Banco Agricola, S.A.', value: 'Banco Agricola, S.A.' },
    { name: 'Banco Cuscatlan de El Salvador, S.A.', value: 'Banco Cuscatlan de El Salvador, S.A.' },
    { name: 'Banco Davivienda Salvadoreño, S.A.', value: 'Banco Davivienda Salvadoreño, S.A.' },
    { name: 'Banco Hipotecario de El Salvador, S.A.', value: 'Banco Hipotecario de El Salvador, S.A.' },
    { name: 'Citibank, N.A., Sucursal El Salvador.', value: 'Citibank, N.A., Sucursal El Salvador.' },
    { name: 'Banco de Fomento Agropecuario.', value: 'Banco de Fomento Agropecuario.' },
    { name: 'Banco Promerica, S.A.', value: 'Banco Promerica, S.A.' },
    { name: 'Banco de America Central, S.A.', value: 'Banco de America Central, S.A.' },

    { name: 'Bancolombia', value: 'Bancolombia' },
    { name: 'Scotiabank Colpatria', value: 'Scotiabank Colpatria' },
    { name: 'Davivienda', value: 'Davivienda' }
];

export const listPropiedadesApFormik = {
    FechaInicio: 12,
    FechaFinal: 13,
    FechaEfectiva: 11,
    TipoVacacion: 15,
    Observacion: 14,
    TipoFalta: 4,
    TipoSancion: 3,
    TipoDespido: 16,
    Salario: 9,
    Cuenta: 8,
    Lob: 1,
    Lob2: '',
    Plaza: 2,
    Supervisor: 6,
    AccountManager: 19,
    Director: 18,
    TiempoCompleto: 21,
    HorasJustificar: 22,
    RazonTerminacion: 23,
    TipoTerminacion: 24
};

export const listTipoAttrition = [
    { name: 'Training', value: 'Training' },
    { name: 'Production', value: 'Production' },
    { name: 'Operative', value: 'Operative' },
];

export const listAllotment = [
    { name: 'Admin', value: 'Admin' },
    { name: 'Staff', value: 'Staff' },
    { name: 'Cuenta', value: 'Cuenta' },
];

export const TermType = [
    { name: 'Job abandonment', value: 'Job abandonment' },
    { name: 'Resignment', value: 'Resignment' },
    { name: 'Dismissal', value: 'Dismissal' },
    { name: 'Promotion', value: 'Promotion' },
    { name: 'Transfer', value: 'Transfer' }
];

export const TypeResignation = [
    { name: 'Term Condition', value: 'Term Condition' },
    { name: 'Ley de Renuncia Voluntaria', value: 'Ley de Renuncia Voluntaria' },
    { name: 'Otro', value: 'Otro' }
];

export const TermReason = [
    { name: 'D - Abandon', value: 'D - Abandon' },
    { name: 'D - Performance', value: 'D - Performance' },
    { name: 'D - Behaviour', value: 'D - Behaviour' },
    { name: 'D - Zero Tolerance', value: 'D - Zero Tolerance' },
    { name: 'D - Client Term', value: 'D - Client Term' },
    { name: 'D - Program Request', value: 'D - Program Request' },
    { name: 'D - Other Reason', value: 'D - Other Reason' },
    { name: 'D - Training', value: 'D - Training' },
    { name: 'R - No reason', value: 'R - No reason' },
    { name: 'R - Other Job', value: 'R - Other Job' },
    { name: 'R - Studies', value: 'R - Studies' },
    { name: 'R - Medical Reason', value: 'R - Medical Reason' },
    { name: 'R - Family Reason', value: 'R - Family Reason' },
    { name: 'R - Migration', value: 'R - Migration' },
    { name: 'R - Management Related', value: 'R - Management Related' },
    { name: 'R - Other Reason', value: 'R - Other Reason' },
    { name: 'R - Training', value: 'R - Training' },
    { name: 'Transfer', value: 'Transfer' },
    { name: 'Promotion', value: 'Promotion' }
];

export const listPropiedadesFormik = {
    // personal
    Nombre: 2,
    Dui: 41,
    DocumentoIdentidad: 58,
    Nit: 42,
    Carnet: 43,
    Sexo: 44,
    FechaNacimiento: 45,
    Parent: 26,
    EstadoEmpleado: 39,
    Profesion: 46,
    Pais: 4,
    Departamento: 34,
    Municipio: 35,
    Direccion: 33,
    Site: 21,
    Phone: 28,
    Phone_2: 29,
    Phone_3: 30,
    EmergencyName: 36,
    EmergencyPhone: 31,
    Seguro: 37,
    Plaza: 6,
    AssaNumber: 47,

    // production
    Supervisor: 10,
    SupervisorName: '',
    AccountManager: 11,
    AccountManagerName: '',
    Director: 12,
    DirectorName: '',
    HireDT: 14,
    TrainingDT: 15,
    ProductionDT: 16,
    Lob2DT: 19,
    Lob3DT: 20,
    Cuenta: 3,
    Lob: 5,
    SubLob: 17,

    // IT
    Byod: 22,
    AccessCard: 23,
    WindowsUser: 24,
    InternalEmail: 25,
    PersonalEmail: 27,
    AnyDesk: 32,

    // payroll
    Sociedad: 48,
    Salario: 38,
    Bono: 49,
    Comision: 50,
    Banco: 51,
    CuentaBanco: 52,
    NumeroISSS: 53,
    Nup: 54,
    AFP: 40,
    TipoAfp: 55,
    IPSFA: 56,

    // Horas
    Acd: 57,
    AcdID: 7,
    AcdName: 8,
    AcdSkill: 9
};

export const listExts = {
    SLV: '(+503) 9999 - 9999',
    COL: '(+57) 999 999 999 9',
    GTM: '(+502) 9999 9999',
    PHL: '(+63) 999 9999',
    MEX: '(+52) 999 999 999 9'
};

export const listEdificios = {
    SLV: [
        { name: 'Skycom 1', value: 'Skycom 1' },
        { name: 'Skycom 2', value: 'Skycom 2' },
        { name: 'Work From Home', value: 'WFH' }
    ],
    COL: [
        { name: 'Medellin', value: 'Medellin' },
        { name: 'Work From Home', value: 'WFH' }
    ],
    GTM: [
        { name: 'Skycom Guatemala', value: 'Skycom Guatemala' },
        { name: 'Work From Home', value: 'WFH' }
    ],
    PHL: [{ name: 'Work From Home', value: 'WFH' }],
    MEX: [{ name: 'Work From Home', value: 'WFH' }]
};

export const listSeguros = [
    { name: 'Seguro ASSA', value: 'ASSA' },
    { name: 'Seguro Privado', value: 'PRIVADO' },
    //{ name: 'Seguro Social', value: 'SOCIAL' },
    { name: 'Instituto de Salud Publica', value: 'SOCIAL' }
];

export const listDepartamentos = [
    { name: 'Ahuachapán', value: 'Ahuachapán' },
    { name: 'Santa Ana', value: 'Santa Ana' },
    { name: 'Sonsonate', value: 'Sonsonate' },
    { name: 'La Libertad', value: 'La Libertad' },
    { name: 'Chalatenango', value: 'Chalatenango' },
    { name: 'San Salvador', value: 'San Salvador' },
    { name: 'Cuscatlán', value: 'Cuscatlán' },
    { name: 'La Paz', value: 'La Paz' },
    { name: 'Cabañas', value: 'Cabañas' },
    { name: 'San Vicente', value: 'San Vicente' },
    { name: 'Usulután', value: 'Usulután' },
    { name: 'Morazán', value: 'Morazán' },
    { name: 'San Miguel', value: 'San Miguel' },
    { name: 'La Unión', value: 'La Unión' }
];

export const listMunicipios = {
    Ahuachapán: [
        { name: 'Ahuachapán', value: 'Ahuachapán' },
        { name: 'Jujutla', value: 'Jujutla' },
        { name: 'Atiquizaya', value: 'Atiquizaya' },
        { name: 'Concepción de Ataco', value: 'Concepción de Ataco' },
        { name: 'El Refugio', value: 'El Refugio' },
        { name: 'Guaymango', value: 'Guaymango' },
        { name: 'Apaneca', value: 'Apaneca' },
        { name: 'San Francisco Menéndez', value: 'San Francisco Menéndez' },
        { name: 'San Lorenzo', value: 'San Lorenzo' },
        { name: 'San Pedro Puxtla', value: 'San Pedro Puxtla' },
        { name: 'Tacuba', value: 'Tacuba' },
        { name: 'Turín', value: 'Turín' }
    ],
    'Santa Ana': [
        { name: 'Candelaria de la Frontera', value: 'Candelaria de la Frontera' },
        { name: 'Chalchuapa', value: 'Chalchuapa' },
        { name: 'Coatepeque', value: 'Coatepeque' },
        { name: 'El Congo', value: 'El Congo' },
        { name: 'El Porvenir', value: 'El Porvenir' },
        { name: 'Masahuat', value: 'Masahuat' },
        { name: 'Metapán', value: 'Metapán' },
        { name: 'San Antonio Pajonal', value: 'San Antonio Pajonal' },
        { name: 'San Sebastián Salitrillo', value: 'San Sebastián Salitrillo' },
        { name: 'Santa Ana', value: 'Santa Ana' },
        { name: 'Santa Rosa Guachipilín', value: 'Santa Rosa Guachipilín' },
        { name: 'Santiago de la Frontera', value: 'Santiago de la Frontera' },
        { name: 'Texistepeque', value: 'Texistepeque' }
    ],
    Sonsonate: [
        { name: 'Acajutla', value: 'Acajutla' },
        { name: 'Armenia', value: 'Armenia' },
        { name: 'Caluco', value: 'Caluco' },
        { name: 'Cuisnahuat', value: 'Cuisnahuat' },
        { name: 'Izalco', value: 'Izalco' },
        { name: 'Juayúa', value: 'Juayúa' },
        { name: 'Nahuizalco', value: 'Nahuizalco' },
        { name: 'Nahulingo', value: 'Nahulingo' },
        { name: 'Salcoatitán', value: 'Salcoatitán' },
        { name: 'San Antonio del Monte', value: 'San Antonio del Monte' },
        { name: 'San Julián', value: 'San Julián' },
        { name: 'Santa Catarina Masahuat', value: 'Santa Catarina Masahuat' },
        { name: 'Santa Isabel Ishuatán', value: 'Santa Isabel Ishuatán' },
        { name: 'Santo Domingo de Guzmán', value: 'Santo Domingo de Guzmán' },
        { name: 'Sonsonate', value: 'Sonsonate' },
        { name: 'Sonzacate', value: 'Sonzacate' }
    ],
    'La Libertad': [
        { name: 'Antiguo Cuscatlán', value: 'Antiguo Cuscatlán' },
        { name: 'Chiltiupán', value: 'Chiltiupán' },
        { name: 'Ciudad Arce', value: 'Ciudad Arce' },
        { name: 'Colón', value: 'Colón' },
        { name: 'Comasagua', value: 'Comasagua' },
        { name: 'Huizúcar', value: 'Huizúcar' },
        { name: 'Jayaque', value: 'Jayaque' },
        { name: 'Jicalapa', value: 'Jicalapa' },
        { name: 'La Libertad', value: 'La Libertad' },
        { name: 'Santa Tecla', value: 'Santa Tecla' },
        { name: 'Nuevo Cuscatlán', value: 'Nuevo Cuscatlán' },
        { name: 'San Juan Opico', value: 'San Juan Opico' },
        { name: 'Quezaltepeque', value: 'Quezaltepeque' },
        { name: 'Sacacoyo', value: 'Sacacoyo' },
        { name: 'San José Villanueva', value: 'San José Villanueva' },
        { name: 'San Matías', value: 'San Matías' },
        { name: 'San Pablo Tacachico', value: 'San Pablo Tacachico' },
        { name: 'Talnique', value: 'Talnique' },
        { name: 'Tamanique', value: 'Tamanique' },
        { name: 'Teotepeque', value: 'Teotepeque' },
        { name: 'Tepecoyo', value: 'Tepecoyo' },
        { name: 'Zaragoza', value: 'Zaragoza' }
    ],
    Chalatenango: [
        { name: 'Agua Caliente', value: 'Agua Caliente' },
        { name: 'Arcatao', value: 'Arcatao' },
        { name: 'Azacualpa', value: 'Azacualpa' },
        { name: 'Cancasque', value: 'Cancasque' },
        { name: 'Chalatenango', value: 'Chalatenango' },
        { name: 'Citalá', value: 'Citalá' },
        { name: 'Comapala', value: 'Comapala' },
        { name: 'Concepción Quezaltepeque', value: 'Concepción Quezaltepeque' },
        { name: 'Dulce Nombre de María', value: 'Dulce Nombre de María' },
        { name: 'El Carrizal', value: 'El Carrizal' },
        { name: 'El Paraíso', value: 'El Paraíso' },
        { name: 'La Laguna', value: 'La Laguna' },
        { name: 'La Palma', value: 'La Palma' },
        { name: 'La Reina', value: 'La Reina' },
        { name: 'Las Vueltas', value: 'Las Vueltas' },
        { name: 'Nueva Concepción', value: 'Nueva Concepción' },
        { name: 'Nueva Trinidad', value: 'Nueva Trinidad' },
        { name: 'Nombre de Jesús', value: 'Nombre de Jesús' },
        { name: 'Ojos de Agua', value: 'Ojos de Agua' },
        { name: 'Potonico', value: 'Potonico' },
        { name: 'San Antonio de la Cruz', value: 'San Antonio de la Cruz' },
        { name: 'San Antonio Los Ranchos', value: 'San Antonio Los Ranchos' },
        {
            name: 'San Fernando (Chalatenango)',
            value: 'San Fernando (Chalatenango)'
        },
        { name: 'San Francisco Lempa', value: 'San Francisco Lempa' },
        { name: 'San Francisco Morazán', value: 'San Francisco Morazán' },
        { name: 'San Ignacio', value: 'San Ignacio' },
        { name: 'San Isidro Labrador', value: 'San Isidro Labrador' },
        { name: 'Las Flores', value: 'Las Flores' },
        { name: 'San Luis del Carmen', value: 'San Luis del Carmen' },
        { name: 'San Miguel de Mercedes', value: 'San Miguel de Mercedes' },
        { name: 'San Rafael', value: 'San Rafael' },
        { name: 'Santa Rita', value: 'Santa Rita' },
        { name: 'Tejutla', value: 'Tejutla' }
    ],
    'San Salvador': [
        { name: 'Aguilares', value: 'Aguilares' },
        { name: 'Apopa', value: 'Apopa' },
        { name: 'Ayutuxtepeque', value: 'Ayutuxtepeque' },
        { name: 'Cuscatancingo', value: 'Cuscatancingo' },
        { name: 'Ciudad Delgado', value: 'Ciudad Delgado' },
        { name: 'El Paisnal', value: 'El Paisnal' },
        { name: 'Guazapa', value: 'Guazapa' },
        { name: 'Ilopango', value: 'Ilopango' },
        { name: 'Mejicanos', value: 'Mejicanos' },
        { name: 'Nejapa', value: 'Nejapa' },
        { name: 'Panchimalco', value: 'Panchimalco' },
        { name: 'Rosario de Mora', value: 'Rosario de Mora' },
        { name: 'San Marcos', value: 'San Marcos' },
        { name: 'San Martín', value: 'San Martín' },
        { name: 'San Salvador', value: 'San Salvador' },
        { name: 'Santiago Texacuangos', value: 'Santiago Texacuangos' },
        { name: 'Santo Tomás', value: 'Santo Tomás' },
        { name: 'Soyapango', value: 'Soyapango' },
        { name: 'Tonacatepeque', value: 'Tonacatepeque' }
    ],
    Cuscatlán: [
        { name: 'Cojutepeque', value: 'Cojutepeque' },
        { name: 'Candelaria', value: 'Candelaria' },
        { name: 'El Carmen (Cuscatlán)', value: 'El Carmen (Cuscatlán)' },
        { name: 'El Rosario (Cuscatlán)', value: 'El Rosario (Cuscatlán)' },
        { name: 'Monte San Juan', value: 'Monte San Juan' },
        { name: 'Oratorio de Concepción', value: 'Oratorio de Concepción' },
        { name: 'San Bartolomé Perulapía', value: 'San Bartolomé Perulapía' },
        { name: 'San Cristóbal', value: 'San Cristóbal' },
        { name: 'San José Guayabal', value: 'San José Guayabal' },
        { name: 'San Pedro Perulapán', value: 'San Pedro Perulapán' },
        { name: 'San Rafael Cedros', value: 'San Rafael Cedros' },
        { name: 'San Ramón', value: 'San Ramón' },
        { name: 'Santa Cruz Analquito', value: 'Santa Cruz Analquito' },
        { name: 'Santa Cruz Michapa', value: 'Santa Cruz Michapa' },
        { name: 'Suchitoto', value: 'Suchitoto' },
        { name: 'Tenancingo', value: 'Tenancingo' }
    ],
    'La Paz': [
        { name: 'Zacatecoluca', value: 'Zacatecoluca' },
        { name: 'Cuyultitán', value: 'Cuyultitán' },
        { name: 'El Rosario (La Paz)', value: 'El Rosario (La Paz)' },
        { name: 'Jerusalén', value: 'Jerusalén' },
        { name: 'Mercedes La Ceiba', value: 'Mercedes La Ceiba' },
        { name: 'Olocuilta', value: 'Olocuilta' },
        { name: 'Paraíso de Osorio', value: 'Paraíso de Osorio' },
        { name: 'San Antonio Masahuat', value: 'San Antonio Masahuat' },
        { name: 'San Emigdio', value: 'San Emigdio' },
        { name: 'San Francisco Chinameca', value: 'San Francisco Chinameca' },
        { name: 'San Pedro Masahuat', value: 'San Pedro Masahuat' },
        { name: 'San Juan Nonualco', value: 'San Juan Nonualco' },
        { name: 'San Juan Talpa', value: 'San Juan Talpa' },
        { name: 'San Juan Tepezontes', value: 'San Juan Tepezontes' },
        { name: 'San Luis La Herradura', value: 'San Luis La Herradura' },
        { name: 'San Luis Talpa', value: 'San Luis Talpa' },
        { name: 'San Miguel Tepezontes', value: 'San Miguel Tepezontes' },
        { name: 'San Pedro Nonualco', value: 'San Pedro Nonualco' },
        { name: 'San Rafael Obrajuelo', value: 'San Rafael Obrajuelo' },
        { name: 'Santa María Ostuma', value: 'Santa María Ostuma' },
        { name: 'Santiago Nonualco', value: 'Santiago Nonualco' },
        { name: 'Tapalhuaca', value: 'Tapalhuaca' }
    ],
    Cabañas: [
        { name: 'Cinquera', value: 'Cinquera' },
        { name: 'Dolores', value: 'Dolores' },
        { name: 'Guacotecti', value: 'Guacotecti' },
        { name: 'Ilobasco', value: 'Ilobasco' },
        { name: 'Jutiapa', value: 'Jutiapa' },
        { name: 'San Isidro (Cabañas)', value: 'San Isidro (Cabañas)' },
        { name: 'Sensuntepeque', value: 'Sensuntepeque' },
        { name: 'Tejutepeque', value: 'Tejutepeque' },
        { name: 'Victoria', value: 'Victoria' }
    ],
    'San Vicente': [
        { name: 'Apastepeque', value: 'Apastepeque' },
        { name: 'Guadalupe', value: 'Guadalupe' },
        { name: 'San Cayetano Istepeque', value: 'San Cayetano Istepeque' },
        { name: 'San Esteban Catarina', value: 'San Esteban Catarina' },
        { name: 'San Ildefonso', value: 'San Ildefonso' },
        { name: 'San Lorenzo', value: 'San Lorenzo' },
        { name: 'San Sebastián', value: 'San Sebastián' },
        { name: 'San Vicente', value: 'San Vicente' },
        { name: 'Santa Clara', value: 'Santa Clara' },
        { name: 'Santo Domingo', value: 'Santo Domingo' },
        { name: 'Tecoluca', value: 'Tecoluca' },
        { name: 'Tepetitán', value: 'Tepetitán' },
        { name: 'Verapaz', value: 'Verapaz' }
    ],
    Usulután: [
        { name: 'Alegría', value: 'Alegría' },
        { name: 'Berlín', value: 'Berlín' },
        { name: 'California', value: 'California' },
        { name: 'Concepción Batres', value: 'Concepción Batres' },
        { name: 'El Triunfo', value: 'El Triunfo' },
        { name: 'Ereguayquín', value: 'Ereguayquín' },
        { name: 'Estanzuelas', value: 'Estanzuelas' },
        { name: 'Jiquilisco', value: 'Jiquilisco' },
        { name: 'Jucuapa', value: 'Jucuapa' },
        { name: 'Jucuarán', value: 'Jucuarán' },
        { name: 'Mercedes Umaña', value: 'Mercedes Umaña' },
        { name: 'Nueva Granada', value: 'Nueva Granada' },
        { name: 'Ozatlán', value: 'Ozatlán' },
        { name: 'Puerto El Triunfo', value: 'Puerto El Triunfo' },
        { name: 'San Agustín', value: 'San Agustín' },
        { name: 'San Buenaventura', value: 'San Buenaventura' },
        { name: 'San Dionisio', value: 'San Dionisio' },
        { name: 'San Francisco Javier', value: 'San Francisco Javier' },
        { name: 'Santa Elena', value: 'Santa Elena' },
        { name: 'Santa María', value: 'Santa María' },
        { name: 'Santiago de María', value: 'Santiago de María' },
        { name: 'Tecapán', value: 'Tecapán' },
        { name: 'Usulután', value: 'Usulután' }
    ],
    Morazán: [
        { name: 'Arambala', value: 'Arambala' },
        { name: 'Cacaopera', value: 'Cacaopera' },
        { name: 'Chilanga', value: 'Chilanga' },
        { name: 'Corinto', value: 'Corinto' },
        { name: 'Delicias de Concepción', value: 'Delicias de Concepción' },
        { name: 'El Divisadero', value: 'El Divisadero' },
        { name: 'El Rosario (Morazán)', value: 'El Rosario (Morazán)' },
        { name: 'Gualococti', value: 'Gualococti' },
        { name: 'Guatajiagua', value: 'Guatajiagua' },
        { name: 'Joateca', value: 'Joateca' },
        { name: 'Jocoaitique', value: 'Jocoaitique' },
        { name: 'Jocoro', value: 'Jocoro' },
        { name: 'Lolotiquillo', value: 'Lolotiquillo' },
        { name: 'Meanguera', value: 'Meanguera' },
        { name: 'Osicala', value: 'Osicala' },
        { name: 'Perquín', value: 'Perquín' },
        { name: 'San Carlos', value: 'San Carlos' },
        { name: 'San Fernando (Morazán)', value: 'San Fernando (Morazán)' },
        { name: 'San Francisco Gotera', value: 'San Francisco Gotera' },
        { name: 'San Isidro (Morazán)', value: 'San Isidro (Morazán)' },
        { name: 'San Simón', value: 'San Simón' },
        { name: 'Sensembra', value: 'Sensembra' },
        { name: 'Sociedad', value: 'Sociedad' },
        { name: 'Torola', value: 'Torola' },
        { name: 'Yamabal', value: 'Yamabal' },
        { name: 'Yoloaiquín', value: 'Yoloaiquín' }
    ],
    'San Miguel': [
        { name: 'Carolina', value: 'Carolina' },
        { name: 'Chapeltique', value: 'Chapeltique' },
        { name: 'Chinameca', value: 'Chinameca' },
        { name: 'Chirilagua', value: 'Chirilagua' },
        { name: 'Ciudad Barrios', value: 'Ciudad Barrios' },
        { name: 'Comacarán', value: 'Comacarán' },
        { name: 'El Tránsito', value: 'El Tránsito' },
        { name: 'Lolotique', value: 'Lolotique' },
        { name: 'Moncagua', value: 'Moncagua' },
        { name: 'Nueva Guadalupe', value: 'Nueva Guadalupe' },
        { name: 'Nuevo Edén de San Juan', value: 'Nuevo Edén de San Juan' },
        { name: 'Quelepa', value: 'Quelepa' },
        { name: 'San Antonio del Mosco', value: 'San Antonio del Mosco' },
        { name: 'San Gerardo', value: 'San Gerardo' },
        { name: 'San Jorge', value: 'San Jorge' },
        { name: 'San Luis de la Reina', value: 'San Luis de la Reina' },
        { name: 'San Miguel', value: 'San Miguel' },
        { name: 'San Rafael Oriente', value: 'San Rafael Oriente' },
        { name: 'Sesori', value: 'Sesori' },
        { name: 'Uluazapa', value: 'Uluazapa' }
    ],
    'La Unión': [
        { name: 'La Unión', value: 'La Unión' },
        { name: 'San Alejo', value: 'San Alejo' },
        { name: 'Yucuaiquín', value: 'Yucuaiquín' },
        { name: 'Conchagua', value: 'Conchagua' },
        { name: 'Intipucá', value: 'Intipucá' },
        { name: 'San José', value: 'San José' },
        { name: 'El Carmen (La Unión)', value: 'El Carmen (La Unión)' },
        { name: 'Yayantique', value: 'Yayantique' },
        { name: 'Bolívar', value: 'Bolívar' },
        { name: 'Meanguera del Golfo', value: 'Meanguera del Golfo' },
        { name: 'Santa Rosa de Lima', value: 'Santa Rosa de Lima' },
        { name: 'Pasaquina', value: 'Pasaquina' },
        { name: 'Anamoros', value: 'Anamoros' },
        { name: 'Nueva Esparta', value: 'Nueva Esparta' },
        { name: 'El Sauce', value: 'El Sauce' },
        { name: 'Concepción de Oriente', value: 'Concepción de Oriente' },
        { name: 'Polorós', value: 'Polorós' },
        { name: 'Lislique', value: 'Lislique' }
    ]
};
