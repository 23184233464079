import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export function DateRangeFormPartialAp({ response }) {
    const [previewImage, setPreviewImage] = useState(null);
    const [disabledState, setDisabledState] = useState(false);

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile[0];
        formik.setFieldValue('ImagenAp', file);
        setPreviewImage(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    });

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            setDisabledState(true)
            response(formValue);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="col-4">
                    <label>Rango de Fechas</label>
                    <Calendar name="FechasEfectivas" value={formik.values.FechasEfectivas} onChange={(e) => formik.setFieldValue('FechasEfectivas', e.value)} selectionMode="range" readOnlyInput dateFormat="yy-mm-dd" className="w-full" />
                </div>
                <div className="col-4">
                    <Checkbox id="TiempoCompleto" name="TiempoCompleto" checked={formik.values.TiempoCompleto} onChange={(e) => formik.setFieldValue('TiempoCompleto', e.checked)} />
                    <label htmlFor="TiempoCompleto">Tiempo Completo</label>
                </div>
                {!formik.values.TiempoCompleto && (
                    <div className="col-4">
                        <labe htmlFor="HorasJustificar">Cantidad de Horas</labe>
                        <InputText id="HorasJustificar" name="HorasJustificar" className="w-full" value={formik.values.HorasJustificar} onChange={formik.handleChange} />
                    </div>
                )}
            </div>
            <div className="grid">
                <div className="col">
                    <label>Observacion</label>
                    <InputTextarea rows={5} cols={30} name="Observacion" className="w-full" value={formik.values.Observacion} onChange={formik.handleChange} />
                </div>
            </div>
            <div className="grid">
                <div className="col-6">
                    <Button type="button" severity="primary" size="large" {...getRootProps()}>
                        Subir Imagen para la AP
                    </Button>
                    <input {...getInputProps()} />
                </div>
                <div className="col-6">
                    <Image src={previewImage} width="100%" />
                </div>
            </div>
            <div className="grid">
                <div className="col">
                    <Button severity="success" type="submit" disabled={disabledState}>
                        Guardar Ap
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        FechasEfectivas: '',
        Observacion: '',
        TiempoCompleto: true,
        HorasJustificar: '',
        ImagenAp: ''
    };
}

function validationSchema() {
    return {
        FechasEfectivas: Yup.array().required(true),
        Observacion: Yup.string().required(true),
        TiempoCompleto: Yup.boolean(),
        HorasJustificar: Yup.string(),
        ImagenAp: Yup.string()
    };
}
