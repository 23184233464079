import React, { useState } from 'react';
import moment from 'moment';
import { size } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { useSkytracking, useUploadFiles } from '../../hooks';

export function CalcAuxHorasPage() {
    const [totalMarca, setTotalMarca] = useState(0);
    const [marcasProcesadas, setMarcasProcesadas] = useState(0);
    const [porcentajeMarca, setPorcentajeMarca] = useState(0);

    const { getMarcasDateCalc, loadingSkytracking } = useSkytracking();
    const { calcAuxMarca } = useUploadFiles();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            const response_marcas = await getMarcasDateCalc(moment(formValue.Fecha[0]).format('YYYY-MM-DD'), moment(formValue.Fecha[1]).format('YYYY-MM-DD'));

            setTotalMarca(size(response_marcas));
            let contador = 1;
            let porcentaje = 0;

            for await (const marca of response_marcas) {
                setMarcasProcesadas(contador);

                porcentaje = (contador / totalMarca) * 100;

                await calcAuxMarca(marca.id);

                contador += 1;
                setPorcentajeMarca(porcentaje);
            }
        }
    });

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Calculo de Auxiliares</h5>
                        <p>Selecciona las fechas para calcular las horas</p>

                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid my-3">
                                <div className="col-6">
                                    <span className="p-float-label">
                                        <Calendar id="Fecha" name="Fecha" className="w-full" selectionMode='range' readOnlyInput="" value={formik.values.Fecha} onChange={(e) => formik.setFieldValue('Fecha', e.value)}></Calendar>
                                        <label htmlFor="Fecha">Fecha</label>
                                    </span>
                                </div>
                            </div>
                            <div className="grid my-3">
                                <div className="col-12 text-right">
                                    <Button type="submit" severity="primary">
                                        Calcular Auxiliares
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {loadingSkytracking ? (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <div className="grid">
                    <div className="col-4 text-center">Marcas Totales {totalMarca}</div>
                    <div className="col-4 text-center">Marcas Procesadas {marcasProcesadas}</div>
                    <div className="col-4 text-center">Porcentaje Final {porcentajeMarca} %</div>
                </div>
            )}
        </>
    );
}

function initialValues() {
    return {
        Fecha: ''
    };
}

function validationSchema() {
    return {
        Fecha: Yup.array().required(true)
    };
}
