import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export function SkypanelPage() {
    const navigate = useNavigate();

    return (
        <div className="grid">
            <div className="col-12">
                <div className="grid">
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Carga de Horas</h5>
                            <p>Puedes cargar horas a SkyVerse SkyTracking desde un file. Puedes cargar mas de un empleado, fecha, cuenta y hasta Pausas en general</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/CargarHoras')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Carga de Roster</h5>
                            <p>Puedes subir la informacion de uno o mas empleados desde un file de excel. Con este modulo puedes cargar varias propiedades y empleados directamente a SkyVerse SkyRoster extremadamente util para hacer cargas masivas</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/CargarRoster')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Carga de Horarios</h5>
                            <p>Aqui solamente puedes cargar horarios de empleados con un formato de excel especifico. Unicamente funciona para cargar horarios, aqui no puedes cargar propiedades para los empleados de SkyVerse SkyRoster</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/CargarHorarios')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Editar Marcas</h5>
                            <p>Si necesitas editar una marca especifica dentro de SkyVerse SkyTracking puedes hacerlo en este modulo, solamente necesitas el Badge y Fecha para poder editar o agregar pausas a una marca</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/EditarMarca')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Editar Empleado</h5>
                            <p>Cambios de roster por empleado y por fecha, este modulo tambien genera un codigo de consola para poder ejecutar cambios masivos desde la terminal</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/EditarEmpleado')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Calculo de Aux Masivo</h5>
                            <p>Realiza un calculo masivo de aux por fecha para todas las marcas que apliquen a esa fecha</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/CalcAux')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Horas Programadas</h5>
                            <p>Si los empleados no aparecen en la parte de SkyVerse SkyTracking puedes cargar horas a futuro desde este modulo y asi apareceran en las horas</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/CalcularProgramadas')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Master Roster Panel</h5>
                            <p>Master Roster para todas las cuenta, desde este reporte puedes descargar toda la informacion de el roster para todas las cuentas</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/MasterRoster')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Horas planilla</h5>
                            <p>Formato de horas Dashboard para creacion de planilla y facturacion mensual</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/Payroll')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Carga de Roster Semanal</h5>
                            <p>Carga el roster de la semana pasada a el roster de la semana actual</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/LoadRosterWeek')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Staff Time Skytracking</h5>
                            <p>Reporte Staff Time para horas con formato</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/Stafftime')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Skytracking Temp PowerBi</h5>
                            <p>Reporte de horas similar a Power Bi</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/SkytrackingTemp')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Reporte Facturacion</h5>
                            <p>Reporte para factura basado en time utilization 2</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skypanel/FacturaVirtuox')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Ejecutar AP</h5>
                            <p>Modulo de Ejecucion de AP Directo</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skyap/ApImplementation')} />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Excepciones Masivas</h5>
                            <p>Modulo para el ingreso de excepciones masivas</p>
                            <Button type="button" severity="primary" size="large" icon="pi pi-wrench" label="Entrar" onClick={() => navigate('/Skytracking/ExcepcionesMasivas')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
