import { useState } from "react"
import {size} from "lodash"
import {getTipoComponenteSiasApi, updateTipoComponenteSiaApi} from "../api/skyinventoryadministrator/tipocomponentesia"
import {getMarcaSiasApi} from "../api/skyinventoryadministrator/marcasia"
import {getModeloSiasApi} from "../api/skyinventoryadministrator/modelosia"
import {getEstadoComponenteSiasApi} from "../api/skyinventoryadministrator/estadocomponentesia"
import {getUbicacionSiasApi} from "../api/skyinventoryadministrator/ubicacionsia"
import {addComponenteSiaApi, getComponenteSiasApi} from "../api/skyinventoryadministrator/componentesia"
import {addDetalleComponenteSiaApi} from "../api/skyinventoryadministrator/detallecomponentesia"
import {getTipoPrestamoSiasApi} from "../api/skyinventoryadministrator/tipoprestamosia"
import {useAuth} from "./useAuth"

export function useCommonSia () {

    const [error, setError] = useState(false);
    const [loadingCommonSia, setLoadingCommonSia] = useState(false);
    const [listTiposComponentes, setListTiposComponentes] = useState(null);
    const [listTiposPrestamos, setListTiposPrestamos] = useState(null);
    const [listMarcas, setListMarcas] = useState(null);
    const [listModelos, setListModelos] = useState(null);
    const [listEstados, setListEstados] = useState(null);
    const [listUbicaciones, setListUbicaciones] = useState(null);

    const {auth} = useAuth();

    const getTiposPrestamos = async () => {
        try {
            setLoadingCommonSia(true)

            const response_getTipoComponenteSiasApi = await getTipoPrestamoSiasApi(auth.token);
            let listFormat = []

            for await (const resp of response_getTipoComponenteSiasApi){
                listFormat.push({
                    name: resp.EstadoPrestamo,
                    value: resp.id
                })
            }

            setListTiposPrestamos(listFormat);

            setLoadingCommonSia(false)
        } catch (error) {
            setLoadingCommonSia(false)
            setError(error)
        }
    }

    const getTiposComponentes = async () => {
        try {
            setLoadingCommonSia(true)

            const response_getTipoComponenteSiasApi = await getTipoComponenteSiasApi(auth.token);
            let listFormat = []

            for await (const resp of response_getTipoComponenteSiasApi){
                listFormat.push({
                    name: resp.TipoComponente,
                    data: resp.id,
                    codigoComponente: `${resp.Prefijo}${resp.Correlativo + 1}`,
                    prefijo: resp.Prefijo,
                    correlativo: resp.Correlativo,
                })
            }

            setListTiposComponentes(listFormat);

            setLoadingCommonSia(false)
        } catch (error) {
            setLoadingCommonSia(false)
            setError(error)
        }
    }
    const getMarcas = async () => {
        try {
            setLoadingCommonSia(true)

            const response_getMarcaSiasApi = await getMarcaSiasApi(auth.token);
            let listFormat = []

            for await (const resp of response_getMarcaSiasApi){
                listFormat.push({
                    name: resp.Marca,
                    value: resp.id,
                })
            }

            setListMarcas(listFormat);

            setLoadingCommonSia(false)
        } catch (error) {
            setLoadingCommonSia(false)
            setError(error)
        }
    }
    const getModelos = async (Marca = "") => {
        try {
            setLoadingCommonSia(true)

            const response_getModeloSiasApi = await getModeloSiasApi(auth.token, Marca);
            let listFormat = []

            for await (const resp of response_getModeloSiasApi){
                listFormat.push({
                    name: resp.Modelo,
                    value: resp.id,
                })
            }

            setListModelos(listFormat);

            setLoadingCommonSia(false)
        } catch (error) {
            setLoadingCommonSia(false)
            setError(error)
        }
    }
    const getEstados = async () => {
        try {
            setLoadingCommonSia(true)

            const response_getEstadoComponenteSiasApi = await getEstadoComponenteSiasApi(auth.token);
            let listFormat = []

            for await (const resp of response_getEstadoComponenteSiasApi){
                listFormat.push({
                    name: resp.EstadoComponente,
                    value: resp.id,
                })
            }

            setListEstados(listFormat);

            setLoadingCommonSia(false)
        } catch (error) {
            setLoadingCommonSia(false)
            setError(error)
        }
    }
    const getUbicaciones = async () => {
        try {
            setLoadingCommonSia(true)

            const response_getUbicacionSiasApi = await getUbicacionSiasApi(auth.token);
            let listFormat = []

            for await (const resp of response_getUbicacionSiasApi){
                listFormat.push({
                    name: `${resp.Edificio} - ${resp.Planta} - ${resp.Storage}`,
                    value: resp.id,
                })
            }

            setListUbicaciones(listFormat);

            setLoadingCommonSia(false)
        } catch (error) {
            setLoadingCommonSia(false)
            setError(error)
        }
    }

    const addComponente = async (data) => {
        try {
            setLoadingCommonSia(true)

            const responseComponente = await addComponenteSiaApi(data, auth.token);

            setLoadingCommonSia(false)

            return responseComponente
        } catch (error) {
            setLoadingCommonSia(false)
            setError(error)
        }
    }

    const addDetalleComponente = async (data) => {
        try {
            setLoadingCommonSia(true)

            const responseComponente = await addDetalleComponenteSiaApi(data, auth.token);

            setLoadingCommonSia(false)

            return responseComponente
        } catch (error) {
            setLoadingCommonSia(false)
            setError(error)
        }
    }

    const validSerialComponente = async (serial) => {
        try {
            setLoadingCommonSia(true)
            
            const serial_valid = await getComponenteSiasApi(auth.token, "", serial);

            if (size(serial_valid) > 0){
                return true
            }else{
                return false
            }

            setLoadingCommonSia(false)
        } catch (error) {
            setLoadingCommonSia(false)
            setError(error)
        }
    }

    const updateContador = async (contador, idTipoComponente) => {
        try {
            setLoadingCommonSia(true);
            await updateTipoComponenteSiaApi({
                Correlativo: contador
            }, idTipoComponente, auth.token);
            setLoadingCommonSia(false);
        } catch (error) {
            setLoadingCommonSia(false);
            setError(error);
        }
    }

    return {

        error,
        loadingCommonSia,
        listTiposComponentes,
        listMarcas,
        listModelos,
        listEstados,
        listUbicaciones,
        listTiposPrestamos,
        getTiposComponentes,
        getMarcas,
        getModelos,
        getEstados,
        getUbicaciones,
        addComponente,
        addDetalleComponente,
        validSerialComponente,
        updateContador,
        getTiposPrestamos,
    }
}