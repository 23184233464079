import { useState } from "react"
import {filter, map} from "lodash"
import {getReportApplicantApi} from "../api/skyapplicantmanagement/detalleaplicantesam"
import {useAuth} from "./useAuth"

export function useApplicant () {

    const [error, setError] = useState(false)
    const [listApplicants, setListApplicants] = useState(null)
    const [loadingApplicant, setLoadingApplicant] = useState(false)

    const {auth} = useAuth()

    const getReportApplicant = async (FechaInicio, FechaFinal, Estado, filters) => {
        try {
            setLoadingApplicant(true)

            let list_report_applicant = await getReportApplicantApi(auth.token, FechaInicio, FechaFinal, Estado)

            let filters_sam = {}

            map(filters, (value, key) => {
                if (key != "FechaAplicacion" && key != "EstadoAplicante" && value != ""){
                 switch (key) {
                    case "NivelIdioma":
                        filters_sam[2] = value
                        break;
                 
                    default:
                        break;
                 }   
                }
            })

            list_report_applicant = filter(list_report_applicant, filters_sam)

            setListApplicants(list_report_applicant)

            setLoadingApplicant(false)
        } catch (error) {
            setLoadingApplicant(false)
            setError(error)
        }
    }

    return {
        error,
        loadingApplicant,
        listApplicants,
        getReportApplicant

    }
}