import { BASE_API } from '../../utilities/constants';

export async function getUbicacionSiasApi(token, IdAp = '') {
    try {
        const url = `${BASE_API}v1/UbicacionSia/${IdAp}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addUbicacionSiaApi(data, token) {
    try {
        const url = `${BASE_API}v1/UbicacionSia/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateUbicacionSiaApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/UbicacionSia/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
