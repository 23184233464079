import { useState } from 'react';
import { filter, size } from 'lodash';
import moment from 'moment';
import { useAuth } from './useAuth';
import { getComponenteSiasApi, updateComponenteSiaApi } from '../api/skyinventoryadministrator/componentesia';
import { getDetalleComponenteSiasApi } from '../api/skyinventoryadministrator/detallecomponentesia';
import { getMarcaSiasApi } from '../api/skyinventoryadministrator/marcasia';
import { addPrestamoSiaApi, getPrestamoSiasApi } from '../api/skyinventoryadministrator/prestamosia';
import { getUbicacionSiasApi } from '../api/skyinventoryadministrator/ubicacionsia';
import { getEstadoComponenteSiasApi } from '../api/skyinventoryadministrator/estadocomponentesia';
import { getTipoPrestamoSiasApi } from '../api/skyinventoryadministrator/tipoprestamosia';
import { getMovimientoHistoricoSiasApi } from '../api/skyinventoryadministrator/movimientohistorico';
import {getUsersApi_byone} from "../api/login/user";
import {getDetalleEmpleadoApi, getEmpleadoByFilterApi} from "../api/skyroster/detalleempleado"
import {getCuentasApi} from "../api/skyroster/cuenta"

export function usePrestamoSia() {
    const [error, setError] = useState(false);
    const [loadingPrestamo, setLoadingPrestamo] = useState(false);
    const [listComponentes, setListComponentes] = useState(null);
    const [listPrestamos, setListPrestamos] = useState(null);
    const [componenteDetalle, setComponenteDetalle] = useState(null);
    const [componenteData, setComponenteData] = useState(null);
    const [prestamosComponenteDetalle, setPrestamosComponenteDetalle] = useState(null);

    const { auth } = useAuth();

    const getComponentes = async () => {
        try {
            setLoadingPrestamo(true);
            const listComponentes = await getComponenteSiasApi(auth.token, 1);
            const listComponentes_nuevos = await getComponenteSiasApi(auth.token, 2);
            const listMarcas = await getMarcaSiasApi(auth.token);
            let listFormat = [];
            for await (const componente of listComponentes) {
                const valor_marca = filter(listMarcas, ['id', componente.modelo_data.Marca]);
                listFormat.push({
                    name: `${componente.tipoComponente_data.TipoComponente} - ${componente.Serial} - ${valor_marca[0].Marca} / ${componente.modelo_data.Modelo}`,
                    value: componente.id
                });
            }

            for await (const componente of listComponentes_nuevos) {
                const valor_marca = filter(listMarcas, ['id', componente.modelo_data.Marca]);
                listFormat.push({
                    name: `${componente.tipoComponente_data.TipoComponente} - ${componente.Serial} - ${valor_marca[0].Marca} / ${componente.modelo_data.Modelo}`,
                    value: componente.id
                });
            }

            setListComponentes(listFormat);

            setLoadingPrestamo(false);
        } catch (error) {
            setError(error);
            setLoadingPrestamo(false);
        }
    };

    const getMovimientoComponente = async (componente) => {
        try {
            setLoadingPrestamo(true);
            const data_movimiento = getMovimientoHistoricoSiasApi(auth.token, componente);

            setLoadingPrestamo(false);

            return data_movimiento
        } catch (error) {
            setLoadingPrestamo(false);
            setError(error);
        }
    }

    const getComponente = async (Serial) => {
        try {
            setLoadingPrestamo(true);
            const listComponentes_nuevos = await getComponenteSiasApi(auth.token, "", Serial);
            setComponenteData(listComponentes_nuevos);

            const listDetalleComponente = await getDetalleComponenteSiasApi(auth.token, "", Serial);
            setComponenteDetalle(listDetalleComponente);

            const listPrestamosComponente = await getPrestamoSiasApi(auth.token, "", Serial);
            setPrestamosComponenteDetalle(listPrestamosComponente);

            setLoadingPrestamo(false);
        } catch (error) {
            setLoadingPrestamo(false);
            setError(error)
        }
    }

    const addPrestamo = async (data, ubicacion = 0) => {
        try {
            setLoadingPrestamo(true);
            data['Usuario'] = auth.me.id;
            await addPrestamoSiaApi(data, auth.token);
            if (ubicacion != 0) {
                await updateComponenteSiaApi(
                    {
                        EstadoComponente: 5,
                        Ubicacion: ubicacion
                    },
                    data['Componente'],
                    auth.token
                );
            } else {
                await updateComponenteSiaApi(
                    {
                        EstadoComponente: 5
                    },
                    data['Componente'],
                    auth.token
                );
            }
            setLoadingPrestamo(false);
        } catch (error) {
            setLoadingPrestamo(false);
            setError(error);
        }
    };

    const getRecibirPrestamo = async (asignacion_empleado, asignacion_cuenta) => {
        try {
            setLoadingPrestamo(true)

            asignacion_empleado = asignacion_empleado != 0 ? asignacion_empleado : "";
            asignacion_cuenta = asignacion_cuenta != 0 ? asignacion_cuenta : "";

            const response_prestamos = await getPrestamoSiasApi(
                auth.token,
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                1,
                "",
                "",
                "",
                asignacion_empleado,
                asignacion_cuenta
            );

            setLoadingPrestamo(false)

            return response_prestamos;
        } catch (error) {
            setLoadingPrestamo(false)
            setError(error)
        }
    }

    const getPrestamosSiaReport = async (formValue) => {
        try {
            setLoadingPrestamo(true);

            const fecha_compra = formValue.FechaCompra != '' ? moment(formValue.FechaCompra).format('YYYY-MM-DD') : '';
            const fecha_salida = formValue.FechaSalida != '' ? moment(formValue.FechaSalida).format('YYYY-MM-DD') : '';
            const fehca_entrega = formValue.FechaEntrega != '' ? moment(formValue.FechaEntrega).format('YYYY-MM-DD') : '';
            const tipo_componente = formValue.TipoComponente?.data != undefined ? formValue.TipoComponente.data : "";

            const response_prestamos = await getPrestamoSiasApi(
                auth.token,
                formValue.EstadoComponente,
                '',
                tipo_componente,
                fecha_compra,
                fecha_compra,
                fecha_salida,
                '',
                '',
                fehca_entrega,
                formValue.Ubicacion,
                formValue.EstadoPrestamo,
                formValue.Marca
            );

            const dictionary_ubicaciones = await getUbicacionSiasApi(auth.token);
            const dictionary_estadoComponente = await getEstadoComponenteSiasApi(auth.token);
            const dictionary_TipoPrestamo = await getTipoPrestamoSiasApi(auth.token);
            const dictionary_Marca = await getMarcaSiasApi(auth.token);
            const dictionary_Cuenta = await getCuentasApi(auth.token, 1);

            let listFormat = [];
            let employee_search = [];
            let user_search = [];

            for await (const componente of response_prestamos) {

                let data_empleadoAsignacion = filter(employee_search, ["id", parseInt(componente.Badge)])

                if (size(data_empleadoAsignacion) > 0){
                    data_empleadoAsignacion = data_empleadoAsignacion[0].Nombre;
                }else{
                    // const data_db_empleado = await getDetalleEmpleadoApi(auth.token, 2, parseInt(componente.Badge));
                    const data_db_empleado = await getEmpleadoByFilterApi(auth.token, "", "", "", "", parseInt(componente.Badge));
                    let data_name_empleado = filter(data_db_empleado, ["Propiedad", 2]);
                    data_name_empleado = size(data_name_empleado) > 0 ? data_name_empleado[0].Valor : "SinNombre";

                    let data_cuenta_empleado = filter(data_db_empleado, ["Propiedad", 3]);
                    data_cuenta_empleado = size(data_cuenta_empleado) > 0 ? data_cuenta_empleado[0].Valor : "Sin Cuenta";
                    
                    if (data_cuenta_empleado != "Sin Cuenta"){
                        data_cuenta_empleado = filter(dictionary_Cuenta, ["id", parseInt(data_cuenta_empleado)])
                        data_cuenta_empleado = (size(data_cuenta_empleado) > 0) ? data_cuenta_empleado[0].Nombre : "Cuenta No Valida";
                    }

                    data_empleadoAsignacion = `${data_name_empleado} - ${data_cuenta_empleado}`;

                    employee_search.push({
                        id: parseInt(componente.Badge),
                        Nombre: data_empleadoAsignacion,
                    })
                }

                let data_usuario = filter(user_search, ["id", componente.Usuario])

                if (size(data_usuario) > 0){
                    data_usuario = data_usuario[0].Nombre;
                }else{
                    const data_db_user = await getUsersApi_byone(auth.token, componente.Usuario);
                    user_search.push({
                        "id": data_db_user.id,
                        "Nombre": `${data_db_user.username} - ${data_db_user.first_name} ${data_db_user.last_name}`
                    })
                    data_usuario = `${data_db_user.username} - ${data_db_user.first_name} ${data_db_user.last_name}`;
                }


                let data_ubicacion = filter(dictionary_ubicaciones, ["id", componente.componente_data.Ubicacion]);
                data_ubicacion = size(data_ubicacion) > 0 ? `${data_ubicacion[0].Edificio} - ${data_ubicacion[0].Planta} - ${data_ubicacion[0].Storage}` : "Sin Ubicacion";

                let data_estadoComponente = filter(dictionary_estadoComponente, ["id", componente.componente_data.EstadoComponente]);
                data_estadoComponente = size(data_estadoComponente) > 0 ? data_estadoComponente[0].EstadoComponente : "Sin Estado de Componente";

                let data_tipoPrestamo = filter(dictionary_TipoPrestamo, ["id", componente.TipoPrestamo]);
                data_tipoPrestamo = size(data_tipoPrestamo) > 0 ? data_tipoPrestamo[0].EstadoPrestamo : "Tipo de Prestamo no definido";

                let data_marca = filter(dictionary_Marca, ["id", componente.componente_data.modelo_data.Marca])
                data_marca = size(data_marca) > 0 ? data_marca[0].Marca : "Sin Marca";

                let fecha_retorno_str = componente.FechaSalida == componente.FechaRetorno ? "Pendiente Retorno" : moment(componente.FechaRetorno).format("DD/MM/YYYY");

                listFormat.push({
                    ID_Prestamo: componente.id,
                    ID_Componente: componente.componente_data.id,
                    ID_Marca: componente.componente_data.modelo_data.Marca,
                    ID_Modelo: componente.componente_data.id,
                    ID_TipoComponente: componente.componente_data.tipoComponente_data.id,
                    ID_Ubicacion: componente.componente_data.Ubicacion,
                    ID_EstadoComponente: componente.componente_data.EstadoComponente,
                    ID_UsuarioPrestamo: componente.Usuario,
                    ID_TipoPrestamo: componente.TipoPrestamo,
                    Asignacion_Cuenta: componente.Cuenta,
                    Asignacion_Empleado: componente.Badge,
                    Empleado: data_empleadoAsignacion,
                    CreacionComponente: moment(componente.componente_data.created_at).format("DD/MM/YYYY HH:mm:ss"),
                    CreacionPrestamo: moment(componente.created_at).format("DD/MM/YYYY HH:mm:ss"),
                    FechaCompra: moment(componente.componente_data.FechaCompra).format("DD/MM/YYYY"),
                    FechaSalida: moment(componente.FechaSalida).format("DD/MM/YYYY"),
                    FechaRetorno: fecha_retorno_str,
                    Pagare: componente.Pagare,
                    Serial: componente.componente_data.Serial,
                    Modelo: componente.componente_data.modelo_data.Modelo,
                    Marca: data_marca,
                    TipoComponente: componente.componente_data.tipoComponente_data.TipoComponente,
                    Correlativo: `${componente.componente_data.Correlativo}`,
                    Ubicacion: data_ubicacion,
                    EstadoComponente: data_estadoComponente,
                    UsuarioPrestamo: data_usuario,
                    TipoPrestamo: data_tipoPrestamo,
                });
            }

            setLoadingPrestamo(false);

            return listFormat;
        } catch (error) {
            setLoadingPrestamo(false);
            setError(error);
        }
    };

    const getComponenteSiaReport = async (formValue) => {
        try {
            setLoadingPrestamo(true);

            const fecha_compra = formValue.FechaCompra != '' ? moment(formValue.FechaCompra).format('YYYY-MM-DD') : '';
            const fecha_salida = formValue.FechaSalida != '' ? moment(formValue.FechaSalida).format('YYYY-MM-DD') : '';
            const fehca_entrega = formValue.FechaEntrega != '' ? moment(formValue.FechaEntrega).format('YYYY-MM-DD') : '';
            const tipo_componente = formValue.TipoComponente?.data != undefined ? formValue.TipoComponente.data : "";

            /* const response_prestamos = await getPrestamoSiasApi(
                auth.token,
                formValue.EstadoComponente,
                '',
                tipo_componente,
                fecha_compra,
                fecha_compra,
                fecha_salida,
                '',
                '',
                fehca_entrega,
                formValue.Ubicacion,
                formValue.EstadoPrestamo,
                formValue.Marca
            ); */

            const response_prestamos = await getComponenteSiasApi(auth.token, formValue.EstadoComponente, "", tipo_componente, fecha_compra, formValue.Ubicacion, formValue.Marca);

            const dictionary_ubicaciones = await getUbicacionSiasApi(auth.token);
            const dictionary_estadoComponente = await getEstadoComponenteSiasApi(auth.token);
            const dictionary_Marca = await getMarcaSiasApi(auth.token);

            let listFormat = [];

            for await (const componente of response_prestamos) {

                let data_ubicacion = filter(dictionary_ubicaciones, ["id", componente.Ubicacion]);
                data_ubicacion = size(data_ubicacion) > 0 ? `${data_ubicacion[0].Edificio} - ${data_ubicacion[0].Planta} - ${data_ubicacion[0].Storage}` : "Sin Ubicacion";

                let data_estadoComponente = filter(dictionary_estadoComponente, ["id", componente.EstadoComponente]);
                data_estadoComponente = size(data_estadoComponente) > 0 ? data_estadoComponente[0].EstadoComponente : "Sin Estado de Componente";

                let data_marca = filter(dictionary_Marca, ["id", componente.modelo_data.Marca])
                data_marca = size(data_marca) > 0 ? data_marca[0].Marca : "Sin Marca";

                listFormat.push({
                    ID_Componente: componente.id,
                    ID_Marca: componente.modelo_data.Marca,
                    ID_Modelo: componente.modelo_data.id,
                    ID_TipoComponente: componente.tipoComponente_data.id,
                    ID_Ubicacion: componente.Ubicacion,
                    ID_EstadoComponente: componente.EstadoComponente,
                    CreacionComponente: moment(componente.created_at).format("DD/MM/YYYY HH:mm:ss"),
                    FechaCompra: moment(componente.FechaCompra).format("DD/MM/YYYY"),
                    Serial: componente.Serial,
                    Modelo: componente.modelo_data.Modelo,
                    Marca: data_marca,
                    TipoComponente: componente.tipoComponente_data.TipoComponente,
                    Correlativo: `${componente.Correlativo}`,
                    Ubicacion: data_ubicacion,
                    EstadoComponente: data_estadoComponente,
                });
            }

            setLoadingPrestamo(false);

            return listFormat;
        } catch (error) {
            setLoadingPrestamo(false);
            setError(error);
        }
    };

    return {
        error,
        loadingPrestamo,
        listPrestamos,
        listComponentes,
        componenteDetalle,
        componenteData,
        prestamosComponenteDetalle,
        getComponentes,
        addPrestamo,
        getPrestamosSiaReport,
        getComponenteSiaReport,
        getComponente,
        getRecibirPrestamo,
        getMovimientoComponente
    };
}
