import { useState } from 'react';
import { size, filter, uniq } from 'lodash';
import { getEmpleadoByFilterApi, getDetalleEmpleadoApi } from '../api/skyroster/detalleempleado';
import { getCuentasApi } from '../api/skyroster/cuenta';
import { addExcepcionApi, getExcepcionesApi, updateExcepcionApi, getOnlyExceptionApi, getSkyExceptionApi } from '../api/skytracking/excepcion';
import { getMarcasByFiltersApi } from '../api/skytracking/marca';
import { addDetalleMarcaApi, getDetalleMarcaExcepcion } from '../api/skytracking/detallemarca';
import { useAuth } from './useAuth';
//import { useUploadFiles } from './useUploadFiles';

export function useException() {
    const [error, setError] = useState(false);
    const [loadingException, setLoadingException] = useState(false);
    const [exceptions, setException] = useState(null);

    const { auth } = useAuth();
    //const { calcAuxMarca } = useUploadFiles();

    const getSkyExceptionView = async (FechaInicio, FechaFinal, Cuenta, Estado) => {
        try {
            setLoadingException(true)
            const response_datos_exception = await getSkyExceptionApi(auth.token, FechaInicio, FechaFinal, Cuenta, Estado)
            setLoadingException(false)

            return response_datos_exception
        } catch (error) {
            setLoadingException(false)
            setError(error)
        }
    }

    const getExceptions = async (FechaInicio, FechaFinal, Cuenta, Lob, Estado) => {
        try {
            setLoadingException(true);

            let response_empleado = [];
            let employee_arr = [];
            let response_excepciones = []

            if (Cuenta != ""){

                if (Lob == '') {
                    response_empleado = await getEmpleadoByFilterApi(auth.token, Cuenta, 3);
                } else {
                    response_empleado = await getEmpleadoByFilterApi(auth.token, Lob, 5);
                }
    
                if (size(response_empleado) > 0) {
                    for await (const empleado of response_empleado) {
                        employee_arr.push(empleado.empleado_data.Badge);
                    }
                } else {
                    throw new Error('No Existe Empleados para la cuenta');
                }

                employee_arr = uniq(employee_arr)
                
                response_excepciones = await getExcepcionesApi(auth.token, FechaInicio, FechaFinal, Estado, employee_arr.join('%2C'));
            }else{
                response_excepciones = await getExcepcionesApi(auth.token, FechaInicio, FechaFinal, Estado, '');

            }

            const response_cuenta = await getCuentasApi(auth.token,"");

            
            let result_exceptions = [];
            for await (const excepcion of response_excepciones) {
                const nombre_result = await getDetalleEmpleadoApi(auth.token, 2, excepcion.empleado_data.id);
                const cuenta_result = await getDetalleEmpleadoApi(auth.token, 3, excepcion.empleado_data.id);
                const response_filter_cuenta = filter(response_cuenta, ["id", parseInt(cuenta_result[0].Valor)])

                result_exceptions.push({
                    id: excepcion.id,
                    FechaInicio: excepcion.FechaInicio,
                    FechaFin: excepcion.FechaFin,
                    Badge: excepcion.empleado_data.Badge,
                    NombreEmpleado: nombre_result[0].Valor,
                    CuentaEmpleado: response_filter_cuenta[0].Nombre,
                    ValorExcepcion: excepcion.ValorExcepcion,
                    tipoMarca_data: excepcion.tipoMarca_data,
                    EstadoExcepcion: excepcion.EstadoExcepcion,
                    Motivo: excepcion.Motivo,
                    MotivoRechazo: excepcion.MotivoRechazo
                });
            }

            setException(result_exceptions);

            setLoadingException(false);
        } catch (error) {
            setLoadingException(false);
            setError(error);
        }
    };

    const validException = async (FechaMarca, Badge, TipoMarca) => {
        try {
            setLoadingException(true)

            const response_excepciones = await getExcepcionesApi(auth.token, FechaMarca, FechaMarca, 0, Badge, TipoMarca);
            
            setLoadingException(false)

            if (size(response_excepciones) > 0){
                return false
            }else{
                return true
            }

        } catch (error) {
            setLoadingException(false)
            setError(error)
        }
    }

    const getExceptionValid = async (FechaMarca, Badge, TipoMarca) => {
        try {
            setLoadingException(true)

            const response_excepciones = await getExcepcionesApi(auth.token, FechaMarca, FechaMarca, 0, Badge, TipoMarca);

            setLoadingException(false)

            return response_excepciones
        } catch (error) {
            setLoadingException(false)
            setError(error)
        }
    }

    const insertException = async (formData, datosMarca) => {
        try {
            console.log(datosMarca)
            setLoadingException(true);
            await addExcepcionApi(
                {
                    TipoMarca: formData.Pausa.code,
                    Motivo: formData.Observacion,
                    Empleado: datosMarca.idEmpleado,
                    FechaInicio: datosMarca.Fecha,
                    FechaFin: datosMarca.Fecha,
                    ValorExcepcion: formData.Horas,
                    SolicitadoPor: auth.me.username,
                    EstadoExcepcion: 0,
                    File: formData.ImageJustificacion,
                },
                auth.token
            );
            setLoadingException(false);
        } catch (error) {
            setLoadingException(false);
            setError(error);
        }
    };

    const insertException_wfm = async (formData) => {
        try {
            setLoadingException(true);
            formData['SolicitadoPor'] = auth.me.username;
            const response_insert_exception = await addExcepcionApi(formData, auth.token);
            setLoadingException(false);
            return response_insert_exception;
        } catch (error) {
            setLoadingException(false);
            setError(error);
        }
    };

    const insertExceptionComplete = async (formData, empleado) => {
        try {
            setLoadingException(true);

            const fecha_inicio = new Date(formData.RangoFechas[0]);
            const fecha_final = new Date(formData.RangoFechas[1]);

            await addExcepcionApi(
                {
                    TipoMarca: formData.Pausa.id_pausa,
                    Motivo: formData.Comentario,
                    Empleado: empleado,
                    FechaInicio: `${fecha_inicio.getFullYear()}-${fecha_inicio.getMonth() + 1}-${fecha_inicio.getDate()}`,
                    FechaFin: `${fecha_final.getFullYear()}-${fecha_final.getMonth() + 1}-${fecha_final.getDate()}`,
                    ValorExcepcion: 0,
                    SolicitadoPor: auth.me.username,
                    EstadoExcepcion: 0
                },
                auth.token
            );
            setLoadingException(false);
        } catch (error) {
            setLoadingException(false);
            setError(error);
        }
    };

    const getMarcaException = async (badge, fecha) => {
        try {
            setLoadingException(true);
            const response = await getMarcasByFiltersApi(auth.token, badge, fecha);
            setLoadingException(false);

            return response[0];
        } catch (error) {
            setLoadingException(false);
            setError(error);
        }
    };

    const getOnlyException = async (idExcepcion) => {
        try {
            setLoadingException(true);
            const response = await getOnlyExceptionApi(auth.token, idExcepcion);
            setLoadingException(false);

            return response;
        } catch (error) {
            setLoadingException(false);
            setError(error);
        }
    };

    const updateExcepcionReject = async (idExcepcion, Motivo) => {
        try {
            setLoadingException(true);
            await updateExcepcionApi(
                {
                    EstadoExcepcion: 2,
                    ManejoEstado: auth.me.username,
                    MotivoRechazo: Motivo
                },
                idExcepcion,
                auth.token
            );
            setLoadingException(false);
        } catch (error) {
            setLoadingException(false);
            setError(error);
        }
    };

    const insertDetalleMarcaExcepcion = async (idMarca, idPausa, Valor, idExcepcion) => {
        try {
            setLoadingException(true);

            let valor_insert = Valor;

            if (valor_insert == 0) {
                const response_horas = await getDetalleMarcaExcepcion(auth.token, idMarca, 1);
                valor_insert = response_horas[0].Horas;
            }

            await addDetalleMarcaApi(
                {
                    Marca: idMarca,
                    TipoMarca: idPausa,
                    Horas: valor_insert
                },
                auth.token
            );

            await updateExcepcionApi(
                {
                    EstadoExcepcion: 1,
                    ManejoEstado: auth.me.username
                },
                idExcepcion,
                auth.token
            );

            setLoadingException(false);
        } catch (error) {
            setLoadingException(false);
            setError(error);
        }
    };

    return {
        error,
        loadingException,
        exceptions,
        setLoadingException,
        getExceptions,
        insertException,
        getMarcaException,
        insertDetalleMarcaExcepcion,
        updateExcepcionReject,
        getOnlyException,
        insertExceptionComplete,
        insertException_wfm,
        validException,
        getExceptionValid,
        getSkyExceptionView
    };
}
