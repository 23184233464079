import React from 'react';
import moment from 'moment';
import { filter, size } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FilterHoras } from '../../components/Skytracking';
import { useSkytracking, useCommon, useMasterRoster } from '../../hooks';

export function ReportePayrollPage() {
    const { getStaffTime, horasPlanilla, loadingSkytracking } = useSkytracking();
    const { getPausasFilter_response } = useCommon();
    const { getSkyEmpleadosViewPersoResponse } = useMasterRoster();

    const onResponseForm = async (formData) => {
        const fechaInicio = moment(formData.RangoFechas[0]).format('YYYY-MM-DD');
        const fechaFinal = moment(formData.RangoFechas[1]).format('YYYY-MM-DD');
        await getStaffTime(fechaInicio, fechaFinal);
    };

    const exportExcel = async () => {
        let list_marcas = [];
        const tipo_marcas = await getPausasFilter_response();
        const empleados_view = await getSkyEmpleadosViewPersoResponse();

        for await (const marca of horasPlanilla) {
            const data_empleado = filter(empleados_view, ['Badge.Badge', marca.Badge.Badge]);
            let nombre_empleado = '';
            let cuenta_empleado = '';
            let lob_empleado = '';

            if (size(data_empleado) > 0) {
                nombre_empleado = data_empleado[0][2];
                cuenta_empleado = data_empleado[0]?.Cuenta.Nombre;
                lob_empleado = data_empleado[0]?.Lob ? data_empleado[0].Lob.Lob : data_empleado[0].Lob;
            } else {
                nombre_empleado = 'Attrition';
                cuenta_empleado = 'Attrition Account';
                lob_empleado = '';
            }

            let list_marcas_detalle = {
                Badge: marca.Badge.Badge,
                Nombre: nombre_empleado,
                Cuenta: cuenta_empleado,
                Lob: lob_empleado,
                Fecha: marca.Marca.Fecha,
                HorasTrabajadas: 0,
                HorasNoTrabajadasJustificadas: 0,
                HorasPagadasLey: 0,
                HorasPagadasExcepcion: 0,
                HorasAddicionales: 0,
                HorasExtra: 0,
                HorasProgramadas: 0,
                HorasTotales: 0,
                HorasJustificar: 0
            };

            for await (const pausa of tipo_marcas) {
                let valor_marca_actual = pausa.id in marca ? marca[pausa.id] : 0;
                list_marcas_detalle[pausa.NombreTipoMarca] = valor_marca_actual;

                if (pausa.id == 1 || pausa.id == 62) {
                    list_marcas_detalle['HorasProgramadas'] += valor_marca_actual;
                }

                switch (pausa.Tipo) {
                    case 2:
                    case 5:
                        list_marcas_detalle['HorasTrabajadas'] += valor_marca_actual;
                        break;
                    case 6:
                        list_marcas_detalle['HorasNoTrabajadasJustificadas'] += valor_marca_actual;
                        break;
                    case 4:
                        list_marcas_detalle['HorasAddicionales'] += valor_marca_actual;
                        break;
                    case 8:
                        list_marcas_detalle['HorasPagadasLey'] += valor_marca_actual;
                        break;
                    case 9:
                        list_marcas_detalle['HorasPagadasExcepcion'] += valor_marca_actual;
                        break;
                    case 3:
                        list_marcas_detalle['HorasExtra'] += valor_marca_actual;
                        break;
                    default:
                        break;
                }
            }

            list_marcas_detalle['HorasTotales'] = list_marcas_detalle['HorasTrabajadas'];

            list_marcas_detalle['HorasTrabajadas'] = (list_marcas_detalle['HorasTrabajadas'] > list_marcas_detalle['HorasProgramadas']) ? list_marcas_detalle['HorasProgramadas'] : list_marcas_detalle['HorasTrabajadas'];

            list_marcas_detalle['HorasTotales'] = list_marcas_detalle['HorasTotales'] > list_marcas_detalle['HorasProgramadas'] ? list_marcas_detalle['HorasProgramadas'] : list_marcas_detalle['HorasTotales'];

            list_marcas_detalle['HorasJustificar'] = list_marcas_detalle['HorasProgramadas'] - list_marcas_detalle['HorasTotales'];

            list_marcas.push(list_marcas_detalle);
        }

        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(list_marcas);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'products');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
        </div>
    );

    return (
        <div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte de planilla</h5>
                        <p>Llena los filtros que necesites para poder consultar las horas</p>
                        <FilterHoras
                            requiredFields={{}}
                            showFields={{
                                RangoFechas: true,
                                Cuenta: true
                            }}
                            responseForm={onResponseForm}
                        />
                    </div>
                </div>
            </div>

            {loadingSkytracking && (
                <div className="grid my-2">
                    <div className="col-12 text-center">
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            )}

            <div className="gird">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte Horas</h5>
                        <DataTable
                            header={header}
                            value={horasPlanilla}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        >
                            <Column field="Badge.Badge" header="Badge" filter></Column>
                            <Column field="Marca.Fecha" header="Fecha" filter></Column>

                            <Column field="1" header="Horas Programadas Diurnas" filter></Column>
                            <Column field="2" header="Day - Login Time" filter></Column>
                            <Column field="3" header="Night - Login Time" filter></Column>
                            <Column field="4" header="Day - Login Time - Holiday" filter></Column>
                            <Column field="5" header="Night - Login Time - Holiday" filter></Column>
                            <Column field="6" header="Break 1" filter></Column>
                            <Column field="7" header="Break 2" filter></Column>
                            <Column field="8" header="Personal Bathroom" filter></Column>
                            <Column field="9" header="One on One" filter></Column>
                            <Column field="10" header="QA Feedback" filter></Column>
                            <Column field="11" header="Team Huddle" filter></Column>
                            <Column field="12" header="Training - Skycom" filter></Column>
                            <Column field="13" header="Training - Client" filter></Column>
                            <Column field="14" header="System Issue - Internal" filter></Column>
                            <Column field="15" header="System Issue - External" filter></Column>
                            <Column field="16" header="Project" filter></Column>
                            <Column field="17" header="Support" filter></Column>
                            <Column field="18" header="Active Pause" filter></Column>
                            <Column field="19" header="Overtime - Skycom" filter></Column>
                            <Column field="20" header="Overtime - Client" filter></Column>
                            <Column field="21" header="Night Overtime - Skycom" filter></Column>
                            <Column field="22" header="Night Overtime - Client" filter></Column>
                            <Column field="23" header="Overtime - Skycom - Holiday" filter></Column>
                            <Column field="24" header="Overtime - Client - Holiday" filter></Column>
                            <Column field="25" header="Night Overtime - Skycom - Holiday" filter></Column>
                            <Column field="26" header="Night Overtime - Client - Holiday" filter></Column>
                            <Column field="27" header="Additional Hours" filter></Column>
                            <Column field="28" header="Night - Additional Hours" filter></Column>
                            <Column field="29" header="Make Up Time" filter></Column>
                            <Column field="30" header="Unpaid Excess Login" filter></Column>
                            <Column field="31" header="Meal" filter></Column>
                            <Column field="32" header="Skycom Induction" filter></Column>
                            <Column field="33" header="Skycom Academy" filter></Column>
                            <Column field="34" header="Training - NO LTID" filter></Column>
                            <Column field="35" header="Vacation" filter></Column>
                            <Column field="36" header="PTO Daily" filter></Column>
                            <Column field="37" header="Paternity" filter></Column>
                            <Column field="38" header="Bereavement" filter></Column>
                            <Column field="39" header="Wedding" filter></Column>
                            <Column field="40" header="Local Holiday" filter></Column>
                            <Column field="41" header="System Issue - Hardware Issue (Non User)" filter></Column>
                            <Column field="42" header="System Issue - IT" filter></Column>
                            <Column field="43" header="Worked Time Unlogged" filter></Column>
                            <Column field="44" header="Nocturnidad" filter></Column>
                            <Column field="45" header="System Issue - Third Party" filter></Column>
                            <Column field="46" header="System Issue - Hardware Issue (User)" filter></Column>
                            <Column field="47" header="Attrition" filter></Column>
                            <Column field="48" header="Transfer - Undefined" filter></Column>
                            <Column field="49" header="ISSS" filter></Column>
                            <Column field="50" header="Medical Leave" filter></Column>
                            <Column field="51" header="Long Medical Leave " filter></Column>
                            <Column field="52" header="No Call No Show" filter></Column>
                            <Column field="53" header="VTO Schedule" filter></Column>
                            <Column field="54" header="US Holiday" filter></Column>
                            <Column field="55" header="Partial PTO" filter></Column>
                            <Column field="56" header="Partial - Maternity" filter></Column>
                            <Column field="57" header="Lateness" filter></Column>
                            <Column field="58" header="Partial VTO Sched" filter></Column>
                            <Column field="59" header="Partial ISSS" filter></Column>
                            <Column field="60" header="Time Adjustment" filter></Column>
                            <Column field="62" header="Horas Programadas Nocturnas" filter></Column>
                            <Column field="63" header="Worked Time Unlogged Night" filter></Column>
                            <Column field="64" header="Lateness Night" filter></Column>
                            <Column field="65" header="Unpaid Excess Login Night" filter></Column>
                            <Column field="66" header="Break 1 Night" filter></Column>
                            <Column field="67" header="Break 2 Night" filter></Column>
                            <Column field="68" header="Personal Bathroom Night" filter></Column>
                            <Column field="69" header="One on One Night" filter></Column>
                            <Column field="70" header="QA Feedback Night" filter></Column>
                            <Column field="71" header="Team Huddle Night" filter></Column>
                            <Column field="72" header="Training - Skycom Night" filter></Column>
                            <Column field="73" header="Training - Client Night" filter></Column>
                            <Column field="74" header="System Issue - Internal Night" filter></Column>
                            <Column field="75" header="System Issue - External Night" filter></Column>
                            <Column field="76" header="Project Night" filter></Column>
                            <Column field="77" header="Support Night" filter></Column>
                            <Column field="78" header="Active Pause Night" filter></Column>
                            <Column field="79" header="Night Make Up Time" filter></Column>
                            <Column field="80" header="Skycom Induction Night" filter></Column>
                            <Column field="81" header="Skycom Academy Night" filter></Column>
                            <Column field="82" header="Training - NO LTID Night" filter></Column>
                            <Column field="83" header="System Issue - Hardware Issue (Non User) Night" filter></Column>
                            <Column field="84" header="System Issue - IT Night" filter></Column>
                            <Column field="86" header="FEP" filter></Column>
                            <Column field="87" header="NatEmergency" filter></Column>
                            <Column field="88" header="NatEmergency Parcial" filter></Column>
                            <Column field="89" header="VTO RTM" filter></Column>
                            <Column field="90" header="VTO PM" filter></Column>
                            <Column field="91" header="Partial VTO RTM" filter></Column>
                            <Column field="92" header="Partial VTO PM" filter></Column>
                            <Column field="93" header="US Holiday Unpaid" filter></Column>
                            <Column field="94" header="US Holiday Invoice Parcial" filter></Column>
                            <Column field="95" header="US Holiday Unpaid Parcial" filter></Column>
                            <Column field="96" header="Time Adjustment Night" filter></Column>
                            <Column field="97" header="Citizen Duty" filter></Column>
                            <Column field="98" header="Citizen Duty Parcial" filter></Column>
                            <Column field="99" header="Jury Duty" filter></Column>
                            <Column field="100" header="Jury Duty Parcial" filter></Column>
                            <Column field="101" header="Paternity Parcial" filter></Column>
                            <Column field="102" header="Bereavement Parcial" filter></Column>
                            <Column field="103" header="Local Holiday Night" filter></Column>
                            <Column field="104" header="Paternity Night" filter></Column>
                            <Column field="105" header="Bereavement Night" filter></Column>
                            <Column field="106" header="Wedding Night" filter></Column>
                            <Column field="107" header="Citizen Duty Night" filter></Column>
                            <Column field="108" header="Citizen Duty Parcial Night" filter></Column>
                            <Column field="109" header="Jury Duty Night" filter></Column>
                            <Column field="110" header="Jury Duty Parcial Night" filter></Column>
                            <Column field="111" header="Paternity Parcial Night" filter></Column>
                            <Column field="112" header="Bereavement Parcial Night" filter></Column>
                            <Column field="113" header="Partial - Maternity Night" filter></Column>
                            <Column field="114" header="PTO Daily Night" filter></Column>
                            <Column field="115" header="Partial PTO Night" filter></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
}
