import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { listEquipoTrabajo } from '../../../utilities/options';

export function ItTab({ formik }) {
    return (
        <div className="grid mt-5 p-3">
            <div className="col-12">
                <h3>Informacion de IT</h3>

                <div className="grid mt-5">
                    <div className="col-12">
                        <span className="p-float-label">
                            <Dropdown className="w-full" name="Byod" id="Byod" options={listEquipoTrabajo} optionLabel="name" value={formik.values.Byod} onChange={(e) => formik.setFieldValue('Byod', e.value)} />
                            <label htmlFor="Byod">BYOD</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AccessCard" id="AccessCard" value={formik.values.AccessCard} onChange={formik.handleChange} />
                            <label htmlFor="AccessCard">Access Card N</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText className="w-full" name="WindowsUser" id="WindowsUser" value={formik.values.WindowsUser} onChange={formik.handleChange} />
                            <label htmlFor="WindowsUser">Windows User</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText className="w-full" name="InternalEmail" id="InternalEmail" value={formik.values.InternalEmail} onChange={formik.handleChange} />
                            <label htmlFor="InternalEmail">Internal Email</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText className="w-full" name="PersonalEmail" id="PersonalEmail" value={formik.values.PersonalEmail} onChange={formik.handleChange} />
                            <label htmlFor="PersonalEmail">Personal Email</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AnyDesk" id="AnyDesk" value={formik.values.AnyDesk} onChange={formik.handleChange} />
                            <label htmlFor="AnyDesk">AnyDesk Code</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
