import React, { useCallback, useEffect, useRef, useState } from 'react';
import { map } from 'lodash';
import {useDropzone} from "react-dropzone"
import {Image} from "primereact/image"
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useCommon, useSkytracking, useException, useNotificacion, useMasterRoster } from '../../hooks';

export function FormExcepcionOnline({ datosMarca, closeModal }) {
    const toast = useRef(null);

    const [pausaDefinicion, setPausaDefinicion] = useState(null);
    const [pausasFormat, setPausasFormat] = useState([]);
    const [horasInput, setHorasInput] = useState(true);
    const [arrPausas, setArrPausas] = useState([]);
    const [pausaTipo, setPausaTipo] = useState(0);
    const [limiteNcns, setLimiteNcns] = useState(datosMarca['52'] > 0 ? datosMarca['52'] : 0);
    const [limiteProgramado, setLimiteProgramado] = useState(datosMarca['57'] > 0 ? datosMarca['57'] : 0);
    const [limiteProgramado_noche, setLimiteProgramado_noche] = useState(datosMarca['64'] > 0 ? datosMarca['64'] : 0);
    const [limiteExtra, setLimiteExtra] = useState(datosMarca['30'] ? datosMarca['30'] : 0);
    const [limiteExtra_noche, setLimiteExtra_noche] = useState(datosMarca['65'] ? datosMarca['65'] : 0);
    const [limiteTotal, setLimiteTotal] = useState(16);
    const [previewImage, setPreviewImage] = useState(null)

    const { getPausasFilter_response } = useCommon();
    const { onChangeStateMarcaRechazada } = useSkytracking();
    const { insertException, validException } = useException();
    const {addNotificacion, addAsignacionNotificacion} = useNotificacion()
    const {getEmpleadoUpdateResponse} = useMasterRoster()

    const onDrop = useCallback(async (acceptedFile) => {
        const file = acceptedFile[0];
        await formik.setFieldValue("ImageJustificacion", file)
        setPreviewImage(URL.createObjectURL(file))

    }, [])

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    })

    useEffect(() => {
        let arr_pausas = [];

        if (datosMarca['57'] == 0 && datosMarca['52'] == 0 && datosMarca['64'] == 0) {
            arr_pausas = [3, 4, 7];
        } else {
            arr_pausas = [3, 4, 5, 6, 7,8, 10];
        }

        setArrPausas(arr_pausas);
    }, [datosMarca]);

    useEffect(() => {
        (async () => {
            let arr_final = [];

            for await (const pausaTipo of arrPausas) {
                const new_element = await getPausasFilter_response(pausaTipo, true, false);

                arr_final = [...arr_final, ...new_element];
            }
            setPausasFormat(pausasFormat_f(arr_final));
        })();
    }, [arrPausas]);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            if (datosMarca.EstadoMarca == 2) {
                await onChangeStateMarcaRechazada(datosMarca.idMarca, 1);
            }

            const response_employee = await getEmpleadoUpdateResponse(datosMarca.idEmpleado)

            const response_notificacion = await addNotificacion({
                Modulo: 2,
                Descripcion: `Excepcion Ingresada para ${datosMarca.Badge} - ${datosMarca.NombreEmpleado} - ${datosMarca.Fecha}`,
            })

            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: 'LTSC5915',
                Estado: 0
            })

            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: 'LTSC5914',
                Estado: 0
            })

            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: 'LT2262',
                Estado: 0
            })

            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: response_employee[10],
                Estado: 0
            })
            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: response_employee[11],
                Estado: 0
            })
            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: response_employee[12],
                Estado: 0
            })

            await insertException(formValue, datosMarca);

            toast.current.show({
                severity: 'success',
                summary: 'Exito',
                detail: 'Excepcion Agregada correctamente',
                life: 3000
            });

            setTimeout(() => {
                closeModal();
            }, 800);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast} />
            <div className="field">
                <label>Pausa</label>
                <Dropdown
                    name="Pausa"
                    value={formik.values.Pausa}
                    optionLabel="name"
                    placeholder="Seleccione una pausa"
                    className="w-full"
                    options={pausasFormat}
                    onChange={async (data) => {
                        const insertexception = await validException(datosMarca.Fecha, datosMarca.Badge, data.value.code)
                        if(insertexception){

                            setPausaDefinicion(data.value.definicion);
                            setHorasInput(false);
                            setPausaTipo(data.value.tipo);
                            setLimiteProgramado(datosMarca['57'] > 0 ? datosMarca['57'] : 0);
                            setLimiteNcns(datosMarca['52'] > 0 ? datosMarca['52'] : 0);
                            
                            setLimiteProgramado_noche(datosMarca['64'] > 0 ? datosMarca['64'] : 0);
                            formik.setFieldValue('Pausa', data.value);
                            formik.setFieldValue('Horas', 0);
                            
                        }else{
                            toast.current.show({
                                severity: 'error',
                                summary: 'Error',
                                detail: 'Hay una excepcion pendiente para esta pausa',
                                life: 3000
                            });
                        }
                    }}
                    filter
                />
            </div>
            {pausaDefinicion && (
                <div className="field bg-indigo-800 p-5 text-white text-center font-bold">
                    <i className="pi pi-arrow-right fadein animation-duration-1000 animation-iteration-infinite font-bold" style={{ color: 'yellow', fontSize: '2.5 rem' }}></i>
                    &nbsp;{pausaDefinicion}&nbsp;
                    <i className="pi pi-arrow-left fadein animation-duration-1000 animation-iteration-infinite font-bold" style={{ color: 'yellow', fontSize: '2.5 rem' }}></i>
                </div>
            )}
            <div className="field">
                <label>Observacion</label>
                <InputTextarea rows={5} cols={30} className="w-full" name="Observacion" value={formik.values.Observacion} onChange={formik.handleChange} />
            </div>
            <div className="field">
                <label>Horas</label>
                <InputNumber
                    minFractionDigits={2}
                    maxFractionDigits={5}
                    className="w-full"
                    readOnly={horasInput}
                    name="Horas"
                    value={formik.values.Horas}
                    onChange={async (e) => {
                        setLimiteProgramado(datosMarca['57'] > 0 ? datosMarca['57'] : 0);
                        setLimiteNcns(datosMarca['52'] > 0 ? datosMarca['52'] : 0);
                        
                        setLimiteProgramado_noche(datosMarca['64'] > 0 ? datosMarca['64'] : 0);
                        setLimiteExtra(datosMarca['30']);
                        setLimiteExtra_noche(datosMarca['65'] ? datosMarca['65'] : 0);
                        setLimiteTotal(16);

                        if (pausaTipo > 4 && pausaTipo != 7) {
                            if (datosMarca['57'] < e.value && datosMarca['52'] < e.value && !(formik.values?.Pausa.name.includes("Night"))) {
                                toast.current.show({
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: 'Las horas ingresadas superan el limite señalado',
                                    life: 3000
                                });

                                e.originalEvent.target.value = 0;
                            }else if (datosMarca['64'] < e.value && formik.values?.Pausa.name.includes("Night")) {
                                toast.current.show({
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: 'Las horas ingresadas superan el limite señalado',
                                    life: 3000
                                });

                                e.originalEvent.target.value = 0;
                            } else {
                                if (formik.values?.Pausa.name.includes("Night")){
                                    setLimiteProgramado_noche((prev) => prev - e.value);
                                }else{
                                    if (limiteProgramado == 0){

                                        setLimiteNcns((prev) => prev - e.value)
                                    }else{

                                        setLimiteProgramado((prev) => prev - e.value);
                                    }
                                }
                                formik.setFieldValue('Horas', e.value);
                            }
                        } else if (pausaTipo > 0 && pausaTipo < 5) {
                            if (datosMarca['30'] < e.value || datosMarca['65'] < e.value) {
                                if (16 < e.value) {
                                    toast.current.show({
                                        severity: 'error',
                                        summary: 'Error',
                                        detail: 'Las horas ingresadas superan el limite señalado',
                                        life: 3000
                                    });

                                    e.originalEvent.target.value = 0;
                                } else {
                                    /* toast.current.show({
                                        severity: 'warn',
                                        summary: 'Aviso',
                                        detail: 'Las horas seran ingresadas como una excepcion de horas extra',
                                        life: 5000
                                    }); */

                                    if(formik.values?.Pausa.name.includes("Night")){

                                        setLimiteExtra_noche((prev) => prev - e.value);
                                    }else{
                                        setLimiteExtra((prev) => prev - e.value);

                                    }

                                    setLimiteTotal((prev) => prev - e.value);
                                    formik.setFieldValue('Horas', e.value);
                                }
                            } else {

                                if(formik.values?.Pausa.name.includes("Night")){

                                    setLimiteExtra_noche((prev) => prev - e.value);
                                }else{
                                    
                                    setLimiteExtra((prev) => prev - e.value);
                                }
                                formik.setFieldValue('Horas', e.value);
                            }
                        } else {
                            if (e.value > 0) {
                                toast.current.show({
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: 'Solo se pueden ingresar numeros Negativos',
                                    life: 5000
                                });

                                e.originalEvent.target.value = 0;
                            } else {
                                if (e.value * -1 > datosMarca['1']) {
                                    toast.current.show({
                                        severity: 'error',
                                        summary: 'Error',
                                        detail: 'El valor ingresado supera el limite de las horas programadas',
                                        life: 5000
                                    });
                                    e.originalEvent.target.value = 0;
                                } else {
                                    formik.setFieldValue('Horas', e.value);
                                }
                            }
                        }
                    }}
                />
                {horasInput ? <p className="text-red-600">Seleccione una pausa</p> : <p>Pausa Seleccionada</p>}
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <div className='grid'>
                        <div className='col-12'>

                            <Button type='button' {...getRootProps()}>Subir imagen</Button>
                            <input {...getInputProps()} />
                        </div>
                    </div>
                    <div className='grid'>
                        <div className='col-12'>
                            <Image src={previewImage} width='100%' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 md:col-4">
                    <div
                        className={`font-bold border-round p-3 text-0 w-full ${classNames('bg-green-600', {
                            'bg-indigo-800': pausaTipo > 4 && pausaTipo != 7 && !(formik.values?.Pausa.name.includes("Night"))
                        })}`}
                    >
                        <div className="grid">
                            <div className="col text-center">Non Worked Hours / NCNS:</div>
                        </div>
                        <div className="grid">
                            <div className="col text-center">{parseFloat(limiteProgramado) + parseFloat(limiteNcns)}</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div
                        className={`font-bold border-round p-3 text-0 w-full ${classNames('bg-green-600', {
                            'bg-indigo-800': pausaTipo > 4 && pausaTipo != 7 && formik.values?.Pausa.name.includes("Night")
                        })}`}
                    >
                        <div className="grid">
                            <div className="col text-center">Non Worked Hours / NCNS (Night):</div>
                        </div>
                        <div className="grid">
                            <div className="col text-center">{limiteProgramado_noche}</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div
                        className={`font-bold border-round p-3 text-0 w-full ${classNames('bg-green-600', {
                            'bg-indigo-800': pausaTipo < 5 && pausaTipo > 0 && !(formik.values?.Pausa.name.includes("Night"))
                        })}`}
                    >
                        <div className="grid">
                            <div className="col text-center">Unpaid Excess:</div>
                        </div>
                        <div className="grid">
                            <div className="col text-center">{limiteExtra < 0 ? 0 : limiteExtra}</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div
                        className={`font-bold border-round p-3 text-0 w-full ${classNames('bg-green-600', {
                            'bg-indigo-800': pausaTipo < 5 && pausaTipo > 0 && formik.values?.Pausa.name.includes("Night")
                        })}`}
                    >
                        <div className="grid">
                            <div className="col text-center">Unpaid Excess (Night):</div>
                        </div>
                        <div className="grid">
                            <div className="col text-center">{limiteExtra_noche < 0 ? 0 : limiteExtra_noche}</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div
                        className={`font-bold border-round p-3 text-0 w-full ${classNames('bg-green-600', {
                            'bg-indigo-800': pausaTipo < 5 && pausaTipo > 0
                        })}`}
                    >
                        <div className="grid">
                            <div className="col text-center">Max Extra Hours</div>
                        </div>
                        <div className="grid">
                            <div className="col text-center">{limiteTotal}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid mt-5">
                <div className="col text-right">
                    <Button type="submit" severity="primary">
                        Guardar Excepcion
                    </Button>
                </div>
            </div>
        </form>
    );
}

function pausasFormat_f(data) {
    return map(data, (item, index) => ({
        name: item.NombreTipoMarca,
        code: item.id,
        definicion: item.Definicion,
        tipo: item.Tipo,
    }));
}

function initialValues() {
    return {
        Pausa: '',
        Observacion: '',
        Horas: '',
        ImageJustificacion: "",
    };
}

function validationSchema() {
    return {
        Pausa: Yup.object().required(true),
        Observacion: Yup.string().required(true),
        Horas: Yup.string().required(true),
        ImageJustificacion: Yup.string(),
    };
}
