import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { size } from 'lodash';
import moment from 'moment';
import { FormExcepcionReject } from '../Skytracking';
import { useException, useSkytracking, useUploadFiles, useCommon } from '../../hooks';

export function TableException({ excepciones }) {
    const toast = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [showButtonsApprove, setShowButtonsApprove] = useState(null);

    const { onAprobarHorasPersonales, onChangeStateMarcaRechazada } = useSkytracking();
    const { getMarcaException, insertDetalleMarcaExcepcion, updateExcepcionReject, getOnlyException } = useException();
    const { calcAuxMarca } = useUploadFiles();
    const { getPlazaValid } = useCommon();

    useEffect(() => {
        (async () => {
            const response_plaza = await getPlazaValid();
            console.log(response_plaza);
            if (size(response_plaza) > 0) {
                switch (response_plaza[0].Valor) {
                    case '8':
                    case '11':
                    case '17':
                    case '16':
                        setShowButtonsApprove(true);
                        break;

                    default:
                        setShowButtonsApprove(false);
                        break;
                }
            } else {
                setShowButtonsApprove(false);
            }
        })();
    }, []);

    const onResponseForm = async (motivo, idExcepcion, event) => {
        try {
            const padre = event.target.parentNode.parentNode;

            padre.parentNode.removeChild(padre);

            const dataExcepcion = await getOnlyException(idExcepcion);

            const response_marca = await getMarcaException(dataExcepcion.empleado_data.Badge, dataExcepcion.FechaInicio);

            if (size(response_marca) > 0) {
                await onAprobarHorasPersonales(response_marca.id, null);
                await onChangeStateMarcaRechazada(response_marca.id, 2);
                await updateExcepcionReject(idExcepcion, motivo);
            }

            toast.current.show({
                severity: 'success',
                summary: 'Excepcion',
                detail: 'Excepcion Rechazada correctamente',
                life: 3000
            });

            setShowModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    const onRechazarExceptionModal = (idExcepcion, event) => {
        setShowModal(true);
        setContentModal(<FormExcepcionReject idExcepcion={idExcepcion.id} response={onResponseForm} elemento={event} />);
    };

    const onAprobarEjecutarException = async (exception, event) => {
        const padre = event.target.parentNode.parentNode;

        padre.parentNode.removeChild(padre);

        let fechaInicio = new Date(exception.FechaInicio);
        let fechaFinal = new Date(exception.FechaFin);
        let arr_fechas = [];

        while (fechaInicio <= fechaFinal) {
            /* arr_fechas.push(`${fechaInicio.getFullYear()}-${fechaInicio.getMonth() + 1}-${fechaInicio.getDate() + 1}`); */
            fechaInicio.setDate(fechaInicio.getDate() + 1);

            arr_fechas.push(moment(fechaInicio).format('YYYY-MM-DD'));
        }

        for await (const fecha of arr_fechas) {
            const response_marca = await getMarcaException(exception.Badge, fecha);

            if (size(response_marca) > 0) {
                await onAprobarHorasPersonales(response_marca.id, null);
                await insertDetalleMarcaExcepcion(response_marca.id, exception.tipoMarca_data.id, exception.ValorExcepcion, exception.id);
                await onChangeStateMarcaRechazada(response_marca.id, 1);

                await calcAuxMarca(response_marca.id);
            }
        }

        toast.current.show({
            severity: 'success',
            summary: 'Excepcion',
            detail: 'Excepcion Aprobada',
            life: 3000
        });
    };

    const actionBodyTemplate = (rowData) => {
        let str_estadoExcepcion = '';

        switch (rowData.EstadoExcepcion) {
            case '0':
                str_estadoExcepcion = 'Pendiente';
                break;

            case '1':
                str_estadoExcepcion = 'Aprobada';
                break;
            case '2':
                str_estadoExcepcion = 'Rechazada';
                break;

            default:
                break;
        }

        return (
            <React.Fragment>
                {rowData.EstadoExcepcion == 0 && showButtonsApprove && (
                    <div className="grid">
                        <div className="col-6">
                            <Button type="button" severity="success" aria-label="Aprobar" onClick={(e) => onAprobarEjecutarException(rowData, e)}>
                                Aprobar
                            </Button>
                        </div>
                        <div className="col-6">
                            <Button type="button" severity="danger" aria-label="Rechazar" onClick={(e) => onRechazarExceptionModal(rowData, e)}>
                                Rechazar
                            </Button>
                        </div>
                    </div>
                )}
                <div className="grid">
                    <div className="col-12">{str_estadoExcepcion}</div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog header="Skyverse Skytracking" style={{ width: '30vw' }} visible={showModal} modal onHide={() => setShowModal(false)}>
                {contentModal}
            </Dialog>
            <DataTable
                value={excepciones}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
                <Column field="FechaInicio" header="Fecha Inicio" filter></Column>
                <Column field="FechaFin" header="Fecha Final" filter></Column>
                <Column field="Badge" header="Empleado" filter></Column>
                <Column field="NombreEmpleado" header="Nombre" filter></Column>
                <Column field="CuentaEmpleado" header="Cuenta" filter></Column>
                <Column field="ValorExcepcion" header="Horas" filter></Column>
                <Column field="tipoMarca_data.NombreTipoMarca" header="Pausa" filter></Column>
                <Column field="Motivo" header="Descripcion de la excepcion" filter></Column>
                <Column body={actionBodyTemplate} exportable={false}></Column>
                <Column field="MotivoRechazo" header="Motivo Rechazo"></Column>
            </DataTable>
        </>
    );
}
