import React from "react"

export function SkytrackingTempPage () {
    return (
        <>
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Reporte de Skytracking Temp</h5>
                    <p>Reporte para visualizar las horas de skytracking temp y Skyverse</p>
                </div>
            </div>
        </div>
        </>
    )
}