import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { size, map } from 'lodash';

export function TableHorasPlanilla({ listHours }) {
    const emptyHours = (rowData, columnData) => {
        return rowData[columnData] ? rowData[columnData] : '0';
    };

    const cols = [
        { field: 'Badge', header: 'Badge' },
        { field: 'Nombre', header: 'Nombre' },
        { field: 'FechaMarca', header: 'FechaMarca' },
        { field: 'HorasTrabajadas', header: 'HorasTrabajadas' },
        { field: 'HorasExtra', header: 'HorasExtra' },
        { field: 'HorasAdicionales', header: 'HorasAdicionales' },
        { field: '1', header: 'Horas Programadas' },
        { field: '2', header: 'Day - Login Time' },
        { field: '3', header: 'Night - Login Time' },
        { field: '4', header: 'Day - Login Time - Holiday' },
        { field: '5', header: 'Night - Login Time - Holiday' },
        { field: '6', header: 'Break 1' },
        { field: '7', header: 'Break 2' },
        { field: '8', header: 'Personal Bathroom' },
        { field: '9', header: 'One on One' },
        { field: '10', header: 'QA Feedback' },
        { field: '11', header: 'Team Huddle' },
        { field: '12', header: 'Training - Skycom' },
        { field: '13', header: 'Training - Client' },
        { field: '14', header: 'System Issue - Internal' },
        { field: '15', header: 'System Issue - External' },
        { field: '16', header: 'Project' },
        { field: '17', header: 'Support' },
        { field: '18', header: 'Active Pause' },
        { field: '19', header: 'Overtime - Skycom' },
        { field: '20', header: 'Overtime - Client' },
        { field: '21', header: 'Night Overtime - Skycom' },
        { field: '22', header: 'Night Overtime - Client' },
        { field: '23', header: 'Overtime - Skycom - Holiday' },
        { field: '24', header: 'Overtime - Client - Holiday' },
        { field: '25', header: 'Night Overtime - Skycom - Holiday' },
        { field: '26', header: 'Night Overtime - Client - Holiday' },
        { field: '27', header: 'Additional Hours' },
        { field: '28', header: 'Night - Additional Hours' },
        { field: '29', header: 'Make Up Time' },
        { field: '31', header: 'Meal' },
        { field: '32', header: 'Skycom Induction' },
        { field: '33', header: 'Skycom Academy' },
        { field: '34', header: 'Training - NO LTID' },
        { field: '35', header: 'Vacation' },
        { field: '36', header: 'PTO Daily' },
        { field: '37', header: 'Paternity' },
        { field: '38', header: 'Bereavement' },
        { field: '39', header: 'Wedding' },
        { field: '40', header: 'Local Holiday' },
        { field: '41', header: 'System Issue - Hardware Issue (Non User' },
        { field: '42', header: 'System Issue - IT' },
        { field: '43', header: 'Worked Time Unlogged' },
        { field: '44', header: 'Nocturnidad' },
        { field: '45', header: 'System Issue - Third Party' },
        { field: '46', header: 'System Issue - Hardware Issue (User' },
        { field: '47', header: 'Attrition' },
        { field: '48', header: 'Transfer - Undefined' },
        { field: '49', header: 'ISSS' },
        { field: '50', header: 'Sick Leave' },
        { field: '51', header: 'Long Medical Leave' },
        { field: '53', header: 'VTO' },
        { field: '54', header: 'US Holiday' },
        { field: '55', header: 'Partial PTO' },
        { field: '56', header: 'Partial - Maternity' },
        { field: '58', header: 'Partial VTO' },
        { field: '59', header: 'Partial ISSS' },
        { field: '60', header: 'Time Adjustment' },
        { field: '61', header: 'Schedule VTO' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, listHours);
                doc.save('MasterRoster.pdf');
            });
        });
    };

    const exportExcel = () => {
        let listEmpleadosExcel = [];

        for (const row of listHours) {
            let temp_listEmpleadosExcel = [];

            temp_listEmpleadosExcel['Badge'] = row.Badge;
            temp_listEmpleadosExcel['Nombre'] = row.Nombre;
            temp_listEmpleadosExcel['Fecha'] = row.FechaMarca;
            temp_listEmpleadosExcel['HorasTrabajadas'] = row.HorasTrabajadas;

            row.forEach((value, i) => {
                const valor_nuevo = cols.filter((item) => item.field == i);

                if (size(valor_nuevo) > 0) {
                    temp_listEmpleadosExcel[valor_nuevo[0].header] = value;
                }
            });

            listEmpleadosExcel.push(temp_listEmpleadosExcel);
        }

        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(listEmpleadosExcel);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'HorasPlanilla');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
        </div>
    );

    return (
        <DataTable
            value={listHours}
            header={header}
            scrollable
            scrollHeight="600px"
            paginator
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50, 500, 1000, 5000]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
        >
            {/* Datos Empleados */}
            <Column field="Badge" header="Badge " frozen filter resizeable={true}></Column>
            <Column field="Nombre" header="Nombre " frozen filter resizeable={true}></Column>
            <Column field="Cuenta" header="Cuenta " frozen filter resizeable={true}></Column>
            <Column field="FechaMarca" header="Fecha " frozen filter></Column>

            {/* Campos Calculados */}
            <Column field="HorasTrabajadas" header="Horas Trabajadas " filter></Column>
            <Column field="HorasExtra" header="Horas Extra " filter></Column>
            <Column field="HorasAdicionales" header="Horas Adicionales " filter></Column>

            {/* Horas Formato Skyverse */}
            <Column field="1" header="Horas Programadas " filter body={(rowData) => emptyHours(rowData, 1)}></Column>
            <Column field="2" header="Day - Login Time " filter body={(rowData) => emptyHours(rowData, 2)}></Column>
            <Column field="3" header="Night - Login Time " filter body={(rowData) => emptyHours(rowData, 3)}></Column>
            <Column field="4" header="Day - Login Time - Holiday " filter body={(rowData) => emptyHours(rowData, 4)}></Column>
            <Column field="5" header="Night - Login Time - Holiday " filter body={(rowData) => emptyHours(rowData, 5)}></Column>
            <Column field="6" header="Break 1  " filter body={(rowData) => emptyHours(rowData, 6)}></Column>
            <Column field="7" header="Break 2  " filter body={(rowData) => emptyHours(rowData, 7)}></Column>
            <Column field="8" header="Personal Bathroom  " filter body={(rowData) => emptyHours(rowData, 8)}></Column>
            <Column field="9" header="One on One " filter body={(rowData) => emptyHours(rowData, 9)}></Column>
            <Column field="10" header="QA Feedback  " filter body={(rowData) => emptyHours(rowData, 10)}></Column>
            <Column field="11" header="Team Huddle  " filter body={(rowData) => emptyHours(rowData, 11)}></Column>
            <Column field="12" header="Training - Skycom  " filter body={(rowData) => emptyHours(rowData, 12)}></Column>
            <Column field="13" header="Training - Client  " filter body={(rowData) => emptyHours(rowData, 13)}></Column>
            <Column field="14" header="System Issue - Internal  " filter body={(rowData) => emptyHours(rowData, 14)}></Column>
            <Column field="15" header="System Issue - External  " filter body={(rowData) => emptyHours(rowData, 15)}></Column>
            <Column field="16" header="Project  " filter body={(rowData) => emptyHours(rowData, 16)}></Column>
            <Column field="17" header="Support  " filter body={(rowData) => emptyHours(rowData, 17)}></Column>
            <Column field="18" header="Active Pause " filter body={(rowData) => emptyHours(rowData, 18)}></Column>
            <Column field="19" header="Overtime - Skycom  " filter body={(rowData) => emptyHours(rowData, 19)}></Column>
            <Column field="20" header="Overtime - Client  " filter body={(rowData) => emptyHours(rowData, 20)}></Column>
            <Column field="21" header="Night Overtime - Skycom  " filter body={(rowData) => emptyHours(rowData, 21)}></Column>
            <Column field="22" header="Night Overtime - Client  " filter body={(rowData) => emptyHours(rowData, 22)}></Column>
            <Column field="23" header="Overtime - Skycom - Holiday  " filter body={(rowData) => emptyHours(rowData, 23)}></Column>
            <Column field="24" header="Overtime - Client - Holiday  " filter body={(rowData) => emptyHours(rowData, 24)}></Column>
            <Column field="25" header="Night Overtime - Skycom - Holiday  " filter body={(rowData) => emptyHours(rowData, 25)}></Column>
            <Column field="26" header="Night Overtime - Client - Holiday  " filter body={(rowData) => emptyHours(rowData, 26)}></Column>
            <Column field="27" header="Additional Hours " filter body={(rowData) => emptyHours(rowData, 27)}></Column>
            <Column field="28" header="Night - Additional Hours " filter body={(rowData) => emptyHours(rowData, 28)}></Column>
            <Column field="29" header="Make Up Time " filter body={(rowData) => emptyHours(rowData, 29)}></Column>
            <Column field="31" header="Meal " filter body={(rowData) => emptyHours(rowData, 31)}></Column>
            <Column field="32" header="Skycom Induction " filter body={(rowData) => emptyHours(rowData, 32)}></Column>
            <Column field="33" header="Skycom Academy " filter body={(rowData) => emptyHours(rowData, 33)}></Column>
            <Column field="34" header="Training - NO LTID " filter body={(rowData) => emptyHours(rowData, 34)}></Column>
            <Column field="35" header="Vacation " filter body={(rowData) => emptyHours(rowData, 35)}></Column>
            <Column field="36" header="PTO Daily  " filter body={(rowData) => emptyHours(rowData, 36)}></Column>
            <Column field="37" header="Paternity  " filter body={(rowData) => emptyHours(rowData, 37)}></Column>
            <Column field="38" header="Bereavement  " filter body={(rowData) => emptyHours(rowData, 38)}></Column>
            <Column field="39" header="Wedding  " filter body={(rowData) => emptyHours(rowData, 39)}></Column>
            <Column field="40" header="Local Holiday  " filter body={(rowData) => emptyHours(rowData, 40)}></Column>
            <Column field="41" header="System Issue - Hardware Issue (Non User) " filter body={(rowData) => emptyHours(rowData, 41)}></Column>
            <Column field="42" header="System Issue - IT  " filter body={(rowData) => emptyHours(rowData, 42)}></Column>
            <Column field="43" header="Worked Time Unlogged " filter body={(rowData) => emptyHours(rowData, 43)}></Column>
            <Column field="44" header="Nocturnidad  " filter body={(rowData) => emptyHours(rowData, 44)}></Column>
            <Column field="45" header="System Issue - Third Party " filter body={(rowData) => emptyHours(rowData, 45)}></Column>
            <Column field="46" header="System Issue - Hardware Issue (User) " filter body={(rowData) => emptyHours(rowData, 46)}></Column>
            <Column field="47" header="Attrition  " filter body={(rowData) => emptyHours(rowData, 47)}></Column>
            <Column field="48" header="Transfer - Undefined " filter body={(rowData) => emptyHours(rowData, 48)}></Column>
            <Column field="49" header="ISSS " filter body={(rowData) => emptyHours(rowData, 49)}></Column>
            <Column field="50" header="Sick Leave " filter body={(rowData) => emptyHours(rowData, 50)}></Column>
            <Column field="51" header="Long Medical Leave " filter body={(rowData) => emptyHours(rowData, 51)}></Column>
            <Column field="53" header="VTO  " filter body={(rowData) => emptyHours(rowData, 53)}></Column>
            <Column field="54" header="US Holiday " filter body={(rowData) => emptyHours(rowData, 54)}></Column>
            <Column field="55" header="Partial PTO  " filter body={(rowData) => emptyHours(rowData, 55)}></Column>
            <Column field="56" header="Partial - Maternity  " filter body={(rowData) => emptyHours(rowData, 56)}></Column>
            <Column field="58" header="Partial VTO  " filter body={(rowData) => emptyHours(rowData, 58)}></Column>
            <Column field="59" header="Partial ISSS " filter body={(rowData) => emptyHours(rowData, 59)}></Column>
            <Column field="60" header="Time Adjustment  " filter body={(rowData) => emptyHours(rowData, 60)}></Column>
            <Column field="61" header="Schedule VTO " filter body={(rowData) => emptyHours(rowData, 61)}></Column>
        </DataTable>
    );
}
