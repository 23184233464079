import React from 'react';
import { FilterRoster, TableRosterUpdate } from '../../components/Skyroster';
import {useRosterUpdate} from "../../hooks"

export function ReportUpdatesPage() {

    const {loadingRosterUpdate, getRosterUpdates, rosterUpdates} = useRosterUpdate()

    const onResponseForm = async (formData) => {

        await getRosterUpdates(formData.Semana, formData.Cuenta, formData.Lob, formData.EstadoRosterUpdate)
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte de Roster Update</h5>
                        <p>Reporte de todos los roster updates creados por lo supervisores</p>

                        <FilterRoster
                            showField={{
                                Semana: true,
                                Cuenta: true,
                                Lob: true,
                                Supervisor: false,
                                EstadoRosterUpdate: true,
                                CuentaRequired:false
                            }}
                            responseForm={onResponseForm}
                        />
                    </div>
                </div>
            </div>

            {loadingRosterUpdate ? (
                <div className='grid mt-5'>
                    <div className='col-12 text-center'>
                        <i className='pi pi-spinner pi-spin' style={{fontSize:'5rem'}}></i>
                    </div>
                </div>
            ) : rosterUpdates && (
                <div className='grid mt-5'>
                    <div className='col-12'>
                        <div className='card'>
                            <h5>Resultado Roster Update</h5>
                            <p>Revisa y apruba todas las solicitudes de roster update pendientes</p>

                            <TableRosterUpdate rosterUpdates={rosterUpdates}/>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
