import React, { useEffect } from 'react';
import { useSkytracking } from '../../hooks';
import { TableMarcasRechazadas } from '../../components/Skytracking';

export function MarcasRechazadasPage() {
    const { getMarcasRechazadas, marcasRechazadas, loadingSkytracking } = useSkytracking();

    useEffect(() => {
        (async () => {
            await getMarcasRechazadas();
        })();
    }, []);

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Marcas Rechazadas</h5>
                    <p>Aqui puedes ver las marcas rechazadas por los agentes y el motivo por el cual a sido rechazada</p>
                    {loadingSkytracking ? (
                        <div className="grid">
                            <div className="col-12 text-center">
                                <i className="pi pi-spinner pi-spin"></i>
                            </div>
                        </div>
                    ) : (
                        marcasRechazadas && <TableMarcasRechazadas listMarcasRechazadas={marcasRechazadas} />
                    )}
                </div>
            </div>
        </div>
    );
}
