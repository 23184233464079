import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image } from 'primereact/image';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export function VacationFormAp({ response }) {
    const [previewImage, setPreviewImage] = useState(null);
    const [disabledState, setDisabledState] = useState(false);

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile[0];
        formik.setFieldValue('ImagenAp', file);
        setPreviewImage(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    });

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            setDisabledState(true)
            response(formValue);
        }
    });

    const TipoVacacion = [
        { name: 'Descansadas', value: 'Descansadas' },
        { name: 'Trabajadas', value: 'Trabajadas' }
    ];

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <label>Rango de Fechas</label>
                    <Calendar name="FechasEfectivas" value={formik.values.FechasEfectivas} onChange={(e) => formik.setFieldValue('FechasEfectivas', e.value)} selectionMode="range" readOnlyInput dateFormat="yy-mm-dd" className="w-full" />
                </div>
                <div className="col-12 md:col-6">
                    <label>Tipo de Vacaion</label>
                    <Dropdown name="TipoVacacion" value={formik.values.TipoVacacion} onChange={(e) => formik.setFieldValue('TipoVacacion', e.value)} options={TipoVacacion} optionLabel="name" className="w-full" />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <label>Observacion</label>
                    <InputTextarea rows={5} cols={30} name="Observacion" className="w-full" value={formik.values.Observacion} onChange={formik.handleChange} />
                </div>
            </div>
            <div className="grid">
                <div className="col-6">
                    <Button type="button" severity="primary" size="large" {...getRootProps()}>
                        Subir Imagen para la AP
                    </Button>
                    <input {...getInputProps()} />
                </div>
                <div className="col-6">
                    <Image src={previewImage} width="100%" />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <Button severity="success" type="submit" disabled={disabledState}>
                        Guardar Ap
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        FechasEfectivas: '',
        TipoVacacion: '',
        Observacion: '',
        ImagenAp: ''
    };
}

function validationSchema() {
    return {
        FechasEfectivas: Yup.array().required(true),
        TipoVacacion: Yup.string().required(true),
        Observacion: Yup.string().required(true),
        ImagenAp: Yup.string()
    };
}
