import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Registra los componentes de Chart.js y el plugin de etiquetas de datos
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export function ChartTeamHours ({ arr_fechas, arr_trabajadas, arr_programadas, charType, charPosition, tipeChart }) {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        let color_final = (charPosition == "y" || tipeChart=="line") ? "#000" : "#fff";
        let bar_lines_color = (tipeChart == "line") ? documentStyle.getPropertyValue('--teal-500') : documentStyle.getPropertyValue('--blue-800');

        const data = {
            labels: arr_fechas,
            datasets: [
                {
                    label: 'Programadas',
                    backgroundColor: documentStyle.getPropertyValue('--surface-700'),
                    borderColor: documentStyle.getPropertyValue('--surface-700'),
                    data: arr_programadas,
                    tension: 0.2
                },
                {
                    type: "bar",
                    label: 'Trabajadas',
                    backgroundColor: bar_lines_color,
                    borderColor: documentStyle.getPropertyValue('--blue-800'),
                    data: arr_trabajadas,
                    tension: 0.2
                },
            ]
        };
        const options = {
            indexAxis: charPosition,
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    position: 'left',
                    labels: {
                        fontColor: textColor,
                        boxWidth: 30
                    }
                },
                datalabels: {
                    color: color_final,
                    display: true,
                    anchor: 'mid',
                    align: 'top',
                    formatter: (value) => value.toFixed(2),
                    font: {
                        weight: 'bold',
                    },
                    padding: 4,
                },
            },
            scales: {
                x: {
                    /* ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    }, */
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    /* ticks: {
                        color: textColorSecondary
                    }, */
                    grid: {
                        /* color: surfaceBorder, */
                        display: false,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [arr_fechas]);

    return (
        <>
            <Chart type={tipeChart} data={chartData} options={chartOptions} />
        </>
    )
}