import React from "react";
import moment from "moment"
import {FiltrosSam,TableAplicantes} from "../../components/skyapplicantmanagement"
import {useApplicant} from "../../hooks"

export function AplicantesReclutamiento () {

    const {getReportApplicant, listApplicants, loadingApplicant} = useApplicant()

    const list_filtros = {
        FechaAplicacion: true,
        EstadoAplicante: true,
        Pauta: true,
        Idioma: true,
        NivelIdioma: true,
        Pais: true,
        Nombre: true,
        Cuenta: false,
        Job: true,
        FechaAprobacion: false,
        Referido: true,
    }

    const list_required = {
        FechaAplicacion: true,
        EstadoAplicante: true,
    }

    const onResponseForm = async (FormData) => {
        await getReportApplicant(moment(FormData.FechaAplicacion[0]).format("YYYY-MM-DD"), moment(FormData.FechaAplicacion[1]).format("YYYY-MM-DD"), FormData.EstadoAplicante, FormData)
    }

    return (
        <>
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Reporte de Lead</h5>
                    <p>Selecciona los campos para poder filtrar a los aplicantes</p>

                    <FiltrosSam filtros={list_filtros} response={onResponseForm} param_required={list_required} />
                </div>
            </div>
        </div>

        {loadingApplicant ? (
            <div className="grid">
                <div className="col-12 text-center">
                    <i className="pi pi-spin pi-spinner" style={{fontSize: '5rem'}}></i>
                </div>
            </div>
        ) : (

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Resultado Aplicantes</h5>
                        <p>En esta tabla puedes ver el resultado de los filtros de sam, ademas puedes realizar acciones sobre los aplicantes</p>
                        <TableAplicantes listApplicant={listApplicants} />
                    </div>
                </div>
            </div>

        )}

        </>
    )
}