import { BASE_API } from '../../utilities/constants';

export async function getRosterUpdateEmpleadosApi(token, Week, Empleado = '', Estado = '') {
    try {
        const weekFilter = `Week=${Week}`;
        const empleadoFilter = `Badge__in=${Empleado}`;
        const estadoFilter = `Estado=${Estado}`;

        const url = `${BASE_API}v1/RosterUpdateEmpleado/?${weekFilter}&${empleadoFilter}&${estadoFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getRosterUpdateEmpleadoExistApi(token, Week, Badge) {
    try {
        const weekFilter = `Week=${Week}`;
        const BadgeFilter = `Badge=${Badge}`;
        const url = `${BASE_API}v1/RosterUpdateEmpleado/?${weekFilter}&${BadgeFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getRosterUpdateEmpleadoIdApi(token, idRosterUpdateEmpleado) {
    try {
        const idRosterUpdateEmpleadoFilter = `id=${idRosterUpdateEmpleado}`;
        const url = `${BASE_API}v1/RosterUpdateEmpleado/?${idRosterUpdateEmpleadoFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getRosterUpdateEmpleadoApi(token) {
    try {
        const url = `${BASE_API}v1/RosterUpdateEmpleado/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addRosterUpdateEmpleadoApi(data, token) {
    try {
        const url = `${BASE_API}v1/RosterUpdateEmpleado/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateRosterUpdateEmpleadoApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/RosterUpdateEmpleado/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
