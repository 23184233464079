import React, { useEffect, useState } from 'react';
import {filter, size} from "lodash"
import moment from "moment"
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FormExcepcionOnline } from '../../components/Skytracking';
import {useCommon} from "../../hooks"

export function TableSkyView({ ListMarcas }) {
    const [detalleHoras, setDetalleHoras] = useState(null);
    const [visibleModal, setVisibleModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [accountValid, setAccountValid] = useState(false);

    const onOpenCloseModal = () => setShowModal((prev) => !prev);

    const {getLob, listLob, getPlazaValid} = useCommon();

    useEffect(() => {
        (async () => {
            await getLob()
            const plaza_data = await getPlazaValid();

            switch (plaza_data[0].Valor) {
                case "8":
                case "4":
                case "5":
                    setAccountValid(true)
                    break;
            
                case "2":
                case "3":
                    setAccountValid(false)
                    break;
            
                default:
                    break;
            }
        })()
    }, [])

    const templateHorasTrabajadas = (rowData) => {
        let horas_trabajadas = 0;
        let horas_programadas = 0;

        for (const horas of rowData.Detalle) {
            switch (horas.TipoMarca) {
                case 1:
                case 62:
                    horas_programadas += horas.Horas;
                    break;

                default:
                    break;
            }

            switch (horas.Tipo) {
                case 2:
                case 5:
                    horas_trabajadas += horas.Horas;
                    break;

                default:
                    break;
            }
        }

        return <>{horas_trabajadas > horas_programadas ? horas_programadas : horas_trabajadas.toFixed(3)}</>;
    };
    const templateHorasExcepLey = (rowData) => {
        let horas_trabajadas = 0;

        for (const horas of rowData.Detalle) {
            switch (horas.Tipo) {
                case 8:
                    horas_trabajadas += horas.Horas;
                    break;

                default:
                    break;
            }
        }

        return <>{horas_trabajadas}</>;
    };
    const templateAdicionales = (rowData) => {
        let horas_trabajadas = 0;

        for (const horas of rowData.Detalle) {
            switch (horas.Tipo) {
                case 4:
                    horas_trabajadas += horas.Horas;
                    break;

                default:
                    break;
            }
        }

        return <>{horas_trabajadas}</>;
    };
    const templateExtras = (rowData) => {
        let horas_trabajadas = 0;

        for (const horas of rowData.Detalle) {
            switch (horas.Tipo) {
                case 3:
                    horas_trabajadas += horas.Horas;
                    break;

                default:
                    break;
            }
        }

        return <>{horas_trabajadas}</>;
    };
    const templateProgramadas = (rowData) => {
        let horas_trabajadas = 0;

        for (const horas of rowData.Detalle) {
            switch (horas.TipoMarca) {
                case 1:
                case 62:
                    horas_trabajadas += horas.Horas;
                    break;

                default:
                    break;
            }
        }

        return <>{horas_trabajadas}</>;
    };
    const templateExcess = (rowData) => {
        let horas_trabajadas = 0;

        for (const horas of rowData.Detalle) {
            switch (horas.TipoMarca) {
                case 30:
                case 65:
                    horas_trabajadas += horas.Horas;
                    break;

                default:
                    break;
            }
        }

        return <>{horas_trabajadas}</>;
    };
    const templateNCNS = (rowData) => {
        let horas_trabajadas = 0;

        for (const horas of rowData.Detalle) {
            switch (horas.TipoMarca) {
                case 52:
                case 57:
                case 64:
                    horas_trabajadas += horas.Horas;
                    break;

                default:
                    break;
            }
        }

        return <>{horas_trabajadas}</>;
    };

    const formatOnlinePause = (Detalle, Badge, Nombre, Fecha, idMarca, idEmpleado) => {
        let object_format = {
            Badge: Badge,
            Fecha: Fecha,
            idMarca: idMarca,
            idEmpleado: idEmpleado,
            NombreEmpleado: Nombre
        };

        for (const detail of Detalle) {
            object_format[detail.TipoMarca] = detail.Horas;
        }

        setTitleModal(`Excepcion ${Badge} - ${Nombre}`);
        setShowModal(true);
        setContentModal(<FormExcepcionOnline datosMarca={object_format} closeModal={onOpenCloseModal} />);
    };

    const templateLob = (rowData) => {

        const response_filter_lob = filter(listLob, ["value", parseInt(rowData.Lob)])

        return (size(response_filter_lob) > 0) ? response_filter_lob[0].name : rowData.Lob
    }

    const templateAction = (rowData) => {

        const date_now = new Date();
        let dia_atras = 3;

        const fecha_actual = moment(date_now);
        
        switch (fecha_actual.day()) {
            case 1:
            case 2:
                dia_atras = 5;
                break;
        
            default:
                break;
        }

        const fecha_consulta = moment(rowData.Fecha);

        const diff_fecha_actual = fecha_actual.diff(fecha_consulta, "days");

        return (
            <>
                <div className="grid">
                    <div className="col-12">
                        <Button
                            severity="primary"
                            type="button"
                            onClick={(e) => {
                                setDetalleHoras(rowData.Detalle);
                                setVisibleModal(true);
                            }}
                        >
                            Detalle de Horas
                        </Button>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        {(diff_fecha_actual < dia_atras || accountValid) && (

                            <Button severity="success" type="button" onClick={() => formatOnlinePause(rowData.Detalle, rowData.Badge, rowData.NombreEmpleado, rowData.Fecha, rowData.ID_Marca, rowData.ID_Empleado)}>
                                Excepciones
                            </Button>

                        )}
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <Dialog footer="" header="Detalle de Horas" visible={visibleModal} style={{ width: '50vw' }} onHide={() => setVisibleModal(false)}>
                <DataTable value={detalleHoras}>
                    <Column footer="" header="Pausa" field="Pausa"></Column>
                    <Column footer="" header="Horas" field="Horas"></Column>
                </DataTable>
            </Dialog>
            <Dialog header={titleModal} style={{ width: '50vw' }} visible={showModal} modal onHide={() => setShowModal(false)}>
                {contentModal}
            </Dialog>
            <DataTable
                value={ListMarcas}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
                <Column footer="Badge" header="Badge" field="Badge" filter sortable></Column>
                <Column footer="Nombre" header="Nombre" field="NombreEmpleado" filter sortable></Column>
                <Column footer="Lob" body={templateLob} header="Lob" filter></Column>
                <Column footer="Acciones" header="Acciones" body={templateAction}></Column>
                <Column footer="Horas Trabajadas" header="Horas Trabajadas" body={templateHorasTrabajadas}></Column>
                <Column footer="Horas por excepcion de ley" header="Horas por excepcion de ley" body={templateHorasExcepLey}></Column>
                <Column footer="Horas Adicionales (x1)" header="Horas Adicionales (x1)" body={templateAdicionales}></Column>
                <Column footer="Horas Adicionales (x2)" header="Horas Adicionales (x2)" body={templateExtras}></Column>
                <Column footer="Horas Programadas" header="Horas Programadas" body={templateProgramadas}></Column>
                <Column footer="Non Work Hours" header="Non Work Hours" body={templateNCNS}></Column>
                <Column footer="Unpaid Excess Login" header="Unpaid Excess Login" body={templateExcess}></Column>
            </DataTable>
        </>
    );
}
