import React, {useEffect} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import {Calendar} from "primereact/calendar"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFilterRoster } from '../../hooks';
import {listExcepcionesEstados} from "../../utilities/options"

export function FilterHoras({ responseForm, showFields, requiredFields }) {

    const { cuentas, lobs, supervisores, estadosRosterUpdate, loadingFilterRoster, getCuentas, getDependientes } = useFilterRoster();

    useEffect(() => {
        (async () => {
            await getCuentas();
        })();
    }, []);

    const onGetLobs = async (idcuenta) => {
        await getDependientes(idcuenta);
    };

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema(requiredFields)),
        validateOnChange: false,
        onSubmit: (formValue) => {

            const FechaInicio = new Date(formValue.RangoFechas[0])
            const FechaFinal = new Date(formValue.RangoFechas[1])
            let Fechas = []

            while (FechaInicio <= FechaFinal){

                let fecha_str_push = `${FechaInicio.getFullYear()}-${FechaInicio.getMonth() + 1}-${FechaInicio.getDate()}`

                Fechas.push(fecha_str_push)

                FechaInicio.setDate(FechaInicio.getDate() + 1)
            }

            formValue["Fechas"] = Fechas

            responseForm(formValue);
        }
    });

    return (
        <form className="mt-5" onSubmit={formik.handleSubmit}>
            <div className="grid">
                {showFields?.RangoFechas ? (
                    <div className="field col-12 md:col-4 lg:col-3">
                        <span className="p-float-label">
                            <Calendar 
                            className={`w-full ${formik.errors.RangoFechas ? 'p-invalid' : ''}`}
                            id="RangoFechas"
                            name="RangoFechas"
                            selectionMode='range'
                            value={formik.values.RangoFechas}
                            onChange={(e) => formik.setFieldValue("RangoFechas", e.value)}
                            />
                            <label htmlFor="RangoFechas">Rango de Fechas</label>
                            <small>{}</small>
                        </span>
                    </div>
                ) : (
                    <InputText type="hidden" name="RangoFechas" value="" />
                )}
                {showFields?.Cuenta ? (
                    <div className="field col-12 md:col-4 lg:col-3">
                        <span className="p-float-label">
                        <Dropdown
                                className={`w-full ${formik.errors.Cuenta ? 'p-invalid' : ''}`}
                                name="Cuenta"
                                id="Cuenta"
                                filter
                                value={formik.values.Cuenta}
                                options={cuentas}
                                optionLabel="name"
                                onChange={(e) => {
                                    formik.setFieldValue('Cuenta', e.value);
                                    onGetLobs(e.value);
                                }}
                            />
                            <label htmlFor="Cuenta">Cuentas</label>
                            <small>{}</small>
                        </span>
                    </div>
                ) : (
                    <InputText type="hidden" name="Cuenta" value="" />
                )}
                {showFields?.Lob ? (
                    <div className="field col-12 md:col-4 lg:col-3">
                        <span className="p-float-label">
                        <Dropdown
                                className="w-full"
                                name="Lob"
                                id="Lob"
                                filter
                                value={formik.values.Lob}
                                options={lobs}
                                optionLabel="name"
                                onChange={(e) => {
                                    formik.setFieldValue('Lob', e.value);
                                }}
                            />
                            <label htmlFor="Lob">Lob</label>
                            <small>{}</small>
                        </span>
                    </div>
                ) : (
                    <InputText type="hidden" name="Lob" value="" />
                )}
                {showFields?.Supervisor ? (
                    <div className="field col-12 md:col-4 lg:col-3">
                        <span className="p-float-label">
                            <InputText className={`w-full ${formik.errors.Supervisor ? 'p-invalid' : ''}`} id="Supervisor" name="Supervisor" value={formik.values.Supervisor} onChange={formik.handleChange} />
                            <label htmlFor="Supervisor">Supervisor</label>
                            <small>{}</small>
                        </span>
                    </div>
                ) : (
                    <InputText type="hidden" name="Supervisor" value="" />
                )}
                {showFields?.EstadoExcepcion ? (
                    <div className="field col-12 md:col-4 lg:col-3">
                        <span className="p-float-label">
                        <Dropdown
                                className={`w-full ${formik.errors.EstadoExcepcion ? 'p-invalid' : ''}`}
                                name="EstadoExcepcion"
                                id="EstadoExcepcion"
                                filter
                                value={formik.values.EstadoExcepcion}
                                options={listExcepcionesEstados}
                                optionLabel="name"
                                onChange={(e) => {
                                    formik.setFieldValue('EstadoExcepcion', e.value);
                                }}
                            />
                            <label htmlFor="EstadoExcepcion">Estado Excepcion</label>
                            <small>{}</small>
                        </span>
                    </div>
                ) : (
                    <InputText type="hidden" name="EstadoExcepcion" value="" />
                )}
            </div>
            <div className="grid pr-5">
                <div className="col-12 text-right">
                    <Button type="submit" severity="primary" size="large">
                        Consultar
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        RangoFechas: '',
        Cuenta: '',
        Lob: '',
        Supervisor: '',
        EstadoExcepcion: ''
    };
}

function validationSchema(requiredFields) {
    return {
        RangoFechas: Yup.array().required(true),
        Cuenta: requiredFields?.Cuenta ? Yup.string().required() : Yup.string(),
        Lob: requiredFields?.Lob ? Yup.string().required() : Yup.string(),
        Supervisor: requiredFields?.Supervisor ? Yup.string().required() : Yup.string(),
        EstadoExcepcion: requiredFields?.EstadoExcepcion ? Yup.string().required() : Yup.string()
    };
}
