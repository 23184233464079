import React, { useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import {Calendar} from "primereact/calendar"
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import {size} from "lodash"
import moment from "moment"
import * as Yup from 'yup';
import { useCommon, useException, useSkytracking } from '../../hooks';

export function ExcepcionesMasivasPage() {
    const { listPausas, getPausasFilter } = useCommon();
    const {getExceptionValid, insertException_wfm} = useException()
    const {getStaffTime, horasPlanilla} = useSkytracking()
    const [pausa, setPausa] = useState(null)
    const [motivo, setMotivo] = useState(null)
    const [fecha, setFecha] = useState(null)

    useEffect(() => {
        (async () => {
            await getPausasFilter([], '', '');
        })();
    }, []);

    useEffect(() => {

        if (size(horasPlanilla) > 0){

            (async () => {
                
                for await (const marca of horasPlanilla){
                    let horas_programadas = marca[1] + marca[62]

                    if (horas_programadas > 0){

                        /* await insertException_wfm({
                            "TipoMarca": pausa,
                            "Motivo": motivo,
                            "Empleado": marca.Marca.Empleado_id,
                            "FechaInicio": fecha,
                            "FechaFin": fecha,
                            "ValorExcepcion": horas_programadas,
                            "EstadoExcepcion": 0
                        }) */

                    }
                }

            })()

        }

    }, [horasPlanilla])

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {

            setPausa(formValue.Pausa.id_pausa)
            setMotivo(formValue.Comentario)
            setFecha(moment(formValue.Fecha).format("YYYY-MM-DD"))

            let excepciones_ingresadas = []
            const FechaException = moment(formValue.Fecha).format("YYYY-MM-DD")
            
            if(formValue.Excepciones){

                const response_excepciones = await getExceptionValid(FechaException, "", formValue.Pausa.id_pausa)
                excepciones_ingresadas = response_excepciones

            }

            const response_marcas = await getStaffTime(FechaException, FechaException)

        }
    });

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Modulo de excepciones masivas</h5>
                        <p>Ingresa todos los datos para poder ingresar las excepciones masivas que necesites</p>

                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid">
                                <div className="col-3">
                                    <span className="p-float-label">
                                        <Dropdown id="Pausa" name="Pausa" filter options={listPausas} optionLabel='name' value={formik.values.Pausa} onChange={(e) => formik.setFieldValue('Pausa', e.value)} className="w-full" />
                                        <label htmlFor="Pausa">Pausa</label>
                                    </span>
                                </div>
                                <div className="col-3">
                                    <span className="p-float-label">
                                        <Calendar id="Fecha" name="Fecha" value={formik.values.Fecha} onChange={(e) => formik.setFieldValue("Fecha", e.value)} />
                                        <label htmlFor="Fecha">Fecha</label>
                                    </span>
                                </div>
                                <div className="col-3">
                                    <span className="p-float-label">
                                        <InputTextarea id="Comentario" name="Comentario" value={formik.values.Comentario} onChange={formik.handleChange} className="w-full" />
                                        <label htmlFor="Comentario">Comentario</label>
                                    </span>
                                </div>
                                <div className="col-3">
                                    <Checkbox
                                        name="Excepciones"
                                        inputId="Excepciones"
                                        value={formik.values.Excepciones}
                                        onChange={(e) => {
                                            formik.setFieldValue('Excepciones', e.checked);
                                        }}
                                        checked={formik.values.Excepciones}
                                        className="mr-2"
                                    />
                                    <label htmlFor="Excepciones">Incluir Excepciones Ingresadas</label>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12">
                                    <Button type="submit" severity="primary">
                                        Guardar Excepciones
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

function initialValues() {
    return {
        Pausa: '',
        Fecha: "",
        Comentario: '',
        Excepciones: true
    };
}

function validationSchema() {
    return {
        Pausa: Yup.object().required(true),
        Fecha: Yup.string().required(true),
        Comentario: Yup.string().required(true),
        Excepciones: Yup.boolean()
    };
}
