import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import { Button } from 'primereact/button';
import { map, round, size } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from "moment"
import { HoursTab, ItTab, PayrollTab, PersonalTab, ProductionTab } from './TabsEmployee';
import { useCommon, useUploadFiles, useRosterUpdate } from '../../hooks';
import { listPropiedadesFormik } from '../../utilities/options';

export function FormAddEmployee() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [validState, setValidState] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [notiInsert, setNotiInsert] = useState(false);

    const { getPlazas, listPlazas, getLob, listLob, getCuenta, listCuentas, getSubLob, listSubLob, getSupervisores, listSupervisores, getAccountManagers, listAccountManager, getDirectores, listDirectores, loadingCommon, getHrGeneralist, listHrGeneralist } = useCommon();
    const { insertEmpleado, insertDetalleEmpleado, loadingFileUpload } = useUploadFiles();
    const {insertUpdateHorario} = useRosterUpdate()

    useEffect(() => {
        (async () => {
            await getPlazas();
            await getCuenta();
            await getSupervisores();
            await getAccountManagers();
            await getDirectores();
            await getHrGeneralist();
        })();
    }, []);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: validState,
        onSubmit: async (formValue) => {
            const empleado_response = await insertEmpleado(formValue.Badge);
            let object_data_horario = {
                "L": {
                    "HoraInicio": "",
                    "HoraFinal": "",
                    "Almuerzo": "",
                },
                "M": {
                    "HoraInicio": "",
                    "HoraFinal": "",
                    "Almuerzo": "",
                },
                "X": {
                    "HoraInicio": "",
                    "HoraFinal": "",
                    "Almuerzo": "",
                },
                "J": {
                    "HoraInicio": "",
                    "HoraFinal": "",
                    "Almuerzo": "",
                },
                "V": {
                    "HoraInicio": "",
                    "HoraFinal": "",
                    "Almuerzo": "",
                },
                "S": {
                    "HoraInicio": "",
                    "HoraFinal": "",
                    "Almuerzo": "",
                },
                "D": {
                    "HoraInicio": "",
                    "HoraFinal": "",
                    "Almuerzo": "",
                },
            }

            for await (const field of Object.keys(formValue)) {
                const field_destruct = formValue[field];
                let propiedad = listPropiedadesFormik[field];

                if (propiedad != '') {
                    switch (propiedad) {
                        case 10:
                        case 11:
                        case 12:
                            
                            await insertDetalleEmpleado(empleado_response.id, propiedad, field_destruct.badge);
                            break;
                    
                        default:
                            if (field_destruct != ""){
                                if (propiedad == undefined){

                                    let dia_get = field.split("_")

                                    if (size(dia_get) > 1){

                                        object_data_horario[dia_get[1]][dia_get[0]] = field_destruct

                                    }
                                    
                                }else{

                                    await insertDetalleEmpleado(empleado_response.id, propiedad, field_destruct);
                                }
                                
                            }
                            break;
                    }
                }
            }

            for await (const dia of Object.keys(object_data_horario)){
                
                const limite_noche = moment()
                limite_noche.hour(19)
                limite_noche.minute(0)
                limite_noche.second(0)

                const limite_dia = moment()
                limite_dia.hour(5)
                limite_dia.minute(59)
                limite_dia.second(59)

                const {HoraInicio, HoraFinal, Almuerzo} = object_data_horario[dia]
                let almuerzo_real = Almuerzo

                let segundos_diff_dia = moment(HoraInicio).diff(limite_dia, "seconds")
                let segundos_diff_noche = limite_noche.diff(moment(HoraFinal), "seconds")
                let horas_prog_noche = moment(HoraFinal).diff(moment(HoraInicio), "seconds")
                let prog_dia = 0
                let prog_noche = 0
                let libre = false
                
                if (HoraInicio == HoraFinal){
                    libre = true
                }else if (segundos_diff_dia > 0 && segundos_diff_noche > 0 && horas_prog_noche > 0){
                    prog_dia = (horas_prog_noche / 3600).toFixed(4)
                }else if(segundos_diff_dia > 0 && horas_prog_noche > 0){
                    prog_dia = (horas_prog_noche / 3600).toFixed(4) - (segundos_diff_dia / 3600).toFixed(4)
                    prog_noche = (segundos_diff_dia / 3600).toFixed(4)
                }else if (segundos_diff_noche > 0 && horas_prog_noche > 0){
                    prog_dia = (horas_prog_noche / 3600).toFixed(4) - (segundos_diff_noche / 3600).toFixed(4)
                    prog_noche = (segundos_diff_noche / 3600).toFixed(4)
                }else{

                    if (horas_prog_noche < 0){
                        prog_noche = ((horas_prog_noche + 86400) / 3600).toFixed(4)
                    }else{
                        prog_dia = (horas_prog_noche / 3600).toFixed(4) - ((segundos_diff_noche * (-1)) / 3600).toFixed(4) - ((segundos_diff_dia * (-1)) / 3600).toFixed(4)
                        prog_noche = round(parseFloat((segundos_diff_noche * (-1)) / 3600) + parseFloat((segundos_diff_dia * (-1)) / 3600), 2)
                    }
                }

                // console.log(moment(HoraInicio).format("HH:mm:ss"), moment(HoraFinal).format("HH:mm:ss"), almuerzo_real, dia, (horas_prog_noche / 3600).toFixed(4), prog_dia, prog_noche)

                if (prog_dia > prog_noche) {
                    prog_dia = prog_dia - almuerzo_real
                }else{
                    prog_noche = prog_noche - almuerzo_real
                }

                if (Almuerzo == ''){
                    almuerzo_real = 0
                }

                await insertUpdateHorario(empleado_response.id, dia, {
                    Dia: dia,
                    HoraInicio: moment(HoraInicio).format("HH:mm:ss"),
                    HoraFinal: moment(HoraFinal).format("HH:mm:ss"),
                    Almuerzo: almuerzo_real,
                    TotalHorasDia: prog_dia,
                    HorasNocturnas: prog_noche,
                    Libre: libre,
                    Empleado: empleado_response.id
                });
            }

            setNotiInsert(true);

            
        }
    });

    useEffect(() => {
        (async () => {
            await getLob(formik.values.Cuenta);
        })();
    }, [formik.values.Cuenta]);

    useEffect(() => {
        (async () => {
            await getSubLob(formik.values.Lob);
        })();
    }, [formik.values.Lob]);

    const checkActiveIndex = useCallback(() => {
        const paths = location.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'production_tab':
                setActiveIndex(1);
                break;
            case 'it_tab':
                setActiveIndex(2);
                break;
            case 'payroll_tab':
                setActiveIndex(3);
                break;
            case 'hours_tab':
                setActiveIndex(4);
                break;
            default:
                break;
        }
    }, [location]);

    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);

    const wizardItems = [
        { label: 'Personal', command: () => navigate('/Skyroster/Add') },
        { label: 'Production', command: () => navigate('production_tab') },
        { label: 'IT Information', command: () => navigate('it_tab') },
        { label: 'Payroll', command: () => navigate('payroll_tab') },
        { label: 'Hours', command: () => navigate('hours_tab') }
    ];
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="col-12 md:col-8">
                    <div className="card card-w-title">
                        <h5>Agregar Empleado</h5>
                        <Steps model={wizardItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
                        <Routes>
                            <Route exact="true" path={'/'} element={<PersonalTab formik={formik} plazas={listPlazas} />} />
                            <Route
                                path={'/production_tab'}
                                element={<ProductionTab formik={formik} loadingShow={loadingCommon} lob={listLob} cuenta={listCuentas} subLob={listSubLob} supervisor={listSupervisores} accountManager={listAccountManager} director={listDirectores} hr_generalist={listHrGeneralist} />}
                            />
                            <Route path={'/it_tab'} element={<ItTab formik={formik} />} />
                            <Route path={'/payroll_tab'} element={<PayrollTab formik={formik} />} />
                            <Route path={'/hours_tab'} element={<HoursTab formik={formik} />} />
                        </Routes>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div className="card card-w-title">
                        <h5>Errores Formulario</h5>
                        <p>Aqui se mostraran los errores que contiene el formulario</p>

                        <div className="grid">
                            <div className="col-12">
                                {map(Object.entries(formik.errors), (index, error_key) => (
                                    <h4 key={index}>{Object.entries(formik.errors)[error_key][1]}</h4>
                                ))}
                            </div>
                        </div>

                        {loadingFileUpload && (
                            <div className="grid">
                                <div className="col-12">
                                    <i className="pi pi-spinner pi-spin"></i>
                                </div>
                            </div>
                        )}

                        {notiInsert && (
                            <div className="grid">
                                <div className="col-12 bg-green-600 text-2xl text-white">
                                    <p>Empleado Guardado Correctamente</p>
                                </div>
                            </div>
                        )}

                        <Button type="submit" severity="primary" size="large" onClick={() => setValidState(true)}>
                            Guardar Empleado
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    let hora_cero = new Date();

    hora_cero.setHours(0);
    hora_cero.setMinutes(0);
    hora_cero.setSeconds(0);

    let hoy_inicio = new Date();
    let hoy_final = new Date();

    let hoy_final_viernes = new Date();

    hoy_inicio.setHours(7);
    hoy_inicio.setMinutes(0);
    hoy_inicio.setSeconds(0);

    hoy_final.setHours(17);
    hoy_final.setMinutes(0);
    hoy_final.setSeconds(0);

    hoy_final_viernes.setHours(16);
    hoy_final_viernes.setMinutes(0);
    hoy_final_viernes.setSeconds(0);

    return {
        // personal
        Badge: '',
        Nombre: '',
        Dui: '',
        DocumentoIdentidad: '',
        Nit: '',
        Carnet: '',
        Sexo: '',
        FechaNacimiento: '',
        Parent: '',
        EstadoEmpleado: '',
        Profesion: '',
        Pais: '',
        Departamento: '',
        Municipio: '',
        Direccion: '',
        Site: '',
        Phone: '',
        Phone_2: '',
        Phone_3: '',
        EmergencyName: '',
        EmergencyPhone: '',
        Seguro: '',
        AssaNumber: '',
        Plaza: '',

        // production
        Supervisor: '',
        SupervisorName: '',
        AccountManager: '',
        AccountManagerName: '',
        Director: '',
        DirectorName: '',
        HireDT: '',
        TrainingDT: '',
        ProductionDT: '',
        Lob2DT: '',
        Lob3DT: '',
        Cuenta: '',
        Lob: '',
        SubLob: '',

        // IT
        Byod: '',
        AccessCard: '',
        WindowsUser: '',
        InternalEmail: '',
        PersonalEmail: '',
        AnyDesk: '',

        // payroll
        Sociedad: '',
        Salario: '',
        Bono: '',
        Comision: '',
        Banco: '',
        CuentaBanco: '',
        NumeroISSS: '',
        Nup: '',
        AFP: '',
        TipoAfp: '',
        IPSFA: '',

        // Horas
        Acd: '',
        AcdID: '',
        AcdName: '',
        AcdSkill: '',
        HoraInicio_L: hoy_inicio,
        HoraFinal_L: hoy_final,
        Almuerzo_L: 1,
        HorasProgramadas_L: '',
        HoraInicio_M: hoy_inicio,
        HoraFinal_M: hoy_final,
        Almuerzo_M: 1,
        HorasProgramadas_M: '',
        HoraInicio_X: hoy_inicio,
        HoraFinal_X: hoy_final,
        Almuerzo_X: 1,
        HorasProgramadas_X: '',
        HoraInicio_J: hoy_inicio,
        HoraFinal_J: hoy_final,
        Almuerzo_J: 1,
        HorasProgramadas_J: '',
        HoraInicio_V: hoy_inicio,
        HoraFinal_V: hoy_final_viernes,
        Almuerzo_V: 1,
        HorasProgramadas_V: '',
        HoraInicio_S: hora_cero,
        HoraFinal_S: hora_cero,
        Almuerzo_S: 0,
        HorasProgramadas_S: '',
        HoraInicio_D: hora_cero,
        HoraFinal_D: hora_cero,
        Almuerzo_D: 0,
        HorasProgramadas_D: '',
        HorasSemanales: 44
    };
}

function validationSchema() {
    return {
        // personal
        Badge: Yup.string().required('Codigo de Empleado no puede ser nulo'),
        Nombre: Yup.string().required('Nombre de Empleado no puede ser nulo'),
        Dui: Yup.string(),
        DocumentoIdentidad: Yup.string(),
        Nit: Yup.string(),
        Carnet: Yup.string(),
        Sexo: Yup.string().required('Debe seleccionar un sexo para el empleado'),
        FechaNacimiento: Yup.string().required('Ingrese una fecha de nacimiento valida'),
        Parent: Yup.string(),
        EstadoEmpleado: Yup.string().required('Estado de empleado no puede ser nulo'),
        Profesion: Yup.string(),
        Pais: Yup.string().required('Pais no puede ser nulo'),
        Departamento: Yup.string(),
        Municipio: Yup.string(),
        Direccion: Yup.string(),
        Site: Yup.string().required('Edificio no puede ser nulo'),
        Phone: Yup.string(),
        Phone_2: Yup.string(),
        Phone_3: Yup.string(),
        EmergencyName: Yup.string(),
        EmergencyPhone: Yup.string(),
        Seguro: Yup.string(),
        AssaNumber: Yup.string(),
        Plaza: Yup.string(),

        // production
        Supervisor: Yup.object().required('Debes seleccionar un Supervisor para el empleado'),
        SupervisorName: Yup.string().required('Debes seleccionar un Supervisor para el empleado'),
        AccountManager: Yup.object().required('Debes seleccionar un Account Manager para el empleado'),
        AccountManagerName: Yup.string().required('Debes seleccionar un Account Manager para el empleado'),
        Director: Yup.object().required('Debes seleccionar un Director para el empleado'),
        DirectorName: Yup.string().required('Debes seleccionar un Director para el empleado'),
        HireDT: Yup.string().required('El Empleado debe tener una fecha de ingreso'),
        TrainingDT: Yup.string(),
        ProductionDT: Yup.string(),
        Lob2DT: Yup.string(),
        Lob3DT: Yup.string(),
        Cuenta: Yup.string(),
        Lob: Yup.string(),
        SubLob: Yup.string(),

        // IT
        Byod: Yup.string(),
        AccessCard: Yup.string(),
        WindowsUser: Yup.string(),
        InternalEmail: Yup.string(),
        PersonalEmail: Yup.string(),
        AnyDesk: Yup.string(),

        // payroll
        Sociedad: Yup.string().required('El campo Sociedad no puede quedar vacio'),
        Salario: Yup.number().required('El campo Salario no puede quedar vacio'),
        Bono: Yup.number(),
        Comision: Yup.number(),
        Banco: Yup.string(),
        CuentaBanco: Yup.number(),
        NumeroISSS: Yup.number().integer('El campo NumeroISSS no debe tener decimales'),
        Nup: Yup.number().integer('El campo Nup no debe tener decimales'),
        AFP: Yup.number(),
        TipoAfp: Yup.string(),
        IPSFA: Yup.number(),
        FechaEgreso: Yup.string(),

        // horas
        Acd: Yup.string(),
        AcdID: Yup.string(),
        AcdName: Yup.string(),
        AcdSkill: Yup.string(),
        HoraInicio_L: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_L: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_L: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_M: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_M: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_M: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_X: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_X: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_X: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_J: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_J: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_J: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_V: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_V: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_V: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_S: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_S: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_S: Yup.string().required('Debes completar el horario del empleado'),
        HoraInicio_D: Yup.string().required('Debes completar el horario del empleado'),
        HoraFinal_D: Yup.string().required('Debes completar el horario del empleado'),
        Almuerzo_D: Yup.string().required('Debes completar el horario del empleado'),
        HorasProgramadas_L: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_M: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_X: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_J: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_V: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_S: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasProgramadas_D: Yup.string().required('Horas Programadas no puede ser nulo'),
        HorasSemanales: Yup.string().required('Horas Programadas no puede ser nulo')
    };
}
