import React, { useRef, useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { size } from 'lodash';
import { BASE_API } from '../../utilities/constants';
import { useUploadFiles } from '../../hooks';
import excel from '../../assets/img/excel.png';
import template from '../../assets/template/TemplateSkyVerseSkyRoster.xlsx';

export function CargarRosterPage() {
    const toast = useRef();

    const { insertEmpleado, insertDetalleEmpleado } = useUploadFiles();
    const [porcentage, setPorcentage] = useState(0);

    const onUpload = async (data) => {
        const response_file = JSON.parse(data.xhr.response);

        let contador = 1;
        const size_array = size(response_file);

        for await (const empleado of Object.keys(response_file)) {
            let calc_procentage = (contador / size_array) * 100;
            const empleado_destruct = response_file[empleado];
            const badge = empleado.split('|');
            
            let empleado_data = null;

            if (size(badge) > 1) {
                empleado_data = await insertEmpleado(badge[1]);
                empleado_data = empleado_data.id
            } else {
                
                empleado_data = empleado
            }

            if (empleado_data != null) {
                for await (const propiedad of Object.keys(empleado_destruct)) {
                    if (empleado_destruct[propiedad] != "N/A"){

                        await insertDetalleEmpleado(empleado_data, propiedad, empleado_destruct[propiedad]);
                        
                    }
                }
            }

            setPorcentage(calc_procentage.toFixed(2));
            contador += 1;
        }

        toast.current.show({
            severity: 'success',
            summary: 'Roster',
            detail: 'Roster Cargado Correctamente',
            life: 3000
        });
    };

    const onDownloadTemplate = () => {
        window.open(template);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Carga de Roster</h5>
                        <p>Carga masivamente todas las propiedades y empleados en SkyVerse SkyRoster sigue las instrucciones para cargar correctamente los empleados en el sistema</p>
                        <div className="grid my-5">
                            <div className="col-12">
                                <h5>Progreso %</h5>
                                <ProgressBar value={porcentage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 md:col-8">
                    <div className="card">
                        <h5>Formato de carga</h5>
                        <p>
                            Este es el template de carga de Roster aqui encontraras un formato para realizar la carga, puedes agregar o quitar todas las columnas que necesites. La primer columna de Payroll es necesaria para que el modulo funcione
                            correctamente
                        </p>
                        <div className="grid my-3">
                            <div className="col-12">
                                <h5>Template</h5>
                                <Button severity="secondary" outlined onClick={onDownloadTemplate}>
                                    <div className="grid">
                                        <div className="col-12">
                                            <img src={excel} />
                                        </div>
                                        <div className="col-12">
                                            <p>DESCARGAR</p>
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div className="card">
                        <h5>Upload</h5>
                        <p>Anexa el formato de carga aqui y da click en subir para iniciar el proceso</p>
                        <div className="grid my-3">
                            <div className="col-12">
                                <FileUpload mode="basic" onUpload={onUpload} accept=".xlsx" name="roster_update" maxFileSize={10000000000} url={`${BASE_API}v1/RosterUpdate_Content/`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
