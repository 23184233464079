import React from "react"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Button} from "primereact/button"
import {filter} from "lodash"
import {listPais, listIdiomaSam} from "../../utilities/options"

export function TableAplicantes ({listApplicant}) {

    const paisFormatTemplate = (applicant) => {
        const pais_filter = filter(listPais, ["value", applicant[5]])

        return pais_filter[0].name
    }

    const idiomaFormatTemplate = (applicant) => {
        const idioma_filter = filter(listIdiomaSam, ["value", applicant[2]])

        return idioma_filter[0].name
    }

    const accionesFormatTemplate = (applicant) => {
        return (
            <div className="grid">
                <div className="col-12">
                    <Button severity="success" type="button">Pasar de Fase</Button>
                </div>
                <div className="col-12">
                    <Button severity="primary" type="button">Comentario</Button>
                </div>
                <div className="col-12">
                    <Button severity="danger" type="button">Rechazar</Button>
                </div>
                <div className="col-12">
                    <Button severity="info" type="button">Ver Comentarios</Button>
                </div>
            </div>
        )
    }

    return (
        <>
            <DataTable value={listApplicant}>
                <Column header="Acciones" body={accionesFormatTemplate}></Column>
                <Column header="Gestionado Por" filter sortable></Column>
                <Column field="1" header="Nombre Aplicante" filter sortable></Column>
                <Column header="Cuenta Asignada" filter sortable></Column>
                <Column field="6" header="Telefono" filter sortable></Column>
                <Column field="4" header="Correo Electronico" filter sortable></Column>
                <Column field="2" header="Idioma" filter sortable body={idiomaFormatTemplate} ></Column>
                <Column header="Pauta" filter sortable></Column>
                <Column field="5" header="Pais" filter sortable body={paisFormatTemplate}></Column>
                <Column header="Referido" filter sortable></Column>
                <Column header="Estado de Aplicante" filter sortable></Column>
                <Column header="Motivo de Rechazo" filter sortable></Column>
                <Column header="Fecha de Aplicacion" filter sortable></Column>
            </DataTable>
        </>
    )
}