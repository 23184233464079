import { useState } from 'react';
import { size, uniq } from 'lodash';
import moment from "moment"
import { getEmpleadoByFilterApi } from '../api/skyroster/detalleempleado';
import { getEmpleadosApi } from '../api/skyroster/empleado';
import { getRosterUpdateEmpleadosApi, addRosterUpdateEmpleadoApi, updateRosterUpdateEmpleadoApi } from '../api/skyroster/RosterUpdateEmpleado';
import { getRosterUpdateDetallesApi, updateRosterUpdateDetalleApi, addRosterUpdateDetalleApi } from '../api/skyroster/RosterUpdateDetalle';
import { getHorarioApi, updateHorarioApi, addHorarioApi } from '../api/skyroster/horario';
import { getNocturnidadPaissApi } from '../api/skyroster/nocturnidad';
import { useAuth } from './useAuth';
import { weekYearDate } from '../utilities/constants';

export function useRosterUpdate() {
    const [error, setError] = useState(false);
    const [loadingRosterUpdate, setLoadingRosterUpdate] = useState(false);
    const [rosterUpdates, setRosterUpdates] = useState(null);
    const [rosterUpdateDetalles, setRosterUpdateDetalles] = useState(null);
    const [inicioNocturnidad, setInicioNocturnidad] = useState(null);
    const [finNocturnidad, setFinNocturnidad] = useState(null);
    const [listNocturnidades, setListNocturnidades] = useState(null);

    const { auth } = useAuth();

    const existRosterUpdate = async (payroll) => {
        try {
            const response_rosterUpdates = await getRosterUpdateEmpleadosApi(auth.token, weekYearDate(), payroll, 0);
            if (size(response_rosterUpdates) > 0) {
                return true;
            } else {
                const response_rosterUpdates_visto = await getRosterUpdateEmpleadosApi(auth.token, weekYearDate(), payroll, 1);
                if (size(response_rosterUpdates_visto) > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        } catch (error) {
            setError(error);
        }
    };

    const buttonsValidState = async (payroll) => {
        try {
            const response_rosterUpdates = await getRosterUpdateEmpleadosApi(auth.token, moment().week(), payroll, 0);
            if (size(response_rosterUpdates) > 0) {
                return true;
            }else{
                return false
            }
        } catch (error) {
            setError(error);
        }
    };

    const setViewRosterUpdate = async (idRosterUpdate, estado) => {
        try {
            await updateRosterUpdateEmpleadoApi(
                {
                    Estado: estado
                },
                idRosterUpdate,
                auth.token
            );
        } catch (error) {
            console.log(error);
            setError(error);
        }
    };

    const getEmpleadoActionRosterUpdate = async (badge, Week, Year) => {
        try {
            setLoadingRosterUpdate(true);

            const response = await getEmpleadosApi(auth.token, Week, badge);

            setLoadingRosterUpdate(false);

            return response;
        } catch (error) {
            setLoadingRosterUpdate(false);
            setError(error);
        }
    };

    const getRosterUpdatePendientes = async () => {
        try {
            
            setLoadingRosterUpdate(true)
            const response_rosterUpdates = await getRosterUpdateEmpleadosApi(auth.token, "", "", 0);
            setLoadingRosterUpdate(false)

            return size(response_rosterUpdates)
        } catch (error) {
            setLoadingRosterUpdate(false)
            setError(error)
        }
    }

    const getRosterUpdates = async (Semana, Cuenta, Lob, EstadoRosterUpdate) => {
        try {
            setLoadingRosterUpdate(true);

            let response_empleado = [];
            let employee_arr = [];

            if (Lob == '') {
                response_empleado = await getEmpleadoByFilterApi(auth.token, Cuenta, 3, Semana);
            } else {
                response_empleado = await getEmpleadoByFilterApi(auth.token, Lob, 5, Semana);
            }

            if (size(response_empleado) > 0) {
                for await (const empleado of response_empleado) {
                    employee_arr.push(empleado.empleado_data.Badge);
                }
            } else {
                setRosterUpdates(null);
                throw new Error('No Existe Empleados para la cuenta');
            }

            let ResultRosterUpdate = [];

            employee_arr = uniq(employee_arr)
            let response_rosterUpdates = []

            if(Cuenta != ""){

                response_rosterUpdates = await getRosterUpdateEmpleadosApi(auth.token, Semana, employee_arr.join('%2C'), EstadoRosterUpdate);
            }else{
                response_rosterUpdates = await getRosterUpdateEmpleadosApi(auth.token, Semana, "", EstadoRosterUpdate);
            }


            for await (const rosterUpdate of response_rosterUpdates) {
                const nombre_result = await getEmpleadoByFilterApi(auth.token, '', 2, Semana, rosterUpdate.Badge);

                ResultRosterUpdate.push({
                    id: rosterUpdate.id,
                    Badge: rosterUpdate.Badge,
                    Nombre: nombre_result[0].Valor,
                    Week: rosterUpdate.Week,
                    Year: rosterUpdate.Year,
                    Estado: rosterUpdate.Estado
                });
            }

            setRosterUpdates(ResultRosterUpdate);

            setLoadingRosterUpdate(false);
        } catch (error) {
            setLoadingRosterUpdate(false);
            setError(error);
        }
    };

    const getRosterUpdateDetail = async (Semana, Empleado) => {
        try {
            setLoadingRosterUpdate(true);

            const response = await getRosterUpdateDetallesApi(auth.token, Semana, Empleado);

            setRosterUpdateDetalles(response);

            setLoadingRosterUpdate(false);
        } catch (error) {
            setLoadingRosterUpdate(false);
            setError(error);
        }
    };

    const insertRosterUpdate = async (badge) => {
        try {
            setLoadingRosterUpdate(true);

            const response = await addRosterUpdateEmpleadoApi(
                {
                    Badge: badge,
                    Week: weekYearDate(),
                    Year: 2023,
                    Estado: 0
                },
                auth.token
            );

            setLoadingRosterUpdate(false);

            return response;
        } catch (error) {
            setLoadingRosterUpdate(false);
            setError(error);
        }
    };

    const insertRosterUpdateDetalle = async (idRosterUpdate, idPropiedad, valor) => {
        try {
            setLoadingRosterUpdate(true);

            const response_rosterUpdate = await getRosterUpdateDetallesApi(auth.token, '', idRosterUpdate, '', idPropiedad);

            if (size(response_rosterUpdate) > 0) {
                await updateRosterUpdateDetalleApi(
                    {
                        Valor: valor
                    },
                    response_rosterUpdate[0].id,
                    auth.token
                );
            } else {
                await addRosterUpdateDetalleApi(
                    {
                        Propiedad: idPropiedad,
                        Empleado: idRosterUpdate,
                        Valor: valor
                    },
                    auth.token
                );
            }

            setLoadingRosterUpdate(false);
        } catch (error) {
            setLoadingRosterUpdate(false);
            setError(error);
        }
    };

    const insertUpdateHorario = async (idEmpleado, dia, data) => {
        try {
            setLoadingRosterUpdate(true);

            const response_horario = await getHorarioApi(auth.token, idEmpleado, dia);

            if (size(response_horario) > 0) {
                await updateHorarioApi(data, response_horario[0].id, auth.token);
            } else {
                await addHorarioApi(data, auth.token);
            }

            setLoadingRosterUpdate(false);
        } catch (error) {
            setLoadingRosterUpdate(false);
            setError(error);
        }
    };

    const getHorariosFilters = async (Badge, Semana) => {
        try {
            setLoadingRosterUpdate(true)
            const response_horario = await getHorarioApi(auth.token, "", "", Badge, Semana);
            setLoadingRosterUpdate(false)

            return response_horario
        } catch (error) {
            setLoadingRosterUpdate(false)
            setError(error)
        }
    }

    const getNocturnidadConfig = async () => {
        try {
            setLoadingRosterUpdate(true);

            const response_nocturnidadConfig = await getNocturnidadPaissApi(auth.token);

            setListNocturnidades(response_nocturnidadConfig);

            setLoadingRosterUpdate(false);
        } catch (error) {
            setLoadingRosterUpdate(false);
            setError(error);
        }
    };

    return {
        error,
        loadingRosterUpdate,
        rosterUpdates,
        rosterUpdateDetalles,
        inicioNocturnidad,
        finNocturnidad,
        listNocturnidades,
        getRosterUpdates,
        getRosterUpdateDetail,
        insertRosterUpdate,
        insertRosterUpdateDetalle,
        existRosterUpdate,
        setViewRosterUpdate,
        getEmpleadoActionRosterUpdate,
        buttonsValidState,
        insertUpdateHorario,
        getNocturnidadConfig,
        getHorariosFilters,
        getRosterUpdatePendientes
    };
}
