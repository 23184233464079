import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Sexo, listTieneHijos, listEstadosEmpleado, listProfesiones, listPais, listDepartamentos, listMunicipios, listEdificios, listSeguros, listExts } from '../../../utilities/options';

export function PersonalTab({ formik, plazas }) {
    return (
        <div className="grid mt-5 p-3">
            <div className="col-12">
                <h3>Informacion Personal</h3>

                <div className="grid mt-5">
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" name="Badge" id="Badge" value={formik.values.Badge} onChange={formik.handleChange} />
                            <label htmlFor="Badge">Codigo de Empleado</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText name="Nombre" id="Nombre" className="w-full" value={formik.values.Nombre} onChange={formik.handleChange} />
                            <label htmlFor="Nombre">Nombre</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputMask className="w-full" mask="99999999-9" name="Dui" id="Dui" value={formik.values.Dui} onChange={formik.handleChange} />
                            <label htmlFor="Dui">Dui</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputMask className="w-full" mask="999999999999" name="DocumentoIdentidad" id="DocumentoIdentidad" value={formik.values.DocumentoIdentidad} onChange={formik.handleChange} autoClear={false} />
                            <label htmlFor="DocumentoIdentidad">Documento de Identidad</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputMask className="w-full" mask="9999-999999-999-9" name="Nit" id="Nit" value={formik.values.Nit} onChange={formik.handleChange} />
                            <label htmlFor="Nit">NIT</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputNumber name="Carnet" id="Carnet" className="w-full" value={formik.values.Carnet} onChange={formik.handleChange} />
                            <label htmlFor="Carnet">Carnet Minoridad</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown options={Sexo} optionLabel="name" name="Sexo" id="Sexo" className="w-full" value={formik.values.Sexo} onChange={(e) => formik.setFieldValue('Sexo', e.value)} />
                            <label htmlFor="Sexo">Sexo</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Calendar dateFormat="dd/mm/yy" name="FechaNacimiento" id="FechaNacimiento" className="w-full" value={formik.values.FechaNacimiento} onChange={(e) => formik.setFieldValue('FechaNacimiento', e.value)} />
                            <label htmlFor="FechaNacimiento">Fecha Nacimiento</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown options={listTieneHijos} optionLabel="name" name="Parent" id="Parent" className="w-full" value={formik.values.Parent} onChange={(e) => formik.setFieldValue('Parent', e.value)} />
                            <label htmlFor="Parent">Parent</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown
                                options={listEstadosEmpleado}
                                optionLabel="name"
                                name="EstadoEmpleado"
                                id="EstadoEmpleado"
                                className="w-full"
                                value={formik.values.EstadoEmpleado}
                                onChange={(e) => formik.setFieldValue('EstadoEmpleado', e.value)}
                            />
                            <label htmlFor="EstadoEmpleado">Estado</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" id="Plaza" name="Plaza" options={plazas} optionLabel="name" value={formik.values.Plaza} onChange={(e) => formik.setFieldValue('Plaza', e.value)} />
                            <label htmlFor="Plaza">Nombre de Plaza</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown options={listProfesiones} optionLabel="name" name="Profesion" id="Profesion" className="w-full" filter value={formik.values.Profesion} onChange={(e) => formik.setFieldValue('Profesion', e.value)} />
                            <label htmlFor="Profesion">Profesion</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown
                                options={listPais}
                                optionLabel="name"
                                name="Pais"
                                id="Pais"
                                className="w-full"
                                value={formik.values.Pais}
                                onChange={(e) => {
                                    formik.setFieldValue('Pais', e.value);
                                }}
                            />
                            <label htmlFor="Pais">Pais</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown
                                options={listDepartamentos}
                                optionLabel="name"
                                name="Departamento"
                                id="Departamento"
                                className="w-full"
                                editable
                                value={formik.values.Departamento}
                                onChange={(e) => formik.setFieldValue('Departamento', e.value)}
                            />
                            <label htmlFor="Departamento">Provincia</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown
                                options={listMunicipios[formik.values.Departamento]}
                                optionLabel="name"
                                name="Municipio"
                                id="Municipio"
                                className="w-full"
                                filter
                                editable
                                value={formik.values.Municipio}
                                onChange={(e) => formik.setFieldValue('Municipio', e.value)}
                            />
                            <label htmlFor="Municipio">Ciudad</label>
                        </span>
                    </div>
                    <div className="field col-12">
                        <span className="p-float-label">
                            <InputTextarea className="w-full" name="Direccion" id="Direccion" cols={30} rows={5} value={formik.values.Direccion} onChange={formik.handleChange} />
                            <label htmlFor="Direccion">Address</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown options={listEdificios[formik.values.Pais]} optionLabel="name" name="Site" id="Site" className="w-full" value={formik.values.Site} onChange={(e) => formik.setFieldValue('Site', e.value)} />
                            <label htmlFor="Site">Site</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputMask className="w-full" mask={listExts[formik.values.Pais]} name="Phone" id="Phone" value={formik.values.Phone} onChange={formik.handleChange} />
                            <label htmlFor="Phone">Phone</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputMask className="w-full" mask={listExts[formik.values.Pais]} name="Phone_2" id="Phone_2" value={formik.values.Phone_2} onChange={formik.handleChange} />
                            <label htmlFor="Phone_2">Contact Phone 1</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputMask className="w-full" mask={listExts[formik.values.Pais]} name="Phone_3" id="Phone_3" value={formik.values.Phone_3} onChange={formik.handleChange} />
                            <label htmlFor="Phone_3">Contact Phone 2</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" name="EmergencyName" id="EmergencyName" value={formik.values.EmergencyName} onChange={formik.handleChange} />
                            <label htmlFor="EmergencyName">Emergency Contact Name</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputMask className="w-full" mask={listExts[formik.values.Pais]} name="EmergencyPhone" id="EmergencyPhone" value={formik.values.EmergencyPhone} onChange={formik.handleChange} />
                            <label htmlFor="EmergencyPhone">Emergency Contact Number</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown options={listSeguros} optionLabel="name" name="Seguro" id="Seguro" className="w-full" value={formik.values.Seguro} onChange={(e) => formik.setFieldValue('Seguro', e.value)} />
                            <label htmlFor="Seguro">Emergency Pref</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AssaNumber" id="AssaNumber" value={formik.values.AssaNumber} onChange={formik.handleChange} />
                            <label htmlFor="AssaNumber">Numero ASSA</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
