import React from 'react';
import './css/styleTableCarga.css';

export function TableTiempoCarga() {
    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <h5>Tiempos de carga para SkyTracking</h5>
                    <table className="table-tiempo-carga">
                        <thead>
                            <tr>
                                <th>Modulo</th>
                                <th>Dias</th>
                                <th>Tiempo Estimado (seg)</th>
                                <th>Tiempo Estimado (Min)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>SkyTracking</td>
                                <td>1</td>
                                <td>33.54</td>
                                <td>0.56</td>
                            </tr>
                            <tr>
                                <td>SkyTracking</td>
                                <td>2</td>
                                <td>67.09</td>
                                <td>1.12</td>
                            </tr>
                            <tr>
                                <td>SkyTracking</td>
                                <td>3</td>
                                <td>100.63</td>
                                <td>1.68</td>
                            </tr>
                            <tr>
                                <td>SkyTracking</td>
                                <td>4</td>
                                <td>134.18</td>
                                <td>2.24</td>
                            </tr>
                            <tr>
                                <td>SkyTracking</td>
                                <td>5</td>
                                <td>167.72</td>
                                <td>2.80</td>
                            </tr>
                            <tr>
                                <td>SkyTracking</td>
                                <td>6</td>
                                <td>201.27</td>
                                <td>3.35</td>
                            </tr>
                            <tr>
                                <td>SkyTracking</td>
                                <td>7</td>
                                <td>234.81</td>
                                <td>3.91</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <h5>Tiempos de carga para SkyRoster</h5>
                    <table className="table-tiempo-carga">
                        <thead>
                            <tr>
                                <th>Modulo</th>
                                <th>Empleados</th>
                                <th>Tiempo Estimado (seg)</th>
                                <th>Tiempo Estimado (Min)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>SkyRoster</td>
                                <td>113</td>
                                <td>52.58</td>
                                <td>0.88</td>
                            </tr>
                            <tr>
                                <td>SkyRoster</td>
                                <td>78</td>
                                <td>36.29</td>
                                <td>0.60</td>
                            </tr>
                            <tr>
                                <td>SkyRoster</td>
                                <td>43</td>
                                <td>20.01</td>
                                <td>0.33</td>
                            </tr>
                            <tr>
                                <td>SkyRoster</td>
                                <td>8</td>
                                <td>3.72</td>
                                <td>0.06</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
