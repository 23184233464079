import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FilterEditarMarcas, FormAddPausa, TableEditarMarcas } from '../../components/Skypanel';
import { useSkytracking } from '../../hooks';

export function EditarMarcaPage() {
    const toast = useRef();

    const [marcaST, setMarcaST] = useState(0);
    const [listDetalleST, setListDetalleST] = useState(null);

    const { getMarcasEditar, loadingSkytracking, addDetalleMarcasEditar } = useSkytracking();

    const onResponseFilter = async (formData) => {
        const fecha = new Date(formData.Fecha);

        const response_marcas = await getMarcasEditar(formData.Badge, `${fecha.getFullYear()}-${fecha.getMonth() + 1}-${fecha.getDate()}`);
        setMarcaST(response_marcas[0].Marca);

        setListDetalleST(response_marcas);
    };

    const onResponseAddPausa = async (formData) => {
        await addDetalleMarcasEditar(marcaST, formData.Pausa.id_pausa, formData.Horas);
        const btnAccion = document.getElementById('btnBuscarDetalle');

        btnAccion.click();

        toast.current.show({
            severity: 'success',
            summary: 'Horas',
            detail: 'Horas Agregadas con exito',
            life: 3000
        });
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Editar Marca</h5>
                        <p>Edita marcar de SkyVerse SkyTracking con el Badge y la Fecha</p>
                        <FilterEditarMarcas response={onResponseFilter} />
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 md:col-7">
                    <div className="card">
                        <h5>Detalle Marca</h5>
                        <p>Resultado de la busqueda para la marca. Puedes editar las pausas existentes o tambien puedes agregar mas pausas</p>
                        {loadingSkytracking ? (
                            <div className="grid">
                                <div className="col-12 text-center">
                                    <i className="pi pi-spinner pi-spin" style={{ fontSize: '4rem' }}></i>
                                </div>
                            </div>
                        ) : (
                            <TableEditarMarcas listDetalle={listDetalleST} />
                        )}
                    </div>
                </div>
                {listDetalleST && (
                    <div className="col-12 md:col-5">
                        <div className="card">
                            <h5>Insertar Pausa</h5>
                            <p>Selecciona la pausa e ingresa la cantidad de horas a ingresar a esta marca</p>
                            <FormAddPausa response={onResponseAddPausa} marca={marcaST} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
