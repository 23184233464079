import { BASE_API } from '../../utilities/constants';

export async function getSubLobsApi(token, estado = '', lob = '', idSubLob = '') {
    try {
        const estadoFilter = `Estado=${estado}`;
        const lobFilter = `Lob=${lob}`;

        const url = `${BASE_API}v1/SubLob/${idSubLob}?${estadoFilter}&${lobFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addSubLobApi(data, token) {
    try {
        const url = `${BASE_API}v1/SubLob/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateSubLobApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/SubLob/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
