import React, { useEffect, useState } from 'react';
import { FilterRoster } from '../../components/Skyroster';
import { TablePanelMasterRoster } from '../../components/Skypanel';
import { useMasterRoster } from '../../hooks';

export function MasterRosterPanelPage() {
    const { getMasterRosterPanel, loadingMasterRoster, empleados, datosConsultados, setDatosConsultados } = useMasterRoster();

    const [segundosTranscurridos, setSegundosTranscurridos] = useState(0);
    const [semanaConsultando, setSemanaConsultando] = useState(0)

    const onResponseForm = async (formData) => {

        setSemanaConsultando(formData.Semana)

        await getMasterRosterPanel(formData.Semana);

        setDatosConsultados(0);
        setSegundosTranscurridos(0);
    };

    const onRefecthEmpleados = async () => {
        await getMasterRosterPanel(semanaConsultando);
    }

    useEffect(() => {
        if (datosConsultados > 0) {
            const timer = setTimeout(() => {
                setDatosConsultados((prev) => prev - 1);
                setSegundosTranscurridos((prev) => prev + 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [datosConsultados]);

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Master Roster Todas Cuentas</h5>
                        <p>Selecciona La fecha para poder ver el master roster de todas las cuentas</p>

                        <FilterRoster
                            showField={{
                                Semana: true,
                                Cuenta: false,
                                Lob: false,
                                Supervisor: false
                            }}
                            responseForm={onResponseForm}
                        />
                    </div>
                </div>
            </div>

            {loadingMasterRoster ? (
                <div className="grid my-2">
                    <div className="col-12 text-center">
                        <div className="relative">
                            <div className="absolute top-0 left-0">
                                <h6>Tiempo Restante Estimado: {datosConsultados.toFixed(0)} seg</h6>
                            </div>
                            <div className="absolute top-0 right-0">
                                <h6>Tiempo Transcurrido: {segundosTranscurridos.toFixed(0)} seg</h6>
                            </div>
                        </div>
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <TablePanelMasterRoster Empleados={empleados} onRefetch={onRefecthEmpleados} />
            )}
        </>
    );
}
