import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import moment from "moment"
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

export function FormHorario({ formik }) {
    const dias = {
        L: 'Lunes',
        M: 'Martes',
        X: 'Miercoles',
        J: 'Jueves',
        V: 'Viernes',
        S: 'Sabado',
        D: 'Domingo'
    };

    const horas_programadas = {
        L: 0,
        M: 0,
        X: 0,
        J: 0,
        V: 0,
        S: 0,
        D: 0
    };

    const almuerzo = [
        { name: '1 Hora', value: 1 },
        { name: '0.5 Hora', value: 0.5 },
        { name: 'Sin Almuerzo', value: 0 }
    ];

    useEffect(() => {
        onCalcHorasProgramadas();
    }, [
        formik.values.HoraInicio_L,
        formik.values.HoraFinal_L,
        formik.values.Almuerzo_L,
        formik.values.HoraInicio_M,
        formik.values.HoraFinal_M,
        formik.values.Almuerzo_M,
        formik.values.HoraInicio_X,
        formik.values.HoraFinal_X,
        formik.values.Almuerzo_X,
        formik.values.HoraInicio_J,
        formik.values.HoraFinal_J,
        formik.values.Almuerzo_J,
        formik.values.HoraInicio_V,
        formik.values.HoraFinal_V,
        formik.values.Almuerzo_V,
        formik.values.HoraInicio_S,
        formik.values.HoraFinal_S,
        formik.values.Almuerzo_S,
        formik.values.HoraInicio_D,
        formik.values.HoraFinal_D,
        formik.values.Almuerzo_D
    ]);

    useEffect(() => {
        let horas_semanales =
            formik.values.HorasProgramadas_L +
            formik.values.HorasProgramadas_M +
            formik.values.HorasProgramadas_X +
            formik.values.HorasProgramadas_J +
            formik.values.HorasProgramadas_V +
            formik.values.HorasProgramadas_S +
            formik.values.HorasProgramadas_D;

        formik.setFieldValue('HorasSemanales', horas_semanales);
    }, [formik.values.HorasProgramadas_L, formik.values.HorasProgramadas_M, formik.values.HorasProgramadas_X, formik.values.HorasProgramadas_J, formik.values.HorasProgramadas_V, formik.values.HorasProgramadas_S, formik.values.HorasProgramadas_D]);

    const onCalcHorasProgramadas = () => {
        for (let key in dias) {
            let hora_inicio = new Date(formik.values[`HoraInicio_${key}`]);
            let hora_final = new Date(formik.values[`HoraFinal_${key}`]);
            let almuerzo = formik.values[`Almuerzo_${key}`];
            let sum_final = 0;

            if (hora_inicio > hora_final) {
                sum_final = 24;
            }

            let diff = hora_final - hora_inicio;

            let horas_programadas = diff / 1000 / 60 / 60;

            formik.setFieldValue(`HorasProgramadas_${key}`, horas_programadas - almuerzo + sum_final);

            let horas_programadas_formik = formik.values[`HorasProgramadas_${key}`];
        }
    };

    const onCalcHours = (fecha_inicio, setInput, programadas) => {

        let horas_sumar = 0;
        let minutos_sumar = 0;

        if (programadas >= 5 && programadas < 6){
            if (setInput.includes("One")){

                horas_sumar = 2;
                minutos_sumar = 30;

            }else if (setInput.includes("Two")){

                horas_sumar = 0;
                minutos_sumar = 0;

            }else if (setInput.includes("Lunch")){

                horas_sumar = 0;
                minutos_sumar = 0;

            }
        }else if (programadas >= 6 && programadas < 7){
            if (setInput.includes("One")){

                horas_sumar = 2;
                minutos_sumar = 0;

            }else if (setInput.includes("Two")){

                horas_sumar = 4;
                minutos_sumar = 45;

            }else if (setInput.includes("Lunch")){

                horas_sumar = 0;
                minutos_sumar = 0;

            }
        }else if (programadas >= 7 && programadas < 8){
            if (setInput.includes("One")){

                horas_sumar = 2;
                minutos_sumar = 15;

            }else if (setInput.includes("Two")){

                horas_sumar = 5;
                minutos_sumar = 0;

            }else if (setInput.includes("Lunch")){

                horas_sumar = 0;
                minutos_sumar = 0;

            }
        }else if (programadas >= 8 && programadas < 9){
            if (setInput.includes("One")){

                horas_sumar = 2;
                minutos_sumar = 0;

            }else if (setInput.includes("Two")){

                horas_sumar = 6;
                minutos_sumar = 0;

            }else if (setInput.includes("Lunch")){

                horas_sumar = 4;
                minutos_sumar = 0;

            }
        }else if (programadas >= 9){
            if (setInput.includes("One")){

                horas_sumar = 2;
                minutos_sumar = 0;

            }else if (setInput.includes("Two")){

                horas_sumar = 6;
                minutos_sumar = 0;

            }else if (setInput.includes("Lunch")){

                horas_sumar = 4;
                minutos_sumar = 0;

            }
        }else{
            horas_sumar = 0;
            minutos_sumar = 0;
        }

        let fecha = moment(fecha_inicio).add(horas_sumar, "hours").add(minutos_sumar, "minutes").format('YYYY-MM-DD HH:mm:ss');
        let fecha_setear = new Date(fecha);


        formik.setFieldValue(`${setInput}`, fecha_setear);
    }

    return (
        <div className="grid mt-5">
            <div className="col-12">
                {map(Object.keys(dias), (index, dia) => (
                    <div className="grid mt-3 mb-3" key={index}>
                        <div className="col-12">
                            <h4>{dias[Object.keys(dias)[dia]]}</h4>
                        </div>
                        <div className="col-12 md:col-1">
                            <span className="p-float-label">
                                <Calendar
                                    timeOnly
                                    className="w-full"
                                    id={`HoraInicio_${Object.keys(dias)[dia]}`}
                                    name={`HoraInicio_${Object.keys(dias)[dia]}`}
                                    value={formik.values[`HoraInicio_${Object.keys(dias)[dia]}`]}
                                    onChange={async (e) => {

                                        await formik.setFieldValue(`HoraInicio_${Object.keys(dias)[dia]}`, e.value);

                                        //await formik.setFieldValue(`HoraBreakOne_${Object.keys(dias)[dia]}`, fecha_setear);

                                        onCalcHours(e.value, `HoraBreakOne_${Object.keys(dias)[dia]}`, formik.values[`HorasProgramadas_${Object.keys(dias)[dia]}`])
                                        onCalcHours(e.value, `HoraBreakTwo_${Object.keys(dias)[dia]}`, formik.values[`HorasProgramadas_${Object.keys(dias)[dia]}`])
                                        onCalcHours(e.value, `HoraLunch_${Object.keys(dias)[dia]}`, formik.values[`HorasProgramadas_${Object.keys(dias)[dia]}`])
                                    }}
                                />
                                <label htmlFor={`HoraInicio_${Object.keys(dias)[dia]}`}>Hora Inicio</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-1">
                            <span className="p-float-label">
                                <Calendar
                                    timeOnly
                                    className="w-full"
                                    id={`HoraFinal_${Object.keys(dias)[dia]}`}
                                    name={`HoraFinal_${Object.keys(dias)[dia]}`}
                                    value={formik.values[`HoraFinal_${Object.keys(dias)[dia]}`]}
                                    onChange={(e) => {
                                        formik.setFieldValue(`HoraFinal_${Object.keys(dias)[dia]}`, e.value);
                                        
                                        onCalcHours(formik.values[`HoraInicio_${Object.keys(dias)[dia]}`], `HoraBreakOne_${Object.keys(dias)[dia]}`, formik.values[`HorasProgramadas_${Object.keys(dias)[dia]}`])
                                        onCalcHours(formik.values[`HoraInicio_${Object.keys(dias)[dia]}`], `HoraBreakTwo_${Object.keys(dias)[dia]}`, formik.values[`HorasProgramadas_${Object.keys(dias)[dia]}`])
                                        onCalcHours(formik.values[`HoraInicio_${Object.keys(dias)[dia]}`], `HoraLunch_${Object.keys(dias)[dia]}`, formik.values[`HorasProgramadas_${Object.keys(dias)[dia]}`])
                                    }}
                                />
                                <label htmlFor={`HoraFinal_${Object.keys(dias)[dia]}`}>Hora Final</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-2">
                            <span className="p-float-label">
                                <Calendar
                                    timeOnly
                                    className="w-full"
                                    id={`HoraBreakOne_${Object.keys(dias)[dia]}`}
                                    name={`HoraBreakOne_${Object.keys(dias)[dia]}`}
                                    value={formik.values[`HoraBreakOne_${Object.keys(dias)[dia]}`]}
                                    stepMinute={15}
                                    onChange={async (e) => {
                                        await formik.setFieldValue(`HoraBreakOne_${Object.keys(dias)[dia]}`, e.value);
                                    }}
                                />
                                <label htmlFor={`HoraBreakOne_${Object.keys(dias)[dia]}`}>Hora Break 1</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-2">
                            <span className="p-float-label">
                                <Calendar
                                    timeOnly
                                    className="w-full"
                                    id={`HoraBreakTwo_${Object.keys(dias)[dia]}`}
                                    name={`HoraBreakTwo_${Object.keys(dias)[dia]}`}
                                    value={formik.values[`HoraBreakTwo_${Object.keys(dias)[dia]}`]}
                                    stepMinute={15}
                                    onChange={async (e) => {
                                        await formik.setFieldValue(`HoraBreakTwo_${Object.keys(dias)[dia]}`, e.value);
                                    }}
                                />
                                <label htmlFor={`HoraBreakTwo_${Object.keys(dias)[dia]}`}>Hora Break 2</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-2">
                            <span className="p-float-label">
                                <Dropdown
                                    className="w-full"
                                    options={almuerzo}
                                    optionLabel="name"
                                    name={`Almuerzo_${Object.keys(dias)[dia]}`}
                                    value={formik.values[`Almuerzo_${Object.keys(dias)[dia]}`]}
                                    id={`Almuerzo${Object.keys(dias)[dia]}`}
                                    onChange={(e) => {
                                        formik.setFieldValue(`Almuerzo_${Object.keys(dias)[dia]}`, e.value);
                                    }}
                                />
                                <label htmlFor={`Almuerzo_${Object.keys(dias)[dia]}`}>Almuerzo</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-2">
                            <span className="p-float-label">
                                <Calendar
                                    timeOnly
                                    className="w-full"
                                    id={`HoraLunch_${Object.keys(dias)[dia]}`}
                                    name={`HoraLunch_${Object.keys(dias)[dia]}`}
                                    value={formik.values[`HoraLunch_${Object.keys(dias)[dia]}`]}
                                    stepMinute={15}
                                    onChange={async (e) => {
                                        await formik.setFieldValue(`HoraLunch_${Object.keys(dias)[dia]}`, e.value);
                                    }}
                                />
                                <label htmlFor={`HoraLunch_${Object.keys(dias)[dia]}`}>Hora Lunch</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-2">
                            <span className="p-float-label">
                                <h5>Programadas: {formik.values[`HorasProgramadas_${Object.keys(dias)[dia]}`]}</h5>
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="col-12 text-center">
                <h3>Horas Programadas a la semana: {formik.values.HorasSemanales}</h3>
            </div>
        </div>
    );
}
