import React, { useEffect, useState } from "react"
import moment from "moment"
import {size, map} from "lodash"
import {useMasterRoster} from "../../hooks"

export function DashboardSkyRosterPage () {

    const {empleados, getSkyEmpleadosViewPerso} = useMasterRoster()

    const [empleadosCuenta, setEmpleadosCuenta] = useState(null)

    useEffect(() => {
        (async () => {
            await getSkyEmpleadosViewPerso()
        })()
    }, [])

    useEffect(() => {
        if (size(empleados) > 0){

            let empleados_cuenta = {}

            map(empleados, (value, index) => {
                
                let cuenta_nombre = size(value.Cuenta) > 0 ? value.Cuenta.Nombre : value.Cuenta
                let lob_nombre = size(value.Lob) > 0 ? value.Lob.Lob : value.Lob

                empleados_cuenta[cuenta_nombre] += 1

            })

            setEmpleadosCuenta(empleados_cuenta)
        }
    }, [empleados])

    console.log(empleadosCuenta)


    return (
        <div className="grid">
            <div className="col-12">
                <h5>Dashboard SkyRoster</h5>
                <div className="grid">
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Semana Actual</h5>
                            <h3>{moment().week()}</h3>
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Empleados Cuenta</h5>
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <h5>Roster Updates Pendientes</h5>
                        </div>
                    </div>
                    
                </div>

                <div className="grid">
                    <div className="col-12 md:col-6">
                        <h5>Empleados Empleados Lob</h5>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="card">
                                    <h5>Lob</h5>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card"></div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <h5>Roster Update Estados</h5>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="card">
                                    <h5>Pendientes</h5>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card">
                                    <h5>Aprobados</h5>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card">
                                    <h5>Rechazados</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}