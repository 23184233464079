import { BASE_API } from '../../utilities/constants';

export async function getHorariosApi(token) {
    try {
        const url = `${BASE_API}v1/Horario/`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getHorarioApi(token, Empleado, Dia, Badge="", Week="") {
    try {
        const empleadoFilter = `Empleado=${Empleado}`;
        const diaFilter = `Dia=${Dia}`;
        const badgeFilter = `Empleado__Badge=${Badge}`
        const weekFilter = `Empleado__Week=${Week}`

        const url = `${BASE_API}v1/Horario/?${empleadoFilter}&${diaFilter}&${badgeFilter}&${weekFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addHorarioApi(data, token) {
    try {
        const url = `${BASE_API}v1/Horario/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateHorarioApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/Horario/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
