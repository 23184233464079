import React, { useState } from 'react';
import moment from 'moment';
import { map, size } from 'lodash';
import { FilterHoras, TableSkyView } from '../../components/Skytracking';
import { useSkytracking } from '../../hooks';

export function SkytrackingReportPage() {
    const [listMarcasComponent, setListMarcasComponent] = useState(null);

    const { getSkyViewMarcas, loadingSkytracking } = useSkytracking();

    const onResponseForm = async (formData) => {
        let array_of_dict = [];

        for await (const fecha of formData.Fechas) {
            const response_marcas = await getSkyViewMarcas(moment(fecha).format('YYYY-MM-DD'), formData.Cuenta, formData.Lob);

            array_of_dict.push(response_marcas);
        }
        console.log(array_of_dict)
        setListMarcasComponent(array_of_dict);
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte de Horas Supervisor</h5>
                        <FilterHoras
                            requiredFields={{
                                Cuenta: true
                            }}
                            showFields={{
                                RangoFechas: true,
                                Cuenta: true,
                                Lob: true,
                                Supervisor: false
                            }}
                            responseForm={onResponseForm}
                        />
                    </div>
                </div>
            </div>

            {loadingSkytracking ? (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spin pi-cog" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <>
                    {map(listMarcasComponent, (value, index) => (
                        <div className="grid">
                            <div className="col-12">
                                <div className="card">
                                    <h5>{size(value) > 0 ? `Horas para el Dia ${value[0].Fecha}` : "Tabla de Resultados"}</h5>

                                    <TableSkyView ListMarcas={value} key={index} />
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </>
    );
}
