import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { map, filter, size } from 'lodash';
import { useRosterUpdate, useUploadFiles, useMasterRoster, useCommon } from '../../hooks';

export function RosterUpdateViewDetail({ rowData }) {
    const toast = useRef(null);
    const [buttonShow, setButtonShow] = useState(true);
    const [comparativeEmpleado, setComparativeEmpleado] = useState(null);

    const { rosterUpdateDetalles, loadingRosterUpdate, getRosterUpdateDetail, setViewRosterUpdate, getEmpleadoActionRosterUpdate, buttonsValidState } = useRosterUpdate();
    const { loadingFileUpload, insertDetalleEmpleado } = useUploadFiles();
    const { getEmpleadoComparativeRosterUpdate } = useMasterRoster();
    const {getLob, listLob} = useCommon()    

    useEffect(() => {
        (async () => {
            await getRosterUpdateDetail(rowData.Week, rowData.id);
            await getLob("");
            const response_comparation = await getEmpleadoComparativeRosterUpdate(rowData.Badge, rowData.Week);
            setComparativeEmpleado(response_comparation)

            if (rowData.Estado == 0){

                setButtonShow(true);

            }else{

                setButtonShow(false);
            }
        })();
    }, []);

    const onAprobarRosterUpdate = async () => {
        const empleadoRoster_response = await getEmpleadoActionRosterUpdate(rowData.Badge, rowData.Week, rowData.Year);

        await setViewRosterUpdate(rowData.id, 2);

        for await (const detalle of rosterUpdateDetalles) {
            await insertDetalleEmpleado(empleadoRoster_response[0].id, detalle.Propiedad, detalle.Valor);
        }

        toast.current.show({
            severity: 'success',
            summary: 'Exito',
            detail: 'Roster Update Aprobado y Ejecutado',
            life: 3000
        });

        setButtonShow(false);
    };

    const onRechazarRosterUpdate = async () => {
        await setViewRosterUpdate(rowData.id, -1);

        toast.current.show({
            severity: 'info',
            summary: 'Exito',
            detail: 'Roster Update Rechazado',
            life: 3000
        });

        setButtonShow(false);
    };

    return (
        <div className="grid">
            <Toast ref={toast} />
            <div className="col-12">
                <h5>Roster Updata Detalle - {rowData.Badge}</h5>
                <p>Verifica la informacion ingresada por el supervisor para aprobarlas o rechazarla segun sea el caso</p>

                {loadingRosterUpdate ? (
                    <div className="grid">
                        <div className="col-12 text-center">
                            <i className="pi pi-spinner pi-spin" style={{ fontSize: '3rem' }}></i>
                        </div>
                    </div>
                ) : (
                    rosterUpdateDetalles && (
                        <div className="grid mt-5">
                            {map(
                                rosterUpdateDetalles,
                                (roster, index) => {

                                    let class_add = ''
                                    let value_show = ''

                                    const valor_db = filter(comparativeEmpleado, ["Propiedad", roster.Propiedad])

                                    if (size(valor_db) > 0){
                                        (valor_db[0].Valor != roster.Valor) ? class_add = 'text-orange-500' : class_add = ''
                                    }else{
                                        class_add = 'text-green-500'
                                    }

                                    if (roster.Propiedad == 5){
                                        const filter_lob = filter(listLob, ["value", parseInt(roster.Valor)])
                                        value_show = (size(filter_lob) > 0) ? filter_lob[0].name : "";
                                    }else{
                                        value_show = roster.Valor
                                    }


                                    return (
                                        <div className={`col-4 ${class_add}`} key={index}>
                                            <h6>{roster.propiedad_data.Propiedad}</h6>
                                            <p>{value_show}</p>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    )
                )}

                {buttonShow ? (
                    <>
                        {loadingFileUpload && (
                            <div className="grid">
                                <div className="col-12">
                                    <i className="pi pi-spinner pi-spin"></i>
                                </div>
                            </div>
                        )}
                        <div className="grid mt-3">
                            <div className="col-12 text-right">
                                <Button type="button" severity="success" className="mx-3 mb-3" size="large" onClick={onAprobarRosterUpdate}>
                                    Aprobar Roster Update
                                </Button>
                                <Button type="button" severity="danger" className="mx-3 mb-3" size="large" onClick={onRechazarRosterUpdate}>
                                    Rechazar Roster Update
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="grid">
                        <div className="col-12">
                            <h5>Este Roster Update ya esta procesado</h5>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
