import React, { useRef, useState } from 'react';
import { size } from 'lodash';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { BASE_API } from '../../utilities/constants';
import { useUploadFiles } from '../../hooks';

import excel from '../../assets/img/excel.png';
import template from '../../assets/template/TemplateSkyVerseSkyTracking.xlsx';

export function CargarHorasPage() {
    const toast = useRef();
    const [valueProgress, setValueProgress] = useState(0);

    const { insertMarca, insertDetalleMarca, calcAuxMarca, loadingFileUpload } = useUploadFiles();

    const onUpload = async (data) => {
        const response_object = JSON.parse(data.xhr.response);

        let contador = 1;
        let total_row = size(response_object);

        for await (const marca of Object.keys(response_object)) {
            let porcentaje = (contador / total_row) * 100;
            setValueProgress(porcentaje.toFixed(2));

            const marca_destruct = response_object[marca];
            const fecha_str = marca_destruct.fecha_marca.split('T');

            const response_marca = await insertMarca(marca_destruct.badge_marca, fecha_str[0]);

            if (size(response_marca) > 0) {
                const response_marca_detalle = await insertDetalleMarca(marca_destruct.detalle, response_marca[0].id);

                const response_calc_aux = await calcAuxMarca(response_marca[0].id);
                /* const response_calc_aux = await calcAuxMarca_complete(response_marca[0].id); */
            }

            contador += 1;
        }

        toast.current.show({
            severity: 'success',
            summary: 'Horas',
            detail: 'Horas Cargadas Correctamente',
            life: 3000
        });
    };

    const onDownloadTemplate = () => {
        window.open(template);
    };
    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Cargar Horas</h5>
                        <p>
                            Sube un file para poder cargar horas. Puedes incluir todas las pausas que necesites, todas las fechas que necesites y todos los empleados que necesites. el template lo podras encontrar aqui mismo, solo tienes que llenar el
                            formato como se indica y subirlo para cargar la informacion
                        </p>
                        <div className="grid my-5">
                            <div className="col-12">
                                <h5>Progreso %</h5>
                                <ProgressBar value={valueProgress} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 md:col-8">
                    <div className="card">
                        <h5>Formato de carga</h5>
                        <p>Este es el template para poder cargar horas, llena el formato con las columnas que se indica o agrega las pausas que necesites agregar</p>
                        <div className="grid my-3">
                            <div className="col-12">
                                <h5>Template</h5>
                                <Button severity="secondary" outlined onClick={onDownloadTemplate}>
                                    <div className="grid">
                                        <div className="col-12">
                                            <img src={excel} />
                                        </div>
                                        <div className="col-12">
                                            <p>DESCARGAR</p>
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div className="card">
                        <h5>Upload</h5>
                        <p>Anexa el formato de carga aqui y da click en subir para iniciar el proceso</p>
                        <div className="grid my-3">
                            <div className="col-12">
                                <FileUpload mode="basic" name="skytracking_update" maxFileSize={10000000000} url={`${BASE_API}v1/HoursUploadFormat/`} onUpload={onUpload} accept=".xlsx" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
