import React, { useState, useCallback, useEffect } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { BoletaPagoTab, DatosPersonalesTab, ResetPasswordTab } from '../../components/Skyprofile/TabsProfile';

export function UserPerfilPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const [activeIndex, setActiveIndex] = useState(0);

    const checkActiveIndex = useCallback(() => {
        const paths = location.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'PayStub':
                setActiveIndex(1);
                break;
            case 'ResetPassword':
                setActiveIndex(2);
                break;
            default:
                break;
        }
    }, [location]);

    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);

    const wizardItems = [
        { label: 'Datos Personales', command: () => navigate('/Skyprofile') },
        { label: 'Boleta de Pago', command: () => navigate('PayStub') },
        { label: 'Cambiar Contraseña', command: () => navigate('ResetPassword') }
    ];

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Perfil de Usuario</h5>
                    <p>En esta seccion encontraras tu informacion y ajustes que puedes hacer</p>

                    <TabMenu model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                    <Routes>
                        <Route exact="true" path={'/'} element={<DatosPersonalesTab />} />
                        <Route path={'/PayStub'} element={<BoletaPagoTab />} />
                        <Route path={'/ResetPassword'} element={<ResetPasswordTab />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}
