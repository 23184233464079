import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCommon } from '../../hooks';

export function FilterFormAp({ response }) {

    const [listEmpleadosComponente, setListEmpleadoComponente] = useState(null)

    const { loadingCommon, listTiposAp, getTipoAp, getSkyViewEmpleados } = useCommon();

    useEffect(() => {
        (async () => {
            /* await getEmpleados(); */
            await getTipoAp();

            const response_empleados = await getSkyViewEmpleados()
            let format_empleados = []

            for (const empleado of response_empleados){
                format_empleados.push({
                    name: `${empleado.Badge} - ${empleado.NombreEmpleado} - ${empleado.Cuenta}`,
                    value: empleado.id
                })
            }

            setListEmpleadoComponente(format_empleados)
        })();
    }, []);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            response(formValue);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid my-3">
                <div className="col-12 md:col-6">
                    {loadingCommon ? (
                        <i className="pi pi-spinner pi-spin"></i>
                    ) : (
                        listEmpleadosComponente && (
                            <span className="p-float-label">
                                <Dropdown
                                    options={listEmpleadosComponente}
                                    optionLabel="name"
                                    filter
                                    className={`w-full ${formik.errors.Empleado && 'p-invalid'}`}
                                    id="Empleado"
                                    name="Empleado"
                                    value={formik.values.Empleado}
                                    onChange={(e) => formik.setFieldValue('Empleado', e.value)}
                                />
                                <label htmlFor="Empleado">Empleados</label>
                            </span>
                        )
                    )}
                </div>
                <div className="col-12 md:col-6">
                    <span className="p-float-label">
                        <Dropdown
                            options={listTiposAp}
                            optionLabel="name"
                            filter
                            className={`w-full ${formik.errors.TipoAp && 'p-invalid'}`}
                            id="TipoAp"
                            name="TipoAp"
                            value={formik.values.TipoAp}
                            onChange={(e) => formik.setFieldValue('TipoAp', e.value)}
                        />
                        <label htmlFor="TipoAp">Tipo de AP</label>
                    </span>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 text-right">
                    <Button severity="primary" size="large" type="submit">
                        Obtener Formulario
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {
        Empleado: '',
        TipoAp: ''
    };
}

function validationSchema() {
    return {
        Empleado: Yup.string().required(true),
        TipoAp: Yup.string().required(true)
    };
}
