import { BASE_API } from '../../utilities/constants';

export async function getDetalleAplicanteSamsApi(token, IdAp = '') {
    try {
        const url = `${BASE_API}v1/DetalleAplicanteSam/${IdAp}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getReportApplicantApi(token, FechaInicio = '', FechaFinal = '', Estado = '') {
    try {
        const fechaInicio_filter = `FechaInicio=${FechaInicio}`;
        const fechaFinal_filter = `FechaFinal=${FechaFinal}`;
        const estado_filter = `EstadoAplicante=${Estado}`;

        const url = `${BASE_API}v1/ReportApplicantSam/?${fechaInicio_filter}&${fechaFinal_filter}&${estado_filter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addDetalleAplicanteSamApi(data, token) {
    try {
        const url = `${BASE_API}v1/DetalleAplicanteSam/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateDetalleAplicanteSamApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/DetalleAplicanteSam/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
