import React from 'react';

export function RosterWFMPage() {
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Roster para WFM</h5>
                    <p>Puedes hacer cambios masivos del roster actual o programarlos para la semana siguiente</p>
                </div>
            </div>
        </div>
    );
}
