import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RosterUpdateViewDetail } from '../Skyroster';
import { useRosterUpdate } from '../../hooks';

export function TableRosterUpdate({ rosterUpdates }) {
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);

    const { setViewRosterUpdate, buttonsValidState } = useRosterUpdate();

    const rosterUpdate = async (rowData) => {
        const estadoBoton = await buttonsValidState(rowData.Badge);
        if (!estadoBoton) {
            await setViewRosterUpdate(rowData.id, 1);
        }
        setShowModal(true);
        setContentModal(<RosterUpdateViewDetail rowData={rowData} />);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" onClick={() => rosterUpdate(rowData)}>
                    View Roster Update
                </Button>
            </React.Fragment>
        );
    };
    return (
        <>
            <DataTable
                value={rosterUpdates}
                paginator
                rows={25}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
                <Column body={actionBodyTemplate} exportable={false}></Column>
                <Column field="Badge" header="Badge"></Column>
                <Column field="Nombre" header="Nombre Empleado"></Column>
                <Column field="Week" header="Semana"></Column>
                <Column field="Year" header="Año"></Column>
            </DataTable>

            <Dialog header="Skyverse Roster" style={{ width: '70vw' }} visible={showModal} modal onHide={() => setShowModal(false)}>
                {contentModal}
            </Dialog>
        </>
    );
}
