// local URL
//export const BASE_API = "http://localhost:8000/api/";
// server URL
export const BASE_API = 'https://development.skycombpo.com/api/';
// test URL
//export const BASE_API = "https://skyverseapi.solucionescontablessv.com/api/";
export const TOKEN = 'token';

export const dicProp = {
    badge: 1,
    nombre: 2,
    plaza: 6,
    equipoTrabajo: 22,
    fechaIngreso: 14,
    fechaEntrenamiento: 15,
    tieneHijos: 26,
    supervisor: 10,
    accountManager: 11,
    director: 12,
    cuenta: 3,
    lob: 5,
    lob_2: 17,
    lob_3: 18,
    acdId: 7,
    acdName: 8,
    acdSkill: 9,
    pais: 4,
    direccion: 33,
    departamento: 34,
    municipio: 35,
    edificio: 21,
    correoElectronico: 27,
    numeroContacto: 28,
    numeroContacto_2: 29,
    numeroContacto_3: 30,
    nombreEmergencia: 36,
    numeroEmergencia: 31,
    seguroEmergencia: 37,
    DocumentoIdentidad: 58
};

export const propiedadesSia = {
    CodigoComponente: 10,
    DescripcionCorta: 1,
    DescripcionDetallada: 2,
    //FechaCompra: 3,
    //Serial: 4,
    //Garantia: 5,
    Costo: 6,
    NoFactura: 7,
    Proveedor: 8,
    Sociedad: 9
};

export function weekYearDate() {
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);

    return weekNumber;
}
