import { BASE_API } from '../../utilities/constants';

export async function getAsignacionNotificacionsApi(token, BadgeAsignacion='', Estado='', Fecha) {
    try {

        const badgeAsignacionFilter = `BadgeAsignacion=${BadgeAsignacion}`
        const estadoFilter = `Estado=${Estado}`
        const fechaFilter = `Notificacion__created_at__gte=${Fecha} 00:00:00`

        const url = `${BASE_API}v1/AsignacionNotificacion/?${badgeAsignacionFilter}&${estadoFilter}&${fechaFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addAsignacionNotificacionApi(data, token) {
    try {
        const url = `${BASE_API}v1/AsignacionNotificacion/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateAsignacionNotificacionApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/AsignacionNotificacion/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
